import React, { Component } from 'react';
import { Row, Col, CardBody, Card, Collapse, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, FormGroup } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_LENGTH, ACTIVE, SUCCESS, NA } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    getComplianceLegalUpdateSearchRequest,
    getIndustryListByStatusRequest,
    getSubIndustryListByStatusRequest,
    getLawCategoryListByStatusRequest,
    updateAssigngcmLegalUpdateRequest,
    legalUpdateDocumentUploadRequest,
    deleteLegalUpdateByLegalUpdateIdRequest,
    getAllupdateTypeListRequest,
    getAllLegalUpdateStatusListRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { makeLegalUpdateSelectField, selectSortedLegalUpdateStatusList, selectSortedUpdateTypeList } from 'store/UpdateCenter/LegalUpdates/selector';
import LegalUpdateSearchForm from './LegalUpdateSearchForm';
import CustomisedReactTableWithPagination from 'components/Application/CustomisedReactTableWithPagination';
import __t from 'i18n/translator';
import CustomisedCollapseForOne from 'components/Application/CustomisedCollapseForOne';
import { checkModulePermission } from 'helpers/projectUtils';
import ShowMoreText from 'react-show-more-text';
import { PENDING, PENDING_WITH_APPROVER, MODULE_LEGAL_UPDATES, PERMISSION_ADD, APPROVED, PENDING_WITH_SUPER_ADMIN } from 'constants/databaseConstants';
import AuditLogModal from 'components/Application/AuditLogModal';
import legalUpdateReducer from 'store/UpdateCenter/LegalUpdates/reducer';
import legalUpdateSaga from 'store/UpdateCenter/LegalUpdates/saga';
import industryReducer from 'store/Masters/Industry/reducer';
import industrySaga from 'store/Masters/Industry/saga';
import subIndustryReducer from 'store/Masters/SubIndustry/reducer';
import subIndustrySaga from 'store/Masters/SubIndustry/saga';
import { selectSortedIndustryList } from 'store/Masters/Industry/selector';
import { selectSortedSubIndustryList } from 'store/Masters/SubIndustry/selector';
import lawCategoryReducer from 'store/Masters/LawCategory/reducer';
import lawCategorySaga from 'store/Masters/LawCategory/saga';
import { selectSortedLawCategoryList } from 'store/Masters/LawCategory/selector';
import ApproveModal from './ApproveModal';
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
import UploadDocumentForm from './UploadDocumentForm';
import { initialLegalUpdateDocumentsState } from './CommonFunctions';
import { formatDateForApi } from 'helpers/dateTimeHelper';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import { decryptData } from 'helpers/encryptor';
import DownloadFileByModel from 'components/Application/DownloadFileByModel';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import { Option, MultiValue, animatedComponents } from 'helpers/projectUtils';

const legalUpdatesKey = 'legalUpdates';
const withLegalUpdatesReducer = injectReducer({
    key: legalUpdatesKey,
    reducer: legalUpdateReducer,
});
const withLawComplianceSaga = injectSaga({
    key: legalUpdatesKey,
    saga: legalUpdateSaga,
});
const industryKey = 'industry';
const withIndustrySaga = injectSaga({ key: industryKey, saga: industrySaga });

const withIndustryReducer = injectReducer({
    key: industryKey,
    reducer: industryReducer,
});
const subIndustryKey = 'subIndustry';

const withSubIndustrySaga = injectSaga({
    key: subIndustryKey,
    saga: subIndustrySaga,
});

const withSubIndustryReducer = injectReducer({
    key: subIndustryKey,
    reducer: subIndustryReducer,
});

const lawCategoryKey = 'lawCategory';
const withLawCategoryReducer = injectReducer({
    key: lawCategoryKey,
    reducer: lawCategoryReducer,
});
const withLawCategorySaga = injectSaga({
    key: lawCategoryKey,
    saga: lawCategorySaga,
});

const legalUpdateSearchListColumns = [
    {
        Header: 'Update ID',
        TransHeader: 'tables.legal_updates.legal_update_code',
        accessor: 'legalUpdateCode',
    },
    {
        Header: 'Country',
        TransHeader: 'tables.legal_updates.country',
        accessor: 'country',
    },
    {
        Header: 'Area Of Law',
        TransHeader: 'tables.legal_updates.area_of_law',
        accessor: 'area_of_law',
    },
    {
        Header: 'Law Short Name',
        TransHeader: 'tables.legal_updates.law_short_name',
        accessor: 'lawShortName',
    },
    {
        Header: 'Update Type',
        TransHeader: 'tables.legal_updates.update_type',
        accessor: 'updateType',
    },
    {
        Header: 'Compliance Classification',
        TransHeader: 'tables.legal_updates.compliance_classification',
        accessor: 'complianceClassification',
    },

    {
        Header: 'Date of Publication',
        TransHeader: 'tables.legal_updates.date_of_publication',
        accessor: 'dateOfPublication',
    },
    {
        Header: 'Effective Date',
        TransHeader: 'tables.legal_updates.effective_date',
        accessor: 'effectiveDate',
    },
    {
        Header: 'Status',
        TransHeader: 'tables.legal_updates.status',
        accessor: 'status',
    },
    {
        Header: 'role',
        TransHeader: 'tables.legal_updates.role',
        accessor: 'role',
    },
    {
        Header: 'Created On',
        TransHeader: 'tables.legal_updates.created_on',
        accessor: 'createdAt',
    },
    {
        Header: 'Actions',
        TransHeader: 'tables.legal_updates.actions',
        accessor: 'actions',
        disableSortBy: true,
    },
];

const initialSearchDataState = () => {
    return {
        searchText: '',
        subIndustryId: [],
        lawCategoryId: [],
        industryId: [],
        updateTypeId: '',
        complianceCategoryId: '',
        departmentId: '',
        statusId: '',
        priority: '',
        fromDate: '',
        toDate: '',
    };
};

class Index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            activeTab: 'legalUpdateTab',
            viewDialog: true,
            searchLawList: [],
            viewLawSection: false,
            resetTableSection: false,
            lawId: '',
            pageSize: DEFAULT_PAGE_LENGTH,
            pageCount: 0,
            sortBy: {
                key: 'effectiveDate',
                value: 'ASC',
            },
            currentPage: 1,
            searchFormDataActual: initialSearchDataState(),
            searchFormData: initialSearchDataState(),
            totalRecords: 0,
            isAuditModalOpen: false,
            auditId: '',
            legalUpdates: false,
            industryList: [],
            userApproval: false,
            countryId: '',
            compliancesId: '',
            loginUserId: parseInt(getValueByKey(authConstants.USER_ID)),
            userRoles: JSON.parse(decryptData(getValueByKey(authConstants.USER_ROLES))),
            isChecker: false,
            statusType: '',
            actionType: 'Approve',
            isFormOpen: false,
            legalUpdatesDocumentData: initialLegalUpdateDocumentsState(),
            downloadFileUrl: '',
            downloadFileName: ''
        };
        this.handleFetchData = this.handleFetchData.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.toggle = this.toggle.bind(this);
    }
    componentDidMount() {
        this.props.getAllLegalUpdateStatusListRequest()
        this.props.getIndustryListByStatusRequest(ACTIVE);
        this.props.getSubIndustryListByStatusRequest(ACTIVE);
        this.props.getLawCategoryListByStatusRequest(ACTIVE);
        this.getLegalUpdateSearchList(
            this.state.pageSize,
            this.state.currentPage,
            this.state.sortBy,
            this.state.searchFormData,
        );
        this.props.getAllupdateTypeListRequest();

        let checkerRoles = this.state.userRoles.filter(
            (x) => x.roleId == 3 && x.type == 'CHECKER'
        );

        if (checkerRoles.length > 0) {
            this.setState({
                isChecker: true,
            });
        }
    }

    documentFormSubmit = (values) => {
        let formValues = values;
        this.setState({ legalUpdatesDocumentData: values });
        const formData = new FormData();
        formData.append('attachmentFile', formValues.documentFile[0]);
        formData.append('legalUpdateId', values.id);
        this.props.legalUpdateDocumentUploadRequest(formData);
    };

    formSubmit = (values) => {
        let formValues = {
            ...values,
            fromDate:
                values.fromDate != '' ? formatDateForApi(values.fromDate) : '',
            toDate: values.toDate != '' ? formatDateForApi(values.toDate) : '',
        };
        this.setState(
            {
                searchFormDataActual: values,
                searchFormData: formValues,
            },
            () => {
                this.getLegalUpdateSearchList(
                    this.state.pageSize,
                    this.state.currentPage,
                    this.state.sortBy,
                    this.state.searchFormData,
                );
            }
        );
    };
    formSubmitByGrid = (values) => {
        let formValues = {
            ...values
        };
        this.setState(
            {
                searchFormDataActual: values,
                searchFormData: formValues,
            },
            () => {
                this.getLegalUpdateSearchList(
                    this.state.pageSize,
                    this.state.currentPage,
                    this.state.sortBy,
                    this.state.searchFormData,
                );
            }
        );
    };

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState(
                {
                    activeTab: tab,
                },
                () => {
                    switch (tab) {
                        case 'legalUpdateTab':
                            this.getLegalUpdateSearchList(
                                this.state.searchFormData,
                                this.state.pageSize,
                                this.state.currentPage,
                                this.state.sortBy
                            );
                            break;
                        default:
                            break;
                    }
                }
            );
        }
    }
    getLegalUpdateSearchList = (limit, page, sortBy, values = {}) => {
        values = {
            ...values,
        };
        let apiData = {
            limit: limit,
            page: page,
            filter: values,
            orderBy: sortBy,
        };

        this.props.getComplianceLegalUpdateSearchRequest(apiData, true);
    };
    handleFetchData(data) {
        if (
            data.pageIndex + 1 !== this.state.currentPage ||
            this.state.pageSize !== data.pageSize
        ) {
            this.setState(
                {
                    pageSize: data.pageSize,
                    searchFormData: {
                        ...this.state.searchFormData,
                        searchText: data.globalFilter,
                    },
                },
                () => {
                    this.getLegalUpdateSearchList(
                        data.pageSize,
                        data.pageIndex + 1,
                        this.state.sortBy,
                        this.state.searchFormData,
                    );
                }
            );
        }
    }
    handleSort(data) {
        if (
            data.sortBy[0]?.desc != undefined &&
            data.sortBy[0]?.desc !== this.state.sortBy?.value
        ) {
            let sortOrder = data.sortBy[0]?.desc ? 'DESC' : 'ASC';
            this.setState(
                {
                    pageSize: data.pageSize,
                    searchFormData: {
                        ...this.state.searchFormData,
                        searchText: data.globalFilter,
                    },
                    sortBy: { key: data.sortBy[0]?.id, value: sortOrder },
                },
                () => {
                    this.getLegalUpdateSearchList(
                        data.pageSize,
                        data.pageIndex + 1,
                        { key: data.sortBy[0]?.id, value: sortOrder },
                        this.state.searchFormData,
                    );
                }
            );
        }
    }
    handleSearch(data) {
        let pageNum =
            data?.globalFilter == this.state.searchFormData?.searchText
                ? data.pageIndex + 1
                : 1;
        this.setState(
            {
                searchFormData: {
                    ...this.state.searchFormData,
                    searchText: data.globalFilter,
                },
            },
            () => {
                this.getLegalUpdateSearchList(
                    this.state.pageSize,
                    pageNum,
                    this.state.sortBy,
                    this.state.searchFormData,
                );
            }
        );
    }
    resetForm = () => {
        this.setState(
            {
                searchFormData: initialSearchDataState(),
                searchFormDataActual: initialSearchDataState(),
                currentPage: 1,
                resetTableSection: true,
            },
            () => {
                this.getLegalUpdateSearchList({}, this.state.pageSize, 1, {
                    key: 'effectiveDate',
                    value: 'ASC',
                });
            }
        );
    };
    documentFormresetForm = () => {
        this.setState({
            legalUpdatesDocumentData: initialLegalUpdateDocumentsState(),
            isFormOpen: false,
        });
    };

    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
        });
    };
    replaceDocumentation = async (legalUpdatesDocumentData) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.replace_documentation')
        );
        if (response) {
            this.setState(
                {
                    isFormOpen: true,
                    legalUpdatesDocumentData:
                        initialLegalUpdateDocumentsState(),
                },
                () => {
                    this.setLegalUpdatesDocumentData(legalUpdatesDocumentData)
                }
            );
        }
    };

    viewLawDocument(legalUpdatesDocumentData) {
        this.setState(
            {
                isFormOpen: true,
                legalUpdatesDocumentData: initialLegalUpdateDocumentsState(),
            },
            () => {

                this.setLegalUpdatesDocumentData(legalUpdatesDocumentData)
            }
        );
    }
    setLegalUpdatesDocumentData = (data) => {
        this.setState({
            legalUpdatesDocumentData: data,
        });
    }
    saveFile = (uploadedFileData) => {
        this.setState({
            downloadFileName: '',
            downloadFileUrl: ''
        }, () => {
            this.setState({
                downloadFileName: uploadedFileData.fileName,
                downloadFileUrl: uploadedFileData.fileUrl
            })
        });
        // return FileSaver.saveAs(
        //     uploadedFileData.fileUrl,
        //     uploadedFileData.fileName
        // );
    };
    deleteLegalUpdates = async (legalUpdateId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.legal_update_delete')
        );
        if (response) {
            this.props.deleteLegalUpdateByLegalUpdateIdRequest(legalUpdateId);
        }
    };
    getRoleName = (values) => {
        let roleName = '';
        if (values.createdBy === this.state.loginUserId) {
            roleName = 'Checker';
        } else if (values.approverUserId === this.state.loginUserId) {
            roleName = 'Approver';
        } else if (values.updaterUserId === this.state.loginUserId) {
            roleName = 'Updater';
        } else if (values.superAdminId === this.state.loginUserId) {
            roleName = 'SUPER ADMIN';
        }
        return roleName;
    };
    getShowMoreTextElement = (element) => {
        return (
            <ShowMoreText
                lines={2}
                more="Show more"
                less="Show less"
                className="show-more-content"
                anchorClass="show-more-link"
                expandByClick={true}
                expanded={false}
                keepNewLines={true}
            >
                {`` + element + '\n'}
            </ShowMoreText>
        );
    };
    editButtonShowHide = (value) => {
        let status = false;
        if (
            (value.legalUpdateStatusId === PENDING_WITH_APPROVER &&
                value.approverUserId === this.state.loginUserId) ||
            (value.legalUpdateStatusId === APPROVED &&
                value.updaterUserId === this.state.loginUserId) ||
            (value.legalUpdateStatusId === PENDING_WITH_SUPER_ADMIN &&
                value.superAdminId === this.state.loginUserId)
        ) {
            status = true;
        }


        return status;
    };
    getUpdateTypeName = (value) => {
        let data = this.props.updateTypeList
            ?.filter((x) => value?.includes(x.value))
            ?.map((y) => y.label);
        if (data.length == 1) {
            return data.map((x) => {
                return x;
            });
        }
        return (
            <ul className="update_type_list">
                {data.map((x) => {
                    return (
                        <>
                            <li>{x}</li>
                        </>
                    );
                })}
            </ul>
        );
    };
    generateTable = (records) => {
        let actsRecords = [];

        actsRecords = records.map((legalUpdatesRecord, i) => {
            let actions = (
                <div className="text-center action-d-box">
                    <a
                        href={() => false}
                        title="View Details"
                        className="btn btn-link text-success p-0"
                        onClick={() => {
                            this.props.history.push(
                                '/legal-update/view-details/' +
                                legalUpdatesRecord.id,
                                '_blank'
                            );
                        }}
                    >
                        {legalUpdatesRecord.isClosed ? <i className="far fa-eye m-0"></i> : <i className="fas fa-file-import m-0"></i>}
                    </a>
                    {
                        legalUpdatesRecord.isClosed ? <a
                            href={() => false}
                            title="Content Updated"
                            className="btn btn-link text-success p-0"
                        >
                            <i className="far fa-flag m-0"></i>
                        </a> : <a
                            href={() => false}
                            title="Content Not Updated"
                            className="btn btn-link text-danger p-0"
                        >
                            <i className="far fa-clock m-0"></i>
                        </a>
                    }
                    <Dropdown className="legal-updates-dropdown" tag="div">
                        <DropdownToggle
                            tag="a"
                            title="Action"
                            className="btn btn-link"
                        >
                            <svg
                                width="20"
                                height="4"
                                viewBox="0 0 20 4"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M16 2C16 1.60444 16.1173 1.21776 16.3371 0.88886C16.5568 0.559962 16.8692 0.303617 17.2346 0.152242C17.6001 0.000866562 18.0022 -0.0387401 18.3902 0.0384303C18.7781 0.115601 19.1345 0.306082 19.4142 0.585787C19.6939 0.865492 19.8844 1.22186 19.9616 1.60982C20.0387 1.99778 19.9991 2.39992 19.8478 2.76537C19.6964 3.13082 19.44 3.44318 19.1111 3.66294C18.7822 3.8827 18.3956 4 18 4C17.4696 4 16.9609 3.78929 16.5858 3.41421C16.2107 3.03914 16 2.53043 16 2V2ZM8 2C8 1.60444 8.1173 1.21776 8.33706 0.88886C8.55682 0.559962 8.86918 0.303617 9.23463 0.152242C9.60008 0.000866562 10.0022 -0.0387401 10.3902 0.0384303C10.7781 0.115601 11.1345 0.306082 11.4142 0.585787C11.6939 0.865492 11.8844 1.22186 11.9616 1.60982C12.0387 1.99778 11.9991 2.39992 11.8478 2.76537C11.6964 3.13082 11.44 3.44318 11.1111 3.66294C10.7822 3.8827 10.3956 4 10 4C9.46957 4 8.96086 3.78929 8.58579 3.41421C8.21071 3.03914 8 2.53043 8 2V2ZM0 2C0 1.60444 0.117298 1.21776 0.337061 0.88886C0.556824 0.559962 0.869181 0.303617 1.23463 0.152242C1.60009 0.000866562 2.00222 -0.0387401 2.39018 0.0384303C2.77814 0.115601 3.13451 0.306082 3.41421 0.585787C3.69392 0.865492 3.8844 1.22186 3.96157 1.60982C4.03874 1.99778 3.99913 2.39992 3.84776 2.76537C3.69638 3.13082 3.44004 3.44318 3.11114 3.66294C2.78224 3.8827 2.39556 4 2 4C1.46957 4 0.960859 3.78929 0.585786 3.41421C0.210714 3.03914 0 2.53043 0 2H0Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu">
                            {legalUpdatesRecord.isClosed === 1 && (
                                <DropdownItem
                                    tag="a"
                                    title={__t('form_labels.relevant_updates.view_analysis')}
                                    className="notify-all"
                                    onClick={() => {
                                        this.props.history.push('/view-update-analysis/' + legalUpdatesRecord.id);
                                    }}
                                >
                                    <i className="fas fa-history"></i>
                                    {__t('form_labels.relevant_updates.view_analysis')}
                                </DropdownItem>
                            )}

                            {this.editButtonShowHide(legalUpdatesRecord) ==
                                true && (
                                    <DropdownItem
                                        tag="a"
                                        title={__t('common.edit')}
                                        className="notify-all"
                                        onClick={() => {
                                            this.props.history.push(
                                                '/legal-update/edit/' +
                                                legalUpdatesRecord.id,
                                                '_blank'
                                            );
                                        }}
                                    >
                                        <i className="fas fa-pencil-alt"></i>
                                        {__t('common.edit')}
                                    </DropdownItem>
                                )}

                            <DropdownItem
                                tag="a"
                                className="notify-all"
                                title={__t('common.upload_file')}
                                onClick={() => {
                                    if (legalUpdatesRecord.documentUrl) {
                                        this.replaceDocumentation(
                                            legalUpdatesRecord
                                        );
                                    } else {
                                        this.viewLawDocument(
                                            legalUpdatesRecord
                                        );
                                    }
                                }}
                            >
                                <i className="fas fa-upload"></i>
                                Upload file
                            </DropdownItem>
                            {legalUpdatesRecord.documentUrl && (
                                <DropdownItem
                                    tag="a"
                                    title={__t('common.download_file')}
                                    className="btn btn-link text-primary tbl-action notify-all"
                                    onClick={() => {
                                        this.saveFile({
                                            id: legalUpdatesRecord.id,
                                            fileName:
                                                legalUpdatesRecord.documentName,
                                            fileUrl:
                                                legalUpdatesRecord.documentUrl,
                                        });
                                    }}
                                >
                                    <i className="fas fa-download"></i>
                                    Download file
                                </DropdownItem>
                            )}
                            {this.state.isChecker === true &&
                                legalUpdatesRecord.createdBy ===
                                this.state.loginUserId &&
                                legalUpdatesRecord.legalUpdateStatusId ===
                                PENDING && (
                                    <DropdownItem
                                        title={__t('common.delete')}
                                        tag="a"
                                        className="notify-all"
                                        onClick={() => {
                                            this.deleteLegalUpdates(
                                                legalUpdatesRecord.id
                                            );
                                        }}
                                    >
                                        <i className="fas fa-trash-alt"></i>
                                        Delete
                                    </DropdownItem>
                                )}
                        </DropdownMenu>
                    </Dropdown>
                </div>
            );

            const roleNameInfo = this.getRoleName(legalUpdatesRecord);
            let roleName =
                roleNameInfo != '' ? (
                    <div className="badge role-badge"> {roleNameInfo}</div>
                ) : (
                    '-'
                );

            return {
                legalUpdateCode: legalUpdatesRecord.legalUpdateCode,
                country: legalUpdatesRecord.country.countryName,
                area_of_law: legalUpdatesRecord.lawCategory.lawCategoryName,
                lawShortName: legalUpdatesRecord.law
                    ? legalUpdatesRecord.law.lawShortName
                    : NA,
                updateType: this.getUpdateTypeName(
                    legalUpdatesRecord.updateTypeId
                ),
                complianceClassification: legalUpdatesRecord?.complianceCategory
                    ?.complianceCategoryName
                    ? legalUpdatesRecord?.complianceCategory
                        ?.complianceCategoryName
                    : NA,
                dateOfPublication: legalUpdatesRecord?.dateOfPublication,
                effectiveDate: legalUpdatesRecord?.effectiveDate
                    ? legalUpdatesRecord?.effectiveDate
                    : NA,
                status: legalUpdatesRecord?.legalUpdateStatus?.statusName,
                role: roleName,
                createdAt: legalUpdatesRecord.createdAt,
                actions: actions,
            };
        });
        return actsRecords;
    };

    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.sortedIndustryListDropdown &&
            this.props.sortedIndustryListDropdown !==
            nextprops.sortedIndustryListDropdown
        ) {
            this.setState({
                industryList: nextprops.sortedIndustryListDropdown,
            });
        }
        if (
            nextprops.sortedSubIndustryListDropdown &&
            this.props.sortedSubIndustryListDropdown !==
            nextprops.sortedSubIndustryListDropdown
        ) {
            this.setState({
                subIndustryList: nextprops.sortedSubIndustryListDropdown,
            });
        }
        if (
            nextprops.sortedLawCategoryListDropdown &&
            this.props.sortedLawCategoryListDropdown !==
            nextprops.sortedLawCategoryListDropdown
        ) {
            this.setState({
                lawCategoryList: nextprops.sortedLawCategoryListDropdown,
            });
        }

        if (
            nextprops.complianceLegalUpdateSearchApiResponse &&
            this.props.complianceLegalUpdateSearchApiResponse !==
            nextprops.complianceLegalUpdateSearchApiResponse
        ) {
            this.setState({
                resetTableSection: false,
                legalUpdates: false,
                sortBy: nextprops.complianceLegalUpdateSearchApiResponse.data
                    .orderBy,
                pageCount:
                    nextprops.complianceLegalUpdateSearchApiResponse.data
                        .totalPages,
                currentPage:
                    nextprops.complianceLegalUpdateSearchApiResponse.data
                        .currentPage,
                totalRecords:
                    nextprops.complianceLegalUpdateSearchApiResponse.data
                        .totalRecords,
                searchLawList: this.generateTable(
                    nextprops.complianceLegalUpdateSearchApiResponse.data.list
                ),
            });
        }

        if (
            nextprops.assignGcmLegalUpdateApiResponse &&
            this.props.assignGcmLegalUpdateApiResponse !==
            nextprops.assignGcmLegalUpdateApiResponse
        ) {
            if (
                nextprops.assignGcmLegalUpdateApiResponse.responseType ===
                SUCCESS
            ) {
                this.getLegalUpdateSearchList(
                    this.state.pageSize,
                    this.state.currentPage,
                    this.state.sortBy,
                    this.state.searchFormData,
                );
            }
        }

        if (
            nextprops.legalUpdatesDocumentApiResponse &&
            this.props.legalUpdatesDocumentApiResponse !==
            nextprops.legalUpdatesDocumentApiResponse
        ) {
            if (
                nextprops.legalUpdatesDocumentApiResponse.responseType ===
                SUCCESS
            ) {
                this.getLegalUpdateSearchList(
                    this.state.pageSize,
                    this.state.currentPage,
                    this.state.sortBy,
                    this.state.searchFormData,
                );
            }
        }

        if (
            nextprops.complianceLegalUpdateDeleteApiResponse &&
            this.props.complianceLegalUpdateDeleteApiResponse !==
            nextprops.complianceLegalUpdateDeleteApiResponse
        ) {
            toastrMessage(
                nextprops.complianceLegalUpdateDeleteApiResponse.message,
                nextprops.complianceLegalUpdateDeleteApiResponse.responseType
            );
            if (
                nextprops.complianceLegalUpdateDeleteApiResponse
                    .responseType === SUCCESS
            ) {
                this.getLegalUpdateSearchList(
                    this.state.pageSize,
                    this.state.currentPage,
                    this.state.sortBy,
                    this.state.searchFormData,
                );
            }
        }
    }

    closeUserApprovalModal = () => {
        this.setState({
            //resetTableSection: true,
            userApproval: false,
            actionType: 'Approve',
        });
    };
    render() {
        const data = {
            columns: legalUpdateSearchListColumns,
            rows: this.state.searchLawList,
        };

        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <CommonBreadcrum type={'legal-updates'} />
                    </Col>
                    <Col lg={12} className="viewlaws_tabs">
                        {this.state.legalUpdates === false ? (
                            <Row>
                                {!this.state.viewLawSection && (
                                    <Col lg="12">
                                        <CustomisedCollapseForOne
                                            className="accordion"
                                            viewDialog={false}
                                            dialogName={__t(
                                                'page_headers.advance_filters'
                                            )}
                                        >
                                            <CardBody className="px-4 pt-4 pb-2">
                                                <Collapse
                                                    isOpen={
                                                        this.state.viewDialog
                                                    }
                                                >
                                                    <LegalUpdateSearchForm
                                                        searchCompliancesFormData={
                                                            this.state
                                                                .searchFormDataActual
                                                        }
                                                        onFormSubmit={(
                                                            searchFormData
                                                        ) => {
                                                            this.formSubmit(
                                                                searchFormData
                                                            );
                                                        }}
                                                        onResetForm={() => {
                                                            this.resetForm();
                                                        }}
                                                    />
                                                </Collapse>
                                            </CardBody>
                                        </CustomisedCollapseForOne>
                                    </Col>
                                )}
                                {!this.state.viewLawSection &&
                                    !this.state.resetTableSection && (
                                        <Col lg="12">
                                            <Card>
                                                <CardBody className="custom_table table-responsive">
                                                    <div className="legal-update-status">
                                                        <FormGroup className='mb-0'>
                                                            <CustomMultiSelect
                                                                component={CustomMultiSelect}
                                                                name="statusId[]"
                                                                className="form-control selectbox"
                                                                options={this.props.legalUpdateStatusList}
                                                                onChange={(opt) => {
                                                                    let selectedValues = opt ? opt.map((x) => x.value) : [];
                                                                    this.formSubmitByGrid({ ...this.state.searchFormData, statusId: selectedValues });
                                                                }}
                                                                value={this.props.legalUpdateStatusList?.filter(
                                                                    (option) => this.state.searchFormData.statusId?.includes(option.value)
                                                                )}
                                                                isMulti
                                                                closeMenuOnSelect={false}
                                                                hideSelectedOptions={false}
                                                                allowSelectAll={true}
                                                                components={{
                                                                    Option,
                                                                    MultiValue,
                                                                    animatedComponents,
                                                                }}
                                                            />
                                                            <Label className="form-label">
                                                                {__t(
                                                                    'form_labels.legal_update.filter_status'
                                                                )}{' '}
                                                            </Label>
                                                        </FormGroup>
                                                        {checkModulePermission(
                                                            MODULE_LEGAL_UPDATES,
                                                            PERMISSION_ADD
                                                        ) === true &&
                                                            this.state.isChecker ===
                                                            true && (
                                                            <a
                                                                href={() => false}
                                                                className="btn btn-primary btn-sm waves-effect waves-light float-right py-2"
                                                                onClick={() => {
                                                                    this.props.history.push(
                                                                        '/legal-updates/upload'
                                                                    );
                                                                }}
                                                            >
                                                                <i className="fas fa-file-excel">
                                                                    {' '}
                                                                </i>{' '}
                                                                {__t(
                                                                    'form_labels.legal_update.upload_legal_update_file'
                                                                )}
                                                            </a>
                                                        )}
                                                    </div>
                                                    <CustomisedReactTableWithPagination
                                                        // ref={this.myRef}
                                                        className="table table-bordered table-striped"
                                                        displayEntries={true}
                                                        searching={true}
                                                        currentPage={
                                                            this.state
                                                                .currentPage
                                                        }
                                                        pageCount={
                                                            this.state.pageCount
                                                        }
                                                        handleFetchData={
                                                            this.handleFetchData
                                                        }
                                                        handleSort={
                                                            this.handleSort
                                                        }
                                                        handleSearch={
                                                            this.handleSearch
                                                        }
                                                        preFilledSearchText={
                                                            this.state
                                                                .searchFormData
                                                                ?.searchText
                                                        }
                                                        tableData={data}
                                                        totalRecords={
                                                            this.state
                                                                .totalRecords
                                                        }
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    )}
                            </Row>
                        ) : (
                            ''
                        )}

                        <AuditLogModal
                            exportBtn={true}
                            isModalOpen={this.state.isAuditModalOpen}
                            modalName={__t('page_headers.audit_trails')}
                            auditType={
                                this.state.legalUpdates === false
                                    ? 'law'
                                    : 'legalUpdates'
                            }
                            auditId={this.state.auditId}
                            onModalDismiss={() => this.closeAuditLogModal()}
                        />
                        <ApproveModal
                            isModalOpen={this.state.userApproval}
                            onModalDismiss={() => this.closeUserApprovalModal()}
                            modalName={
                                this.state.actionType == 'Approve'
                                    ? this.state.statusType ==
                                        PENDING_WITH_APPROVER
                                        ? __t(
                                            'tables.legal_updates.assign_updater'
                                        )
                                        : __t(
                                            'tables.legal_updates.assign_approver'
                                        )
                                    : __t(
                                        'tables.legal_updates.reject_compliance'
                                    )
                            }
                            countryId={this.state.countryId}
                            compliancesId={this.state.compliancesId}
                            statusType={this.state.statusType}
                            actionType={this.state.actionType}
                        />
                    </Col>
                </Row>
                <UploadDocumentForm
                    isModalOpen={this.state.isFormOpen}
                    legalUpdatesDocumentData={
                        this.state.legalUpdatesDocumentData
                    }
                    onFormValid={this.documentFormSubmit}
                    onModalDismiss={this.documentFormresetForm}
                />
                <DownloadFileByModel
                    modelType='legalUpdateDocument'
                    downloadFileName={this.state.downloadFileName}
                    downloadFileUrl={this.state.downloadFileUrl}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    complianceLegalUpdateSearchApiResponse: makeLegalUpdateSelectField(
        'complianceLegalUpdateSearchApiResponse'
    ),
    sortedIndustryListDropdown: selectSortedIndustryList(),
    sortedSubIndustryListDropdown: selectSortedSubIndustryList(),
    sortedLawCategoryListDropdown: selectSortedLawCategoryList(),
    assignGcmLegalUpdateApiResponse: makeLegalUpdateSelectField(
        'assignGcmLegalUpdateApiResponse'
    ),
    legalUpdatesDocumentApiResponse: makeLegalUpdateSelectField(
        'legalUpdatesDocumentApiResponse'
    ),
    complianceLegalUpdateDeleteApiResponse: makeLegalUpdateSelectField(
        'complianceLegalUpdateDeleteApiResponse'
    ),
    updateTypeList: selectSortedUpdateTypeList('updateTypeList'),
    legalUpdateStatusList: selectSortedLegalUpdateStatusList('legalUpdateStatusList'),
});

const withConnect = connect(mapStatetoProps, {
    getComplianceLegalUpdateSearchRequest,
    getIndustryListByStatusRequest,
    getSubIndustryListByStatusRequest,
    getLawCategoryListByStatusRequest,
    updateAssigngcmLegalUpdateRequest,
    legalUpdateDocumentUploadRequest,
    deleteLegalUpdateByLegalUpdateIdRequest,
    getAllupdateTypeListRequest,
    getAllLegalUpdateStatusListRequest
});
export default compose(
    withConnect,
    withLegalUpdatesReducer,
    withLawComplianceSaga,
    withLawComplianceSaga,
    withIndustryReducer,
    withIndustrySaga,
    withSubIndustryReducer,
    withSubIndustrySaga,
    withLawCategoryReducer,
    withLawCategorySaga,
    withRouter
)(Index);
