import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import __t from 'i18n/translator';
import { getComplianceLegalUpdateDetailsByIdRequest, getLawDefinitionVersionsListRequest, getLawDocumentVersionsListRequest } from 'store/actions';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import legalUpdateReducer from 'store/UpdateCenter/LegalUpdates/reducer';
import legalUpdateSaga from 'store/UpdateCenter/LegalUpdates/saga';
import lawDocumentReducer from 'store/LawDocument/reducer';
import lawDocumentSaga from 'store/LawDocument/saga';
import lawDefinitionReducer from 'store/LawDefinition/reducer';
import lawDefinitionSaga from 'store/LawDefinition/saga'
import { ACTIVE_LABEL, INACTIVE_LABEL, SUCCESS } from 'constants/commonConstants';
import { toastrMessage } from 'helpers/messageHelper';
import { makeLegalUpdateSelectField } from 'store/UpdateCenter/LegalUpdates/selector';
import DiffViewer, { DiffMethod } from 'react-diff-viewer';
import { Card, CardBody, Col, Collapse, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { makeLawDefinitionSelectField } from 'store/LawDefinition/selector';
import { DEACTIVATION, DEACTIVATION_DEFINITION, DEACTIVATION_DOCUMENT, MODIFICATIONS_UPDATE, SUB_UPDATE_DEFINITIONS, SUB_UPDATE_DOCUMENTS } from 'constants/databaseConstants';
import { makeLawDocumentSelectField } from 'store/LawDocument/selector';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import { isGTROLE } from 'helpers/projectUtils';

const legalUpdatesKey = 'legalUpdates';
const withLegalUpdatesReducer = injectReducer({
    key: legalUpdatesKey,
    reducer: legalUpdateReducer,
});
const withLegalUpdatesSaga = injectSaga({
    key: legalUpdatesKey,
    saga: legalUpdateSaga,
});
const lawDefinitionKey = 'lawDefinition';
const withLawDefinitionReducer = injectReducer({
    key: lawDefinitionKey,
    reducer: lawDefinitionReducer,
});
const withLawDefinitionSaga = injectSaga({
    key: lawDefinitionKey,
    saga: lawDefinitionSaga,
});
const lawDocumentKey = 'lawDocument';
const withLawDocumentReducer = injectReducer({
    key: lawDocumentKey,
    reducer: lawDocumentReducer,
});
const withLawDocumentSaga = injectSaga({
    key: lawDocumentKey,
    saga: lawDocumentSaga,
});

class RelevantUpdateAnalysis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.updateId,
            legalUpdateData: {},
            activeTab: 'complianceAnalysisTab',
            loginUserId: parseInt(getValueByKey(authConstants.USER_ID)),
            updateAnalysis: {
                lawFields: [],
                complianceFields: [],
            },
            collapseDefinitionId: '',
            lawDefinitionVersionsList: [],
            collapseDocumentId: '',
            lawDocumentVersionsList: [],
        };
        this.toggle = this.toggle.bind(this);
        this.toggleDefinition = this.toggleDefinition.bind(this);
        this.toggleDocument = this.toggleDocument.bind(this);
    }

    componentDidMount() {
        this.props.getComplianceLegalUpdateDetailsByIdRequest(this.state.id);
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }
    toggleDefinition(e) {
        let definition = e.target.dataset.definition;
        this.setState({
            collapseDefinitionId:
                this.state.collapseDefinitionId == Number(definition)
                    ? 0
                    : Number(definition),
        });
    }
    toggleDocument(e) {
        let document = e.target.dataset.document;
        this.setState({
            collapseDocumentId:
                this.state.collapseDocumentId == Number(document)
                    ? 0
                    : Number(document),
        });
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.compliancelegalUpdateDetailsApiResponse &&
            this.props.compliancelegalUpdateDetailsApiResponse !==
            nextprops.compliancelegalUpdateDetailsApiResponse
        ) {
            if (
                nextprops.compliancelegalUpdateDetailsApiResponse
                    .responseType === SUCCESS
            ) {
                let oldDefinitionVersionIds = [], newDefinitionVersionIds = [], oldDocumentVersionIds = [], newDocumentVersionIds = [];
                nextprops.compliancelegalUpdateDetailsApiResponse.data.updatedLawDefinitions.forEach((uld) => {
                    oldDefinitionVersionIds.push(uld.oldVersionId);
                    newDefinitionVersionIds.push(uld.newVersionId);
                });
                nextprops.compliancelegalUpdateDetailsApiResponse.data.updatedLawDocuments.forEach((uld) => {
                    oldDocumentVersionIds.push(uld.oldVersionId);
                    newDocumentVersionIds.push(uld.newVersionId);
                });
                this.setState({
                    legalUpdateData:
                        nextprops.compliancelegalUpdateDetailsApiResponse.data,
                    updateAnalysis: {
                        lawFields: JSON.parse(
                            nextprops.compliancelegalUpdateDetailsApiResponse
                                .data.lawFields
                        ),
                        complianceFields: JSON.parse(
                            nextprops.compliancelegalUpdateDetailsApiResponse
                                .data.complianceFields
                        ),
                    }
                }, () => {
                    this.props.getLawDefinitionVersionsListRequest([...oldDefinitionVersionIds, ...newDefinitionVersionIds]);
                    this.props.getLawDocumentVersionsListRequest([...oldDocumentVersionIds, ...newDocumentVersionIds]);
                });
            } else {
                toastrMessage(
                    nextprops.compliancelegalUpdateDetailsApiResponse.message,
                    nextprops.compliancelegalUpdateDetailsApiResponse
                        .responseType
                );
            }
        }
        if (
            nextprops.lawDefinitionVersionsList &&
            this.props.lawDefinitionVersionsList !== nextprops.lawDefinitionVersionsList
        ) {
            this.setState({
                lawDefinitionVersionsList: nextprops.lawDefinitionVersionsList
            });
        }
        if (
            nextprops.lawDocumentVersionsList &&
            this.props.lawDocumentVersionsList !== nextprops.lawDocumentVersionsList
        ) {
            this.setState({
                lawDocumentVersionsList: nextprops.lawDocumentVersionsList
            });
        }

    }
    render() {
        let definitionCols = ['section', 'rule', 'definition', 'definitionRemarks', 'isActive'];
        let definitionLabels = ['Section', 'Rule', 'Definition', 'Cross Referencing (If Any)', 'Active/Inactive'];
        let documentCols = ['documentType', 'referenceNo', 'releaseDate', 'effectiveDate', 'documentDescription', 'briefNote', 'isActive'];
        let documentLabels = ['Document Type', 'Reference No', 'Date of Publication', 'Effective Date', 'Description of the Document', 'Brief Notes (If Any)', 'Active/Inactive'];

        let lawDefinitionsTab = false;
        let lawDocumentsTab = false;
        if ((this.state.legalUpdateData?.updateTypeId?.includes(MODIFICATIONS_UPDATE) || this.state.legalUpdateData?.updateTypeId?.includes(DEACTIVATION)) && (this.state.legalUpdateData?.subUpdateTypeId?.includes(SUB_UPDATE_DEFINITIONS) || this.state.legalUpdateData?.subUpdateTypeId?.includes(DEACTIVATION_DEFINITION))) {
            lawDefinitionsTab = true;
        }
        if ((this.state.legalUpdateData?.updateTypeId?.includes(MODIFICATIONS_UPDATE) || this.state.legalUpdateData?.updateTypeId?.includes(DEACTIVATION)) && (this.state.legalUpdateData?.subUpdateTypeId?.includes(SUB_UPDATE_DOCUMENTS) || this.state.legalUpdateData?.subUpdateTypeId?.includes(DEACTIVATION_DOCUMENT))) {
            lawDocumentsTab = true;
        }
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <CommonBreadcrum type={'relevant-updates-analysis'} />
                    </Col>
                </Row>
                <div className="relevant_update">
                    <div className="page-header mb-2">
                        <h4>
                            {this.props.history.length != 1 && (
                                <a
                                    href={() => false}
                                    className="dripicons-arrow-thin-left"
                                    onClick={() => {
                                        this.props.history.goBack();
                                    }}
                                >
                                    { }
                                </a>
                            )}
                            <span>
                                {this.state.legalUpdateData?.legalUpdateCode} -{' '}
                                {__t('page_headers.update_analysis')}
                            </span>
                        </h4>
                    </div>

                    <Nav tabs className="nav-tabs-custom nav-justified">
                        {this.state.legalUpdateData?.editableType == 2 && (
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            this.state.activeTab ===
                                            'complianceAnalysisTab',
                                    })}
                                    onClick={() => {
                                        this.toggle('complianceAnalysisTab');
                                    }}
                                >
                                    Compliance Analysis
                                </NavLink>
                            </NavItem>
                        )}
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active:
                                        this.state.activeTab ===
                                        'lawAnalysisTab',
                                })}
                                onClick={() => {
                                    this.toggle('lawAnalysisTab');
                                }}
                            >
                                Law Analysis
                            </NavLink>
                        </NavItem>
                        {lawDefinitionsTab === true && (
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            this.state.activeTab ===
                                            'definitionAnalysisTab',
                                    })}
                                    onClick={() => {
                                        this.toggle('definitionAnalysisTab');
                                    }}
                                >
                                    Definition Analysis
                                </NavLink>
                            </NavItem>
                        )}
                        {lawDocumentsTab === true && (
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            this.state.activeTab ===
                                            'standardDocumentAnalysisTab',
                                    })}
                                    onClick={() => {
                                        this.toggle('standardDocumentAnalysisTab');
                                    }}
                                >
                                    Related Document Analysis
                                </NavLink>
                            </NavItem>
                        )}

                    </Nav>

                    <TabContent
                        className="relevant-update-dtl"
                        activeTab={this.state.activeTab}
                    >
                        <TabPane className="" tabId="lawAnalysisTab">
                            <Card>
                                <CardBody>
                                    {/* {Start Law Update Analysis} */}
                                    <h6 class="mt-0">Law Analysis</h6>
                                    {this.state.updateAnalysis?.lawFields?.map(
                                        (lawField, key) => {
                                            let oldValue = lawField.oldValue.replace(/(\r\n|\n|\r)/gm, " ");
                                            oldValue = oldValue.replace(/\s{2,}/gm, " ");
                                            let newValue = lawField.newValue.replace(/(\r\n|\n|\r)/gm, " ");
                                            newValue = newValue.replace(/\s{2,}/gm, " ");
                                            return (
                                                <Card key={'law_' + key}>
                                                    <CardBody>
                                                        <label>
                                                            {key + 1}){' '}
                                                            {lawField.key}
                                                        </label>
                                                        <p>
                                                            <DiffViewer
                                                                oldValue={
                                                                    oldValue
                                                                }
                                                                newValue={
                                                                    newValue
                                                                }
                                                                splitView={true}
                                                                compareMethod={
                                                                    DiffMethod.WORDS
                                                                }
                                                                hideLineNumbers={
                                                                    true
                                                                }
                                                            />
                                                        </p>
                                                    </CardBody>
                                                </Card>
                                            );
                                        }
                                    )}
                                    {/* {End Law Update Analysis} */}
                                </CardBody>
                            </Card>
                        </TabPane>
                        <TabPane className="" tabId="complianceAnalysisTab">
                            <Card>
                                <CardBody>
                                    {/* {Start Compliance Update Analysis} */}
                                    <h6 class="mt-0">Compliance Analysis</h6>
                                    {this.state.updateAnalysis?.complianceFields?.map(
                                        (complianceField, key) => {
                                            let showKey = true;
                                            if (!isGTROLE() && (complianceField.key === 'Verbatim (Section / Reference)' || complianceField.key === 'Verbatim (Rule/Reference)' || complianceField.key === 'Type Of Entity' || complianceField.key === 'Type Of Facility' || complianceField.key === 'Applicability' || complianceField.key === 'Sub Applicability')) {
                                                showKey = false
                                            }
                                            if (showKey) {
                                                let oldValue = complianceField.oldValue.replace(/(\r\n|\n|\r)/gm, " ");
                                                oldValue = oldValue.replace(/\s{2,}/gm, " ");
                                                let newValue = complianceField.newValue.replace(/(\r\n|\n|\r)/gm, " ");
                                                newValue = newValue.replace(/\s{2,}/gm, " ");
                                                return (
                                                    <Card key={'cmp' + key}>
                                                        <CardBody>
                                                            <label>
                                                                {key + 1}){' '}
                                                                {
                                                                    complianceField.key
                                                                }
                                                            </label>
                                                            <p>
                                                                <DiffViewer
                                                                    oldValue={
                                                                        oldValue
                                                                    }
                                                                    newValue={
                                                                        newValue
                                                                    }
                                                                    splitView={true}
                                                                    compareMethod={
                                                                        DiffMethod.WORDS
                                                                    }
                                                                    hideLineNumbers={
                                                                        true
                                                                    }
                                                                />
                                                            </p>
                                                        </CardBody>
                                                    </Card>
                                                );
                                            }
                                        }
                                    )}
                                    {/* {End Compliance Update Analysis} */}
                                </CardBody>
                            </Card>
                        </TabPane>
                        {lawDefinitionsTab === true && (
                            <TabPane className="" tabId="definitionAnalysisTab">
                                <Card className='m-0'>
                                    <CardBody>
                                        {/* {Start Definition Update Analysis} */}
                                        <h6 class="mt-0">Definition Analysis</h6>
                                        {/* <h3>Updated Definitions</h3> */}
                                        <ul className='list-styled pl-0 relevant-update-list'>
                                            {this.state.lawDefinitionVersionsList.length > 0 && this.state.legalUpdateData?.updatedLawDefinitions && this.state.legalUpdateData?.updatedLawDefinitions.length > 0 && this.state.legalUpdateData?.updatedLawDefinitions.map((updatedLawDefinition, idx) => {
                                                let oldLawDefinitionVersion = this.state.lawDefinitionVersionsList.find((l) => l.id === updatedLawDefinition.oldVersionId);
                                                let newLawDefinitionVersion = this.state.lawDefinitionVersionsList.find((l) => l.id === updatedLawDefinition.newVersionId);
                                                if (oldLawDefinitionVersion && newLawDefinitionVersion) {
                                                    let definitionKeys = Object.keys(oldLawDefinitionVersion).filter((dkey) => definitionCols.includes(dkey));
                                                    return (
                                                        <li
                                                            className={this.state.collapseDefinitionId === updatedLawDefinition.definitionId ? "list-group-item active" : "list-group-item"}
                                                            key={updatedLawDefinition.definitionId}
                                                            data-definition={updatedLawDefinition.definitionId}
                                                        >
                                                            <h6 onClick={this.toggleDefinition} data-definition={updatedLawDefinition.definitionId}>{newLawDefinitionVersion.terminology} <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 7L7.70711 7.70711L7 8.41421L6.29289 7.70711L7 7ZM13.7071 1.70711L7.70711 7.70711L6.29289 6.29289L12.2929 0.292893L13.7071 1.70711ZM6.29289 7.70711L0.292893 1.70711L1.70711 0.292894L7.70711 6.29289L6.29289 7.70711Z" fill="currentColor"></path></svg></h6>
                                                            <Collapse isOpen={
                                                                this.state
                                                                    .collapseDefinitionId ===
                                                                updatedLawDefinition.definitionId
                                                            }>
                                                                {definitionKeys?.map(
                                                                    (definitionField, key) => {
                                                                        let dKeyForLabel = definitionCols.findIndex((x) => x === definitionField);
                                                                        let oldDefValue = oldLawDefinitionVersion[definitionField];
                                                                        let newDefValue = newLawDefinitionVersion[definitionField];
                                                                        if (definitionField === 'isActive') {
                                                                            oldDefValue = (oldLawDefinitionVersion[definitionField] === true) ? ACTIVE_LABEL : INACTIVE_LABEL;
                                                                            newDefValue = (newLawDefinitionVersion[definitionField] === true) ? ACTIVE_LABEL : INACTIVE_LABEL;
                                                                        }
                                                                        if (oldDefValue !== newDefValue) {
                                                                            let oldValue = oldDefValue.replace(/(\r\n|\n|\r)/gm, " ");
                                                                            oldValue = oldValue.replace(/\s{2,}/gm, " ");
                                                                            let newValue = newDefValue.replace(/(\r\n|\n|\r)/gm, " ");
                                                                            newValue = newValue.replace(/\s{2,}/gm, " ");
                                                                            return (
                                                                                <Card key={'def' + key}>
                                                                                    <CardBody>
                                                                                        <label>
                                                                                            {key + 1}{') '}
                                                                                            {
                                                                                                definitionLabels[dKeyForLabel]
                                                                                            }
                                                                                        </label>
                                                                                        <p>
                                                                                            <DiffViewer
                                                                                                oldValue={oldValue}
                                                                                                newValue={newValue}
                                                                                                splitView={true}
                                                                                                compareMethod={DiffMethod.WORDS}
                                                                                                hideLineNumbers={true}
                                                                                                showDiffOnly={false}
                                                                                            />
                                                                                        </p>
                                                                                    </CardBody>
                                                                                </Card>
                                                                            );
                                                                        }
                                                                    }
                                                                )}
                                                            </Collapse>
                                                        </li>
                                                    )
                                                }
                                            })}
                                        </ul>
                                        {/* <h3>New Definitions</h3> */}
                                        {/* {End Definition Update Analysis} */}
                                    </CardBody>
                                </Card>
                            </TabPane>
                        )}
                        {lawDocumentsTab === true && (
                            <TabPane className="" tabId="standardDocumentAnalysisTab">
                                <Card className='m-0'>
                                    <CardBody>
                                        {/* {Start Standard Document Update Analysis} */}
                                        <h6 class="mt-0">Related Document Analysis</h6>
                                        <ul className='list-styled pl-0 relevant-update-list'>
                                            {this.state.lawDocumentVersionsList.length > 0 && this.state.legalUpdateData?.updatedLawDocuments && this.state.legalUpdateData?.updatedLawDocuments.length > 0 && this.state.legalUpdateData?.updatedLawDocuments.map((updatedLawDocument, idx) => {
                                                let oldLawDocumentVersion = this.state.lawDocumentVersionsList.find((l) => l.id === updatedLawDocument.oldVersionId);
                                                let newLawDocumentVersion = this.state.lawDocumentVersionsList.find((l) => l.id === updatedLawDocument.newVersionId);
                                                if (oldLawDocumentVersion && newLawDocumentVersion) {
                                                    let documentKeys = Object.keys(oldLawDocumentVersion).filter((dkey) => documentCols.includes(dkey));
                                                    return (
                                                        <li
                                                            className={this.state.collapseDocumentId ===
                                                                updatedLawDocument.documentId ? "list-group-item active" : 'list-group-item'}
                                                            key={updatedLawDocument.documentId}
                                                            data-document={updatedLawDocument.documentId}
                                                        >
                                                            <h6 onClick={this.toggleDocument} data-document={updatedLawDocument.documentId}>{newLawDocumentVersion.documentName} <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 7L7.70711 7.70711L7 8.41421L6.29289 7.70711L7 7ZM13.7071 1.70711L7.70711 7.70711L6.29289 6.29289L12.2929 0.292893L13.7071 1.70711ZM6.29289 7.70711L0.292893 1.70711L1.70711 0.292894L7.70711 6.29289L6.29289 7.70711Z" fill="currentColor"></path></svg></h6>
                                                            <Collapse isOpen={
                                                                this.state
                                                                    .collapseDocumentId ===
                                                                updatedLawDocument.documentId
                                                            }>
                                                                {documentKeys?.map(
                                                                    (documentField, key) => {
                                                                        let oldDocValue = oldLawDocumentVersion[documentField];
                                                                        let newDocValue = newLawDocumentVersion[documentField];
                                                                        if (documentField === 'documentType') {
                                                                            let oldDocType = oldLawDocumentVersion[documentField];
                                                                            oldDocValue = oldDocType.documentTypeName;
                                                                            let newDocType = newLawDocumentVersion[documentField];
                                                                            newDocValue = newDocType.documentTypeName;
                                                                        }
                                                                        if (documentField === 'isActive') {
                                                                            oldDocValue = (oldLawDocumentVersion[documentField] === true) ? ACTIVE_LABEL : INACTIVE_LABEL;
                                                                            newDocValue = (oldLawDocumentVersion[documentField] === true) ? ACTIVE_LABEL : INACTIVE_LABEL;
                                                                        }
                                                                        let dKeyForLabel = documentCols.findIndex((x) => x === documentField);
                                                                        if (oldDocValue !== newDocValue) {
                                                                            let oldValue = oldDocValue.replace(/(\r\n|\n|\r)/gm, " ");
                                                                            oldValue = oldValue.replace(/\s{2,}/gm, " ");
                                                                            let newValue = newDocValue.replace(/(\r\n|\n|\r)/gm, " ");
                                                                            newValue = newValue.replace(/\s{2,}/gm, " ");
                                                                            return (
                                                                                <Card key={'doc' + key}>
                                                                                    <CardBody>
                                                                                        <label>
                                                                                            {key + 1}{') '}
                                                                                            {documentLabels[dKeyForLabel]}
                                                                                        </label>
                                                                                        <p>
                                                                                            <DiffViewer
                                                                                                oldValue={oldValue}
                                                                                                newValue={newValue}
                                                                                                splitView={true}
                                                                                                compareMethod={DiffMethod.WORDS}
                                                                                                hideLineNumbers={true}
                                                                                                showDiffOnly={false}
                                                                                            />
                                                                                        </p>
                                                                                    </CardBody>
                                                                                </Card>
                                                                            );
                                                                        }
                                                                    }
                                                                )}
                                                            </Collapse>
                                                        </li>
                                                    )
                                                }
                                            })}
                                        </ul>
                                        {/* {End Standard Document Update Analysis} */}
                                    </CardBody>
                                </Card>
                            </TabPane>
                        )}
                    </TabContent>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    compliancelegalUpdateDetailsApiResponse: makeLegalUpdateSelectField('compliancelegalUpdateDetailsApiResponse'),
    lawDefinitionVersionsList: makeLawDefinitionSelectField('lawDefinitionVersionsList'),
    lawDocumentVersionsList: makeLawDocumentSelectField('lawDocumentVersionsList')
});
const withConnect = connect(mapStatetoProps, {
    getComplianceLegalUpdateDetailsByIdRequest,
    getLawDefinitionVersionsListRequest,
    getLawDocumentVersionsListRequest
});
export default compose(
    withConnect,
    withLegalUpdatesReducer,
    withLegalUpdatesSaga,
    withLawDefinitionReducer,
    withLawDefinitionSaga,
    withLawDocumentReducer,
    withLawDocumentSaga,
    withRouter
)(RelevantUpdateAnalysis);
