import React, { Component } from 'react';
import {
    Row,
    Col,
    FormGroup,
    Button,
    Label,
    CardBody,
    Card,
    CardHeader,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    SUCCESS,
    UPDATE,
    CANCEL,
    SUBMIT,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { toastrMessage } from 'helpers/messageHelper';
import { createStructuredSelector } from 'reselect';
import {
    editTicketRequest,
    listTicketCategoryRequest,
    getTicketDetailsByIdRequest,
    listTicketAssigneeUsersRequest
} from 'store/actions';
import 'react-datepicker/dist/react-datepicker.css';
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import ticketSaga from 'store/Ticket/saga';
import ticketReducer from 'store/Ticket/reducer';
import { makeTicketSelectField, selectSortedTicketAssigneeUserList, selectSortedTicketCategoryList } from 'store/Ticket/selector';
import __t from 'i18n/translator';
import JoditEditor from 'jodit-react';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import { redirectUrl } from 'helpers/generalUtils';
import { ticketPriorityList, ticketStatusList } from 'constants/dropdowns';
import ShowMoreText from "react-show-more-text";
import { TICKET_CLOSE, TICKET_OPEN, TICKET_REOPEN } from 'constants/databaseConstants';
import TicketHistory from './TicketHistory';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
import { getTicketStatusNameById, isGTROLE } from 'helpers/projectUtils';
import { getFullDateTimeFromDateTimeString } from 'helpers/dateTimeHelper';

const ticketKey = 'ticket';
const withTicketReducer = injectReducer({
    key: ticketKey,
    reducer: ticketReducer,
});
const withTicketSaga = injectSaga({ key: ticketKey, saga: ticketSaga });

const initialTicketDataState = () => {
    return {
        title: '',
        ticketCategoryId: '',
        status: TICKET_OPEN, // open
        priority: 2, // Medium
        description: '',
    }
}
class TicketDetails extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            loginUserId: parseInt(getValueByKey(authConstants.USER_ID)),
            operationType: UPDATE,
            ticketId: this.props.match.params.ticketId,
            pageHeaderName: __t('page_headers.ticket_edit'),
            ticketData: initialTicketDataState(),
            isEditOpen: false
        };
    }
    componentDidMount() {
        this.props.listTicketCategoryRequest();
        this.props.getTicketDetailsByIdRequest(this.state.ticketId);
        this.props.listTicketAssigneeUsersRequest();
    }
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            let formValues = { ...values };
            this.props.editTicketRequest(this.state.ticketId, formValues);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    reopenTicket = () => {
        this.props.editTicketRequest(this.state.ticketId, {
            ...this.state.ticketData, status: TICKET_REOPEN,
            reopen: true
        });
    }

    getShowMoreTextElement = (element) => {
        return <ShowMoreText
            lines={3}
            more="Show more"
            less="Show less"
            className="show-more-content"
            anchorClass="show-more-link"
            expandByClick={true}
            expanded={false}
            keepNewLines={true}
        >{`` + element + '\n'}</ShowMoreText>
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.ticketApiResponse &&
            this.props.ticketApiResponse !== nextprops.ticketApiResponse
        ) {
            toastrMessage(
                nextprops.ticketApiResponse.message,
                nextprops.ticketApiResponse.responseType
            );
            if (nextprops.ticketApiResponse.responseType === SUCCESS) {
                this.props.history.push('/support-tickets');
            }
        }
        if (
            nextprops.ticketDetailsApiResponse &&
            this.props.ticketDetailsApiResponse !==
            nextprops.ticketDetailsApiResponse
        ) {
            this.setState({
                operationType: UPDATE,
                ticketData: {
                    ...nextprops.ticketDetailsApiResponse.data?.ticketDetails,
                },
            }, () => {
                this.validator.purgeFields();
                this.validator.hideMessages();
            });
        }
    }
    render() {
        let { statusName, statusClassName } = getTicketStatusNameById(this.state.ticketData.status);

        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <CommonBreadcrum
                            type={'add-support-ticket'}
                            edit={this.state.operationType == UPDATE}
                        />
                    </Col>
                    <Col lg="12">
                        <div className='common-header support-head'>
                            <div className='common-flex-box'>
                                <div className="page-header">
                                    <h4 className="with-back-btn">
                                        <a href={() => false} onClick={() => this.props.history.push('/support-tickets')} className="dripicons-arrow-thin-left">
                                            { }
                                        </a>{' '}
                                        {this.state.pageHeaderName}{' '}
                                        <span className={`ticket-status mr-2 badge-pill ` + statusClassName}>
                                            {statusName}
                                        </span>
                                    </h4>
                                </div>
                            </div>
                            <div className='common-flex-box'>
                                {this.state.ticketData.status === TICKET_CLOSE && (
                                    <div className='ticket-close d-flex align-items-center'>
                                        <div className='close-on pr-2'>
                                            <strong className='text-primary'>Closed On: &nbsp;</strong>
                                            <span>{getFullDateTimeFromDateTimeString(this.state.ticketData.closedAt)}</span>
                                        </div>
                                        <div className='close-by pr-2'>
                                            <strong className='text-primary'>Closed By: &nbsp;</strong>
                                            <span>{(this.state.ticketData.closedByUser) ? this.state.ticketData.closedByUser.fullName : ''}</span>
                                        </div>
                                    </div>
                                )}
                                {(!this.state.isEditOpen && (isGTROLE() === true || this.state.loginUserId === this.state.ticketData.assignedUserId) && this.state.ticketData.status !== TICKET_CLOSE) && (
                                    <Button
                                        type="button"
                                        color="primary"
                                        onClick={() => {
                                            this.setState({
                                                isEditOpen: true
                                            })
                                        }}
                                    ><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.7566 1.62145C15.5852 -0.207149 18.55 -0.207149 20.3786 1.62145C22.2072 3.45005 22.2072 6.41479 20.3786 8.24339L10.8933 17.7287C10.3514 18.2706 10.0323 18.5897 9.67745 18.8665C9.25915 19.1927 8.80655 19.4725 8.32766 19.7007C7.92136 19.8943 7.49334 20.037 6.76623 20.2793L3.43511 21.3897L2.63303 21.6571C1.98247 21.8739 1.26522 21.7046 0.780321 21.2197C0.29542 20.7348 0.126102 20.0175 0.342956 19.367L1.72068 15.2338C1.96303 14.5067 2.10568 14.0787 2.29932 13.6724C2.52755 13.1935 2.80727 12.7409 3.13354 12.3226C3.41035 11.9677 3.72939 11.6487 4.27137 11.1067L13.7566 1.62145ZM3.40051 19.8201L6.24203 18.8729C7.03314 18.6092 7.36927 18.4958 7.68233 18.3466C8.06287 18.1653 8.42252 17.943 8.75492 17.6837C9.02837 17.4704 9.28013 17.2205 9.86979 16.6308L17.4393 9.06137C16.6506 8.78321 15.6346 8.26763 14.6835 7.31651C13.7324 6.36538 13.2168 5.34939 12.9387 4.56075L5.36917 12.1302C4.77951 12.7199 4.52959 12.9716 4.3163 13.2451C4.05704 13.5775 3.83476 13.9371 3.65341 14.3177C3.50421 14.6307 3.3908 14.9669 3.12709 15.758L2.17992 18.5995L3.40051 19.8201ZM14.1554 3.34404C14.1896 3.519 14.2474 3.75684 14.3438 4.03487C14.561 4.66083 14.9712 5.48288 15.7442 6.25585C16.5171 7.02881 17.3392 7.43903 17.9651 7.6562C18.2432 7.75266 18.481 7.81046 18.656 7.84466L19.3179 7.18272C20.5607 5.93991 20.5607 3.92492 19.3179 2.68211C18.0751 1.4393 16.0601 1.4393 14.8173 2.68211L14.1554 3.34404Z" fill="currentColor" /></svg> Edit
                                    </Button>
                                )}

                                {(this.state.loginUserId === this.state.ticketData.createdBy && this.state.ticketData.status === TICKET_CLOSE && this.state.ticketData.canReopen === true) && (
                                    <Button
                                        type="button"
                                        color="warning"
                                        onClick={() => {
                                            this.reopenTicket();
                                        }}
                                    >
                                        <i className='fas fa-undo'></i> Reopen
                                    </Button>
                                )}


                                {(this.state.isEditOpen === true) && (
                                    <div className='ticket-edit animate__animated animate__fadeInRight'>
                                        <Formik
                                            initialValues={this.state.ticketData}
                                            enableReinitialize={true}
                                            onSubmit={this.formSubmit}
                                        >
                                            {({ values, setFieldValue }) => (
                                                <Form className="news_edit_form">
                                                    <FormGroup>
                                                        <Field
                                                            component={Select}
                                                            name="ticketCategoryId"
                                                            className="form-control selectbox"
                                                            options={
                                                                this.props
                                                                    .sortedTicketCategoryListDropDown
                                                            }
                                                            onChange={(opt) => {
                                                                setFieldValue(
                                                                    'ticketCategoryId',
                                                                    opt
                                                                        ? opt.value
                                                                        : ''
                                                                );
                                                            }}
                                                            value={this.props.sortedTicketCategoryListDropDown.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    values.ticketCategoryId
                                                            )}
                                                            isClearable={true}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.support_tickets.ticket_category'
                                                            )}{' '}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.support_tickets.ticket_category'
                                                            ),
                                                            values.ticketCategoryId,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required: __t(
                                                                        'validations.support_tickets.ticket_category_required'
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Field
                                                            component={Select}
                                                            name="assignedUserId"
                                                            className="form-control selectbox"
                                                            options={
                                                                this.props
                                                                    .sortedTicketAssigneeUserListDropDown
                                                            }
                                                            onChange={(opt) => {
                                                                setFieldValue(
                                                                    'assignedUserId',
                                                                    opt
                                                                        ? opt.value
                                                                        : ''
                                                                );
                                                            }}
                                                            value={this.props.sortedTicketAssigneeUserListDropDown.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    values.assignedUserId
                                                            )}
                                                            isClearable={true}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.support_tickets.assignee'
                                                            )}{' '}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.support_tickets.assignee'
                                                            ),
                                                            values.assignedUserId,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required: __t(
                                                                        'validations.support_tickets.assignee_required'
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Field
                                                            component={Select}
                                                            name="status"
                                                            className="form-control selectbox"
                                                            options={ticketStatusList}
                                                            onChange={(opt) => {
                                                                setFieldValue(
                                                                    'status',
                                                                    opt
                                                                        ? opt.value
                                                                        : ''
                                                                );
                                                            }}
                                                            value={ticketStatusList.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    values.status
                                                            )}
                                                            isClearable={true}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.support_tickets.status'
                                                            )}{' '}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.support_tickets.status'
                                                            ),
                                                            values.status,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required: __t(
                                                                        'validations.support_tickets.status_required'
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Field
                                                            component={Select}
                                                            name="priority"
                                                            className="form-control selectbox"
                                                            options={ticketPriorityList}
                                                            onChange={(opt) => {
                                                                setFieldValue(
                                                                    'priority',
                                                                    opt
                                                                        ? opt.value
                                                                        : ''
                                                                );
                                                            }}
                                                            value={ticketPriorityList.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    values.priority
                                                            )}
                                                            isClearable={true}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.support_tickets.priority'
                                                            )}{' '}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.support_tickets.priority'
                                                            ),
                                                            values.priority,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required: __t(
                                                                        'validations.support_tickets.priority_required'
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                    >
                                                        {
                                                            this.state
                                                                .operationType
                                                        }
                                                    </Button>
                                                    <Button
                                                        type="reset"
                                                        color="secondary"
                                                        onClick={() => {
                                                            this.setState({
                                                                isEditOpen: false
                                                            })
                                                        }}
                                                    >
                                                        {CANCEL}
                                                    </Button>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='support-details'>
                    <Col lg="7">
                        <Card>
                            <CardHeader>
                                <div className='ticket-on'>
                                    Raised By:
                                    <span>
                                        {(this.state.ticketData?.createdByUser) ? this.state.ticketData?.createdByUser.fullName : '-'}</span>
                                </div>
                                <div className='ticket-on'>
                                    Tenant:
                                    <span>
                                        {(this.state.ticketData?.tenant) ? this.state.ticketData?.tenant.name + ' [' + this.state.ticketData?.tenant.code + ']' : '-'}</span>
                                </div>
                                {this.state.ticketData?.entity && (
                                    <div className='ticket-on'>
                                        Entity:
                                        <span>
                                            {(this.state.ticketData?.entity) ? this.state.ticketData?.entity.entityName : '-'}</span>
                                    </div>
                                )}
                                {this.state.ticketData?.department && (
                                    <div className='ticket-on'>
                                        Department:
                                        <span>
                                            {(this.state.ticketData?.department) ? this.state.ticketData?.department.departmentName : '-'}</span>
                                    </div>
                                )}
                                <div className='ticket-on'>
                                    Assignee:
                                    <span>
                                        {(this.state.ticketData?.assignedUser) ? this.state.ticketData?.assignedUser.fullName : '-'}</span>
                                </div>
                                <div className='ticket-on'>
                                    Category:
                                    <span>
                                        {(this.state.ticketData?.ticketCategory) ? this.state.ticketData?.ticketCategory.ticketCategoryName : '-'}</span>
                                </div>
                            </CardHeader>
                            <CardBody className='p-3'>
                                <h6 className='text-primary mt-0 mb-1'>{this.state.ticketData.title}
                                </h6>
                                <strong className='w-100 mb-1 d-inline-block'>{__t('form_labels.support_tickets.description')}:</strong>
                                <div className='JoditEditor-border' dangerouslySetInnerHTML={{
                                    __html: this.state.ticketData.description
                                }}></div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="5">
                        <TicketHistory
                            ticketData={this.state.ticketData}
                            ticketId={this.state.ticketId}
                            loginUserId={this.state.loginUserId}
                        />
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    ticketApiResponse: makeTicketSelectField('ticketApiResponse'),
    ticketDetailsApiResponse: makeTicketSelectField('ticketDetailsApiResponse'),
    sortedTicketCategoryListDropDown: selectSortedTicketCategoryList(),
    sortedTicketAssigneeUserListDropDown: selectSortedTicketAssigneeUserList()
});
const withConnect = connect(mapStatetoProps, {
    editTicketRequest,
    listTicketCategoryRequest,
    getTicketDetailsByIdRequest,
    listTicketAssigneeUsersRequest
});
export default compose(
    withConnect,
    withTicketReducer,
    withTicketSaga,
    withRouter
)(TicketDetails);
