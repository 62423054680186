import { ADD_ENTITY_GROUP_REQUEST, ADD_ENTITY_GROUP_RESPONSE, CLEAR_COMPANY_GROUP_LIST_RESPONSE, DELETE_ENTITY_GROUP_BY_ENTITY_GROUP_ID_REQUEST, DELETE_ENTITY_GROUP_BY_ENTITY_GROUP_ID_RESPONSE, GET_ALL_COMPANY_GROUPS_LIST_ON_USER_LIST_PAGE_REQUEST, GET_ALL_COMPANY_GROUPS_LIST_ON_USER_LIST_PAGE_RESPONSE, GET_ALL_ENTITY_GROUP_LIST_REQUEST, GET_ALL_ENTITY_GROUP_LIST_RESPONSE, GET_ENTITY_GROUP_BY_ENTITY_GROUP_ID_REQUEST, GET_ENTITY_GROUP_BY_ENTITY_GROUP_ID_RESPONSE, GET_ENTITY_GROUP_LIST_BY_STATUS_REQUEST, GET_ENTITY_GROUP_LIST_BY_STATUS_RESPONSE, UPDATE_ENTITY_GROUP_REQUEST, UPDATE_ENTITY_GROUP_RESPONSE, UPDATE_ENTITY_GROUP_STATUS_BY_ENTITY_GROUP_ID_REQUEST, UPDATE_ENTITY_GROUP_STATUS_BY_ENTITY_GROUP_ID_RESPONSE } from "./actionTypes";
export const addCompanyGroupRequest = data => {
    return {
        type: ADD_ENTITY_GROUP_REQUEST,
        payload: { data },
    };
};
export const addCompanyGroupResponse = (message, responseType) => {
    return {
        type: ADD_ENTITY_GROUP_RESPONSE,
        payload: { message, responseType },
    };
};
export const getAllCompanyGroupListRequest = () => {
    return {
        type: GET_ALL_ENTITY_GROUP_LIST_REQUEST
    };
};
export const getAllCompanyGroupListResponse = companyGroupList => {
    return {
        type: GET_ALL_ENTITY_GROUP_LIST_RESPONSE,
        payload: { companyGroupList },
    };
};
export const getCompanyGroupByCompanyGroupIdRequest = (companyGroupId) => {
    return {
        type: GET_ENTITY_GROUP_BY_ENTITY_GROUP_ID_REQUEST,
        payload: { companyGroupId: companyGroupId },
    };
};
export const getCompanyGroupByCompanyGroupIdResponse = (message, responseType, data) => {
    return {
        type: GET_ENTITY_GROUP_BY_ENTITY_GROUP_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const updateCompanyGroupRequest = (companyGroupId, data) => {
    return {
        type: UPDATE_ENTITY_GROUP_REQUEST,
        payload: { companyGroupId, data },
    };
};
export const updateCompanyGroupResponse = (message, responseType) => {
    return {
        type: UPDATE_ENTITY_GROUP_RESPONSE,
        payload: { message, responseType },
    };
};
export const updateCompanyGroupStatusByCompanyGroupIdRequest = (companyGroupId, status) => {
    return {
        type: UPDATE_ENTITY_GROUP_STATUS_BY_ENTITY_GROUP_ID_REQUEST,
        payload: { companyGroupId, status },
    };
};
export const updateCompanyGroupStatusByCompanyGroupIdResponse = (message, responseType) => {
    return {
        type: UPDATE_ENTITY_GROUP_STATUS_BY_ENTITY_GROUP_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getCompanyGroupListByStatusRequest = (status, type = '') => {
    return {
        type: GET_ENTITY_GROUP_LIST_BY_STATUS_REQUEST,
        payload: { status, type },
    };
};
export const getCompanyGroupListByStatusResponse = companyGroupList => {
    return {
        type: GET_ENTITY_GROUP_LIST_BY_STATUS_RESPONSE,
        payload: { companyGroupList },
    };
};
export const deleteCompanyGroupByCompanyGroupIdRequest = (companyGroupId) => {
    return {
        type: DELETE_ENTITY_GROUP_BY_ENTITY_GROUP_ID_REQUEST,
        payload: { companyGroupId },
    };
};
export const deleteCompanyGroupByCompanyGroupIdResponse = (message, responseType) => {
    return {
        type: DELETE_ENTITY_GROUP_BY_ENTITY_GROUP_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const clearCompanyGroupListResponse = () => {
    return {
        type: CLEAR_COMPANY_GROUP_LIST_RESPONSE
    };
};

export const getAllCompanyGroupListOnUserListPageRequest = () => {
    return {
        type: GET_ALL_COMPANY_GROUPS_LIST_ON_USER_LIST_PAGE_REQUEST
    };
};
export const getAllCompanyGroupListOnUserListPageResponse = companyGroupListOnUserList => {
    return {
        type: GET_ALL_COMPANY_GROUPS_LIST_ON_USER_LIST_PAGE_RESPONSE,
        payload: { companyGroupListOnUserList },
    };
};