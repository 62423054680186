import React, { Component } from 'react';
import { Row, Col, Label, FormGroup, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    ACTIVE,
    CLEAR,
    SEARCH,
    DATE_FORMAT_DATEPICKER,
    DATE_FORMAT_PLACEHOLDER,
} from 'constants/commonConstants';
import { Field, Form, Formik } from 'formik';
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import industryReducer from 'store/Masters/Industry/reducer';
import industrySaga from 'store/Masters/Industry/saga';
import subIndustryReducer from 'store/Masters/SubIndustry/reducer';
import subIndustrySaga from 'store/Masters/SubIndustry/saga';
import lawCategoryReducer from 'store/Masters/LawCategory/reducer';
import lawCategorySaga from 'store/Masters/LawCategory/saga';
import {
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getIndustryListByStatusRequest,
    getLawCategoryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
    getLawListRequest,
    getEventListByFilterForAllocationRequest,
    getComplianceCategoriesRequest,
    getAllupdateTypeListRequest,
    getDepartmentListByStatusRequest,
    getAllLegalUpdateStatusListRequest,
    getGcmAuthUserCountryListRequest
} from 'store/actions';
import { selectGcmAuthUserCountryList } from 'store/Masters/Country/selector';
import { selectSortedStateList } from 'store/Masters/State/selector';
import { selectSortedCityList } from 'store/Masters/City/selector';
import { selectSortedIndustryList } from 'store/Masters/Industry/selector';
import { selectSortedSubIndustryList } from 'store/Masters/SubIndustry/selector';
import { compareString, setEmptyToNull } from 'helpers/generalUtils';
import { selectSortedLawCategoryList } from 'store/Masters/LawCategory/selector';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import { Option, MultiValue, animatedComponents } from 'helpers/projectUtils';
import { selectSortedLawSearchList } from 'store/Masters/Law/selector';
import __t from 'i18n/translator';
import lawReducer from 'store/Masters/Law/reducer';
import lawSaga from 'store/Masters/Law/saga';
import eventReducer from 'store/Masters/Event/reducer';
import eventSaga from 'store/Masters/Event/saga';
import { selectSortedEventList } from 'store/Masters/Event/selector';
import { contentStatus, priorityStatus } from 'constants/dropdowns';
import {
    getDateObjectWithValueFromMoment,
} from 'helpers/dateTimeHelper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { makeLawComplianceSelectField } from 'store/LawCompliance/selector';
import lawComplianceReducer from 'store/LawCompliance/reducer';
import lawComplianceSaga from 'store/LawCompliance/saga';
import { selectSortedLegalUpdateStatusList, selectSortedUpdateTypeList } from 'store/UpdateCenter/LegalUpdates/selector';
import legalUpdateReducer from 'store/UpdateCenter/LegalUpdates/reducer';
import legalUpdateSaga from 'store/UpdateCenter/LegalUpdates/saga';
import { selectSortedDepartmentList } from 'store/Masters/Department/selector';
import departmentReducer from 'store/Masters/Department/reducer';
import departmentSaga from 'store/Masters/Department/saga';

const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const industryKey = 'industry';
const withIndustryReducer = injectReducer({
    key: industryKey,
    reducer: industryReducer,
});
const withIndustrySaga = injectSaga({ key: industryKey, saga: industrySaga });
const subIndustryKey = 'subIndustry';
const withSubIndustryReducer = injectReducer({
    key: subIndustryKey,
    reducer: subIndustryReducer,
});
const withSubIndustrySaga = injectSaga({
    key: subIndustryKey,
    saga: subIndustrySaga,
});
const lawCategoryKey = 'lawCategory';
const withLawCategoryReducer = injectReducer({
    key: lawCategoryKey,
    reducer: lawCategoryReducer,
});
const withLawCategorySaga = injectSaga({
    key: lawCategoryKey,
    saga: lawCategorySaga,
});

const lawKey = 'law';
const withLawReducer = injectReducer({
    key: lawKey,
    reducer: lawReducer,
});

const eventKey = 'event';
const withEventReducer = injectReducer({
    key: eventKey,
    reducer: eventReducer,
});
const withEventSaga = injectSaga({ key: eventKey, saga: eventSaga });
const withLawSaga = injectSaga({ key: lawKey, saga: lawSaga });
export const intFields = ['country', 'state', 'city'];
export const nullableFields = [];
export const formatValuesForApi = (values) => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};

const lawComplianceKey = 'lawCompliance';
const withLawComplianceReducer = injectReducer({
    key: lawComplianceKey,
    reducer: lawComplianceReducer,
});
const withLawComplianceSaga = injectSaga({
    key: lawComplianceKey,
    saga: lawComplianceSaga,
});

const legalUpdatesKey = 'legalUpdates';
const withLegalUpdatesReducer = injectReducer({
    key: legalUpdatesKey,
    reducer: legalUpdateReducer,
});
const withLegalUpdatesSaga = injectSaga({
    key: legalUpdatesKey,
    saga: legalUpdateSaga,
});

const departmentKey = 'department';
const withDepartmentReducer = injectReducer({
    key: departmentKey,
    reducer: departmentReducer,
});
const withDepartmentSaga = injectSaga({
    key: departmentKey,
    saga: departmentSaga,
});
class LegalUpdateSearchForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            searchCompliancesFormData: this.props.searchCompliancesFormData,
            allCompliancesCategoriesList: [],
            sortedSubIndustryListDropdown: [],
            sortedLawListDropDown: []
        };
    }
    componentDidMount() {
        this.props.getIndustryListByStatusRequest(ACTIVE);
        this.props.getLawCategoryListByStatusRequest(ACTIVE);
        this.props.getAllupdateTypeListRequest();
        this.props.getComplianceCategoriesRequest();
        this.props.getDepartmentListByStatusRequest(ACTIVE);
        this.props.getGcmAuthUserCountryListRequest();
    }
    getStateListByCountry(countryId) {
        this.props.getStateListByCountryRequest(countryId);
    }
    getCityListByState(stateId) {
        this.props.getCityListByStateRequest(stateId);
    }
    getSubIndustryListByIndustry(industryId) {
        this.props.getSubIndustryListByIndustryRequest(industryId);
    }
    getEventListByFilterForAllocation(values) {
        this.props.getEventListByFilterForAllocationRequest(values);
    }
    formSubmit = (values) => {
        if (this.validator.allValid()) {

            this.setState(
                {
                    searchCompliancesFormData: values,
                },
                () => {
                    this.props.onFormSubmit(formatValuesForApi(values));
                }
            );
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    resetForm = () => {
        this.validator.hideMessages();
        this.props.onResetForm();
    };
    getLawsByFilters(values) {
        let apiData = {
            limit: -1,
            page: -1,
            filter: {
                countryId: values.countryId ? values.countryId : [],
                // stateId: values.stateId ? values.stateId : [],
                // industryId: values.industryId ? values.industryId : [],
                // subIndustryId: values.subIndustryId ? values.subIndustryId : [],
                // lawCategoryId: values.lawCategoryId ? values.lawCategoryId : [],
                type: this.props.pageType,
            },
            orderBy: {},
        };
        this.props.getLawListRequest(apiData, false);
    }
    getEventsBySearch(values) {
        this.props.getEventListBySearchRequest(values);
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.allCompliancesCategoriesList &&
            this.props.allCompliancesCategoriesList !==
            nextprops.allCompliancesCategoriesList
        ) {
            this.setState({
                allCompliancesCategoriesList:
                    nextprops.allCompliancesCategoriesList
                        ?.map((opt) => ({
                            value: opt.id,
                            label: opt.complianceCategoryName,
                        }))
                        .sort((item1, item2) =>
                            compareString(item1.label, item2.label)
                        ),
            });
        }

        if (nextprops.sortedSubIndustryListDropdown && this.props.sortedSubIndustryListDropdown != nextprops.sortedSubIndustryListDropdown) {
            this.setState({
                sortedSubIndustryListDropdown: nextprops.sortedSubIndustryListDropdown
            })
        }

        if (nextprops.sortedLawListDropDown && this.props.sortedLawListDropDown != nextprops.sortedLawListDropDown) {
            this.setState({
                sortedLawListDropDown: nextprops.sortedLawListDropDown
            })
        }
    }
    render() {
        return (
            <React.Fragment>
                <Formik
                    initialValues={this.props.searchCompliancesFormData}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Row className="filter-form">
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={Select}
                                        name="industryId"
                                        className="form-control selectbox"
                                        options={
                                            this.props
                                                .sortedIndustryListDropdown
                                        }
                                        onChange={(opt) => {
                                            let industry = opt
                                                ? opt.map((x) => x.value)
                                                : [];

                                            setFieldValue(
                                                'industryId',
                                                industry
                                            );
                                            setFieldValue(
                                                'subIndustryId',
                                                []
                                            );
                                            if (industry != '') {
                                                this.props.getSubIndustryListByIndustryRequest(
                                                    industry
                                                );
                                            } else {
                                                this.setState({
                                                    sortedSubIndustryListDropdown: []
                                                })
                                            }
                                        }}
                                        isMulti
                                        value={this.props.sortedIndustryListDropdown?.filter(
                                            (option) =>
                                                values.industryId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isClearable={true}
                                    />

                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.allocation.industry'
                                        )}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={Select}
                                        name="subIndustryId"
                                        className="form-control selectbox"
                                        options={
                                            this.state
                                                .sortedSubIndustryListDropdown
                                        }
                                        onChange={(opt) => {
                                            let subIndustry = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue(
                                                'subIndustryId',
                                                subIndustry
                                            );
                                        }}
                                        value={this.state.sortedSubIndustryListDropdown?.filter(
                                            (option) =>
                                                values.subIndustryId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        isClearable={true}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.allocation.sub_industry'
                                        )}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={Select}
                                        name="countryId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedGcmAuthUserCountryList
                                        }
                                        styles={{
                                            menuPortal: (provided) => ({
                                                ...provided,
                                                zIndex: 9999,
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                zIndex: 9999,
                                            }),
                                        }}
                                        onChange={(opt) => {
                                            let selectedValues = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue(
                                                'countryId',
                                                selectedValues
                                            );
                                            setFieldValue('stateId', []);
                                            setFieldValue('lawId', []);

                                            this.getStateListByCountry(
                                                selectedValues
                                            );
                                            if (selectedValues.length > 0) {
                                                this.getLawsByFilters({
                                                    ...values,
                                                    countryId: selectedValues,
                                                });
                                            } else {
                                                this.setState({
                                                    sortedLawListDropDown: []
                                                })
                                            }
                                        }}
                                        value={this.props.sortedGcmAuthUserCountryList?.filter(
                                            (option) =>
                                                values.countryId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.allocation.country'
                                        )}{' '}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="lawCategoryId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props
                                                .sortedLawCategoryListDropdown
                                        }
                                        onChange={(opt) => {
                                            let selectedValues = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue(
                                                'lawCategoryId',
                                                selectedValues
                                            );

                                        }}
                                        value={this.props.sortedLawCategoryListDropdown?.filter(
                                            (option) =>
                                                values.lawCategoryId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                        key="lawCategoryId"
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.allocation.law_category'
                                        )}{' '}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="lawId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.state
                                                .sortedLawListDropDown
                                        }
                                        onChange={(opt) => {
                                            let selectedValues = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue(
                                                'lawId',
                                                selectedValues
                                            );
                                        }}
                                        value={this.state.sortedLawListDropDown?.filter(
                                            (option) =>
                                                values.lawId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                        key="laws"
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.legal_update.law_id'
                                        )}{' '}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        name="updateTypeId"
                                        className="form-control selectbox"
                                        options={this.props.updateTypeList}
                                        component={CustomMultiSelect}
                                        onChange={(opt) => {
                                            let selectedValues = opt
                                                ? opt.map((x) => x.value)
                                                : [];

                                            setFieldValue(
                                                'updateTypeId',
                                                selectedValues
                                            );
                                        }}
                                        value={this.props.updateTypeList?.filter(
                                            (option) =>
                                                values.updateTypeId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.legal_update.update_type'
                                        )}{' '}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="departmentId"
                                        className="form-control selectbox"
                                        options={
                                            this.props
                                                .sortedDepartmentListDropdown
                                        }
                                        onChange={(opt) => {
                                            setFieldValue(
                                                `departmentId`,
                                                opt &&
                                                opt.map((x) => x.value)
                                            );
                                        }}
                                        value={this.props.sortedDepartmentListDropdown.filter(
                                            (option) =>
                                                values.departmentId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                    />

                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.legal_update.name_of_business_function'
                                        )}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={Select}
                                        name="priority"
                                        className="form-control selectbox"
                                        options={priorityStatus}
                                        onChange={(opt) => {
                                            let priority = opt
                                                ? opt.value
                                                : '';
                                            setFieldValue(
                                                'priority',
                                                priority
                                            );
                                        }}
                                        value={priorityStatus?.filter(
                                            (option) =>
                                                option.value ===
                                                values.priority
                                        )}
                                        isClearable={true}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.legal_update.priority'
                                        )}
                                    </Label>
                                </FormGroup>
                                <Col lg="4">
                                    <Row>
                                        <FormGroup className="col-lg-6 w-100 sticky_label">
                                            <Field
                                                name="fromDate"
                                                component={DatePicker}
                                                isClearable={true}
                                                className="form-control"
                                                placeholderText={DATE_FORMAT_PLACEHOLDER}
                                                selected={
                                                    values.fromDate
                                                        ? getDateObjectWithValueFromMoment(
                                                            values.fromDate
                                                        )
                                                        : null
                                                }
                                                dateFormat={DATE_FORMAT_DATEPICKER}
                                                onChange={(date) => {

                                                    setFieldValue(`fromDate`, (date && date != null) ? date : '');

                                                    let dateCheck =
                                                        getDateObjectWithValueFromMoment(
                                                            values.toDate
                                                        ) <
                                                            getDateObjectWithValueFromMoment(
                                                                date
                                                            )
                                                            ? true
                                                            : false;
                                                    if (dateCheck) {
                                                        setFieldValue('toDate', '');
                                                    }
                                                }}
                                                // maxDate={getDateObjectWithValueFromMoment(
                                                //     this.props.maxDueDate
                                                // )}
                                                showMonthDropdown={true}
                                                showYearDropdown={true}
                                                dropdownMode="select"
                                                onFocus={(e) => e.target.blur()}
                                            />
                                            <Label className="form-label">
                                                {__t(
                                                    'form_labels.legal_update.from_period'
                                                )}{' '}
                                            </Label>
                                        </FormGroup>
                                        <FormGroup className="col-lg-6  w-100 sticky_label">
                                            <Field
                                                name="toDate"
                                                component={DatePicker}
                                                isClearable={true}
                                                className="form-control"
                                                placeholderText={DATE_FORMAT_PLACEHOLDER}
                                                selected={
                                                    values.toDate
                                                        ? getDateObjectWithValueFromMoment(
                                                            values.toDate
                                                        )
                                                        : null
                                                }
                                                dateFormat={DATE_FORMAT_DATEPICKER}
                                                onChange={(date) => {
                                                    setFieldValue(`toDate`, (date && date != null) ? date : '');
                                                }}
                                                showMonthDropdown={true}
                                                showYearDropdown={true}
                                                dropdownMode="select"
                                                onFocus={(e) => e.target.blur()}
                                                minDate={getDateObjectWithValueFromMoment(
                                                    values?.fromDate
                                                )}
                                            />
                                            <Label className="form-label">
                                                {__t(
                                                    'form_labels.legal_update.to_period'
                                                )}{' '}
                                            </Label>
                                        </FormGroup>
                                    </Row>
                                </Col>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={Select}
                                        name="complianceCategoryId"
                                        className="form-control selectbox"
                                        options={
                                            this.state
                                                .allCompliancesCategoriesList
                                        }
                                        onChange={(opt) => {
                                            setFieldValue(
                                                'complianceCategoryId',
                                                (opt) ? opt.value : ''
                                            );
                                        }}
                                        value={this.state.allCompliancesCategoriesList?.filter(
                                            (option) =>
                                                option.value ===
                                                values.complianceCategoryId
                                        )}
                                        isClearable={true}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.compliance.compliance_classification'
                                        )}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="contentStatus"
                                        className="form-control selectbox"
                                        options={contentStatus}
                                        onChange={(opt) => {
                                            let content_status = opt &&
                                                opt.map((x) => x.value);
                                            setFieldValue(
                                                'contentStatus',
                                                content_status
                                            );
                                        }}
                                        value={contentStatus?.filter(
                                            (option) =>
                                                values.contentStatus?.includes(
                                                    option.value
                                                )
                                        )}
                                        isClearable={true}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.legal_update.content_status'
                                        )}
                                    </Label>
                                </FormGroup>
                                <Col lg="4">
                                    <FormGroup className="mb-2 d-flex justify-content-end">
                                        <div className="d-inline-block">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="mr-1"
                                            >
                                                {SEARCH}
                                            </Button>
                                            <Button
                                                type="reset"
                                                color="secondary"
                                                onClick={this.resetForm}
                                            >
                                                {CLEAR}
                                            </Button>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedIndustryListDropdown: selectSortedIndustryList(),
    sortedSubIndustryListDropdown: selectSortedSubIndustryList(),
    sortedStateListDropdown: selectSortedStateList(),
    sortedCityListDropdown: selectSortedCityList(),
    sortedLawCategoryListDropdown: selectSortedLawCategoryList(),
    sortedLawListDropDown: selectSortedLawSearchList(),
    sortedEventListDropdown: selectSortedEventList(),
    allCompliancesCategoriesList: makeLawComplianceSelectField(
        'allCompliancesCategoriesList'
    ),
    updateTypeList: selectSortedUpdateTypeList('updateTypeList'),
    sortedDepartmentListDropdown: selectSortedDepartmentList(),
    legalUpdateStatusList: selectSortedLegalUpdateStatusList('legalUpdateStatusList'),
    sortedGcmAuthUserCountryList: selectGcmAuthUserCountryList('gcmAuthUserCountryList')
});
const withConnect = connect(mapStatetoProps, {
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getIndustryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
    getLawCategoryListByStatusRequest,
    getLawListRequest,
    getEventListByFilterForAllocationRequest,
    getComplianceCategoriesRequest,
    getAllupdateTypeListRequest,
    getDepartmentListByStatusRequest,
    getAllLegalUpdateStatusListRequest,
    getGcmAuthUserCountryListRequest
});
export default compose(
    withConnect,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withIndustryReducer,
    withIndustrySaga,
    withSubIndustryReducer,
    withSubIndustrySaga,
    withLawCategoryReducer,
    withLawCategorySaga,
    withLawReducer,
    withLawSaga,
    withEventReducer,
    withEventSaga,
    withLawComplianceReducer,
    withLawComplianceSaga,
    withLegalUpdatesReducer,
    withLegalUpdatesSaga,
    withDepartmentReducer,
    withDepartmentSaga,
    withRouter
)(LegalUpdateSearchForm);
