import { createSelector } from 'reselect';
import { initialState } from './reducer';
const reducerState = state => {
    return state.RegisteredAccount || initialState;
}
const makeLiteClientRegistrationSelectField = field =>
    createSelector(reducerState, registrationState => registrationState[field]);

export {
    makeLiteClientRegistrationSelectField,
};
