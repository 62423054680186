import React, { Component } from 'react'
import RegisteredAccounts from './RegisteredAccounts';
import AccountDetails from './AccountDetails';
import ComplianceDiscoveryDetails from './ComplianceDiscoveryDetails';
import QuestionMaster from './QuestionMaster';
import { getValueByKey, setValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
import { MODULE_LITE, MODULE_LITE_CLIENT_ACCOUNT_DETAILS, MODULE_LITE_COMPLIANCE_DISCOVERY_DETAILS, MODULE_LITE_QUESTION_MASTER, MODULE_LITE_REGISTERED_CLIENTS, PERMISSION_VIEW, ROLE_SUPER_ADMIN } from 'constants/databaseConstants';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import injectReducer from 'helpers/injectReducer';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getClientRegistrationRequest, getClientRegistrationResponse, putResendEmailRequest, putStatusUpdateClientRequest } from 'store/Lite/actions';
import injectSaga from 'helpers/injectSaga';
import RegisteredAccount from 'store/Lite/reducer';
import LiteRegistrationSaga from 'store/Lite/saga';
import { makeLiteClientRegistrationSelectField } from 'store/Lite/selector';
import __t from 'i18n/translator';
import { checkModulePermission } from 'helpers/projectUtils';
import { decryptData } from 'helpers/encryptor';

const registerClientKey = 'RegisteredAccount';
const withRegisterClientReducer = injectReducer({
    key: registerClientKey,
    reducer: RegisteredAccount,
});
const withRegisterClientSaga = injectSaga({ key: registerClientKey, saga: LiteRegistrationSaga });
class LiteModule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "registered_accounts",
            registrationClientList: [],
        }
    }
    componentDidMount() {
        const currentRole = JSON.parse(decryptData(getValueByKey(authConstants.USER_ROLES)));
        if (currentRole[0].roleId !== ROLE_SUPER_ADMIN.toString() && !checkModulePermission(MODULE_LITE, PERMISSION_VIEW)) {
            window.location.pathname = '/home';
        }
        setValueByKey(
            authConstants.LITE_LAYOUT,
            'true'
        );
        if (checkModulePermission(
            MODULE_LITE_REGISTERED_CLIENTS,
            PERMISSION_VIEW
        )) {
            this.setState({
                activeTab: "registered_accounts"
            });
        } else if (checkModulePermission(
            MODULE_LITE_CLIENT_ACCOUNT_DETAILS,
            PERMISSION_VIEW
        )) {
            this.setState({
                activeTab: "account_details"
            });
            return 'account_details'
        } else if (checkModulePermission(
            MODULE_LITE_COMPLIANCE_DISCOVERY_DETAILS,
            PERMISSION_VIEW
        )) {
            this.setState({
                activeTab: "compliance_discovery_details"
            });
        } else if (checkModulePermission(
            MODULE_LITE_QUESTION_MASTER,
            PERMISSION_VIEW
        )) {
            this.setState({
                activeTab: "question_master"
            });
        }
    }


    render() {
        return (
            <div className='lite-registration'>
                <div className="common-header">
                    <div className="common-flex-box">
                        <div className="page-header">
                            <h4>{__t("lite.gcms_pro_lite")}</h4>
                        </div>
                    </div>
                </div>
                <Nav tabs className="nav-tabs-custom nav-justified">
                    {checkModulePermission(
                        MODULE_LITE_REGISTERED_CLIENTS,
                        PERMISSION_VIEW
                    ) === true ? <NavItem>
                        <NavLink
                            className={classnames('btn btn-primary', { active: this.state.activeTab === 'registered_accounts', })}
                            onClick={() => {
                                this.setState({
                                    activeTab: "registered_accounts"
                                });
                            }}
                        >
                            {__t('lite.registered_account')}
                        </NavLink>
                    </NavItem> : null}
                    {checkModulePermission(
                        MODULE_LITE_CLIENT_ACCOUNT_DETAILS,
                        PERMISSION_VIEW
                    ) === true ?
                        <NavItem>
                            <NavLink
                                className={classnames('btn btn-success', { active: this.state.activeTab === 'account_details', })}
                                onClick={() => {
                                    this.setState({
                                        activeTab: "account_details"
                                    });
                                }}
                            >
                                {__t('lite.account_details')}
                            </NavLink>
                        </NavItem> : null}
                    {checkModulePermission(
                        MODULE_LITE_COMPLIANCE_DISCOVERY_DETAILS,
                        PERMISSION_VIEW
                    ) === true ?
                        <NavItem>
                            <NavLink
                                className={classnames('btn btn-orange', { active: this.state.activeTab === 'compliance_discovery_details', })}
                                onClick={() => {
                                    this.setState({
                                        activeTab: "compliance_discovery_details"
                                    });
                                }}
                            >
                                {__t('lite.compliance_details')}
                            </NavLink>
                        </NavItem> : null}
                    {checkModulePermission(
                        MODULE_LITE_QUESTION_MASTER,
                        PERMISSION_VIEW
                    ) === true ?
                        <NavItem>
                            <NavLink
                                className={classnames('btn btn-info', { active: this.state.activeTab === 'question_master', })}
                                onClick={() => {
                                    this.setState({
                                        activeTab: "question_master"
                                    });
                                }}
                            >
                                {__t("lite.question_master")}
                            </NavLink>
                        </NavItem> : null}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    {
                        checkModulePermission(
                            MODULE_LITE_REGISTERED_CLIENTS,
                            PERMISSION_VIEW
                        ) === true ?
                            <TabPane className="laws_tab" tabId="registered_accounts">
                                <h5>{__t("lite.registered_account")}</h5>
                                <RegisteredAccounts
                                    getClientRegistrationRequest={this.props.getClientRegistrationRequest}
                                    registrationClient={this.props.registeredAccounts}
                                    putResendEmailRequest={this.props.putResendEmailRequest}
                                    resendEmail={this.props.resendEmail}
                                    putStatusUpdateClientRequest={this.props.putStatusUpdateClientRequest}
                                    statusUpdate={this.props.statusUpdate}
                                />
                            </TabPane> : null}
                    {
                        checkModulePermission(
                            MODULE_LITE_CLIENT_ACCOUNT_DETAILS,
                            PERMISSION_VIEW
                        ) === true ? <TabPane className="laws_tab" tabId="account_details">
                            <AccountDetails />
                        </TabPane> : null}
                    {
                        checkModulePermission(
                            MODULE_LITE_COMPLIANCE_DISCOVERY_DETAILS,
                            PERMISSION_VIEW
                        ) === true ? <TabPane className="laws_tab" tabId="compliance_discovery_details">
                            <ComplianceDiscoveryDetails />
                        </TabPane> : null
                    }
                    {
                        checkModulePermission(
                            MODULE_LITE_QUESTION_MASTER,
                            PERMISSION_VIEW
                        ) === true ? <TabPane className="laws_tab" tabId="question_master">
                            <QuestionMaster />
                        </TabPane> : null
                    }
                </TabContent>
            </div>
        )
    }
}

const mapStatetoProps = createStructuredSelector({
    registeredAccounts: makeLiteClientRegistrationSelectField(
        'registeredAccounts'
    ),
    resendEmail: makeLiteClientRegistrationSelectField(
        'resendEmail'
    ),
    statusUpdate: makeLiteClientRegistrationSelectField(
        'statusUpdate'
    ),

});
const withConnect = connect(mapStatetoProps, {
    getClientRegistrationRequest,
    getClientRegistrationResponse,
    putResendEmailRequest,
    putStatusUpdateClientRequest
});
export default compose(
    withConnect,
    withRegisterClientReducer,
    withRegisterClientSaga,
)(LiteModule);