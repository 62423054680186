import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
import ReactTooltip from 'react-tooltip';
import { NA } from 'constants/commonConstants';
import { getFullDateTimeFromDateTimeString } from 'helpers/dateTimeHelper';
const listColumns = [
    {
        label: 'Code',
        field: 'legalUpdateCode',
    },
    {
        label: 'Country',
        field: 'country',
    },
    {
        label: 'Area Of Law',
        field: 'area_of_law',
    },
    {
        label: 'Law Short Name',
        field: 'lawShortName',
    },
    {
        label: 'Update Type',
        field: 'updateType',
    },
    {
        label: 'Compliance Classification',
        field: 'complianceClassification',
    },

    {
        label: 'Date of Publication',
        field: 'dateOfPublication',
    },
    {
        label: 'Effective Date',
        field: 'effectiveDate',
    },
    {
        label: 'Status',
        field: 'status',
    },
    {
        label: 'Role',
        field: 'role',
    },
    {
        label: 'Created On',
        field: 'createdAt',
    },
    {
        label: 'Actions',
        field: 'actions',
        sort: 'disabled',
    },
];

class LegalUpdateList extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            loginUserId: parseInt(getValueByKey(authConstants.USER_ID)),
            legalUpdateList: [],
        };
    }

    getRoleName = (values) => {
        let roleName = '';
        if (values.createdBy === this.state.loginUserId) {
            roleName = 'Checker';
        } else if (values.approverUserId === this.state.loginUserId) {
            roleName = 'Approver';
        } else if (values.updaterUserId === this.state.loginUserId) {
            roleName = 'Updater';
        } else if (values.superAdminId === this.state.loginUserId) {
            roleName = 'SUPER ADMIN';
        }
        return roleName;
    };
    getUpdateTypeName = (value) => {
        let data = this.props.updateTypeList
            ?.filter((x) => value?.includes(x.value))
            ?.map((y) => y.label);
        if (data.length == 1) {
            return data.map((x) => {
                return x;
            });
        }
        return (
            <ul className="update_type_list">
                {data.map((x) => {
                    return (
                        <>
                            <li>{x}</li>
                        </>
                    );
                })}
            </ul>
        );
    };
    generateTable = (records) => {
        let legalUpdateRecords = [];
        legalUpdateRecords = records.map((legalUpdatesRecord, i) => {
            let actions = (
                <div className="text-center action-d-box">
                    <a
                        href={() => false}
                        title="View Details"
                        className="btn-link text-success"
                        onClick={() => {
                            this.redireactToLegalUpdate(legalUpdatesRecord.id)

                        }}
                    >
                        <i className="far fa-eye m-0"></i>
                    </a>
                </div>
            );

            const roleNameInfo = this.getRoleName(legalUpdatesRecord);
            let roleName =
                roleNameInfo != '' ? (
                    <div className="badge role-badge"> {roleNameInfo}</div>
                ) : (
                    '-'
                );

            return {
                legalUpdateCode: legalUpdatesRecord.legalUpdateCode,
                country: legalUpdatesRecord.country.countryName,
                area_of_law: legalUpdatesRecord.lawCategory.lawCategoryName,
                lawShortName: legalUpdatesRecord.law
                    ? legalUpdatesRecord.law.lawShortName
                    : NA,
                updateType: this.getUpdateTypeName(
                    legalUpdatesRecord.updateTypeId
                ),
                complianceClassification: legalUpdatesRecord?.complianceCategory
                    ?.complianceCategoryName
                    ? legalUpdatesRecord?.complianceCategory
                        ?.complianceCategoryName
                    : NA,
                dateOfPublication: legalUpdatesRecord?.dateOfPublication,
                effectiveDate: legalUpdatesRecord?.effectiveDate
                    ? legalUpdatesRecord?.effectiveDate
                    : NA,
                status: legalUpdatesRecord?.legalUpdateStatus?.statusName,
                role: roleName,
                createdAt: getFullDateTimeFromDateTimeString(
                    legalUpdatesRecord.createdAt
                ),
                actions: actions,
            };
        });
        return legalUpdateRecords;
    };
    redireactToLegalUpdate = (id) => {
        this.props.history.push(
            '/legal-update/view-details/' +
            id,
            '_blank'
        );
    }
    generateTableForAgeing = (records) => {
        let legalUpdateRecords = [];
        legalUpdateRecords = records.map((legalUpdatesRecord, i) => {
            let actions = (
                <div className="text-center action-d-box">
                    <a
                        href={() => false}
                        title="View Details"
                        className="btn-link text-success"
                        onClick={() => {
                            this.redireactToLegalUpdate(legalUpdatesRecord.id)
                        }}
                    >
                        <i className="far fa-eye m-0"></i>
                    </a>
                </div>
            );

            const roleNameInfo = this.getRoleName(legalUpdatesRecord);
            let roleName =
                roleNameInfo != '' ? (
                    <div className="badge role-badge"> {roleNameInfo}</div>
                ) : (
                    '-'
                );

            return {
                legalUpdateCode: legalUpdatesRecord.legalUpdateCode,
                country: legalUpdatesRecord.countryName,
                area_of_law: legalUpdatesRecord.lawCategoryName,
                lawShortName: legalUpdatesRecord.lawShortName
                    ? legalUpdatesRecord.lawShortName
                    : NA,
                updateType: this.getUpdateTypeName(
                    legalUpdatesRecord.updateTypeId
                ),
                complianceClassification:
                    legalUpdatesRecord?.complianceCategoryName
                        ? legalUpdatesRecord?.complianceCategoryName
                        : NA,
                dateOfPublication: legalUpdatesRecord?.dateOfPublication,
                effectiveDate: legalUpdatesRecord?.effectiveDate
                    ? legalUpdatesRecord?.effectiveDate
                    : NA,
                status: legalUpdatesRecord?.statusName,
                role: roleName,
                createdAt: getFullDateTimeFromDateTimeString(
                    legalUpdatesRecord.createdAt
                ),
                actions: actions,
            };
        });
        return legalUpdateRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.clickedData) {
            if (nextprops.clickableType != 'ageing') {
                this.setState({
                    legalUpdateList: this.generateTable(nextprops.clickedData),
                });
            } else {
                this.setState({
                    legalUpdateList: this.generateTableForAgeing(
                        nextprops.clickedData
                    ),
                });
            }
        }
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.legalUpdateList,
        };
        return (
            <React.Fragment>
                <ReactTooltip />
                <Row>
                    <Col lg="12">
                        <Link
                            to="#"
                            className="btn btn-primary mb-3"
                            onClick={() => {
                                this.props.closeClickedData();
                            }}
                        >
                            Back
                        </Link>
                        <Card>
                            <CardBody className="custom_table">
                                <CustomisedDatatable
                                    tableRecords={data}
                                    sortRows={['dueDate']}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({});
const withConnect = connect(mapStatetoProps, {});
export default compose(withConnect, withRouter)(LegalUpdateList);
