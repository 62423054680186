export const ADD_LAW_COMPLIANCE_REQUEST = 'add_law_compliance_request';
export const ADD_LAW_COMPLIANCE_RESPONSE = 'add_law_compliance_success';
export const GET_ALL_UPLOADED_FILES_REQUEST = 'get_all_uploaded_files_request';
export const GET_ALL_UPLOADED_FILES_RESPONSE =
    'get_all_uploaded_files_response';
export const SHOW_LAW_COMPLIANCE = 'show_law_compliance';
export const GET_ALL_LAW_COMPLIANCES_REQUEST =
    'get_all_law_compliances_request';
export const GET_ALL_LAW_COMPLIANCES_RESPONSE =
    'get_all_law_compliances_response';
export const GET_LAW_COMPLIANCES_BY_LAW_ID_REQUEST =
    'get_law_compliances_by_law_id_request';
export const GET_LAW_COMPLIANCES_BY_LAW_ID_RESPONSE =
    'get_law_compliances_by_law_id_response';
export const GET_LAW_COMPLIANCES_BY_LAW_CATEGORY_ID_REQUEST =
    'get_law_compliances_by_law_category_id_request';
export const GET_LAW_COMPLIANCES_BY_LAW_CATEGORY_ID_RESPONSE =
    'get_law_compliances_by_law_category_id_response';
export const GET_LAW_COMPLIANCE_DETAILS_BY_LAW_COMPLIANCE_ID_REQUEST =
    'get_law_compliance_details_by_law_compliance_id_request';
export const GET_LAW_COMPLIANCE_DETAILS_BY_LAW_COMPLIANCE_ID_RESPONSE =
    'get_law_compliance_details_by_law_compliance_id_response';
export const GET_LAW_COMPLIANCES_FOR_ALLOCATION_REQUEST =
    'get_law_compliances_for_allocation_request';
export const GET_LAW_COMPLIANCES_FOR_ALLOCATION_RESPONSE =
    'get_law_compliances_for_allocation_response';
export const UPDATE_LAW_COMPLIANCE_STATUS_BY_LAW_COMPLIANCE_ID_REQUEST =
    'update_law_compliance_status_by_law_compliance_id_request';
export const UPDATE_LAW_COMPLIANCE_STATUS_BY_LAW_COMPLIANCE_ID_RESPONSE =
    'update_law_compliance_status_by_law_compliance_id_response';

export const GET_LAW_COMPLIANCE_SEARCH_REQUEST =
    'get_law_compliance_search_request';
export const GET_LAW_COMPLIANCE_SEARCH_RESPONSE =
    'get_law_compliance_search_response';

export const GET_COMPLIANCE_TYPES_REQUEST = 'get_compliance_types_request';
export const GET_COMPLIANCE_TYPES_RESPONSE = 'get_compliance_types_response';

export const GET_COMPLIANCE_CATEGORIES_REQUEST =
    'get_compliance_categories_request';
export const GET_COMPLIANCE_CATEGORIES_RESPONSE =
    'get_compliance_categories_response';

export const GET_PERIODICITIES_REQUEST = 'get_periodicities_request';
export const GET_PERIODICITIES_RESPONSE = 'get_periodicities_response';

export const GET_PENALTY_TYPES_REQUEST = 'get_penalty_types_request';
export const GET_PENALTY_TYPES_RESPONSE = 'get_penalty_types_response';

export const GET_RISK_RATING_REQUEST = 'get_risk_rating_request';
export const GET_RISK_RATING_RESPONSE = 'get_risk_rating_response';

export const GET_IMPACT_MANAGEMENT_PERSONNELS_REQUEST = 'get_impact_management_personnels_request';
export const GET_IMPACT_MANAGEMENT_PERSONNELS_RESPONSE = 'get_impact_management_personnels_response';

export const UPDATE_LAW_COMPLIANCE_REQUEST = 'update_law_compliance_request';
export const UPDATE_LAW_COMPLIANCE_RESPONSE = 'update_law_compliance_response';

export const GET_LAW_COMPLIANCE_LOW_ID_REQUEST =
    'get_law_compliance_low_id_request';
export const GET_LAW_COMPLIANCE_LOW_ID_RESPONSE =
    'get_law_compliance_low_id_response';

export const GET_LAW_SECTION_BY_LOW_ID_REQUEST =
    'get_law_section_by_low_id_request';
export const GET_LAW_SECTION_BY_LOW_ID_RESPONSE =
    'get_law_section_by_low_id_response';

export const GET_LEGAL_UPDATE_REFERENCE_ID_REQUEST =
    'get_legal_update_reference_id_request';
export const GET_LEGAL_UPDATE_REFERENCE_ID_RESPONSE =
    'get_legal_update_reference_id_response';

export const GET_LAW_COMPLIANCE_VERSIONS_BY_COMPLIANCE_ID_REQUEST =
    'get_law_compliance_versions_by_compliance_id_request';
export const GET_LAW_COMPLIANCE_VERSIONS_BY_COMPLIANCE_ID_RESPONSE =
    'get_law_compliance_versions_by_compliance_id_response';

export const DELETE_COMPLIANCE_QUEUE_FILE_BY_ID_REQUEST =
    'delete_compliance_queue_file_by_id_request';
export const DELETE_COMPLIANCE_QUEUE_FILE_BY_ID_RESPONSE =
    'delete_compliance_queue_file_by_id_response';
export const CLEAR_RISK_RATING_RESPONSE = 'clear_risk_rating_response';
export const CLEAR_COMPLIANCE_CATEGORY_RESPONSE = 'clear_compliance_category_response';
export const CLEAR_COMPLIANCE_TYPE_RESPONSE = 'clear_compliance_type_response';

export const SET_COMPLIANCE_FOR_LITE_RESPONSE = 'set-compliance-for-lite-response';
export const SET_COMPLIANCE_FOR_LITE_REQUEST = 'set-compliance-for-lite-request';