import React, { Component } from 'react'

class ComplianceDiscoveryDetails extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return <div>
            ComplianceDiscoveryDetails
        </div>
    }
}

export default ComplianceDiscoveryDetails