import CustomisedReactTableWithPagination from 'components/Application/CustomisedReactTableWithPagination';
import React, { Component } from 'react'
import { Card, CardBody, Col } from 'reactstrap';

const UnVerifiedAccounts = [
    {
        Header: 'User Full Name',
        accessor: 'userFullName',
    },
    {
        Header: 'Account Name',
        accessor: 'accountName',
    },
    {
        Header: 'Email Id',
        accessor: 'emailId',
    },

    {
        Header: 'Request Date',
        accessor: 'createdAt',
    },
    {
        Header: 'Mobile Number',
        accessor: 'mobileNo',
        disableSortBy: true,
    },
    {
        Header: 'Company Full Name',
        accessor: 'companyFullName',
    },
    {
        Header: 'Website',
        accessor: 'companyWebsite',
        disableSortBy: true,
    },
    {
        Header: 'Action',
        TransHeader: 'common.action',
        accessor: 'actions',
        disableSortBy: true,
    },
];

class UnVerifiedAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modale: false
        }
    }

    render() {
        const data2 = {
            columns: UnVerifiedAccounts,
            rows: this.props.data,
        };
        return <>
            <Col lg="12">
                <Card>
                    <CardBody className="custom_table table-responsive">
                        <CustomisedReactTableWithPagination
                            className="table table-bordered table-striped"
                            displayEntries={
                                true
                            }
                            searching={true}
                            handleFetchData={this.props.handleFetchData}
                            handleSort={this.props.handleSort}
                            handleSearch={this.props.handleSearch}
                            currentPage={
                                this.props.currentPage
                            }

                            pageCount={
                                this.props.pageCount
                            }
                            preFilledSearchText={
                                this.props.searchFormData
                                    ?.searchText
                            }
                            tableData={data2}
                            totalRecords={this.props.totalRecords}
                        />
                    </CardBody>
                </Card>
            </Col>
        </>
    }
}

export default UnVerifiedAccount;