import { LITE_PLAN_SELECTION_REQUEST, LITE_PLAN_SELECTION_RESPONSE, LITE_PRICING_LIST_REQUEST, LITE_PRICING_LIST_RESPONSE } from "./actionTypes";

export const getLitePricingRequest = () => {
    return {
        type: LITE_PRICING_LIST_REQUEST,
    };
};

export const getLitePricingResponse = data => {
    return {
        type: LITE_PRICING_LIST_RESPONSE,
        payload: { data },
    };
};

export const selectPlanSelectionRequest = data => {
    return {
        type: LITE_PLAN_SELECTION_REQUEST,
        payload: { data }
    };
};

export const getPlanSelectionResponse = data => {
    return {
        type: LITE_PLAN_SELECTION_RESPONSE,
        payload: { data }
    };
};


