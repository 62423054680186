export const ADD_ENTITY_GROUP_REQUEST = 'add_company_group_request';
export const ADD_ENTITY_GROUP_RESPONSE = 'add_company_group_response';
export const GET_ALL_ENTITY_GROUP_LIST_REQUEST = 'get_all_company_group_list_request';
export const GET_ALL_ENTITY_GROUP_LIST_RESPONSE = 'get_all_company_group_list_response';
export const GET_ENTITY_GROUP_BY_ENTITY_GROUP_ID_REQUEST = 'get_company_group_by_company_group_id_request';
export const GET_ENTITY_GROUP_BY_ENTITY_GROUP_ID_RESPONSE = 'get_company_group_by_company_group_id_response';
export const UPDATE_ENTITY_GROUP_REQUEST = 'update_company_group_request';
export const UPDATE_ENTITY_GROUP_RESPONSE = 'update_company_group_response';
export const UPDATE_ENTITY_GROUP_STATUS_BY_ENTITY_GROUP_ID_REQUEST = 'update_company_group_status_by_company_group_id_request';
export const UPDATE_ENTITY_GROUP_STATUS_BY_ENTITY_GROUP_ID_RESPONSE = 'update_company_group_status_by_company_group_id_response';
export const GET_ENTITY_GROUP_LIST_BY_STATUS_REQUEST = 'get_company_group_list_by_status_request';
export const GET_ENTITY_GROUP_LIST_BY_STATUS_RESPONSE = 'get_company_group_list_by_status_response';
export const DELETE_ENTITY_GROUP_BY_ENTITY_GROUP_ID_REQUEST = 'delete_company_group_by_company_group_id_request';
export const DELETE_ENTITY_GROUP_BY_ENTITY_GROUP_ID_RESPONSE = 'delete_company_group_by_company_group_id_response';
export const CLEAR_COMPANY_GROUP_LIST_RESPONSE = 'clear_company_group_list_response'
export const GET_ALL_COMPANY_GROUPS_LIST_ON_USER_LIST_PAGE_REQUEST = 'get_all_company_groups_list_on_user_list_page_request';
export const GET_ALL_COMPANY_GROUPS_LIST_ON_USER_LIST_PAGE_RESPONSE = 'get_all_company_groups_list_on_user_list_page_response';
