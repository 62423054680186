import { isGTROLE } from 'helpers/projectUtils';
import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

class CommonBreadcrum extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <React.Fragment>
                {(() => {
                    let breadCrumbHTML = '';
                    switch (this.props.type) {
                        case 'user':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Masters
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Company Setup
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        User
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'tenant':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Masters
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Company Setup
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Tenant
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'add-tenant':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Masters
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Company Setup
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href={'/tenants'}>
                                        Tenant
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        {this.props.edit
                                            ? 'Edit Tenant'
                                            : 'Add Tenant'}
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'company-group':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Masters
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Company Setup
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Company Group
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'entity':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Masters
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Company Setup
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Entity
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'add-entity':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Masters
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Company Setup
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href={'/entities'}>
                                        Entity
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        {this.props.edit
                                            ? 'Edit Entity'
                                            : 'Add Entity'}
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'view-entity':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Masters
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Company Setup
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href={'/entities'}>
                                        Entity
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        View Entity
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'entity-location':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Masters
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Company Setup
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Entity Location
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'law-category':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Masters
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Internal Activity
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Law Category
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'law':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Masters
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Internal Activity
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Law
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'add-law':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Masters
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Internal Activity
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href={'/laws'}>
                                        Law
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        {this.props.edit
                                            ? 'Edit Law'
                                            : 'Add Law'}
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'section-rule':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Masters
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Internal Activity
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Section/Rule
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;

                        case 'country':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Masters
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Country, State & City
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Country
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'state':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Masters
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Country, State & City
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        State/Province
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'city':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Masters
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Country, State & City
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        City/Location
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'industry':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Masters
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Internal Activity
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Industry
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'applicablity':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Masters
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Internal Activity
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Applicablity
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'department':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Masters
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Company Setup
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Department
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'event':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Masters
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Internal Activity
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Event Master
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'upload-compliance':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Masters
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Internal Activity
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Upload Compliance
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'excel-upload':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Masters
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Excel Upload
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'system-configuration':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Masters
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        System Configuration
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'country-coverage':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Compliance Library
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Statutory Compliance
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Country Coverage
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'statutory-compliances':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Compliance Library
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Statutory Compliance
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        View Laws & Compliances
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'statutory-compliances-view-details':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Compliance Library
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Statutory Compliance
                                    </BreadcrumbItem>
                                    <BreadcrumbItem
                                        tag="a"
                                        href="/statutory-compliances"
                                    >
                                        View Laws & Compliances
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Compliances Details
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'know-your-compliances':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Compliance Library
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Statutory Compliance
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Know Your Compliances
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'track-compliance-status':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Compliance Library
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Statutory Compliance
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Track Compliance Status
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'report-compliances':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Compliance Library
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Statutory Compliance
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Report Compliances
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'review-compliances':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Compliance Library
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Statutory Compliance
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Review/Approve Compliances
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'audit-compliances':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Compliance Library
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Statutory Compliance
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Audit
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;

                        case 'task-details':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Compliance Library
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Statutory Compliance
                                    </BreadcrumbItem>
                                    {this.props.pageType === 'report' && (
                                        <BreadcrumbItem tag="a" href={'/compliances/report'}>
                                            Report Compliances
                                        </BreadcrumbItem>
                                    )}
                                    {this.props.pageType === 'review' && (
                                        <BreadcrumbItem tag="a" href={'/compliances/review'}>
                                            Review Compliances
                                        </BreadcrumbItem>
                                    )}
                                    {this.props.pageType === 'audit' && (
                                        <BreadcrumbItem tag="a" href={'/compliances/audit'}>
                                            Audit Compliances
                                        </BreadcrumbItem>
                                    )}
                                    <BreadcrumbItem active tag="span">
                                        Task Details
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'standard-documents':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Doc Valut
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Standard Documents
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'transactional-documents':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Doc Valut
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Transactional Documents
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'archive-records':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Doc Valut
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Archive Records
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'new-allocation':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Allocation
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        New Allocation
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'users-allocation':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Allocation
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        User Allocation
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'event-allocation':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Allocation
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Event Allocation
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'view-allocation':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Allocation
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        View Allocation
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'track-allocation':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Allocation
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        New Allocation Status
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'allocation-review':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Allocation
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Allocation Review
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'generate-report':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Reports & certificates
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Generate Report
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'generate-certificate':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Reports & certificates
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Generate Certificate
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'legal-updates':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Update Center
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Legal Updates
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'legal-updates-details':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Update Center
                                    </BreadcrumbItem>
                                    <BreadcrumbItem
                                        tag="a"
                                        href="/legal-updates"
                                    >
                                        Legal Updates
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Legal Updates Details
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'legal-watch-dog':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Update Center
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Legal WatchDog
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'add-legal-watch-dog':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Update Center
                                    </BreadcrumbItem>
                                    <BreadcrumbItem
                                        tag="a"
                                        href="/legal-watch-dog"
                                    >
                                        Legal WatchDog
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        {this.props.edit
                                            ? 'Edit Legal Watchdog'
                                            : 'Add Legal Watchdog'}
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'relevant-updates':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Update Center
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Relevant Updates
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;

                        case 'relevant-updates-details':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Update Center
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href={'/relevant-updates'}>
                                        Relevant Updates
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        View Relevant Update
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;

                        case 'relevant-updates-analysis':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Update Center
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href={'/relevant-updates'}>
                                        {isGTROLE() ? 'Legal Updates' : 'Relevant Updates'}
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        View Update Analysis
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;

                        case 'view-legal-watch-dog':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Update Center
                                    </BreadcrumbItem>
                                    <BreadcrumbItem
                                        tag="a"
                                        href="/legal-watch-dog"
                                    >
                                        Legal WatchDog
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        View Legal Watchdog
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'new-additions':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Update Center
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        New Additions
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'assigned-event':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Manage Event
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Plan Events
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'ongoing-event':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Manage Event
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Ongoing Events
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'executed-event':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Manage Event
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Closed Events
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'delegation':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>Home                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">Service Desk                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">Change Request                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">Delegation                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'change-details':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Service Desk
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Change Request
                                    </BreadcrumbItem>

                                    <BreadcrumbItem active tag="span">
                                        Change Details
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'event-details':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Event Compliances
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'support-tickets':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Service Desk
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Support Tickets
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'add-support-ticket':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Service Desk
                                    </BreadcrumbItem>
                                    <BreadcrumbItem
                                        tag="a"
                                        href="/support-tickets"
                                    >
                                        Support Tickets
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        {this.props.edit
                                            ? 'Edit Ticket'
                                            : 'Create New Ticket'}
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'view-ticket':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href="#">
                                        Service Desk
                                    </BreadcrumbItem>
                                    <BreadcrumbItem
                                        tag="a"
                                        href="/support-tickets"
                                    >
                                        Support Tickets
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        View Ticket
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        case 'countries-list':
                            breadCrumbHTML = (
                                <Breadcrumb tag="nav">
                                    <BreadcrumbItem tag="a" href={'/home'}>
                                        Home
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="span" active>
                                        Countries
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            );
                            break;
                        default:
                            break;
                    }
                    return breadCrumbHTML;
                })()}
            </React.Fragment>
        );
    }
}
export default CommonBreadcrum;
