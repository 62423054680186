import {
    AUDITED_AND_CLOSED,
    PENDING_FOR_AUDIT,
    PENDING_WITH_FIRST_APPROVER,
    PENDING_WITH_SECOND_APPROVER,
    SEND_BACK_BY_AUDITOR_TO_APPROVER,
    SEND_BACK_TO_APPROVER,
} from 'constants/databaseConstants';
import {
    getDayDateName,
    getMonthName,
    getYearName,
} from 'helpers/dateTimeHelper';
import { getInitials } from 'helpers/generalUtils';
import React from 'react';
import { Button, Collapse } from 'reactstrap';
class SubTaskItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openItemID: '',
        };
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick = (itemID) => {
        if (itemID === this.state.openItemID) {
            this.setState({ openItemID: '' });
        } else {
            this.setState({ openItemID: itemID });
        }
    };
    render() {
        const findTaskStatusName = this.props?.taskStatusList
            && this.props?.taskStatusList.length > 0 ? this.props?.taskStatusList.find((element) => element.value == this.props.subTask?.taskStatusId)?.label ?? "" : "";
        return (
            <React.Fragment key={'main-' + this.props.subTask.id}>
                <div className="tree-item">
                    <div className="inner-item">
                        <svg
                            onClick={() =>
                                this.handleClick(this.props.subTask.id)
                            }
                            width="48px"
                            height="48px"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                width="48"
                                height="48"
                                fill="white"
                                fillOpacity="0.01"
                            />
                            <path
                                d="M37 18L25 30L13 18"
                                stroke="currentColor"
                                strokeWidth="4"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <b
                            onClick={() =>
                                this.handleClick(this.props.subTask.id)
                            }
                            key={this.props.subTask.id}
                        >
                            <a href={() => false}>
                                {this.props.subTask.title}
                            </a>
                            <label title="Due date">
                                {getDayDateName(this.props.subTask.dueDate)}-
                                {getMonthName(this.props.subTask.dueDate)}-
                                {getYearName(this.props.subTask.dueDate)}
                            </label>
                            <span>{this.props.subTask.description}</span>
                        </b>
                        <div className="task-action">
                            <p
                                className="submitter"
                                title={
                                    this.props.subTask?.submitterUser?.fullName
                                }
                            >
                                {getInitials(
                                    this.props.subTask?.submitterUser?.fullName
                                )}
                            </p>
                            {this.props.subTask.firstApproverUserId !== '' &&
                                this.props.subTask?.firstApproverUserId !==
                                null && (
                                    <p
                                        className="approver"
                                        title={
                                            this.props.subTask
                                                ?.firstApproverUser?.fullName
                                        }
                                    >
                                        {getInitials(
                                            this.props.subTask
                                                ?.firstApproverUser?.fullName
                                        )}
                                    </p>
                                )}
                            {this.props.subTask.secondApproverUserId !== '' &&
                                this.props.subTask?.secondApproverUserId !==
                                null && (
                                    <p
                                        className="approvertwo"
                                        title={
                                            this.props.subTask
                                                ?.secondApproverUser?.fullName
                                        }
                                    >
                                        {getInitials(
                                            this.props.subTask
                                                ?.secondApproverUser?.fullName
                                        )}
                                    </p>
                                )}
                            <span className="badge badge-success">
                                {findTaskStatusName}
                            </span>
                            {(this.props.subTask.submitterId ===
                                this.props.loginUserId ||
                                this.props.subTask.firstApproverUserId ===
                                this.props.loginUserId ||
                                (this.props.subTask.secondApproverUserId !==
                                    '' &&
                                    this.props.subTask.secondApproverUserId ===
                                    this.props.loginUserId) ||
                                this.props.hodUserIds.includes(
                                    this.props.loginUserId
                                ) ||
                                this.props.loginUserId ===
                                this.props.subTask.createdBy) && (
                                    <React.Fragment>
                                        {this.props.loginUserId ===
                                            this.props.subTask.createdBy && (
                                                <React.Fragment>
                                                    <Button
                                                        className="text-success btn-link py-0 px-2 ml-2"
                                                        color="link"
                                                        title="Edit Sub task"
                                                        onClick={() => {
                                                            this.props.openEditSubTaskModal(
                                                                this.props.subTask
                                                            );
                                                        }}
                                                    >
                                                        <i className="fas fa-pencil-alt"></i>
                                                    </Button>
                                                    <Button
                                                        className="text-danger py-0 px-2"
                                                        color="link"
                                                        title="Delete task"
                                                        onClick={() => {
                                                            this.props.deleteSubTask(
                                                                this.props.subTask.id
                                                            );
                                                        }}
                                                    >
                                                        <i className="fas fa-trash-alt"></i>
                                                    </Button>
                                                </React.Fragment>
                                            )}
                                        <Button
                                            className="text-primary py-0 px-1 task_icon"
                                            color="link"
                                            onClick={() => {
                                                this.props.openSubTaskModal(
                                                    this.props.subTask.id,
                                                    this.props.subTask
                                                );
                                            }}
                                        >
                                            <i className="fas fa-plus fa-fw"></i>
                                        </Button>
                                    </React.Fragment>
                                )}

                            <Button
                                className="text-primary py-0 px-2"
                                color="link"
                                title="Reporting"
                                onClick={() => {
                                    let type = 'report';
                                    if (
                                        [
                                            PENDING_FOR_AUDIT,
                                            AUDITED_AND_CLOSED,
                                        ].includes(
                                            this.props.subTask.taskStatusId
                                        )
                                    ) {
                                        type = 'audit';
                                    }
                                    if (
                                        [
                                            PENDING_WITH_FIRST_APPROVER,
                                            PENDING_WITH_SECOND_APPROVER,
                                            SEND_BACK_TO_APPROVER,
                                            SEND_BACK_BY_AUDITOR_TO_APPROVER,
                                        ].includes(
                                            this.props.subTask.taskStatusId
                                        )
                                    ) {
                                        type = 'review';
                                    }
                                    window.location.replace(
                                        '/compliances/' +
                                        type +
                                        '/' +
                                        this.props.subTask.id
                                    );
                                }}
                            >
                                <i className="fas fa-file-import"></i>
                            </Button>
                        </div>
                    </div>
                    <Collapse
                        isOpen={this.state.openItemID === this.props.subTask.id}
                        unmountOnExit
                    >
                        {this.props.subTask?.subTask.length > 0 &&
                            this.props.subTask.subTask.map((subTask, key) => {
                                return (
                                    <div
                                        className={
                                            subTask.subTask.length > 0
                                                ? ' tree-parent has-child '
                                                : ' tree-parent '
                                        }
                                        key={'child-' + subTask.id}
                                    >
                                        <SubTaskItem
                                            openSubTaskModal={
                                                this.props.openSubTaskModal
                                            }
                                            openEditSubTaskModal={
                                                this.props.openEditSubTaskModal
                                            }
                                            getShowMoreTextElement={
                                                this.props
                                                    .getShowMoreTextElement
                                            }
                                            deleteSubTask={
                                                this.props.deleteSubTask
                                            }
                                            history={this.props.history}
                                            subTask={subTask}
                                            loginUserId={this.props.loginUserId}
                                            hodUserIds={this.props.hodUserIds}
                                            taskStatusList={this.props.taskStatusList}
                                        />
                                    </div>
                                );
                            })}
                    </Collapse>
                </div>
            </React.Fragment>
        );
    }
}
export default SubTaskItem;
