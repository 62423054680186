import React, { Component } from 'react';
import {
    Row,
    Col,
    FormGroup,
    Button,
    Label,
    CardBody,
    Card,
    CardHeader,
    CustomInput,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    SUCCESS,
    UPDATE,
    CANCEL,
    SUBMIT,
    NONE,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { toastrMessage } from 'helpers/messageHelper';
import { createStructuredSelector } from 'reselect';
import {
    addTenantRequest,
    updateTenantRequest,
    getTenantByTenantIdRequest,
    filterUserRequest,
    generateDatabaseByTenantIdRequest,
    cleareFilterUserResponse
} from 'store/actions';
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import tenantReducer from 'store/Masters/Tenant/reducer';
import tenantSaga from 'store/Masters/Tenant/saga';
import userReducer from 'store/User/reducer';
import userSaga from 'store/User/saga';
import { customValidTextForTenantNameField } from 'helpers/customValidators';
import { initialTenantDataState } from './CommonFunctions';
import __t from 'i18n/translator';
import { makeTenantSelectField } from 'store/Masters/Tenant/selector';
import 'react-datepicker/dist/react-datepicker.css';
import { selectSortedUserList } from 'store/User/selector';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import { getFirstCapitalize } from 'helpers/generalUtils';
import CustomisedModal from 'components/Application/CustomisedModal';

const userKey = 'user';
const withUserReducer = injectReducer({
    key: userKey,
    reducer: userReducer,
});
const withUserSaga = injectSaga({ key: userKey, saga: userSaga });

const tenantKey = 'tenant';
const withTenantReducer = injectReducer({
    key: tenantKey,
    reducer: tenantReducer,
});
const withTenantSaga = injectSaga({ key: tenantKey, saga: tenantSaga });
class TenantForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(customValidTextForTenantNameField()),
        });
        this.state = {
            isOpenErrorModal: false,
            isDatabaseLogs: false,
            errorObj: {},
            operationType: SUBMIT,
            userList: [],
            tenantId: this.props.match.params.tenantId
                ? parseInt(this.props.match.params.tenantId)
                : '',
            pageHeaderName: this.props.match.params.tenantId
                ? __t('page_headers.tenant_edit')
                : __t('page_headers.tenant_add'),
            tenantData: initialTenantDataState(),
        };
    }
    componentDidMount() {
        if (this.state.tenantId) {
            this.props.getTenantByTenantIdRequest(this.state.tenantId);
        }
        this.props.filterUserRequest({ filter: { companyGroupId: [NONE] } });
    }
    componentWillUnmount() {
        this.props.cleareFilterUserResponse();
    }
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            let formValues = {
                ...values,
            };
            if (this.state.operationType === UPDATE) {
                this.props.updateTenantRequest(this.state.tenantId, formValues);
            } else {
                this.props.addTenantRequest(formValues);
            }
        } else {
            window.scrollTo(0, 0);
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    openErrorModal = (errorFileObj) => {
        this.setState({
            isOpenErrorModal: true,
            errorObj: errorFileObj,
        });
    };
    closeErrorModal = () => {
        this.setState({
            isOpenErrorModal: false,
            errorObj: {},
        });
    };
    openLogsModal = () => {
        this.setState({
            isDatabaseLogs: true,
        });
    };
    closeLogsModal = () => {
        this.setState({
            isDatabaseLogs: false,
        });
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.tenantApiResponse &&
            this.props.tenantApiResponse !== nextprops.tenantApiResponse
        ) {
            toastrMessage(
                nextprops.tenantApiResponse.message,
                nextprops.tenantApiResponse.responseType
            );
            if (nextprops.tenantApiResponse.responseType === SUCCESS) {
                this.props.history.push('/tenants');
            }
        }
        if (
            nextprops.tenantDbApiResponse &&
            this.props.tenantDbApiResponse !== nextprops.tenantDbApiResponse
        ) {
            toastrMessage(
                nextprops.tenantDbApiResponse.message,
                nextprops.tenantDbApiResponse.responseType
            );
            if (nextprops.tenantDbApiResponse.responseType === SUCCESS) {
                this.props.history.push('/tenants');
            }
        }
        if (
            nextprops.editTenantApiResponse &&
            this.props.editTenantApiResponse !== nextprops.editTenantApiResponse
        ) {
            this.setState({
                operationType: UPDATE,
                tenantData: {
                    ...nextprops.editTenantApiResponse.data,
                    dbLogs:
                        (nextprops.editTenantApiResponse.data.tenantDbLogs[0] &&
                            nextprops.editTenantApiResponse.data.tenantDbLogs[0]
                                .dbLog) ||
                        null,
                    // startDate: startDate,
                    // endDate: endDate,
                },
            });
        }
        if (nextprops.userList && this.props.userList !== nextprops.userList) {
            this.setState({
                userList: nextprops.userList,
            });
        }
    }
    render() {
        return (
            <React.Fragment>
                <Col lg="12">
                    <CommonBreadcrum
                        type={'add-tenant'}
                        edit={this.state.operationType == UPDATE}
                    />
                </Col>
                <div className="page-header tenants_header">
                    <h4 className="with-back-btn mt-0 d-flex">
                        <a
                            href={() => false}
                            className="dripicons-arrow-thin-left"
                            onClick={() => {
                                this.props.history.push('/tenants');
                            }}
                        >
                            {}
                        </a>{' '}
                        &nbsp;{this.state.pageHeaderName}{' '}
                    </h4>
                </div>
                <Formik
                    initialValues={this.state.tenantData}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form className="add-tenant">
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardHeader>
                                            <div className="page-header">
                                                <svg
                                                    width="43"
                                                    height="32"
                                                    viewBox="0 0 43 32"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <rect
                                                        x="1"
                                                        y="1.85913"
                                                        width="41"
                                                        height="28.7354"
                                                        rx="4"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                    />
                                                    <path
                                                        d="M12.7824 16.3988C15.4658 16.3988 17.6414 14.1438 17.6414 11.3626C17.6414 8.58146 15.4658 6.32648 12.7824 6.32648C10.0991 6.32648 7.92346 8.58146 7.92346 11.3626C7.9264 14.1426 10.1009 16.3958 12.7824 16.3988ZM12.7824 7.85259C14.653 7.85259 16.169 9.42387 16.169 11.3626C16.169 13.3014 14.653 14.8727 12.7824 14.8727C10.9119 14.8727 9.39587 13.3014 9.39587 11.3626C9.39823 9.42509 10.9131 7.85503 12.7824 7.85259ZM21.5569 24.0196C20.6416 19.8051 17.0613 16.704 12.7818 16.704C8.50241 16.704 4.92208 19.8051 4.01802 23.9573L4.00683 24.0196C3.99622 24.0684 3.98975 24.1246 3.98975 24.182C3.98975 24.6032 4.31957 24.945 4.72595 24.945C5.07698 24.945 5.37087 24.6899 5.44449 24.3492L5.44567 24.3444C6.21192 20.8221 9.20505 18.2301 12.7824 18.2301C16.3598 18.2301 19.3524 20.8215 20.1092 24.2919L20.1186 24.3437C20.1928 24.6893 20.4855 24.9444 20.8366 24.9444C20.8925 24.9444 20.9467 24.9377 20.9991 24.9255L20.9944 24.9267C21.3283 24.8504 21.5739 24.5464 21.5739 24.1826C21.5739 24.1246 21.5675 24.0684 21.5557 24.0141L21.5569 24.0196Z"
                                                        fill="currentColor"
                                                    />
                                                    <path
                                                        d="M24.0859 9.42957L38.1237 9.42956"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                    />
                                                    <path
                                                        d="M25.1202 22.8763L38.1237 22.8763"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                    />
                                                    <path
                                                        d="M28.2234 16.2269L38.1237 16.2269"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                    />
                                                </svg>

                                                <h4>
                                                    {__t(
                                                        'form_labels.tenant.basic_details'
                                                    )}
                                                </h4>
                                            </div>
                                            
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <FormGroup className="col-lg-12">
                                                    <Field
                                                        name="name"
                                                        className="form-control"
                                                        placeholder={__t(
                                                            'form_labels.tenant.tenant_name'
                                                        )}
                                                        value={values.name}
                                                        maxLength={50}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.tenant.tenant_name'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.tenant.tenant_name'
                                                        ),
                                                        values.name,
                                                        'required|max:50|onlyCharactersForTenantName',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.tenant.tenant_name_required'
                                                                ),
                                                                max: __t(
                                                                    'validations.tenant.tenant_name_max',
                                                                    { max: 50 }
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>

                                                <FormGroup className="col-lg-12">
                                                    <Field
                                                        component={Select}
                                                        name="tenantAdmin[]"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.state.userList
                                                        }
                                                        onChange={(opt) => {
                                                            setFieldValue(
                                                                'tenantAdmin',
                                                                opt &&
                                                                    opt.map(
                                                                        (x) =>
                                                                            x.value
                                                                    )
                                                            );
                                                        }}
                                                        value={this.state.userList.filter(
                                                            (option) =>
                                                                values.tenantAdmin?.includes(
                                                                    option.value
                                                                )
                                                        )}
                                                        isMulti={true}
                                                        closeMenuOnSelect={
                                                            false
                                                        }
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.masters.tenant_admin'
                                                        )}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup className="col-lg-12">
                                                    <CustomInput
                                                        type="checkbox"
                                                        id="isFundManagementEnabled"
                                                        name="isFundManagementEnabled"
                                                        onChange={(e) => {
                                                            const { checked } =
                                                                e.target;

                                                            setFieldValue(
                                                                'isFundManagementEnabled',
                                                                checked
                                                            );
                                                        }}
                                                        checked={
                                                            values.isFundManagementEnabled
                                                        }
                                                        label={__t(
                                                            'form_labels.masters.is_fund_manangement_enabled'
                                                        )}
                                                        disabled={
                                                            this.state
                                                                .operationType ==
                                                                UPDATE &&
                                                            (this.state
                                                                .tenantData
                                                                ?.dbConfigStatus ==
                                                                'IN PROGRESS' ||
                                                                this.state
                                                                    .tenantData
                                                                    ?.dbConfigStatus ==
                                                                    'GENERATED')
                                                        }
                                                    />
                                                </FormGroup>
                                                <Col lg="6">
                                                    {this.state.operationType ==
                                                        UPDATE &&
                                                        (this.state.tenantData
                                                            ?.dbConfigStatus ==
                                                            'PENDING' ||
                                                            this.state
                                                                .tenantData
                                                                ?.dbConfigStatus ==
                                                                'FAILED') && (
                                                            <Button
                                                                onClick={() => {
                                                                    this.props.generateDatabaseByTenantIdRequest(
                                                                        this
                                                                            .state
                                                                            .tenantData
                                                                            .id
                                                                    );
                                                                }}
                                                                color="info"
                                                            >
                                                                {__t(
                                                                    'form_labels.masters.generate_db'
                                                                )}
                                                            </Button>
                                                        )}
                                                    {this.state.tenantData
                                                        ?.dbConfigStatus ==
                                                        'IN PROGRESS' && (
                                                        <label>
                                                            Database Status:{' '}
                                                            <span className="lh-base badge badge-primary">
                                                                IN PROGRESS
                                                            </span>
                                                        </label>
                                                    )}
                                                    {this.state.tenantData
                                                        ?.dbConfigStatus ==
                                                        'GENERATED' &&
                                                        this.state.tenantData
                                                            ?.isDbCreated ==
                                                            false && (
                                                            <label>
                                                                Database Status:{' '}
                                                                <span className="lh-base badge badge-success">
                                                                    IN PROGRESS
                                                                </span>
                                                            </label>
                                                        )}
                                                    {this.state.tenantData
                                                        ?.dbConfigStatus ==
                                                        'GENERATED' &&
                                                        this.state.tenantData
                                                            ?.isDbCreated ==
                                                            true && (
                                                            <label>
                                                                Database Status:{' '}
                                                                <span className="lh-base badge badge-success">
                                                                    GENERATED
                                                                </span>
                                                            </label>
                                                        )}
                                                    {this.state.tenantData
                                                        ?.dbConfigStatus ==
                                                        'FAILED' && (
                                                        <label>
                                                            Database Status:{' '}
                                                            <span className="lh-base badge badge-danger">
                                                                FAILED
                                                            </span>
                                                        </label>
                                                    )}
                                                </Col>
                                                <FormGroup className="col-6 mb-0 d-flex justify-content-end">
                                                    <div className="d-inline-block">
                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            className="mr-2"
                                                        >
                                                            {
                                                                this.state
                                                                    .operationType
                                                            }
                                                        </Button>
                                                        <Button
                                                            type="reset"
                                                            color="secondary"
                                                            onClick={() => {
                                                                this.props.history.push(
                                                                    '/tenants'
                                                                );
                                                            }}
                                                        >
                                                            {CANCEL}
                                                        </Button>
                                                    </div>
                                                </FormGroup>
                                            </Row>
                                            {this.state.operationType ==
                                                UPDATE &&
                                                this.state.tenantData
                                                    ?.dbConfigStatus !=
                                                    'PENDING' &&
                                                this.state.tenantData?.dbLogs !=
                                                    null && (
                                                    <Row>
                                                        <Col lg="12">
                                                            Database Logs:
                                                        </Col>
                                                        <Col lg="12">
                                                            <Button
                                                                color="primary"
                                                                className="mr-2"
                                                                onClick={
                                                                    this
                                                                        .openLogsModal
                                                                }
                                                            >
                                                                Open
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                )}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
                <CustomisedModal
                    isModalOpen={this.state.isOpenErrorModal}
                    modalName={'Error Logs | ' + this.state.errorObj?.file}
                    onModalDismiss={() => {
                        this.closeErrorModal();
                    }}
                >
                    <p>
                        <b>Error:</b> {this.state.errorObj?.errors}
                    </p>
                    <hr></hr>
                    <p>{this.state.errorObj?.errorStatck}</p>
                </CustomisedModal>
                <CustomisedModal
                    isModalOpen={this.state.isDatabaseLogs}
                    modalName={'Database Logs'}
                    modalSize={"modal-xl modal-dialog-scrollable"}
                    onModalDismiss={() => {
                        this.closeLogsModal();
                    }}
                >
                    {this.state.operationType == UPDATE &&
                        this.state.tenantData?.dbConfigStatus != 'PENDING' &&
                        this.state.tenantData?.dbLogs != null &&
                        Object.entries(
                            JSON.parse(this.state.tenantData?.dbLogs)
                        ).map((opt, key) => {
                            return (
                                <li key={key}>
                                    <b>
                                        {getFirstCapitalize(
                                            opt[0].replace(/_/g, ' ')
                                        )}
                                    </b>{' '}
                                    :{typeof opt[1] == 'string' && opt[1]}
                                    {typeof opt[1] == 'object' &&
                                        opt[1].map((obj) => {
                                            return (
                                                <p className="mb-0 ml-5">
                                                    {obj.status ==
                                                    'completed' ? (
                                                        <i className="fas fa-check-circle text-success"></i>
                                                    ) : (
                                                        <i
                                                            onClick={() => {
                                                                this.openErrorModal(
                                                                    obj
                                                                );
                                                            }}
                                                            className="far fa-times-circle text-danger"
                                                        ></i>
                                                    )}
                                                    &nbsp;{obj.file}
                                                </p>
                                            );
                                        })}
                                </li>
                            );
                        })}
                </CustomisedModal>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    tenantApiResponse: makeTenantSelectField('tenantApiResponse'),
    tenantDbApiResponse: makeTenantSelectField('tenantDbApiResponse'),
    editTenantApiResponse: makeTenantSelectField('editTenantApiResponse'),
    userList: selectSortedUserList('userList'),
});
const withConnect = connect(mapStatetoProps, {
    updateTenantRequest,
    addTenantRequest,
    getTenantByTenantIdRequest,
    filterUserRequest,
    generateDatabaseByTenantIdRequest,
    cleareFilterUserResponse
});
export default compose(
    withConnect,
    withUserReducer,
    withUserSaga,
    withTenantReducer,
    withTenantSaga,
    withRouter
)(TenantForm);
