import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { ADD_ENTITY_GROUP_REQUEST, DELETE_ENTITY_GROUP_BY_ENTITY_GROUP_ID_REQUEST, GET_ALL_COMPANY_GROUPS_LIST_ON_USER_LIST_PAGE_REQUEST, GET_ALL_ENTITY_GROUP_LIST_REQUEST, GET_ENTITY_GROUP_BY_ENTITY_GROUP_ID_REQUEST, GET_ENTITY_GROUP_LIST_BY_STATUS_REQUEST, UPDATE_ENTITY_GROUP_REQUEST, UPDATE_ENTITY_GROUP_STATUS_BY_ENTITY_GROUP_ID_REQUEST } from './actionTypes';
import {
    handleApiCallException,
} from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import { addCompanyGroupResponse, deleteCompanyGroupByCompanyGroupIdResponse, getAllCompanyGroupListOnUserListPageResponse, getAllCompanyGroupListRequest, getAllCompanyGroupListResponse, getCompanyGroupByCompanyGroupIdResponse, getCompanyGroupListByStatusResponse, updateCompanyGroupResponse, updateCompanyGroupStatusByCompanyGroupIdResponse } from './actions';
import { ENTITY_GROUP, ENTITY_GROUP_BY_STATUS } from 'helpers/apiConstants';
function* addCompanyGroup({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', ENTITY_GROUP, data);
        if (response.status) {
            yield put(getAllCompanyGroupListRequest());
            yield put(addCompanyGroupResponse(response.message, SUCCESS));
        } else {
            yield put(addCompanyGroupResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllCompanyGroupList() {
    try {
        const response = yield call(invokeApi, 'get', ENTITY_GROUP, '');
        if (response.status) {
            yield put(getAllCompanyGroupListResponse(response.data.list));
        } else {
            yield put(getAllCompanyGroupListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getCompanyGroupByCompanyGroupId({ payload: { companyGroupId } }) {
    try {
        const response = yield call(invokeApi, 'get', ENTITY_GROUP + '/' + companyGroupId, '');
        if (response.status) {
            yield put(
                getCompanyGroupByCompanyGroupIdResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(getCompanyGroupByCompanyGroupIdResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateCompanyGroup({ payload: { companyGroupId, data } }) {
    try {
        const response = yield call(invokeApi, 'put', ENTITY_GROUP + '/' + companyGroupId, data);
        if (response.status) {
            yield put(getAllCompanyGroupListRequest());
            yield put(updateCompanyGroupResponse(response.message, SUCCESS));
        } else {
            yield put(updateCompanyGroupResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateCompanyGroupStatusByCompanyGroupId({ payload: { companyGroupId, status } }) {
    try {
        const response = yield call(invokeApi, 'patch', ENTITY_GROUP + '/' + companyGroupId + '/status/' + status);
        if (response.status) {
            yield put(getAllCompanyGroupListRequest());
            yield put(updateCompanyGroupStatusByCompanyGroupIdResponse(response.message, SUCCESS));
        } else {
            yield put(updateCompanyGroupStatusByCompanyGroupIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getCompanyGroupListByStatus({ payload: { status, type } }) {
    try {
        const typedata = (!type) ? 'default' : type;
        const response = yield call(invokeApi, 'get', ENTITY_GROUP_BY_STATUS + '/' + status + '/' + typedata);
        if (response.status) {
            yield put(getCompanyGroupListByStatusResponse(response.data.list));
        } else {
            yield put(getCompanyGroupListByStatusResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* deleteCompanyGroupByCompanyGroupId({ payload: { companyGroupId } }) {
    try {
        const response = yield call(invokeApi, 'delete', ENTITY_GROUP + '/' + companyGroupId);
        if (response.status) {
            yield put(getAllCompanyGroupListRequest());
            yield put(deleteCompanyGroupByCompanyGroupIdResponse(response.message, SUCCESS));
        } else {
            yield put(deleteCompanyGroupByCompanyGroupIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getAllCompanyGroupListOnUserListPage() {
    try {
        const response = yield call(invokeApi, 'get', ENTITY_GROUP, '');
        if (response.status) {
            yield put(getAllCompanyGroupListOnUserListPageResponse(response.data.list));
        } else {
            yield put(getAllCompanyGroupListOnUserListPageResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchCompanyGroup() {
    yield takeEvery(ADD_ENTITY_GROUP_REQUEST, addCompanyGroup);
    yield takeEvery(GET_ALL_ENTITY_GROUP_LIST_REQUEST, getAllCompanyGroupList);
    yield takeEvery(GET_ENTITY_GROUP_BY_ENTITY_GROUP_ID_REQUEST, getCompanyGroupByCompanyGroupId);
    yield takeEvery(UPDATE_ENTITY_GROUP_REQUEST, updateCompanyGroup);
    yield takeEvery(UPDATE_ENTITY_GROUP_STATUS_BY_ENTITY_GROUP_ID_REQUEST, updateCompanyGroupStatusByCompanyGroupId);
    yield takeEvery(GET_ENTITY_GROUP_LIST_BY_STATUS_REQUEST, getCompanyGroupListByStatus);
    yield takeEvery(DELETE_ENTITY_GROUP_BY_ENTITY_GROUP_ID_REQUEST, deleteCompanyGroupByCompanyGroupId);
    yield takeEvery(GET_ALL_COMPANY_GROUPS_LIST_ON_USER_LIST_PAGE_REQUEST, getAllCompanyGroupListOnUserListPage);

}
function* companyGroupSaga() {
    yield all([fork(watchCompanyGroup)]);
}
export default companyGroupSaga;