export const CLIENT_REGISTRATION_LIST_REQUEST = "client-registration-list-request";
export const CLIENT_REGISTRATION_LIST_RESPONSE = "client-registration-list-response";
export const CLIENT_REGISTRATION_RESEND_EMAIL_REQUEST = "client-registration-resend-email-request";

export const CLIENT_REGISTRATION_RESEND_EMAIL_RESPONSE = "client-registration-resend-email-response";

export const CLIENT_REGISTRATION_STATUS_UPDATE_CLIENT_REQUEST = "client-registration-status-update-client-request";

export const CLIENT_REGISTRATION_STATUS_UPDATE_CLIENT_RESPONSE = "client-registration-status-update-client-response";

export const LITE_COUNTRIES_LIST_AS_PER_PLAN_REQUEST = "lite-countries-list-as-per-plan-request";
export const LITE_COUNTRIES_LIST_AS_PER_PLAN_RESPONSE = "lite-countries-list-as-per-plan-response";


export const LITE_COUNTRIES_WISE_ENTITY_LIST_REQUEST = "lite-countries-wise-entity-list-request";
export const LITE_COUNTRIES_WISE_ENTITY_LIST_RESPONSE = "lite-countries-wise-entity-list-response";

export const LITE_COUNTRIES_WISE_ENTITY_ADD_REQUEST = "lite-countries-wise-entity-add-response";

export const LITE_COUNTRIES_WISE_ENTITY_ADD_RESPONSE = "lite-countries-wise-entity-add-response";







