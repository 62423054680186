import React, { Component } from 'react';
import { Col, Label, Row } from 'reactstrap';
import __t from 'i18n/translator';
import { getDiscrepancyTypeNameById, getExpenseTypeNameById, getSubmitterCompletionStatusNameById } from 'helpers/projectUtils';
import { ACTIVITY_TYPE_AUDITED, ACTIVITY_TYPE_DISCREPANCY, ACTIVITY_TYPE_SUBMITTED } from 'constants/databaseConstants';
import { AUTO_CLOSE, QUICK_UPDATE } from 'constants/commonConstants';
import { getDayDateName, getMonthName, getTimeFromDateTimeString, getYearName } from 'helpers/dateTimeHelper';
import ReactTooltip from 'react-tooltip';

class WorkFlowTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    getFileNameForInfo = (fileName) => {
        return fileName.substr(4);
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
    }
    render() {
        return (
            <ul className="timeline list-unstyled">
                {this.props.taskActivityList.map(
                    (
                        taskActivity,
                        key
                    ) => {
                        let activityDocs = this.props.allTransactionalDocs.filter((transDoc) => transDoc.taskActivityId == taskActivity.id);
                        const activityTypeName = this.props?.activityType && this.props?.activityType?.length > 0 ? this.props.activityType?.find((element) => element.id == taskActivity?.activityTypeId)?.activityTypeName ?? "" : ""
                        return <React.Fragment key={key}>
                            <li className="timeline-list">
                                <div className="cd-timeline-content bg-light p-3">
                                    <Row>
                                        <Col lg="12">
                                            <div className="d-flex justify-content-between align-items-center">
                                                {taskActivity?.createdByUser && taskActivity.createdByUser?.fullName && (
                                                    <div className="activity_log_user">
                                                        <span>
                                                            <svg
                                                                width="27"
                                                                height="27"
                                                                viewBox="0 0 27 27"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M13.9091 0C18.2019 0 21.6818 3.47997 21.6818 7.77273C21.6818 10.7065 20.0565 13.2606 17.6571 14.5837C22.8492 16.3205 26.5909 21.2233 26.5909 27H24.9545C24.9545 20.6738 19.8262 15.5455 13.5 15.5455C7.17382 15.5455 2.04545 20.6738 2.04545 27H0.409088C0.409088 21.0275 4.40868 15.9892 9.87561 14.4173C7.63372 13.0548 6.13636 10.5886 6.13636 7.77273C6.13636 3.47997 9.61633 0 13.9091 0ZM13.9091 1.63636C10.5201 1.63636 7.77272 4.38371 7.77272 7.77273C7.77272 11.1617 10.5201 13.9091 13.9091 13.9091C17.2981 13.9091 20.0455 11.1617 20.0455 7.77273C20.0455 4.38371 17.2981 1.63636 13.9091 1.63636Z"
                                                                    fill="currentColor"
                                                                />
                                                            </svg>
                                                        </span>
                                                        <h5>
                                                            {taskActivity.createdByUser?.fullName}
                                                        </h5>
                                                    </div>
                                                )}
                                                {taskActivity?.status && (
                                                    <div className="activity_completion_status">
                                                        <span className='m-0'>
                                                            {getSubmitterCompletionStatusNameById(parseInt(taskActivity.status))}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>

                                            <h6 className="mt-0 mb-1">
                                                {activityTypeName}{' '}
                                                {taskActivity.isQuickUpdate === 1 && (
                                                    <small className="quick-update-activity-log">{QUICK_UPDATE}</small>
                                                )}
                                                {taskActivity.isAutoClose === 1 && (
                                                    <small className="auto-close-activity-log">{AUTO_CLOSE}</small>
                                                )} {(taskActivity?.activityTypeId === ACTIVITY_TYPE_SUBMITTED || taskActivity?.activityTypeId === ACTIVITY_TYPE_AUDITED || taskActivity?.activityTypeId === ACTIVITY_TYPE_DISCREPANCY) && (
                                                    <a href={() => false} className="mr-3 view-activity-link p-0 btn btn-link" onClick={() => {
                                                        this.props.openViewActivityModal({ ...taskActivity, transactionalDocuments: activityDocs });
                                                    }}>
                                                        <small>View more</small>
                                                    </a>
                                                )}
                                            </h6>
                                            {taskActivity.remarks !=
                                                '' && (
                                                    <p className="mb-1">
                                                        {this.props.getShowMoreTextElement(
                                                            taskActivity.remarks?.trim()
                                                        )}
                                                    </p>
                                                )}
                                            {activityDocs.length > 0 && activityDocs.map((file, docsKey) => {
                                                return (
                                                    <>
                                                        <ReactTooltip
                                                            id={`show-file-` + docsKey}
                                                            place="right"
                                                        />
                                                        <a
                                                            // href={
                                                            //     getFileUrlByModule('poc', file.fileUrl, { companyGroupId: this.state.companyGroupId, entityId: this.state.entityId })
                                                            // }
                                                            // target="_blank"
                                                            // rel="noopener noreferrer"
                                                            onClick={() => {
                                                                this.props.saveFile({
                                                                    fileName: file.fileName,
                                                                    fileUrl: file.fileUrl,
                                                                    type: 'transactionalDocument'
                                                                })
                                                            }}
                                                            className="btn btn-primary btn-sm p-1 activity_file"
                                                            data-for={`show-file-` + docsKey}
                                                            data-tip={this.getFileNameForInfo(file.fileName)}
                                                        >
                                                            <svg
                                                                width="31"
                                                                height="39"
                                                                viewBox="0 0 31 39"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M17.4583 9.70833V1.875H5.79163C3.90601 1.875 2.9632 1.875 2.37741 2.46079C1.79163 3.04657 1.79163 3.98938 1.79163 5.875V33.125C1.79163 35.0106 1.79163 35.9534 2.37741 36.5392C2.9632 37.125 3.90601 37.125 5.79162 37.125H25.2083C27.0939 37.125 28.0367 37.125 28.6225 36.5392C29.2083 35.9534 29.2083 35.0106 29.2083 33.125V13.625H21.375C19.5286 13.625 18.6055 13.625 18.0319 13.0514C17.4583 12.4778 17.4583 11.5547 17.4583 9.70833Z"
                                                                    fill="#7E869E"
                                                                    fillOpacity="0.25"
                                                                />
                                                                <path
                                                                    d="M17.7943 1.875H9.62496C5.93229 1.875 4.08596 1.875 2.93879 3.02217C1.79163 4.16933 1.79163 6.01566 1.79163 9.70833V29.2917C1.79163 32.9843 1.79163 34.8307 2.93879 35.9778C4.08596 37.125 5.93229 37.125 9.62496 37.125H21.375C25.0676 37.125 26.914 37.125 28.0611 35.9778C29.2083 34.8307 29.2083 32.9843 29.2083 29.2917V13.289C29.2083 12.4885 29.2083 12.0883 29.0592 11.7284C28.9102 11.3685 28.6271 11.0855 28.0611 10.5195L20.5638 3.02217C19.9978 2.45615 19.7148 2.17314 19.3549 2.02407C18.995 1.875 18.5948 1.875 17.7943 1.875Z"
                                                                    stroke="CurrentColor"
                                                                    strokeWidth="2.2"
                                                                />
                                                                <path
                                                                    d="M9.625 21.458L21.375 21.458"
                                                                    stroke="CurrentColor"
                                                                    strokeWidth="2.2"
                                                                    strokeLinecap="round"
                                                                />
                                                                <path
                                                                    d="M9.625 29.292L17.4583 29.292"
                                                                    stroke="CurrentColor"
                                                                    strokeWidth="2.2"
                                                                    strokeLinecap="round"
                                                                />
                                                                <path
                                                                    d="M17.4584 1.875V9.70833C17.4584 11.5547 17.4584 12.4778 18.032 13.0514C18.6055 13.625 19.5287 13.625 21.375 13.625H29.2084"
                                                                    stroke="CurrentColor"
                                                                    strokeWidth="2.2"
                                                                />
                                                            </svg>
                                                            File{' '}
                                                            {docsKey +
                                                                1}
                                                        </a>{' '}
                                                        &nbsp;
                                                    </>
                                                );
                                            }
                                            )}
                                        </Col>
                                        <Col
                                            lg="12"
                                            className="activity_bottom_details mt-2"
                                        >
                                            {taskActivity.compliancePerformanceDate && (
                                                <p className="mb-0">
                                                    <Label className="m-0 mr-2">
                                                        <small className="text-primary">
                                                            {__t(
                                                                'form_labels.reporting.compliance_performance_date'
                                                            )}
                                                        </small>
                                                    </Label>
                                                    <span>
                                                        <small>
                                                            {
                                                                taskActivity.compliancePerformanceDate
                                                            }
                                                        </small>
                                                    </span>
                                                </p>
                                            )}
                                            {taskActivity.documentProcurementDate && (
                                                <p className="mb-0">
                                                    <Label className="m-0 mr-2">
                                                        <small className="text-primary">
                                                            {__t(
                                                                'form_labels.reporting.document_procurement_date'
                                                            )}
                                                        </small>
                                                    </Label>
                                                    <span>
                                                        <small>
                                                            {
                                                                taskActivity.documentProcurementDate
                                                            }
                                                        </small>
                                                    </span>
                                                </p>
                                            )}
                                            {taskActivity
                                                ?.department
                                                ?.departmentName && (
                                                    <p className="mb-0">
                                                        <Label className="m-0 mr-2">
                                                            <small className="text-primary">
                                                                {__t(
                                                                    'form_labels.reporting.department'
                                                                )}
                                                            </small>
                                                        </Label>
                                                        <span>
                                                            <small>
                                                                {taskActivity
                                                                    ?.department
                                                                    ?.departmentName !=
                                                                    '' &&
                                                                    taskActivity
                                                                        ?.department
                                                                        ?.departmentName}
                                                            </small>
                                                        </span>
                                                    </p>
                                                )}
                                            {taskActivity?.activityTypeId ==
                                                ACTIVITY_TYPE_SUBMITTED && (
                                                    <>
                                                        {taskActivity?.expenseType && (
                                                            <p className="mb-0" >
                                                                <Label className="m-0 mr-2">
                                                                    <small className="text-primary">
                                                                        {__t(
                                                                            'form_labels.reporting.expense_type_incurred'
                                                                        )}
                                                                    </small>
                                                                </Label>
                                                                <span>
                                                                    <small>
                                                                        {taskActivity?.expenseType?.split(',')?.map((opt, x) => {
                                                                            return (
                                                                                <span key={x}>
                                                                                    {getExpenseTypeNameById(parseInt(opt))}
                                                                                    <br />
                                                                                </span>
                                                                            );
                                                                        })}
                                                                    </small>
                                                                </span>
                                                            </p>
                                                        )}
                                                    </>
                                                )}

                                            {taskActivity?.activityTypeId ==
                                                ACTIVITY_TYPE_DISCREPANCY && (
                                                    <>
                                                        {taskActivity?.discrepancyType && (
                                                            <p className="mb-0" >
                                                                <Label className="m-0 mr-2">
                                                                    <small className="text-primary">
                                                                        {__t(
                                                                            'form_labels.reporting.discrepancy_type'
                                                                        )}
                                                                    </small>
                                                                </Label>
                                                                <span>
                                                                    <small>
                                                                        {taskActivity?.discrepancyType?.split(',')?.map((opt, x) => {
                                                                            return (
                                                                                <span key={x}>
                                                                                    {getDiscrepancyTypeNameById(parseInt(opt))}
                                                                                    <br />
                                                                                </span>
                                                                            );
                                                                        })}
                                                                    </small>
                                                                </span>
                                                            </p>
                                                        )}
                                                    </>
                                                )}
                                        </Col>
                                    </Row>
                                    <div className="date bg-primary">
                                        <h4 className="mt-0">
                                            {getDayDateName(taskActivity.createdAt)}
                                        </h4>
                                        <p className="mb-0 text-white-50">
                                            <span className="mr-1">
                                                {getMonthName(taskActivity.createdAt)}
                                            </span>
                                            {getYearName(taskActivity.createdAt)}
                                        </p>
                                        <hr className="mt-1 mb-1 bg-white"></hr>
                                        <p className="mb-0 text-white">
                                            {getTimeFromDateTimeString(taskActivity.createdAt)}
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </React.Fragment>
                    }
                )}
            </ul>
        );
    }
}
export default WorkFlowTab;
