import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { CLIENT_REGISTRATION_LIST_REQUEST, CLIENT_REGISTRATION_RESEND_EMAIL_REQUEST, CLIENT_REGISTRATION_STATUS_UPDATE_CLIENT_REQUEST, LITE_COUNTRIES_LIST_AS_PER_PLAN_REQUEST, LITE_COUNTRIES_WISE_ENTITY_ADD_REQUEST, LITE_COUNTRIES_WISE_ENTITY_LIST_REQUEST } from "./actionTypes";
import { addLiteEntityResponse, getClientRegistrationResponse, getLiteCountriesEntityResponse, getLiteCountriesListResponse, putResendEmailResponse, putStatusUpdateClientResponse } from "./actions";
import { handleApiCallException } from "store/Shared/actions";
import { invokeApi } from "helpers/axiosHelper";
import { LITE_COUNTRIES_LIST_API, LITE_ENTITY_ADD_API, LITE_ENTITY_LIST_API, REGISTER_CLIENT_LIST, RESEND_EMAIL_LITE, STATUS_UPDATE_CLIENT } from "helpers/apiConstants";

function* getClientRegistration({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', REGISTER_CLIENT_LIST, data);
        if (response.status) {
            yield put(getClientRegistrationResponse(response.data));
        } else {
            yield put(getClientRegistrationResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* putResendEmail({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'put', `${RESEND_EMAIL_LITE}/${data.user_id}/verification-type/${data.verification_type}`, data);
        if (response.status) {
            yield put(putResendEmailResponse(response));
        } else {
            yield put(putResendEmailResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* PUTStatusUpdateClient({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'put', `${STATUS_UPDATE_CLIENT}/${data.user_id}/status/${data.status}`, data);
        if (response.status) {
            yield put(putStatusUpdateClientResponse(response));
        } else {
            yield put(putStatusUpdateClientResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getLiteCountriesList() {
    try {
        const response = yield call(invokeApi, 'get', LITE_COUNTRIES_LIST_API, '');
        if (response.status) {
            yield put(getLiteCountriesListResponse(response.data));
        } else {
            yield put(getLiteCountriesListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getLiteEntityList() {
    try {
        const response = yield call(invokeApi, 'get', LITE_ENTITY_LIST_API, '');
        if (response.status) {
            yield put(getLiteCountriesEntityResponse(response.data));
        } else {
            yield put(getLiteCountriesEntityResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* postEntityCountry({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', LITE_ENTITY_ADD_API, data);
        if (response.status) {
            yield put(addLiteEntityResponse(response.data));
        } else {
            yield put(addLiteEntityResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}


export function* watchClientRegistration() {
    yield takeEvery(CLIENT_REGISTRATION_LIST_REQUEST, getClientRegistration);
    yield takeEvery(CLIENT_REGISTRATION_RESEND_EMAIL_REQUEST, putResendEmail);
    yield takeEvery(CLIENT_REGISTRATION_STATUS_UPDATE_CLIENT_REQUEST, PUTStatusUpdateClient);
    yield takeEvery(LITE_COUNTRIES_LIST_AS_PER_PLAN_REQUEST, getLiteCountriesList);

    yield takeEvery(LITE_COUNTRIES_WISE_ENTITY_LIST_REQUEST, getLiteEntityList);
    yield takeEvery(LITE_COUNTRIES_WISE_ENTITY_ADD_REQUEST, postEntityCountry);


}
function* LiteRegistrationSaga() {
    yield all([fork(watchClientRegistration)]);
}
export default LiteRegistrationSaga;