import React, { Component } from 'react';
import { Col, Label, Row } from 'reactstrap';
import __t from 'i18n/translator';
import { getDiscrepancyTypeNameById, getSubmitterCompletionStatusNameById } from 'helpers/projectUtils';
import { DISCREPANCY_TYPE_MONETARY, DISCREPANCY_TYPE_NON_MONETARY, DISCREPANCY_TYPE_OTHERS } from 'constants/databaseConstants';
import { getDayDateName, getMonthName, getTimeFromDateTimeString, getYearFromDateTimeString } from 'helpers/dateTimeHelper';

class ActivityList extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
    }
    render() {
        return (
            <React.Fragment>
                <ul className="timeline list-unstyled">
                    {this.props.recentActivityList.map((record) => {
                        let discrepancyType = record?.discrepancyType?.split(',').map((opt) => { return getDiscrepancyTypeNameById(parseInt(opt)); }) || [];
                        let discrepancyTypes = record?.discrepancyType?.split(',').map((opt) => { return parseInt(opt); }) || [];
                        let activityDocs = this.props.allTransactionalDocs.filter((transDoc) => transDoc.taskActivityId == record.id);
                        let departmentForDiscrepancy = [];
                        if (discrepancyTypes.length > 0 && record?.discrepancyDepartmentAmountData) {
                            let discrepancyDepartmentAmountData = record?.discrepancyDepartmentAmountData !== null && record?.discrepancyDepartmentAmountData !== '' ? JSON.parse(record?.discrepancyDepartmentAmountData) : [];
                            let discrepancyDepartmentIds = discrepancyDepartmentAmountData.map((opt) => { return parseInt(opt.departmentId); }) || [];
                            departmentForDiscrepancy = this.props.departmentDataForInput.filter((e) => {
                                return discrepancyDepartmentIds.includes(e.value);
                            });
                        }
                        const activityTypeName = this.props?.activityType && this.props?.activityType?.length > 0 ? this.props.activityType?.find((element) => element.id == record?.activityTypeId)?.activityTypeName ?? "" : ""
                        return (
                            <li className="timeline-list" key={'activity_' + record.id}>
                                <a
                                    href={() => false}
                                    onClick={() =>
                                        this.props.openViewActivityModal({ ...record, transactionalDocuments: activityDocs })
                                    }
                                    className="cd-timeline-content p-2"
                                    key={'activity_doc_link' + record.id}
                                >
                                    <div className="time-line-content-header">
                                        <div className="d-flex align-items-center">
                                            <div className="mt-0 mb-2 activity_title">
                                                <span>
                                                    <svg
                                                        width="27"
                                                        height="27"
                                                        viewBox="0 0 27 27"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M13.9091 0C18.2019 0 21.6818 3.47997 21.6818 7.77273C21.6818 10.7065 20.0565 13.2606 17.6571 14.5837C22.8492 16.3205 26.5909 21.2233 26.5909 27H24.9545C24.9545 20.6738 19.8262 15.5455 13.5 15.5455C7.17382 15.5455 2.04545 20.6738 2.04545 27H0.409088C0.409088 21.0275 4.40868 15.9892 9.87561 14.4173C7.63372 13.0548 6.13636 10.5886 6.13636 7.77273C6.13636 3.47997 9.61633 0 13.9091 0ZM13.9091 1.63636C10.5201 1.63636 7.77272 4.38371 7.77272 7.77273C7.77272 11.1617 10.5201 13.9091 13.9091 13.9091C17.2981 13.9091 20.0455 11.1617 20.0455 7.77273C20.0455 4.38371 17.2981 1.63636 13.9091 1.63636Z"
                                                            fill="currentColor"
                                                        />
                                                    </svg>
                                                </span>
                                                <h6>
                                                    {record.createdByUser?.fullName}
                                                    {record.department?.departmentName !== '' && record.department?.departmentName !== null && (
                                                        <small className="departmentName">
                                                            {__t('form_labels.reporting.department')}{' '}
                                                            {record.department?.departmentName}
                                                        </small>
                                                    )}
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="right-box mb-2">
                                            <span className="attached-file-box">
                                                <svg
                                                    width="31"
                                                    height="39"
                                                    viewBox="0 0 31 39"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M17.4583 9.70833V1.875H5.79163C3.90601 1.875 2.9632 1.875 2.37741 2.46079C1.79163 3.04657 1.79163 3.98938 1.79163 5.875V33.125C1.79163 35.0106 1.79163 35.9534 2.37741 36.5392C2.9632 37.125 3.90601 37.125 5.79162 37.125H25.2083C27.0939 37.125 28.0367 37.125 28.6225 36.5392C29.2083 35.9534 29.2083 35.0106 29.2083 33.125V13.625H21.375C19.5286 13.625 18.6055 13.625 18.0319 13.0514C17.4583 12.4778 17.4583 11.5547 17.4583 9.70833Z"
                                                        fill="#7E869E"
                                                        fillOpacity="0.25"
                                                    />
                                                    <path
                                                        d="M17.7943 1.875H9.62496C5.93229 1.875 4.08596 1.875 2.93879 3.02217C1.79163 4.16933 1.79163 6.01566 1.79163 9.70833V29.2917C1.79163 32.9843 1.79163 34.8307 2.93879 35.9778C4.08596 37.125 5.93229 37.125 9.62496 37.125H21.375C25.0676 37.125 26.914 37.125 28.0611 35.9778C29.2083 34.8307 29.2083 32.9843 29.2083 29.2917V13.289C29.2083 12.4885 29.2083 12.0883 29.0592 11.7284C28.9102 11.3685 28.6271 11.0855 28.0611 10.5195L20.5638 3.02217C19.9978 2.45615 19.7148 2.17314 19.3549 2.02407C18.995 1.875 18.5948 1.875 17.7943 1.875Z"
                                                        stroke="CurrentColor"
                                                        strokeWidth="2.2"
                                                    />
                                                    <path
                                                        d="M9.625 21.458L21.375 21.458"
                                                        stroke="CurrentColor"
                                                        strokeWidth="2.2"
                                                        strokeLinecap="round"
                                                    />
                                                    <path
                                                        d="M9.625 29.292L17.4583 29.292"
                                                        stroke="CurrentColor"
                                                        strokeWidth="2.2"
                                                        strokeLinecap="round"
                                                    />
                                                    <path
                                                        d="M17.4584 1.875V9.70833C17.4584 11.5547 17.4584 12.4778 18.032 13.0514C18.6055 13.625 19.5287 13.625 21.375 13.625H29.2084"
                                                        stroke="CurrentColor"
                                                        strokeWidth="2.2"
                                                    />
                                                </svg>{' '}
                                                {__t('form_labels.reporting.attached_file')}
                                                <span>{activityDocs.length}</span>
                                            </span>
                                            {record.status !=
                                                '' &&
                                                record.status !=
                                                null && (
                                                    <span className="badge badge-success">
                                                        {' '}
                                                        {getSubmitterCompletionStatusNameById(parseInt(record.status))}
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <Row>
                                        <Col lg="4">
                                            <div className="recent_card">
                                                <p>{__t('form_labels.reporting.activity')}</p>
                                                <span>
                                                    {activityTypeName}
                                                </span>
                                            </div>
                                        </Col>

                                        {record.compliancePerformanceDate && (
                                            <Col lg="4">
                                                <div className="recent_card">
                                                    <p>
                                                        {__t('form_labels.reporting.compliance_performance_date')}
                                                    </p>
                                                    <span>
                                                        {' '}
                                                        {record.compliancePerformanceDate}
                                                    </span>
                                                </div>
                                            </Col>
                                        )}
                                        {record.documentProcurementDate && (
                                            <Col lg="4">
                                                <div className="recent_card">
                                                    <p>
                                                        {__t('form_labels.reporting.document_procurement_date')}
                                                    </p>
                                                    <span>
                                                        {' '}
                                                        {record.documentProcurementDate}
                                                    </span>
                                                </div>
                                            </Col>
                                        )}
                                        {record.systemReportingDate && (
                                            <Col
                                                lg="4"
                                                className="d-none"
                                            >
                                                <div className="recent_card">
                                                    <p>
                                                        {__t('form_labels.reporting.system_reporting_date')}
                                                    </p>
                                                    <span>
                                                        {' '}
                                                        {record.systemReportingDate}
                                                    </span>
                                                </div>
                                            </Col>
                                        )}
                                        {record.remarks !== '' && (
                                            <Col>
                                                <div className="recent_card">
                                                    <p>
                                                        {__t('form_labels.reporting.remarks')}
                                                    </p>
                                                    <span>
                                                        {this.props.getShowMoreTextElement(record.remarks)}
                                                    </span>
                                                </div>
                                            </Col>
                                        )}
                                        {(record.activityTypeId === 9) && (
                                            <Col>
                                                <div className="recent_card">
                                                    <p>
                                                        {__t('form_labels.reporting.recommendation')}
                                                    </p>
                                                    <span>
                                                        {this.props.getShowMoreTextElement(record.recommendations)}
                                                    </span>
                                                </div>
                                            </Col>
                                        )}
                                        {discrepancyType.length > 0 && (
                                            <Col lg="12">
                                                <Row>
                                                    <Col>
                                                        <div className="recent_card">
                                                            <p>
                                                                {__t('form_labels.reporting.discrepancy_type')}
                                                            </p>
                                                            <span>
                                                                {discrepancyType?.join(', ')}
                                                            </span>
                                                        </div>
                                                    </Col>
                                                    <Col className='pl-0'>
                                                        <div className="recent_card">
                                                            <p>
                                                                {__t('form_labels.reporting.discrepancy_department')}
                                                            </p>
                                                            <span>
                                                                {departmentForDiscrepancy?.length > 0 ? departmentForDiscrepancy.map((d) => d.label).join(', ') : '-'}
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        )}
                                        {discrepancyTypes?.includes(DISCREPANCY_TYPE_NON_MONETARY) && (
                                            <>
                                                <Col>
                                                    <div className="recent_card">
                                                        <p>
                                                            {__t('form_labels.reporting.discrepancy_non_monetary_description')}
                                                        </p>
                                                        <span>
                                                            {this.props.getShowMoreTextElement(record?.discrepancyNonMonetaryDescription)}
                                                        </span>
                                                    </div>
                                                </Col>
                                            </>
                                        )}
                                        {discrepancyTypes?.includes(DISCREPANCY_TYPE_OTHERS) && (
                                            <>
                                                <Col>
                                                    <div className="recent_card">
                                                        <p>
                                                            {__t('form_labels.reporting.discrepancy_others_description')}
                                                        </p>
                                                        <span>
                                                            {this.props.getShowMoreTextElement(record.discrepancyOthersDescription)}
                                                        </span>
                                                    </div>
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                    <div className="date">
                                        <h5 className="m-0">
                                            {getDayDateName(record.createdAt)}
                                        </h5>
                                        <p className="mb-0 text-orange">
                                            <span className="mr-1">
                                                {getMonthName(record.createdAt)}
                                            </span>
                                            {getYearFromDateTimeString(record.createdAt)}
                                        </p>
                                        <p className="mb-0 bg-white">
                                            {getTimeFromDateTimeString(record.createdAt)}
                                        </p>
                                    </div>
                                </a>
                            </li>
                        );
                    }
                    )}
                </ul>
            </React.Fragment>
        );
    }
}
export default ActivityList;
