import React, { Component } from 'react';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Button,
    Label,
    Input,
} from 'reactstrap';

import {
    SUCCESS,
    ACTIVE,
    UPDATE,
    CANCEL,
    DATE_FORMAT_DATEPICKER,
    INACTIVE,
} from 'constants/commonConstants';
import DatePicker from 'react-datepicker';
import ShowMoreText from 'react-show-more-text';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import { Formik, Form, Field } from 'formik';
import { makeEventSelectField } from 'store/Masters/Event/selector';
import { Option, MultiValue, animatedComponents } from 'helpers/projectUtils';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import {
    getLawComplianceDetailsByLawComplianceIdRequest,
    getComplianceTypesRequest,
    getComplianceCategoriesRequest,
    getPeriodicitiesRequest,
    getEventListBySearchRequest,
    getFilingTypesRequest,
    getPenaltyTypesRequest,
    getRiskRatingsRequest,
    getImpactManagementPersonnelsRequest,
    updateLawcomplianceRequest,
    getLegalUpdateReferenceIdRequest,
    getAllupdateTypeListRequest,
    getLawComplianceSearchRequest,
    getLawsSearchListRequest,
    getAllEntityTypeListRequest,
    getAllFacilityTypeListRequest,
    getApplicabilityListByStatusRequest,
    getSubApplicabilityListByApplicabilityRequest,
} from 'store/actions';

import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { compareString } from 'helpers/generalUtils';

import lawComplianceReducer from 'store/LawCompliance/reducer';
import lawComplianceSaga from 'store/LawCompliance/saga';
import { makeLawComplianceSelectField } from 'store/LawCompliance/selector';
import { permissionDeniedMessage, toastrMessage } from 'helpers/messageHelper';
import {
    customValidatorForUserName,
    customValidPhoneField,
    customValidTextField,
} from 'helpers/customValidators';
import __t from 'i18n/translator';

import SimpleReactValidator from 'simple-react-validator';
import {
    APPROVAL_BASED_UPDATE,
    COMPLIANCE_CATEGORY_EVENT_BASED,
    COMPLIANCE_CATEGORY_ONE_TIME,
    COMPLIANCE_CATEGORY_ON_GOING,
    CONFIRMED,
    DEACTIVATION,
    DEACTIVATION_COMPLIANCE,
    HIGH_IMPACT_COMPLIANCE_CLASS,
    HIGH_IMPACT_COMPLIANCE_CLASSIFICATION,
    HIGH_IMPACT_DUE_DATE_CALCULATION,
    HIGH_IMPACT_DUE_DATE_DESCRIPTION,
    HIGH_IMPACT_EVENT_DUE_DATE_CALCULATION,
    HIGH_IMPACT_FREQUENCY,
    HIGH_IMPACT_IF_EVENT_BASED,
    HIGH_IMPACT_TRIGGERING_COMPLIANCE,
    MANDATORY,
    NEW_ADDITIONS,
    TENTATIVE,
} from 'constants/databaseConstants';
import {
    formatDateForApiSystem,
    getDateObjectWithValueFromMoment,
} from 'helpers/dateTimeHelper';
import { initialStatutoryCompliancesState } from './CommonFunctions';
import eventReducer from 'store/Masters/Event/reducer';
import eventSaga from 'store/Masters/Event/saga';
import { CompliancePeriodStatusList } from 'constants/dropdowns';
import importMasterReducer from 'store/Masters/ImportMaster/reducer';
import importMasterSaga from 'store/Masters/ImportMaster/saga';
import { makeImportMasterSelectField } from 'store/Masters/ImportMaster/selector';
import { selectSortedUpdateTypeList } from 'store/UpdateCenter/LegalUpdates/selector';
import legalUpdateReducer from 'store/UpdateCenter/LegalUpdates/reducer';
import legalUpdateSaga from 'store/UpdateCenter/LegalUpdates/saga';
import applicabilityReducer from 'store/Masters/Applicability/reducer';
import applicabilitySaga from 'store/Masters/Applicability/saga';
import lawReducer from 'store/Masters/Law/reducer';
import lawSaga from 'store/Masters/Law/saga';
import { makeLawSelectField, selectSortedEntityTypeList, selectSortedFacilityTypeList } from 'store/Masters/Law/selector';
import { selectSortedApplicabilityList, selectSortedSubApplicabilityList } from 'store/Masters/Applicability/selector';
const lawComplianceKey = 'lawCompliance';
const withLawComplianceReducer = injectReducer({
    key: lawComplianceKey,
    reducer: lawComplianceReducer,
});
const withLawComplianceSaga = injectSaga({
    key: lawComplianceKey,
    saga: lawComplianceSaga,
});

const eventKey = 'event';
const withEventReducer = injectReducer({
    key: eventKey,
    reducer: eventReducer,
});
const withEventSaga = injectSaga({ key: eventKey, saga: eventSaga });

const importMasterKey = 'importMaster';
const withImportMasterReducer = injectReducer({
    key: importMasterKey,
    reducer: importMasterReducer,
});
const withImportMasterSaga = injectSaga({
    key: importMasterKey,
    saga: importMasterSaga,
});

const legalUpdatesKey = 'legalUpdates';
const withLegalUpdatesReducer = injectReducer({
    key: legalUpdatesKey,
    reducer: legalUpdateReducer,
});
const withLegalUpdatesSaga = injectSaga({
    key: legalUpdatesKey,
    saga: legalUpdateSaga,
});

const lawKey = 'law';
const withLawReducer = injectReducer({
    key: lawKey,
    reducer: lawReducer,
});
const withLawSaga = injectSaga({ key: lawKey, saga: lawSaga });

const applicabilityKey = 'applicability';
const withApplicabilityReducer = injectReducer({
    key: applicabilityKey,
    reducer: applicabilityReducer,
});
const withApplicabilitySaga = injectSaga({ key: applicabilityKey, saga: applicabilitySaga });

class ComplianceDetailsForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(
                customValidPhoneField(),
                customValidTextField(),
                customValidatorForUserName()
            ),
        });
        this.state = {
            complianceId: this.props.match.params.complianceId,
            complianceData: {},
            statutoryCompliancesData: initialStatutoryCompliancesState(),
            allCompliancesTypesList: [],
            allCompliancesCategoriesList: [],
            allPeriodicitiesList: [],
            eventList: [],
            filingTypesList: [],
            penaltyTypesList: [],
            riskRatingsList: [],
            impactManagementPersonnelList: [],
            relevantlawList: [],
            legalUpdateReferenceList: [],
            legalUpdateReferenceDropdownList: [],
            isFreeze: true,
            isComplianceActiveFreeze: true,
            isComplianceClassFreeze: true,
            isComplianceClassificationFreeze: true,
            isFrequencyFreeze: true,
            isDueDateDesFreeze: true,
            isDueDateCalFreeze: true,
            isIfEventFreeze: true,
            isTriggeringCompFreeze: true,
            isEventCalFreeze: true,
            lawComplianceDropdownList: [],
        };
    }
    componentDidMount() {
        this.props.getLawComplianceDetailsByLawComplianceIdRequest(this.state.complianceId);
        this.props.getAllupdateTypeListRequest();
        this.props.getComplianceTypesRequest();
        this.props.getComplianceCategoriesRequest();
        this.props.getPeriodicitiesRequest();
        this.props.getFilingTypesRequest();
        this.props.getPenaltyTypesRequest();
        this.props.getRiskRatingsRequest();
        this.props.getImpactManagementPersonnelsRequest();
        this.props.getLegalUpdateReferenceIdRequest(this.state.complianceId, 'compliance_edit');
        this.props.getAllEntityTypeListRequest();
        this.props.getAllFacilityTypeListRequest();
        this.props.getApplicabilityListByStatusRequest(ACTIVE);
    }

    scrollToSection = (className) => {
        const section = document.querySelector('#' + className);
        let position = section.getBoundingClientRect();

        window.scrollTo(position.left, position.top + window.scrollY - 100);
        document.getElementById(className).classList.add('animate__bounceIn');
        setTimeout(function () {
            document
                .getElementById(className)
                .classList.remove('animate__bounceIn');
        }, 2000);
    };

    getShowMoreTextElement = (element) => {
        return (
            <ShowMoreText
                lines={2}
                more="Show more"
                less="Show less"
                className="show-more-content"
                anchorClass="show-more-link"
                expandByClick={true}
                expanded={false}
                keepNewLines={true}
            >
                {`` + element + '\n'}
            </ShowMoreText>
        );
    };
    getSubApplicabilityListByApplicability(applicabilityId) {
        this.props.getSubApplicabilityListByApplicabilityRequest(applicabilityId);
    }
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            values = {
                ...values,
                enforcementDate: formatDateForApiSystem(values.enforcementDate),
            };
            this.props.updateLawcomplianceRequest(
                this.state.complianceId,
                values
            );
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.lawComplianceDetailsApiResponse &&
            this.props.lawComplianceDetailsApiResponse !==
            nextprops.lawComplianceDetailsApiResponse
        ) {
            if (
                nextprops.lawComplianceDetailsApiResponse.responseType ===
                SUCCESS
            ) {
                this.props.getLawsSearchListRequest(
                    {
                        limit: -1,
                        page: -1,
                        filter: {
                            countryId: [
                                nextprops.lawComplianceDetailsApiResponse.data
                                    .compliance.countryId,
                            ],
                        },
                        orderBy: {
                            key: 'createdAt',
                            value: 'DESC',
                        },
                    },
                    true
                );
                this.setState(
                    {
                        complianceData:
                            nextprops.lawComplianceDetailsApiResponse.data
                                .compliance,
                    },
                    () => {
                        if (
                            nextprops.lawComplianceDetailsApiResponse.data
                                .compliance.countryId
                        ) {
                            this.props.getEventListBySearchRequest({
                                countryId: [
                                    this.state.complianceData.countryId,
                                ],
                            });
                            if (
                                this.state.complianceData.eventId &&
                                this.state.complianceData.eventId != null
                            ) {
                                this.props.getLawComplianceSearchRequest(
                                    {
                                        limit: -1,
                                        page: -1,
                                        filter: {
                                            countryId: [
                                                this.state.complianceData
                                                    .countryId,
                                            ],
                                            eventId: [
                                                this.state.complianceData
                                                    .eventId,
                                            ],
                                            lawId: [
                                                this.state.complianceData.lawId,
                                            ],
                                        },
                                    },
                                    false
                                );
                            }
                        }
                        let data =
                            nextprops.lawComplianceDetailsApiResponse.data
                                .compliance;
                        this.setState({
                            statutoryCompliancesData: {
                                lawName: data?.law?.lawName,
                                lawId: data?.lawId,
                                complianceCode: data?.complianceCode,
                                countryId: data.countryId,
                                sectionReference: data?.sectionReference,
                                ruleReference: data?.ruleReference,
                                verbatimSectionReference: data?.verbatimSectionReference && data.verbatimSectionReference.length > 0 && JSON.parse(data.verbatimSectionReference).map(section => `${section.sectionName} - ${section.lawShortName} # ${section.sectionDescription}`).join(' ; ').trim() || '',
                                verbatimRuleReference: data?.verbatimRuleReference && data.verbatimRuleReference.length > 0 && JSON.parse(data.verbatimRuleReference).map(section => `${section.sectionName} - ${section.lawShortName} # ${section.sectionDescription}`).join(' ; ').trim() || '',
                                complianceTitle: data?.complianceTitle,
                                complianceSpecification:
                                    data?.complianceSpecification,
                                complianceDetails: data?.complianceDetails, // More Details on Compliance
                                complianceApplicability:
                                    data?.complianceApplicability, //Compliance Applicability
                                complianceTypeId: data?.complianceTypeId, //Compliance Class
                                complianceCategoryId:
                                    data?.complianceCategoryId, // compliance classification
                                applicabilityCondition:
                                    data?.applicabilityCondition, //Applicability Type
                                periodicityId: data?.periodicityId, //frequency
                                dueDateDescription: data?.dueDateDescription,
                                dueDateFormula: data?.dueDateFormula, //
                                isEventBased: data?.isEventBased,
                                eventId: data?.eventId,
                                eventDueDateFormula: data?.eventDueDateFormula,
                                compliancePeriod:
                                    data?.compliancePeriod != 'na' &&
                                        data?.compliancePeriod
                                        ? data?.compliancePeriod
                                        : '',
                                formNo: data?.formNo,
                                isFilingRequired: data?.isFilingRequired,
                                filingTypeId:
                                    data?.filingTypeId != null
                                        ? data?.filingTypeId
                                        : '',
                                isProofRequired: data?.isProofRequired, //Need Compliance Evidence
                                penaltyDescription: data?.penaltyDescription, //consequence_of_non_compliance
                                entityTypeId: data?.entityTypeId && data.entityTypeId.split(",").map(Number) || [],
                                facilityTypeId: data?.facilityTypeId && data.facilityTypeId.split(",").map(Number) || [],
                                applicabilityId: data?.applicabilityId && data.applicabilityId.split(",").map(Number) || [],
                                subApplicabilityId: data?.subApplicabilityId && data.subApplicabilityId.split(",").map(Number) || [],
                                penaltyTypeId:
                                    (data?.penaltyTypeId &&
                                        data.penaltyTypeId
                                            .split(',')
                                            .map(Number)) ||
                                    [], //consequence_category
                                impactManagementPersonnel:
                                    data?.impactManagementPersonnel
                                        ? data?.impactManagementPersonnel
                                        : INACTIVE, //management_personnel_impact
                                riskRatingId: data?.riskRatingId,
                                impactManagementId:
                                    (data?.impactManagementId &&
                                        data.impactManagementId
                                            .split(',')
                                            .map(Number)) ||
                                    [],
                                relevantLawId:
                                    (data?.relevantLawId &&
                                        data.relevantLawId
                                            .split(',')
                                            .map(Number)) ||
                                    [],
                                monetaryPenaltyValue:
                                    data?.monetaryPenaltyValue,
                                currency: data?.currency,
                                governingAuthorityWebsite:
                                    data?.governingAuthorityWebsite,
                                briefNotes: data?.briefNotes,
                                enforcementDate: data?.enforcementDate
                                    ? getDateObjectWithValueFromMoment(
                                        data?.enforcementDate
                                    )
                                    : null,
                                isRevision: true,
                                triggeringComplianceId:
                                    data?.triggeringComplianceId,
                                isActive: data?.isActive,
                            },
                        });
                        this.getSubApplicabilityListByApplicability(data?.applicabilityId && data.applicabilityId.split(",").map(Number) || [])
                    }
                );
            } else {
                toastrMessage(
                    nextprops.lawComplianceDetailsApiResponse.message,
                    nextprops.lawComplianceDetailsApiResponse.responseType
                );
            }
        }
        if (
            nextprops.allCompliancesTypesList &&
            this.props.allCompliancesTypesList !==
            nextprops.allCompliancesTypesList
        ) {
            this.setState({
                allCompliancesTypesList: nextprops.allCompliancesTypesList
                    ?.map((opt) => ({
                        value: opt.id,
                        label: opt.complianceTypeName,
                    }))
                    .sort((item1, item2) =>
                        compareString(item1.label, item2.label)
                    ),
            });
        }
        if (
            nextprops.allCompliancesCategoriesList &&
            this.props.allCompliancesCategoriesList !==
            nextprops.allCompliancesCategoriesList
        ) {
            this.setState({
                allCompliancesCategoriesList:
                    nextprops.allCompliancesCategoriesList
                        ?.map((opt) => ({
                            value: opt.id,
                            label: opt.complianceCategoryName,
                        }))
                        .sort((item1, item2) =>
                            compareString(item1.label, item2.label)
                        ),
            });
        }
        if (
            nextprops.allPeriodicitiesList &&
            this.props.allPeriodicitiesList !== nextprops.allPeriodicitiesList
        ) {
            this.setState({
                allPeriodicitiesList: nextprops.allPeriodicitiesList
                    ?.map((opt) => ({
                        value: opt.id,
                        label: opt.periodicityName,
                    }))
                    .sort((item1, item2) =>
                        compareString(item1.label, item2.label)
                    ),
            });
        }

        if (
            nextprops.eventList &&
            this.props.eventList !== nextprops.eventList
        ) {
            this.setState({
                eventList: nextprops.eventList
                    ?.map((opt) => ({
                        value: opt.id,
                        label: opt.eventName,
                    }))
                    .sort((item1, item2) =>
                        compareString(item1.label, item2.label)
                    ),
            });
        }

        if (
            nextprops.filingTypesList &&
            this.props.filingTypesList !== nextprops.filingTypesList
        ) {
            this.setState({
                filingTypesList: nextprops.filingTypesList
                    ?.map((opt) => ({
                        value: opt.id,
                        label: opt.filingTypeName,
                    }))
                    .sort((item1, item2) =>
                        compareString(item1.label, item2.label)
                    ),
            });
        }
        if (
            nextprops.penaltyTypesList &&
            this.props.penaltyTypesList !== nextprops.penaltyTypesList
        ) {
            this.setState({
                penaltyTypesList: nextprops.penaltyTypesList
                    ?.map((opt) => ({
                        value: opt.id,
                        label: opt.penaltyTypeName,
                    }))
                    .sort((item1, item2) =>
                        compareString(item1.label, item2.label)
                    ),
            });
        }
        if (
            nextprops.riskRatingsList &&
            this.props.riskRatingsList !== nextprops.riskRatingsList
        ) {
            this.setState({
                riskRatingsList: nextprops.riskRatingsList
                    ?.map((opt) => ({
                        value: opt.id,
                        label: opt.riskRatingName,
                    }))
                    .sort((item1, item2) =>
                        compareString(item1.label, item2.label)
                    ),
            });
        }
        if (
            nextprops.impactManagementPersonnelList &&
            this.props.impactManagementPersonnelList !==
            nextprops.impactManagementPersonnelList
        ) {
            this.setState({
                impactManagementPersonnelList:
                    nextprops.impactManagementPersonnelList
                        ?.map((opt) => ({
                            value: opt.id,
                            label: opt.impactManagementPersonnelName,
                        }))
                        .sort((item1, item2) =>
                            compareString(item1.label, item2.label)
                        ),
            });
        }
        if (
            nextprops.lawSearchApiResponse &&
            this.props.lawSearchApiResponse !== nextprops.lawSearchApiResponse
        ) {
            this.setState({
                relevantlawList: nextprops.lawSearchApiResponse.data.laws
                    ?.map((opt) => ({
                        value: opt.id,
                        label: opt.lawCode + '-' + opt.lawShortName,
                    }))
                    .sort((item1, item2) =>
                        compareString(item1.label, item2.label)
                    ),
            });
        }
        if (
            nextprops.updateLawComplianceApiResponse &&
            this.props.updateLawComplianceApiResponse !==
            nextprops.updateLawComplianceApiResponse
        ) {
            toastrMessage(
                nextprops.updateLawComplianceApiResponse.message,
                nextprops.updateLawComplianceApiResponse.responseType
            );

            if (
                nextprops.updateLawComplianceApiResponse.responseType ===
                SUCCESS
            ) {
                if (this.props.history.length != 1) {
                    this.props.history.goBack();
                } else {
                    this.props.history.push('/statutory-compliances');
                }
            }
        }

        if (
            nextprops.legalUpdateReferenceList &&
            this.props.legalUpdateReferenceList !==
            nextprops.legalUpdateReferenceList
        ) {
            if (nextprops.legalUpdateReferenceList.length === 0) {
                this.permissionDenied(__t('alerts.permission_denied'));
            } else {
                this.setState({
                    legalUpdateReferenceList:
                        nextprops.legalUpdateReferenceList,
                    legalUpdateReferenceDropdownList:
                        nextprops.legalUpdateReferenceList
                            .map((opt) => ({
                                value: opt.id,
                                label: opt.legalUpdateCode,
                            }))
                            .sort((item1, item2) =>
                                compareString(item1.label, item2.label)
                            ),
                });
            }
        }

        if (
            nextprops.lawComplianceSearchApiResponse &&
            this.props.lawComplianceSearchApiResponse !==
            nextprops.lawComplianceSearchApiResponse
        ) {
            this.setState({
                lawComplianceDropdownList:
                    nextprops.lawComplianceSearchApiResponse.data.lawCompliances
                        .map((opt) => ({
                            value: opt.id,
                            label: opt.complianceTitle,
                        }))
                        .sort((item1, item2) =>
                            compareString(item1.label, item2.label)
                        ),
            });
        }
    }

    setFreezeValues = (id) => {
        let legalUpdateData = this.state.legalUpdateReferenceList.filter(
            (x) => x.id == id
        )[0];
        let updateType = legalUpdateData.updateTypeId
            ? legalUpdateData.updateTypeId.split(',').map(Number)
            : [];

        let subUpdateType = legalUpdateData.subUpdateTypeId
            ? legalUpdateData.subUpdateTypeId.split(',').map(Number)
            : [];
        if (
            (updateType.includes(APPROVAL_BASED_UPDATE) ||
                updateType.includes(NEW_ADDITIONS) ||
                updateType.includes(DEACTIVATION)
            ) &&
            legalUpdateData.legalUpdateStatusId == CONFIRMED
        ) {
            if (updateType.includes(APPROVAL_BASED_UPDATE) || updateType.includes(DEACTIVATION)) {
                this.setState({
                    isComplianceActiveFreeze: subUpdateType.includes(
                        DEACTIVATION_COMPLIANCE
                    )
                        ? false
                        : true,
                    isComplianceClassFreeze: subUpdateType.includes(
                        HIGH_IMPACT_COMPLIANCE_CLASS
                    )
                        ? false
                        : true,
                    isComplianceClassificationFreeze: subUpdateType.includes(
                        HIGH_IMPACT_COMPLIANCE_CLASSIFICATION
                    )
                        ? false
                        : true,
                    isFrequencyFreeze: subUpdateType.includes(
                        HIGH_IMPACT_FREQUENCY
                    )
                        ? false
                        : true,
                    isDueDateDesFreeze: subUpdateType.includes(
                        HIGH_IMPACT_DUE_DATE_DESCRIPTION
                    )
                        ? false
                        : true,
                    isDueDateCalFreeze: subUpdateType.includes(
                        HIGH_IMPACT_DUE_DATE_CALCULATION
                    )
                        ? false
                        : true,
                    isIfEventFreeze: subUpdateType.includes(
                        HIGH_IMPACT_IF_EVENT_BASED
                    )
                        ? false
                        : true,
                    isTriggeringCompFreeze: subUpdateType.includes(
                        HIGH_IMPACT_TRIGGERING_COMPLIANCE
                    )
                        ? false
                        : true,
                    isEventCalFreeze: subUpdateType.includes(
                        HIGH_IMPACT_EVENT_DUE_DATE_CALCULATION
                    )
                        ? false
                        : true,
                });
            }
        } else {
            this.setState({
                isFreeze: true,
                isComplianceActiveFreeze: true,
                isComplianceClassFreeze: true,
                isComplianceClassificationFreeze: true,
                isFrequencyFreeze: true,
                isDueDateDesFreeze: true,
                isDueDateCalFreeze: true,
                isIfEventFreeze: true,
                isTriggeringCompFreeze: true,
                isEventCalFreeze: true
            });
        }
    };

    permissionDenied = async (message) => {
        const response = await permissionDeniedMessage(message);
        if (response) {
            if (this.props.history.length != 1) {
                this.props.history.goBack();
            } else {
                this.props.history.push('/statutory-compliances');
            }
        }
    };

    render() {

        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <Card className="edit_compliance_form">
                            <CardHeader>
                                <div className="page-header">
                                    <h4 className="with-back-btn">
                                        {this.props.history.length != 1 && (
                                            <a
                                                href={() => false}
                                                className="dripicons-arrow-thin-left"
                                                onClick={() => {
                                                    this.props.history.goBack();
                                                }}
                                            >
                                                { }
                                            </a>
                                        )}
                                        <span>
                                            &nbsp;
                                            {this.state.complianceData
                                                ?.complianceCode +
                                                ' - ' +
                                                this.state.complianceData
                                                    ?.complianceTitle}
                                        </span>
                                    </h4>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Formik
                                    initialValues={Object.assign(
                                        {},
                                        this.state.statutoryCompliancesData
                                    )}
                                    enableReinitialize={true}
                                    onSubmit={this.formSubmit}
                                >
                                    {({ values, setFieldValue }) => (
                                        <Form className="">
                                            <Row className="edit_card_row">
                                                <Col lg="12">
                                                    <h4>Basic</h4>
                                                </Col>
                                                <FormGroup className="col-lg-12">
                                                    <Field
                                                        component={Select}
                                                        name="referenceId"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.state
                                                                .legalUpdateReferenceDropdownList
                                                        }
                                                        onChange={(opt) => {
                                                            setFieldValue(
                                                                'referenceId',
                                                                opt.value
                                                            );
                                                            this.setFreezeValues(
                                                                opt.value
                                                            );
                                                        }}
                                                        value={this.state.legalUpdateReferenceDropdownList?.filter(
                                                            (option) =>
                                                                option.value ===
                                                                values.referenceId
                                                        )}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.legal_update_reference'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.compliance.legal_update_reference'
                                                        ),
                                                        values.referenceId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.statutory_compliances.legal_update_reference_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component="textarea"
                                                        name="lawName"
                                                        className="form-control"
                                                        placeholder={__t(
                                                            'form_labels.laws.law_name'
                                                        )}
                                                        value={values.lawName}
                                                        maxLength={5000}
                                                        rows="3"
                                                        disabled={true}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.laws.law_name'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.masters.users.user_full_name'
                                                        ),
                                                        values.lawName,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.statutory_compliances.name_of_act_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component="textarea"
                                                        name="sectionReference"
                                                        className="form-control"
                                                        placeholder={__t(
                                                            'form_labels.compliance.section_reference'
                                                        )}
                                                        value={
                                                            values.sectionReference
                                                        }
                                                        maxLength={5000}
                                                        rows="3"
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.section_reference'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.compliance.section_reference'
                                                        ),
                                                        values.sectionReference,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.statutory_compliances.section_reference_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component="textarea"
                                                        name="ruleReference"
                                                        className="form-control"
                                                        placeholder={__t(
                                                            'form_labels.compliance.rule_reference'
                                                        )}
                                                        value={
                                                            values.ruleReference
                                                        }
                                                        maxLength={5000}
                                                        rows="3"
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.rule_reference'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.compliance.rule_reference'
                                                        ),
                                                        values.ruleReference,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.statutory_compliances.rule_reference_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component="textarea"
                                                        name="verbatimSectionReference"
                                                        className="form-control"
                                                        placeholder={__t(
                                                            'form_labels.compliance.verbatim_section_reference'
                                                        )}
                                                        value={
                                                            values.verbatimSectionReference
                                                        }
                                                        maxLength={20000}
                                                        rows="3"
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.verbatim_section_reference'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.compliance.verbatim_section_reference'
                                                        ),
                                                        values.verbatimSectionReference,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.statutory_compliances.verbatim_section_reference_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component="textarea"
                                                        name="verbatimRuleReference"
                                                        className="form-control"
                                                        placeholder={__t(
                                                            'form_labels.compliance.verbatim_rule_reference'
                                                        )}
                                                        value={
                                                            values.verbatimRuleReference
                                                        }
                                                        maxLength={20000}
                                                        rows="3"
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.verbatim_rule_reference'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.compliance.verbatim_rule_reference'
                                                        ),
                                                        values.verbatimRuleReference,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.statutory_compliances.verbatim_rule_reference_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component="textarea"
                                                        name="complianceTitle"
                                                        className="form-control"
                                                        placeholder={__t(
                                                            'form_labels.compliance.compliance_title'
                                                        )}
                                                        value={
                                                            values.complianceTitle
                                                        }
                                                        maxLength={255}
                                                        rows="3"
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.compliance_title'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.compliance.compliance_title'
                                                        ),
                                                        values.complianceTitle,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.statutory_compliances.compliance_title_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component="textarea"
                                                        name="complianceSpecification"
                                                        className="form-control"
                                                        placeholder={__t(
                                                            'form_labels.compliance.specification_of_compliance'
                                                        )}
                                                        value={
                                                            values.complianceSpecification
                                                        }
                                                        maxLength={3000}
                                                        rows="3"
                                                    />

                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.specification_of_compliance'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.compliance.specification_of_compliance'
                                                        ),
                                                        values.complianceSpecification,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.statutory_compliances.compliance_specification_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component="textarea"
                                                        name="complianceDetails"
                                                        className="form-control"
                                                        placeholder={__t(
                                                            'form_labels.compliance.more_details_on_compliance'
                                                        )}
                                                        value={
                                                            values.complianceDetails
                                                        }
                                                        maxLength={1000}
                                                        rows="3"
                                                    />

                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.more_details_on_compliance'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.compliance.more_details_on_compliance'
                                                        ),
                                                        values.complianceDetails,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.statutory_compliances.more_details_on_compliance_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component="textarea"
                                                        name="complianceApplicability"
                                                        className="form-control"
                                                        placeholder={__t(
                                                            'form_labels.compliance.compliance_applicability'
                                                        )}
                                                        value={
                                                            values.complianceApplicability
                                                        }
                                                        maxLength={500}
                                                        rows="3"
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.compliance_applicability'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.compliance.compliance_applicability'
                                                        ),
                                                        values.complianceApplicability,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.statutory_compliances.compliance_applicability_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component="textarea"
                                                        name="penaltyDescription"
                                                        className="form-control"
                                                        placeholder={__t(
                                                            'form_labels.compliance.consequence_of_non_compliance'
                                                        )}
                                                        value={
                                                            values.penaltyDescription
                                                        }
                                                        maxLength={5000}
                                                        rows="3"
                                                    />

                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.consequence_of_non_compliance'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.compliance.consequence_of_non_compliance'
                                                        ),
                                                        values.penaltyDescription,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.statutory_compliances.consequence_of_non_compliance_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component="textarea"
                                                        name="briefNotes"
                                                        className="form-control"
                                                        placeholder={__t(
                                                            'form_labels.compliance.brief_notes'
                                                        )}
                                                        value={
                                                            values.briefNotes
                                                        }
                                                        maxLength={5000}
                                                        rows="3"
                                                    />

                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.brief_notes'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                </FormGroup>
                                            </Row>

                                            <Row className="mt-4 edit_card_row">
                                                <Col lg="12">
                                                    <h4>Advance</h4>
                                                </Col>

                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component={Select}
                                                        name="complianceTypeId"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.state
                                                                .allCompliancesTypesList
                                                        }
                                                        onChange={(opt) => {
                                                            setFieldValue(
                                                                'complianceTypeId',
                                                                opt.value
                                                            );
                                                        }}
                                                        value={this.state.allCompliancesTypesList?.filter(
                                                            (option) =>
                                                                option.value ===
                                                                values.complianceTypeId
                                                        )}
                                                        isDisabled={
                                                            this.state
                                                                .isComplianceClassFreeze
                                                        }
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.compliance_class'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.compliance.compliance_class'
                                                        ),
                                                        values.complianceTypeId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.statutory_compliances.compliance_class_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component={Select}
                                                        name="complianceCategoryId"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.state
                                                                .allCompliancesCategoriesList
                                                        }
                                                        onChange={(opt) => {
                                                            setFieldValue(
                                                                'complianceCategoryId',
                                                                opt.value
                                                            );
                                                            if (
                                                                opt.value ==
                                                                COMPLIANCE_CATEGORY_EVENT_BASED
                                                            ) {
                                                                setFieldValue(
                                                                    'isEventBased',
                                                                    ACTIVE
                                                                );
                                                                setFieldValue(
                                                                    'periodicityId',
                                                                    ''
                                                                );
                                                                setFieldValue(
                                                                    'dueDateDescription',
                                                                    ''
                                                                );
                                                                setFieldValue(
                                                                    'dueDateFormula',
                                                                    ''
                                                                );
                                                            } else {
                                                                setFieldValue(
                                                                    'isEventBased',
                                                                    INACTIVE
                                                                );
                                                            }
                                                        }}
                                                        value={this.state.allCompliancesCategoriesList?.filter(
                                                            (option) =>
                                                                option.value ===
                                                                values.complianceCategoryId
                                                        )}
                                                        isDisabled={
                                                            this.state
                                                                .isComplianceClassificationFreeze
                                                        }
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.compliance_classification'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.compliance.compliance_classification'
                                                        ),
                                                        values.complianceCategoryId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.statutory_compliances.compliance_classification_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component={Select}
                                                        name="periodicityId"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.state
                                                                .allPeriodicitiesList
                                                        }
                                                        onChange={(opt) => {
                                                            setFieldValue(
                                                                'periodicityId',
                                                                opt.value
                                                            );
                                                        }}
                                                        value={this.state.allPeriodicitiesList?.filter(
                                                            (option) =>
                                                                option.value ===
                                                                values.periodicityId
                                                        )}
                                                        isDisabled={
                                                            this.state
                                                                .isFrequencyFreeze
                                                        }
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.frequency'
                                                        )}
                                                        {values.complianceCategoryId !=
                                                            COMPLIANCE_CATEGORY_EVENT_BASED && (
                                                                <span className="text-danger required">
                                                                    *
                                                                </span>
                                                            )}
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.compliance.frequency'
                                                        ),
                                                        values.periodicityId,
                                                        values.complianceCategoryId !=
                                                            COMPLIANCE_CATEGORY_EVENT_BASED
                                                            ? 'required'
                                                            : '',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.statutory_compliances.frequency_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component={
                                                            CustomMultiSelect
                                                        }
                                                        name="relevantLawId[]"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.state
                                                                .relevantlawList
                                                        }
                                                        onChange={(opt) => {
                                                            let relevantLawId =
                                                                opt &&
                                                                opt.map(
                                                                    (x) =>
                                                                        x.value
                                                                );
                                                            setFieldValue(
                                                                'relevantLawId',
                                                                relevantLawId
                                                            );
                                                        }}
                                                        value={this.state.relevantlawList?.filter(
                                                            (option) =>
                                                                values.relevantLawId?.includes(
                                                                    option.value
                                                                )
                                                        )}
                                                        isMulti
                                                        closeMenuOnSelect={
                                                            false
                                                        }
                                                        hideSelectedOptions={
                                                            false
                                                        }
                                                        allowSelectAll={true}
                                                        components={{
                                                            Option,
                                                            MultiValue,
                                                            animatedComponents,
                                                        }}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.relevant_law_id'
                                                        )}{' '}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        name="dueDateDescription"
                                                        className="form-control"
                                                        value={
                                                            values.dueDateDescription
                                                        }
                                                        placeholder={__t(
                                                            'form_labels.compliance.due_date_description'
                                                        )}
                                                        maxLength={100}
                                                        disabled={
                                                            this.state
                                                                .isDueDateDesFreeze
                                                        }
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.due_date_description'
                                                        )}
                                                        {values.complianceCategoryId !=
                                                            COMPLIANCE_CATEGORY_EVENT_BASED &&
                                                            values.complianceCategoryId !=
                                                            COMPLIANCE_CATEGORY_ONE_TIME

                                                            && (
                                                                <span className="text-danger required">
                                                                    *
                                                                </span>
                                                            )}
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.compliance.due_date_description'
                                                        ),
                                                        values.dueDateDescription,
                                                        values.complianceCategoryId !=
                                                            COMPLIANCE_CATEGORY_EVENT_BASED &&
                                                            values.complianceCategoryId !=
                                                            COMPLIANCE_CATEGORY_ON_GOING &&
                                                            values.complianceCategoryId !=
                                                            COMPLIANCE_CATEGORY_ONE_TIME

                                                            ? 'required'
                                                            : '',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.statutory_compliances.due_date_description_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        name="dueDateFormula"
                                                        className="form-control"
                                                        value={
                                                            values.dueDateFormula
                                                        }
                                                        placeholder={__t(
                                                            'form_labels.compliance.dueDate_formula'
                                                        )}
                                                        maxLength={100}
                                                        disabled={
                                                            this.state
                                                                .isDueDateCalFreeze
                                                        }
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.dueDate_formula'
                                                        )}
                                                        {values.complianceCategoryId !=
                                                            COMPLIANCE_CATEGORY_EVENT_BASED &&
                                                            values.complianceCategoryId !=
                                                            COMPLIANCE_CATEGORY_ON_GOING &&
                                                            (values.complianceCategoryId !=
                                                                COMPLIANCE_CATEGORY_ONE_TIME

                                                            )
                                                            && (
                                                                <span className="text-danger required">
                                                                    *
                                                                </span>
                                                            )}
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.compliance.dueDate_formula'
                                                        ),
                                                        values.dueDateFormula,
                                                        values.complianceCategoryId !=
                                                            COMPLIANCE_CATEGORY_EVENT_BASED &&
                                                            values.complianceCategoryId !=
                                                            COMPLIANCE_CATEGORY_ON_GOING &&
                                                            (values.complianceCategoryId !=
                                                                COMPLIANCE_CATEGORY_ONE_TIME


                                                            )
                                                            ? 'required'
                                                            : '',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.statutory_compliances.due_date_formula_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component={Select}
                                                        name="compliancePeriod"
                                                        className="form-control selectbox"
                                                        options={
                                                            CompliancePeriodStatusList
                                                        }
                                                        onChange={(opt) => {
                                                            setFieldValue(
                                                                'compliancePeriod',
                                                                opt.value
                                                            );
                                                        }}
                                                        value={CompliancePeriodStatusList.filter(
                                                            (option) =>
                                                                option.value ===
                                                                values.compliancePeriod
                                                        )}
                                                    />

                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.compliance_period'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.compliance.compliance_period'
                                                        ),
                                                        values.compliancePeriod,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.statutory_compliances.compliance_period_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component={
                                                            CustomMultiSelect
                                                        }
                                                        name="penaltyTypeId[]"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.state
                                                                .penaltyTypesList
                                                        }
                                                        onChange={(opt) => {
                                                            let penaltyTypeId =
                                                                opt
                                                                    ? opt.map(
                                                                        (x) =>
                                                                            x.value
                                                                    )
                                                                    : [];
                                                            setFieldValue(
                                                                'penaltyTypeId',
                                                                penaltyTypeId
                                                            );
                                                        }}
                                                        value={this.state.penaltyTypesList?.filter(
                                                            (option) =>
                                                                values.penaltyTypeId?.includes(
                                                                    option.value
                                                                )
                                                        )}
                                                        isMulti
                                                        closeMenuOnSelect={
                                                            false
                                                        }
                                                        hideSelectedOptions={
                                                            false
                                                        }
                                                        allowSelectAll={true}
                                                        components={{
                                                            Option,
                                                            MultiValue,
                                                            animatedComponents,
                                                        }}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.consequence_category'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.compliance.consequence_category'
                                                        ),
                                                        values.penaltyTypeId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.statutory_compliances.consequence_category_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component={Select}
                                                        name="riskRatingId"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.state
                                                                .riskRatingsList
                                                        }
                                                        onChange={(opt) => {
                                                            setFieldValue(
                                                                'riskRatingId',
                                                                opt.value
                                                            );
                                                        }}
                                                        value={this.state.riskRatingsList?.filter(
                                                            (option) =>
                                                                option.value ===
                                                                values.riskRatingId
                                                        )}
                                                    />

                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.risk_level'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.compliance.risk_level'
                                                        ),
                                                        values.riskRatingId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.statutory_compliances.risk_level_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>

                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        name="formNo"
                                                        className="form-control"
                                                        value={values.formNo}
                                                        placeholder={__t(
                                                            'form_labels.compliance.formno_formats'
                                                        )}
                                                        maxLength={1000}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.formno_formats'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.compliance.formno_formats'
                                                        ),
                                                        values.formNo,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.statutory_compliances.formno_formats_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        name="monetaryPenaltyValue"
                                                        className="form-control"
                                                        value={
                                                            values.monetaryPenaltyValue
                                                        }
                                                        placeholder={__t(
                                                            'form_labels.compliance.monetary_penalty_value'
                                                        )}
                                                        maxLength={100}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.monetary_penalty_value'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.compliance.monetary_penalty_value'
                                                        ),
                                                        values.monetaryPenaltyValue,
                                                        'numeric|min:0,num',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.statutory_compliances.monetary_penalty_value_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        name="governingAuthorityWebsite"
                                                        className="form-control"
                                                        value={
                                                            values.governingAuthorityWebsite
                                                        }
                                                        placeholder={__t(
                                                            'form_labels.compliance.governing_authority_website'
                                                        )}
                                                        maxLength={100}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.governing_authority_website'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.compliance.governing_authority_website'
                                                        ),
                                                        values.governingAuthorityWebsite,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.statutory_compliances.governing_authority_website_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>

                                                <FormGroup className="col-lg-4 edit_datepicker_col">
                                                    <Field
                                                        name="enforcementDate"
                                                        component={DatePicker}
                                                        isClearable={true}
                                                        className="form-control"
                                                        placeholderText={__t(
                                                            'form_labels.compliance.date_of_enforcement'
                                                        )}
                                                        selected={
                                                            values.enforcementDate
                                                                ? getDateObjectWithValueFromMoment(
                                                                    values.enforcementDate
                                                                )
                                                                : null
                                                        }
                                                        dateFormat={
                                                            DATE_FORMAT_DATEPICKER
                                                        }
                                                        onChange={(date) => {
                                                            setFieldValue(
                                                                `enforcementDate`,
                                                                date
                                                            );
                                                        }}
                                                        showMonthDropdown={true}
                                                        showYearDropdown={true}
                                                        dropdownMode="select"
                                                        onFocus={(e) =>
                                                            e.target.blur()
                                                        }
                                                    />

                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.date_of_enforcement'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.compliance.date_of_enforcement'
                                                        ),
                                                        values.enforcementDate,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.statutory_compliances.date_of_enforcement_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>

                                                <FormGroup className="col-lg-4 sticky_label radio_btn">
                                                    <div className="form-check form-check-inline">
                                                        <Field
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="applicabilityCondition"
                                                            id="applicabilityConditionMandatory"
                                                            onChange={(opt) => {
                                                                if (opt) {
                                                                    setFieldValue(
                                                                        'applicabilityCondition',
                                                                        MANDATORY
                                                                    );
                                                                } else {
                                                                    setFieldValue(
                                                                        'applicabilityCondition',
                                                                        TENTATIVE
                                                                    );
                                                                }
                                                            }}
                                                            value={MANDATORY}
                                                        />
                                                        <Label
                                                            className="form-check-label"
                                                            for="applicabilityConditionMandatory"
                                                        >
                                                            {__t(
                                                                'form_labels.compliance.mandatory'
                                                            )}
                                                        </Label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <Field
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="applicabilityCondition"
                                                            id="applicabilityConditionTentative"
                                                            onChange={(opt) => {
                                                                if (opt) {
                                                                    setFieldValue(
                                                                        'applicabilityCondition',
                                                                        TENTATIVE
                                                                    );
                                                                } else {
                                                                    setFieldValue(
                                                                        'applicabilityCondition',
                                                                        MANDATORY
                                                                    );
                                                                }
                                                            }}
                                                            value={TENTATIVE}
                                                        />
                                                        <Label
                                                            className="form-check-label"
                                                            for="applicabilityConditionTentative"
                                                        >
                                                            {__t(
                                                                'form_labels.compliance.tentative'
                                                            )}
                                                        </Label>
                                                    </div>
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.applicability_type'
                                                        )}
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.compliance.tentative'
                                                        ),
                                                        values.applicabilityCondition,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.statutory_compliances.applicability_type_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4 sticky_label radio_btn">
                                                    <div className="form-check form-check-inline">
                                                        <Field
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="isProofRequired"
                                                            id="isProofRequired"
                                                            onChange={(opt) => {
                                                                if (opt) {
                                                                    setFieldValue(
                                                                        'isProofRequired',
                                                                        ACTIVE
                                                                    );
                                                                } else {
                                                                    setFieldValue(
                                                                        'isProofRequired',
                                                                        INACTIVE
                                                                    );
                                                                }
                                                            }}
                                                            value={ACTIVE}
                                                        />
                                                        <Label
                                                            className="form-check-label"
                                                            for="isProofRequired"
                                                        >
                                                            {__t(
                                                                'form_labels.compliance.yes'
                                                            )}
                                                        </Label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <Field
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="isProofRequired"
                                                            id="isProofRequired1"
                                                            onChange={(opt) => {
                                                                if (opt) {
                                                                    setFieldValue(
                                                                        'isProofRequired',
                                                                        INACTIVE
                                                                    );
                                                                } else {
                                                                    setFieldValue(
                                                                        'isProofRequired',
                                                                        ACTIVE
                                                                    );
                                                                }
                                                            }}
                                                            value={INACTIVE}
                                                        />
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.compliance.need_compliance_evidence'
                                                            ),
                                                            values.isProofRequired,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required:
                                                                        __t(
                                                                            'validations.statutory_compliances.need_compliance_evidence_required'
                                                                        ),
                                                                },
                                                            }
                                                        )}
                                                        <Label
                                                            className="form-check-label"
                                                            for="isProofRequired1"
                                                        >
                                                            {__t(
                                                                'form_labels.compliance.no'
                                                            )}
                                                        </Label>
                                                    </div>

                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.need_compliance_evidence'
                                                        )}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component={
                                                            CustomMultiSelect
                                                        }
                                                        name="impactManagementId[]"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.state
                                                                .impactManagementPersonnelList
                                                        }
                                                        onChange={(opt) => {
                                                            let impactManagementId =
                                                                opt
                                                                    ? opt.map(
                                                                        (x) =>
                                                                            x.value
                                                                    )
                                                                    : [];
                                                            setFieldValue(
                                                                'impactManagementId',
                                                                impactManagementId
                                                            );
                                                        }}
                                                        value={this.state.impactManagementPersonnelList?.filter(
                                                            (option) =>
                                                                values.impactManagementId?.includes(
                                                                    option.value
                                                                )
                                                        )}
                                                        isMulti
                                                        closeMenuOnSelect={
                                                            false
                                                        }
                                                        hideSelectedOptions={
                                                            false
                                                        }
                                                        allowSelectAll={true}
                                                        components={{
                                                            Option,
                                                            MultiValue,
                                                            animatedComponents,
                                                        }}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.management_personnel_impact'
                                                        )}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component={
                                                            CustomMultiSelect
                                                        }
                                                        name="entityTypeId[]"
                                                        className="form-control selectbox"
                                                        options={this.props.sortedEntityTypeListDropDown}
                                                        onChange={(opt) => {
                                                            let entityTypeId =
                                                                opt
                                                                    ? opt.map(
                                                                        (x) =>
                                                                            x.value
                                                                    )
                                                                    : [];
                                                            setFieldValue(
                                                                'entityTypeId',
                                                                entityTypeId
                                                            );
                                                        }}
                                                        value={this.props.sortedEntityTypeListDropDown?.filter(
                                                            (option) =>
                                                                values.entityTypeId?.includes(
                                                                    option.value
                                                                )
                                                        )}
                                                        isMulti
                                                        closeMenuOnSelect={
                                                            false
                                                        }
                                                        hideSelectedOptions={
                                                            false
                                                        }
                                                        allowSelectAll={true}
                                                        components={{
                                                            Option,
                                                            MultiValue,
                                                            animatedComponents,
                                                        }}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.laws.entity_types'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.laws.entity_types'
                                                        ),
                                                        values.entityTypeId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.laws.entity_types_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component={
                                                            CustomMultiSelect
                                                        }
                                                        name="facilityTypeId[]"
                                                        className="form-control selectbox"
                                                        options={this.props.sortedFacilityTypeListDropDown}
                                                        onChange={(opt) => {
                                                            let facilityTypeId =
                                                                opt
                                                                    ? opt.map(
                                                                        (x) =>
                                                                            x.value
                                                                    )
                                                                    : [];
                                                            setFieldValue(
                                                                'facilityTypeId',
                                                                facilityTypeId
                                                            );
                                                        }}
                                                        value={this.props.sortedFacilityTypeListDropDown?.filter(
                                                            (option) =>
                                                                values.facilityTypeId?.includes(
                                                                    option.value
                                                                )
                                                        )}
                                                        isMulti
                                                        closeMenuOnSelect={
                                                            false
                                                        }
                                                        hideSelectedOptions={
                                                            false
                                                        }
                                                        allowSelectAll={true}
                                                        components={{
                                                            Option,
                                                            MultiValue,
                                                            animatedComponents,
                                                        }}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.laws.facility_types'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.laws.facility_types'
                                                        ),
                                                        values.facilityTypeId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.laws.facility_types_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component={
                                                            CustomMultiSelect
                                                        }
                                                        name="applicabilityId[]"
                                                        className="form-control selectbox"
                                                        options={this.props.sortedApplicabilityListDropDown}
                                                        onChange={(opt) => {
                                                            let applicabilityId =
                                                                opt
                                                                    ? opt.map(
                                                                        (x) =>
                                                                            x.value
                                                                    )
                                                                    : [];
                                                            setFieldValue(
                                                                'applicabilityId',
                                                                applicabilityId
                                                            );
                                                            this.getSubApplicabilityListByApplicability(
                                                                applicabilityId
                                                            );
                                                        }}
                                                        value={this.props.sortedApplicabilityListDropDown?.filter(
                                                            (option) =>
                                                                values.applicabilityId?.includes(
                                                                    option.value
                                                                )
                                                        )}
                                                        isMulti
                                                        closeMenuOnSelect={
                                                            false
                                                        }
                                                        hideSelectedOptions={
                                                            false
                                                        }
                                                        allowSelectAll={true}
                                                        components={{
                                                            Option,
                                                            MultiValue,
                                                            animatedComponents,
                                                        }}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.laws.applicabilities'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.laws.applicabilities'
                                                        ),
                                                        values.applicabilityId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.laws.applicability_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component={
                                                            CustomMultiSelect
                                                        }
                                                        name="subApplicabilityId[]"
                                                        className="form-control selectbox"
                                                        options={this.props.sortedSubApplicabilityListDropDown}
                                                        onChange={(opt) => {
                                                            let subApplicabilityId =
                                                                opt
                                                                    ? opt.map(
                                                                        (x) =>
                                                                            x.value
                                                                    )
                                                                    : [];
                                                            setFieldValue(
                                                                'subApplicabilityId',
                                                                subApplicabilityId
                                                            );
                                                        }}
                                                        value={this.props.sortedSubApplicabilityListDropDown?.filter(
                                                            (option) =>
                                                                values.subApplicabilityId?.includes(
                                                                    option.value
                                                                )
                                                        )}
                                                        isMulti
                                                        closeMenuOnSelect={
                                                            false
                                                        }
                                                        hideSelectedOptions={
                                                            false
                                                        }
                                                        allowSelectAll={true}
                                                        components={{
                                                            Option,
                                                            MultiValue,
                                                            animatedComponents,
                                                        }}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.laws.sub_applicabilities'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.laws.sub_applicabilities'
                                                        ),
                                                        values.applicabilityId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.laws.sub_applicability_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <Col
                                                    lg="4"
                                                    className="edit_card_col"
                                                >
                                                    <Row
                                                        className={
                                                            values.isEventBased ===
                                                                ACTIVE
                                                                ? 'edit_card_row_inner'
                                                                : ''
                                                        }
                                                    >
                                                        <FormGroup className="col-12 sticky_label radio_btn mb-0 p-0">
                                                            <div className="form-check form-check-inline">
                                                                <Field
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="isEventBased"
                                                                    id="isEventBased"
                                                                    onChange={(
                                                                        opt
                                                                    ) => {
                                                                        if (
                                                                            opt
                                                                        ) {
                                                                            setFieldValue(
                                                                                'isEventBased',
                                                                                ACTIVE
                                                                            );
                                                                        } else {
                                                                            setFieldValue(
                                                                                'isEventBased',
                                                                                INACTIVE
                                                                            );
                                                                        }
                                                                        this.validator.purgeFields();
                                                                    }}
                                                                    value={
                                                                        ACTIVE
                                                                    }
                                                                    disabled={
                                                                        this
                                                                            .state
                                                                            .isIfEventFreeze
                                                                    }
                                                                />
                                                                <Label
                                                                    className="form-check-label"
                                                                    for="isEventBasedLc"
                                                                >
                                                                    {__t(
                                                                        'form_labels.compliance.yes'
                                                                    )}
                                                                </Label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <Field
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="isEventBased"
                                                                    id="isEventBased"
                                                                    onChange={(
                                                                        opt
                                                                    ) => {
                                                                        if (
                                                                            opt
                                                                        ) {
                                                                            setFieldValue(
                                                                                'isEventBased',
                                                                                INACTIVE
                                                                            );
                                                                        } else {
                                                                            setFieldValue(
                                                                                'isEventBased',
                                                                                ACTIVE
                                                                            );
                                                                        }
                                                                        setFieldValue(
                                                                            'eventId',
                                                                            ''
                                                                        );
                                                                        setFieldValue(
                                                                            'eventDueDateFormula',
                                                                            ''
                                                                        );
                                                                        this.validator.purgeFields();
                                                                    }}
                                                                    value={
                                                                        INACTIVE
                                                                    }
                                                                    disabled={
                                                                        this
                                                                            .state
                                                                            .isIfEventFreeze
                                                                    }
                                                                />
                                                                <Label
                                                                    className="form-check-label"
                                                                    for="authenticationModeSso"
                                                                >
                                                                    {__t(
                                                                        'form_labels.compliance.no'
                                                                    )}
                                                                </Label>
                                                            </div>
                                                            <Label className="form-label">
                                                                {__t(
                                                                    'form_labels.compliance.event_based'
                                                                )}
                                                            </Label>
                                                            {this.validator.message(
                                                                __t(
                                                                    'form_labels.compliance.event_based'
                                                                ),
                                                                values.isEventBased,
                                                                'required',
                                                                {
                                                                    messages: {
                                                                        required:
                                                                            __t(
                                                                                'validations.statutory_compliances.event_based_required'
                                                                            ),
                                                                    },
                                                                }
                                                            )}
                                                        </FormGroup>
                                                        {values.isEventBased ===
                                                            ACTIVE && (
                                                                <>
                                                                    <FormGroup className="col-12 p-0 mt-3">
                                                                        <Field
                                                                            component={
                                                                                Select
                                                                            }
                                                                            name="eventId"
                                                                            className="form-control selectbox"
                                                                            options={
                                                                                this
                                                                                    .state
                                                                                    .eventList
                                                                            }
                                                                            onChange={(
                                                                                opt
                                                                            ) => {
                                                                                setFieldValue(
                                                                                    'eventId',
                                                                                    opt.value
                                                                                );
                                                                                if (
                                                                                    opt.value !=
                                                                                    ''
                                                                                ) {
                                                                                    this.props.getLawComplianceSearchRequest(
                                                                                        {
                                                                                            limit: -1,
                                                                                            page: -1,
                                                                                            filter: {
                                                                                                countryId: [
                                                                                                    this
                                                                                                        .state
                                                                                                        .complianceData
                                                                                                        .countryId,
                                                                                                ],
                                                                                                eventId:
                                                                                                    [
                                                                                                        opt.value,
                                                                                                    ],
                                                                                                lawId: [
                                                                                                    this
                                                                                                        .state
                                                                                                        .complianceData
                                                                                                        .lawId,
                                                                                                ],
                                                                                            },
                                                                                        },
                                                                                        false
                                                                                    );
                                                                                }
                                                                            }}
                                                                            isDisabled={
                                                                                this
                                                                                    .state
                                                                                    .isIfEventFreeze
                                                                            }
                                                                            value={this.state.eventList?.filter(
                                                                                (
                                                                                    option
                                                                                ) =>
                                                                                    option.value ===
                                                                                    values.eventId
                                                                            )}
                                                                        />
                                                                        <Label className="form-label">
                                                                            {__t(
                                                                                'form_labels.compliance.event_name'
                                                                            )}
                                                                            <span className="text-danger required">
                                                                                *
                                                                            </span>
                                                                        </Label>
                                                                        {this.validator.message(
                                                                            __t(
                                                                                'form_labels.compliance.event_based'
                                                                            ),
                                                                            values.eventId,
                                                                            'required',
                                                                            {
                                                                                messages:
                                                                                {
                                                                                    required:
                                                                                        __t(
                                                                                            'validations.statutory_compliances.event_name_required'
                                                                                        ),
                                                                                },
                                                                            }
                                                                        )}
                                                                    </FormGroup>
                                                                    <FormGroup className="col-12 p-0 mt-3">
                                                                        <Field
                                                                            name="eventDueDateFormula"
                                                                            className="form-control"
                                                                            value={
                                                                                values.eventDueDateFormula
                                                                            }
                                                                            placeholder={__t(
                                                                                'form_labels.compliance.event_due_date_formula'
                                                                            )}
                                                                            maxLength={
                                                                                100
                                                                            }
                                                                            disabled={
                                                                                this
                                                                                    .state
                                                                                    .isEventCalFreeze
                                                                            }
                                                                        />
                                                                        <Label className="form-label">
                                                                            {__t(
                                                                                'form_labels.compliance.event_due_date_formula'
                                                                            )}

                                                                        </Label>

                                                                    </FormGroup>
                                                                    <FormGroup className="col-12 p-0 mt-3">
                                                                        <Field
                                                                            component={
                                                                                Select
                                                                            }
                                                                            name="triggeringComplianceId"
                                                                            className="form-control selectbox"
                                                                            options={
                                                                                this
                                                                                    .state
                                                                                    .lawComplianceDropdownList
                                                                            }
                                                                            onChange={(
                                                                                opt
                                                                            ) => {
                                                                                setFieldValue(
                                                                                    'triggeringComplianceId',
                                                                                    opt
                                                                                        ? opt.value
                                                                                        : ''
                                                                                );
                                                                            }}
                                                                            value={this.state.lawComplianceDropdownList?.filter(
                                                                                (
                                                                                    option
                                                                                ) =>
                                                                                    option.value ===
                                                                                    values.triggeringComplianceId
                                                                            )}
                                                                            isDisabled={
                                                                                this
                                                                                    .state
                                                                                    .isTriggeringCompFreeze
                                                                            }
                                                                            isClearable={
                                                                                true
                                                                            }
                                                                        />
                                                                        <Label className="form-label">
                                                                            {__t(
                                                                                'form_labels.compliance.triggering_compliance'
                                                                            )}{' '}

                                                                        </Label>

                                                                    </FormGroup>
                                                                </>
                                                            )}
                                                    </Row>
                                                </Col>
                                                <Col
                                                    lg="4"
                                                    className="edit_card_col"
                                                >
                                                    <Row
                                                        className={
                                                            values.isFilingRequired ===
                                                                ACTIVE
                                                                ? 'edit_card_row_inner'
                                                                : ''
                                                        }
                                                    >
                                                        <FormGroup className="col-lg-12 sticky_label radio_btn mb-0 p-0">
                                                            <div className="form-check form-check-inline">
                                                                <Field
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="isFilingRequired"
                                                                    id="isFilingRequired"
                                                                    onChange={(
                                                                        opt
                                                                    ) => {
                                                                        if (
                                                                            opt
                                                                        ) {
                                                                            setFieldValue(
                                                                                'isFilingRequired',
                                                                                ACTIVE
                                                                            );
                                                                        } else {
                                                                            setFieldValue(
                                                                                'isFilingRequired',
                                                                                INACTIVE
                                                                            );
                                                                        }
                                                                    }}
                                                                    value={
                                                                        ACTIVE
                                                                    }
                                                                />
                                                                <Label
                                                                    className="form-check-label"
                                                                    for="isFilingRequiredLc"
                                                                >
                                                                    {__t(
                                                                        'form_labels.compliance.yes'
                                                                    )}
                                                                </Label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <Field
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="isFilingRequired"
                                                                    id="isFilingRequired"
                                                                    onChange={(
                                                                        opt
                                                                    ) => {
                                                                        if (
                                                                            opt
                                                                        ) {
                                                                            setFieldValue(
                                                                                'isFilingRequired',
                                                                                INACTIVE
                                                                            );
                                                                        } else {
                                                                            setFieldValue(
                                                                                'isFilingRequired',
                                                                                ACTIVE
                                                                            );
                                                                        }
                                                                        setFieldValue(
                                                                            'filingTypeId',
                                                                            ''
                                                                        );
                                                                    }}
                                                                    value={
                                                                        INACTIVE
                                                                    }
                                                                />
                                                                <Label
                                                                    className="form-check-label"
                                                                    for="authenticationModeSso"
                                                                >
                                                                    {__t(
                                                                        'form_labels.compliance.no'
                                                                    )}
                                                                </Label>
                                                                {this.validator.message(
                                                                    __t(
                                                                        'form_labels.compliance.filing_required'
                                                                    ),
                                                                    values.isFilingRequired,
                                                                    'required',
                                                                    {
                                                                        messages:
                                                                        {
                                                                            required:
                                                                                __t(
                                                                                    'validations.statutory_compliances.filing_required_required'
                                                                                ),
                                                                        },
                                                                    }
                                                                )}
                                                            </div>

                                                            <Label className="form-label">
                                                                {__t(
                                                                    'form_labels.compliance.filing_required'
                                                                )}
                                                            </Label>
                                                        </FormGroup>

                                                        {values.isFilingRequired ===
                                                            ACTIVE && (
                                                                <FormGroup className="col-lg-12 p-0 mt-3">
                                                                    <Field
                                                                        component={
                                                                            Select
                                                                        }
                                                                        name="filingTypeId"
                                                                        className="form-control selectbox"
                                                                        options={
                                                                            this
                                                                                .state
                                                                                .filingTypesList
                                                                        }
                                                                        onChange={(
                                                                            opt
                                                                        ) => {
                                                                            setFieldValue(
                                                                                'filingTypeId',
                                                                                opt.value
                                                                            );
                                                                        }}
                                                                        value={this.state.filingTypesList?.filter(
                                                                            (
                                                                                option
                                                                            ) =>
                                                                                option.value ===
                                                                                values.filingTypeId
                                                                        )}
                                                                    />
                                                                    <Label className="form-label">
                                                                        {__t(
                                                                            'form_labels.compliance.filing_type'
                                                                        )}
                                                                        <span className="text-danger required">
                                                                            *
                                                                        </span>
                                                                    </Label>

                                                                    {this.validator.message(
                                                                        __t(
                                                                            'form_labels.compliance.filing_required'
                                                                        ),
                                                                        values.filingTypeId,
                                                                        'required',
                                                                        {
                                                                            messages:
                                                                            {
                                                                                required:
                                                                                    __t(
                                                                                        'validations.statutory_compliances.filing_type_required'
                                                                                    ),
                                                                            },
                                                                        }
                                                                    )}
                                                                    {values.filingTypeId ===
                                                                        INACTIVE &&
                                                                        this.validator.purgeFields()}
                                                                </FormGroup>
                                                            )}
                                                    </Row>
                                                </Col>
                                                <FormGroup className="col-lg-4 sticky_label radio_btn">
                                                    <div className="form-check form-check-inline">
                                                        <Field
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="isActive"
                                                            id="isActive1"
                                                            disabled={this.state.isComplianceActiveFreeze}
                                                            onChange={(opt) => {
                                                                if (opt) {
                                                                    setFieldValue(
                                                                        'isActive',
                                                                        ACTIVE
                                                                    );
                                                                } else {
                                                                    setFieldValue(
                                                                        'isActive',
                                                                        INACTIVE
                                                                    );
                                                                }
                                                            }}
                                                            value={ACTIVE}
                                                        />
                                                        <Label
                                                            className="form-check-label"
                                                            for="isActive1"
                                                        >
                                                            {__t(
                                                                'form_labels.compliance.active'
                                                            )}
                                                        </Label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <Field
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="isActive"
                                                            id="isActive2"
                                                            disabled={this.state.isComplianceActiveFreeze}
                                                            onChange={(opt) => {
                                                                if (opt) {
                                                                    setFieldValue(
                                                                        'isActive',
                                                                        INACTIVE
                                                                    );
                                                                } else {
                                                                    setFieldValue(
                                                                        'isActive',
                                                                        ACTIVE
                                                                    );
                                                                }
                                                            }}
                                                            value={INACTIVE}
                                                        />
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.compliance.inActive'
                                                            ),
                                                            values.isActive,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required:
                                                                        __t(
                                                                            'validations.statutory_compliances.required_active'
                                                                        ),
                                                                },
                                                            }
                                                        )}
                                                        <Label
                                                            className="form-check-label"
                                                            for="isActive2"
                                                        >
                                                            {__t(
                                                                'form_labels.compliance.inActive'
                                                            )}
                                                        </Label>
                                                    </div>

                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.status'
                                                        )}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup className="col-lg-3 revision_checkbox ml-3 mt-3 ">
                                                    <Input
                                                        type="checkbox"
                                                        id="isRevision"
                                                        name="isRevision"
                                                        onChange={(e) => {
                                                            const { checked } =
                                                                e.target;

                                                            setFieldValue(
                                                                'isRevision',
                                                                checked
                                                            );
                                                        }}
                                                        checked={
                                                            values.isRevision
                                                        }
                                                    />
                                                    <label className="form-check-label">
                                                        {__t(
                                                            'form_labels.common.version_update'
                                                        )}
                                                    </label>
                                                </FormGroup>
                                            </Row>
                                            <Col
                                                lg="12"
                                                className="d-flex align-items-end justify-content-end"
                                            >
                                                <FormGroup className="mb-0 d-flex justify-content-end">
                                                    <div className="d-inline-block mt-3 mr-2">
                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            className="mr-3"
                                                            onClick={() => {
                                                                this.setState({
                                                                    saveContinue: false,
                                                                });
                                                            }}
                                                        >
                                                            {UPDATE}
                                                        </Button>

                                                        <Button
                                                            type="reset"
                                                            color="secondary"
                                                            onClick={() => {
                                                                this.props.history.goBack();
                                                            }}
                                                        >
                                                            {CANCEL}
                                                        </Button>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Form>
                                    )}
                                </Formik>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = createStructuredSelector({
    lawComplianceDetailsApiResponse: makeLawComplianceSelectField(
        'lawComplianceDetailsApiResponse'
    ),
    allCompliancesTypesList: makeLawComplianceSelectField(
        'allCompliancesTypesList'
    ),
    allCompliancesCategoriesList: makeLawComplianceSelectField(
        'allCompliancesCategoriesList'
    ),
    allPeriodicitiesList: makeLawComplianceSelectField('allPeriodicitiesList'),
    eventList: makeEventSelectField('eventList'),
    filingTypesList: makeImportMasterSelectField('filingTypesList'),
    penaltyTypesList: makeLawComplianceSelectField('penaltyTypesList'),
    riskRatingsList: makeLawComplianceSelectField('riskRatingsList'),
    impactManagementPersonnelList: makeLawComplianceSelectField(
        'impactManagementPersonnelList'
    ),
    lawSearchApiResponse: makeLawSelectField('lawSearchApiResponse'),
    updateLawComplianceApiResponse: makeLawComplianceSelectField(
        'updateLawComplianceApiResponse'
    ),
    legalUpdateReferenceList: makeLawComplianceSelectField(
        'legalUpdateReferenceList'
    ),
    updateTypeList: selectSortedUpdateTypeList('updateTypeList'),
    lawComplianceSearchApiResponse: makeLawComplianceSelectField(
        'lawComplianceSearchApiResponse'
    ),
    sortedEntityTypeListDropDown: selectSortedEntityTypeList(),
    sortedFacilityTypeListDropDown: selectSortedFacilityTypeList(),
    sortedApplicabilityListDropDown: selectSortedApplicabilityList(),
    sortedSubApplicabilityListDropDown: selectSortedSubApplicabilityList(),
});

const withConnect = connect(mapStatetoProps, {
    getLawComplianceDetailsByLawComplianceIdRequest,
    getComplianceTypesRequest,
    getComplianceCategoriesRequest,
    getPeriodicitiesRequest,
    getEventListBySearchRequest,
    getFilingTypesRequest,
    getPenaltyTypesRequest,
    getRiskRatingsRequest,
    getImpactManagementPersonnelsRequest,
    updateLawcomplianceRequest,
    getLegalUpdateReferenceIdRequest,
    getAllupdateTypeListRequest,
    getLawComplianceSearchRequest,
    getLawsSearchListRequest,
    getAllEntityTypeListRequest,
    getAllFacilityTypeListRequest,
    getApplicabilityListByStatusRequest,
    getSubApplicabilityListByApplicabilityRequest,
});

export default compose(
    withConnect,
    withLawComplianceReducer,
    withLawComplianceSaga,
    withEventReducer,
    withEventSaga,
    withImportMasterReducer,
    withImportMasterSaga,
    withLegalUpdatesReducer,
    withLegalUpdatesSaga,
    withLawReducer,
    withLawSaga,
    withApplicabilityReducer,
    withApplicabilitySaga,
    withRouter
)(ComplianceDetailsForm);
