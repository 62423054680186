import React, { Component } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    FormGroup,
    Button,
    Label,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Container,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getCountryListByStatusRequest,
    getUserEntityListRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getDepartmentListByStatusRequest,
    getComplianceCategoriesRequest,
    getComplianceTypesRequest,
    getRiskRatingsRequest,
    getTaskStatusListRequest,
    getLawsListByLoginUserRequest,
    getReportDataByTypeRequest,
    getSystemRuntimeReportRequest,
    getUserListByEntityAndRoleRequest,
    clearFilterEntityUserResponse
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import ReportFilterForm from './ReportFilterForm';
import CustomisedCollapseForOne from 'components/Application/CustomisedCollapseForOne';
import __t from 'i18n/translator';
import { Formik, Form, Field } from 'formik';
import ApexCharts from 'react-apexcharts';
import classnames from 'classnames';
import {
    dateDifference,
    diffYMDHMS,
    formatDateForApiSystem,
    getCurrentDate,
    getDateFrom,
    getDateObjectWithValueFromMoment,
} from 'helpers/dateTimeHelper';
import { makeReportSelectField } from 'store/Report/selector';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import lawReducer from 'store/Masters/Law/reducer';
import lawSaga from 'store/Masters/Law/saga';
import lawComplianceReducer from 'store/LawCompliance/reducer';
import lawComplianceSaga from 'store/LawCompliance/saga';
import departmentReducer from 'store/Masters/Department/reducer';
import departmentSaga from 'store/Masters/Department/saga';
import taskReducer from 'store/Task/reducer';
import taskSaga from 'store/Task/saga';
import reportReducer from 'store/Report/reducer';
import reportSaga from 'store/Report/saga';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import {
    complianceStatusChartOptions,
    initialReportFilterDataForGraphState,
    initialReportFilterDataForProState,
    initialReportFilterDataState,
    initialRunTimeFilterDataState,
    listColumns1,
    listColumns2,
} from './CommonFunctions';
import {
    ACTIVE,
    CLEAR,
    DANGER,
    DATE_FORMAT_DATEPICKER,
    DATE_FORMAT_PLACEHOLDER,
    PENDING_TO_TRIGGERED,
    SEARCH,
    SUCCESS,
} from 'constants/commonConstants';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
import { roleList } from 'constants/dropdowns';
import { compareIntValue, compareString, isDarkMode } from 'helpers/generalUtils';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedStateList } from 'store/Masters/State/selector';
import { selectSortedCityList } from 'store/Masters/City/selector';
import { selectSortedEntityList, selectSortedUserListByEntityAndRole } from 'store/Entity/selector';
import { selectSortedDepartmentList } from 'store/Masters/Department/selector';
import { makeLawComplianceSelectField } from 'store/LawCompliance/selector';
import { selectSortedLawListOfLoginUser } from 'store/Masters/Law/selector';
import { selectSortedTaskStatusList } from 'store/Task/selector';
import Select from 'react-select';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import {
    Option,
    MultiValue,
    animatedComponents,
    isManagementRole,
    isHodRole,
    isGTROLE,
    getComplianceScore,
} from 'helpers/projectUtils';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SimpleReactValidator from 'simple-react-validator';
import { exportToCSV } from 'components/Application/ExportCSVManual';
import {
    AUDITED_AND_CLOSED,
    CLOSED,
    DISCREPANCY_COMPLETED_RESOLUTION,
    DISCREPANCY_ONGOING_RESOLUTION,
    DISCREPANCY_PENDING_RESOLUTION,
    DISCREPANCY_TYPE_MONETARY,
    DISCREPANCY_TYPE_NON_MONETARY,
    PENDING_FOR_AUDIT,
    PENDING_FOR_COMPLETION,
} from 'constants/databaseConstants';
import moment from 'moment';
import RuntimeFilterForm from './RuntimeFilterForm';
import ProReportFilterForm from './ProReportFilterForm';
import { toastrMessage } from 'helpers/messageHelper';
import { parseFloatHelper, roundOffAmount } from 'helpers/mathHelper';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import { decryptText } from 'helpers/crypto';
import PdfReportModal from './PdfReportModal';
import { MDBDataTable } from 'mdbreact';

const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });
const lawComplianceKey = 'lawCompliance';
const withLawComplianceReducer = injectReducer({
    key: lawComplianceKey,
    reducer: lawComplianceReducer,
});
const withLawComplianceSaga = injectSaga({
    key: lawComplianceKey,
    saga: lawComplianceSaga,
});
const departmentKey = 'department';
const withDepartmentReducer = injectReducer({
    key: departmentKey,
    reducer: departmentReducer,
});
const withDepartmentSaga = injectSaga({
    key: departmentKey,
    saga: departmentSaga,
});
const taskKey = 'task';
const withTaskReducer = injectReducer({
    key: taskKey,
    reducer: taskReducer,
});
const withTaskSaga = injectSaga({ key: taskKey, saga: taskSaga });
const lawKey = 'law';
const withLawReducer = injectReducer({
    key: lawKey,
    reducer: lawReducer,
});
const withLawSaga = injectSaga({ key: lawKey, saga: lawSaga });
const reportKey = 'report';
const withReportSaga = injectSaga({ key: reportKey, saga: reportSaga });
const withReportReducer = injectReducer({
    key: reportKey,
    reducer: reportReducer,
});

class Index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            loginUserId: parseInt(getValueByKey(authConstants.USER_ID)),
            isGTROLE: isGTROLE(),
            reportGrid: false,
            reportGridForPro: false,
            reportGridForRuntime: false,
            activeTab: 'dashboardTab',
            // viewDocLayout: false,
            // isPrintReady: false,
            // getPrintViewData: {},
            entityId: '',
            groupOfEntity: [],
            filterDataForDepartmentByEntity: [],
            isDefaultPro: false,
            filterDataForGraphs: initialReportFilterDataForGraphState(),
            preFilledData: initialReportFilterDataForProState(),
            searchFormData: initialReportFilterDataState(),
            searchFormDataForPro: initialReportFilterDataForProState(),
            searchFormDataForRuntime: initialRunTimeFilterDataState(),
            masterData: {
                performersData: {
                    topPerformUsers: [],
                    scopeOfImprovementUsers: []
                },
                entityDepartmentsWithHod: [],
                departments: [],
                entityAdmins: [],
                companyGroup: {},
                masterCountryData: [],
                masterStateData: [],
                masterCityData: [],
                masterComplianceCategoriesData: [],
                masterComplianceTypesData: [],
                masterComplianceRiskRatingsData: []
            },
            userDepartments: [],
            entityDepartments: [], // it will use for system runtime report
            reportDataList: [],
            proReportDataList: [],
            runtimeReportDataList: [],
            allCompliancesTypesList: [],
            allCompliancesCategoriesList: [],
            riskRatingsList: [],
            taskStatusList: [],
            complianceStatusData: [
                {
                    name: 'Compliant',
                    data: [0],
                },
                {
                    name: 'Overdue',
                    data: [0],
                },
                {
                    name: 'Delay',
                    data: [0],
                },
                {
                    name: 'Reported',
                    data: [0],
                },
            ],
            complianceStatusOptions: {
                // chart: {
                //     toolbar: false
                // },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        maxBarHeight: '10',
                        columnWidth: '10',
                        barHeight: '10',
                        offsetX: 0,
                        offsetY: 0,
                    },
                }
            },
            riskLevelData: [
                {
                    name: 'Delay',
                    data: [0, 0, 0, 0],
                },
                {
                    name: 'Overdue',
                    data: [0, 0, 0, 0],
                },
            ],
            riskLevelOptions: {
                chart: {
                    // height: 'auto',
                    type: 'radar',
                    dropShadow: {
                        enabled: true,
                        blur: 2,
                        left: 1,
                        top: 1,
                    },
                    toolbar: {
                        tools: {
                            download: true,
                            selection: true,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset:
                                false |
                                '<img src="/static/icons/reset.png" width="20">',
                            customIcons: [],
                        },
                        export: {
                            csv: {
                                filename: 'Risk Level - Delay and Overdue',
                                columnDelimiter: ',',
                                headerCategory: 'Risk Level',
                                headerValue: 'value',
                                dateFormatter(timestamp) {
                                    return new Date(timestamp).toDateString();
                                },
                            },
                            svg: {
                                filename: 'Risk Level',
                            },
                            png: {
                                filename: 'Risk Level',
                            },
                        },
                    },
                },
                colors: isDarkMode()
                    ? ['#f6732f', '#81192f', '#5bbc7b', '#86a5ff']
                    : ['#f6732f', '#DE002E', '#9BD732', '#3f70fd'],
                labels: ['Critical', 'High', 'Moderate', 'Low'],
                stroke: {
                    width: 1,
                },
                fill: {
                    opacity: 0.3,
                    colors: isDarkMode()
                        ? ['#f6732f', '#81192f', '#5bbc7b', '#86a5ff']
                        : ['#f6732f', '#DE002E', '#9BD732', '#3f70fd'],
                },
                tooltip: {
                    intersect: true,
                    custom: function ({
                        series,
                        seriesIndex,
                        dataPointIndex,
                        w,
                    }) {
                        let tooltipHtml =
                            '<div class="risk_level-tooltip"><span class="tooltip-title">' +
                            (w.config.labels[dataPointIndex] != undefined
                                ? w.config.labels[dataPointIndex]
                                : '') +
                            '</span><ul>';
                        if (
                            w.config.series[0].data[dataPointIndex] != undefined
                        ) {
                            tooltipHtml +=
                                '<li class="risk_level"><span class="bg-Delay"></span>' +
                                w.config.series[0].name +
                                ': ' +
                                w.config.series[0].data[dataPointIndex] +
                                '</li>';
                        }
                        if (
                            w.config.series[1].data[dataPointIndex] != undefined
                        ) {
                            tooltipHtml +=
                                '<li class="risk_level"><span class="bg-overdue"></span>' +
                                w.config.series[1].name +
                                ': ' +
                                w.config.series[1].data[dataPointIndex] +
                                '</li>';
                        }
                        tooltipHtml += '</ul></div>';
                        return tooltipHtml;
                    },
                },
                xaxis: {
                    labels: {
                        show: true,
                        style: {
                            colors: isDarkMode() ? ['#ced4da'] : ['#000'],
                            fontSize: '12px',
                            fontFamily: 'GT Walsheim Pro',
                        },
                        formatter: (value) => {
                            var len = value.length;
                            return len > 25
                                ? value.substring(0, 22) + '...'
                                : value;
                        },
                    },
                },
                yaxis: {
                    min: 0,
                    forceNiceScale: true,
                    decimalsInFloat: 0,
                    labels: {
                        show: true,
                        style: {
                            colors: isDarkMode() ? '#ced4da' : '#000',
                            fontSize: '12px',
                            fontFamily: 'GT Walsheim Pro',
                        },
                        formatter: (value) => {
                            var len = value.length;
                            return len > 25
                                ? value.substring(0, 22) + '...'
                                : value;
                        },
                    },
                },
                legend: {
                    labels: {
                        colors: isDarkMode() ? ['#ced4da'] : ['#000'],
                    },
                },
            },
            complianceClassificationOptions: {
                chart: {
                    height: '300px',
                    type: 'line',
                    toolbar: {
                        color: '#000',
                        tools: {
                            download: true,
                            selection: true,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset:
                                false |
                                '<img src="/static/icons/reset.png" width="20">',
                            customIcons: [],
                        },
                        export: {
                            csv: {
                                filename: 'Compliance Classification',
                                columnDelimiter: ',',
                                headerCategory: 'Compliance Classification',
                                headerValue: 'value',
                                dateFormatter(timestamp) {
                                    return new Date(timestamp).toDateString();
                                },
                            },
                            svg: {
                                filename: 'Compliance Classification',
                            },
                            png: {
                                filename: 'Compliance Classification',
                            },
                        },
                    },
                },
                colors: isDarkMode()
                    ? ['#8568a5', '#5bbc7b', '#81192f', '#f6732f', '#26FF39']
                    : ['#4F2D7F', '#9BD732', '#DE002E', '#f6732f', '#f8b425'],
                stroke: {
                    width: [0, 4, 4, 4, 4],
                    curve: 'smooth',
                },
                grid: {
                    show: true,
                    borderColor: isDarkMode() ? '#6c757d' : '#ece7f3',
                },
                plotOptions: {
                    bar: {
                        columnWidth: '30%',
                    },
                },
                xaxis: {
                    categories: [
                        'One-Time',
                        'Event-Based',
                        'Due-Date',
                        'On-Going',
                    ],
                    labels: {
                        style: {
                            colors: isDarkMode() ? '#ced4da' : '#000',
                        },
                    },
                },
                yaxis: {
                    min: 0,
                    forceNiceScale: true,
                    decimalsInFloat: 0,
                    labels: {
                        show: true,
                        style: {
                            colors: isDarkMode() ? ['#ced4da'] : ['#000'],
                            fontSize: '12px',
                            fontFamily: 'GT Walsheim Pro',
                        },
                        formatter: (value) => {
                            var len = value.length;
                            return len > 25
                                ? value.substring(0, 22) + '...'
                                : value;
                        },
                    },
                },
                legend: {
                    offsetY: 5,
                    itemMargin: {
                        horizontal: 7,
                        vertical: 0,
                    },
                    labels: {
                        colors: isDarkMode() ? ['#ced4da'] : ['#000'],
                    },
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                },
            },
            complianceClassificationData: [
                {
                    name: 'Total',
                    type: 'column',
                    data: [0, 0, 0, 0],
                },
                {
                    name: 'Compliant',
                    type: 'line',
                    data: [0, 0, 0, 0],
                    yAxisIndex: 1,
                },
                {
                    name: 'Overdue',
                    type: 'line',
                    data: [0, 0, 0, 0],
                    yAxisIndex: 1,
                },
                {
                    name: 'Delay',
                    type: 'line',
                    data: [0, 0, 0, 0],
                    yAxisIndex: 1,
                },
                {
                    name: 'Reported',
                    type: 'line',
                    data: [0, 0, 0, 0],
                    yAxisIndex: 1,
                },
            ],
            pdfReport: false,
            showPdfPopup: false,
            pdfReportType: '',
            pdfReportData: {}
        };
        this.toggle = this.toggle.bind(this);
    }
    componentDidMount() {
        if (!this.state.isGTROLE) {
            this.props.getUserEntityListRequest();
            this.props.getCountryListByStatusRequest(ACTIVE);
            this.props.getDepartmentListByStatusRequest(ACTIVE);
            this.props.getComplianceCategoriesRequest();
            this.props.getComplianceTypesRequest();
            this.props.getRiskRatingsRequest();
            this.props.getTaskStatusListRequest();
            this.props.getLawsListByLoginUserRequest();
            this.getReportDataByType({});
        } else {
            this.props.history.push('/home');
        }
    }
    componentWillUnmount() {
        this.props.clearFilterEntityUserResponse();
    }
    getStateListByCountry(countryIds) {
        this.props.getStateListByCountryRequest(countryIds ? countryIds : []);
    }
    getCityListByState(stateIds) {
        this.props.getCityListByStateRequest(stateIds ? stateIds : []);
    }
    getUserListByEntityAndRole(entityIds, roleIds) {
        this.props.getUserListByEntityAndRoleRequest(entityIds, roleIds);
    }
    onPrintClick = () => {
        this.setState({
            isPrintReady: true,
            getPrintViewData: {},
        });
    };
    toggle(tab) {
        this.setState(
            {
                reportGrid: false,
                reportGridForPro: false,
                activeTab: tab,
                isDefaultPro: tab == 'proReportTab' ? true : false,
            },
            () => {
                if (tab == 'proReportTab') {
                    this.getReportDataByType({});
                }
            }
        );
        // }
    }
    getReportType = () => {
        let reportType = 'dashboard';
        switch (this.state.activeTab) {
            case 'customReportTab':
                reportType = 'custom';
                break;
            case 'proReportTab':
                reportType = 'pro';
                break;
            default:
                break;
        }
        return reportType;
    };
    getReportDataByType = (searchValues) => {
        let filterValues = {
            ...searchValues,
            fromDate:
                searchValues.fromDate && searchValues.fromDate != ''
                    ? formatDateForApiSystem(searchValues.fromDate)
                    : '',
            toDate:
                searchValues.toDate && searchValues.toDate != ''
                    ? formatDateForApiSystem(searchValues.toDate)
                    : '',
            isManagementUser: isManagementRole(),
        };
        this.props.getReportDataByTypeRequest({
            reportType: this.getReportType(),
            filter: filterValues,
        });
    };
    getSystemRunTimeReport = (searchValues) => {
        let filterValues = {
            ...searchValues,
            fromDate:
                searchValues.fromDate && searchValues.fromDate != ''
                    ? formatDateForApiSystem(searchValues.fromDate)
                    : '',
            toDate:
                searchValues.toDate && searchValues.toDate != ''
                    ? formatDateForApiSystem(searchValues.toDate)
                    : '',
            isManagementUser: isManagementRole(),
        };
        this.props.getSystemRuntimeReportRequest({ filter: filterValues });
    };
    formSubmitForReport = (values) => {
        if (this.validator.allValid()) {
            this.setState(
                {
                    entityId: '',
                    filterDataForGraphs: values,
                },
                () => {
                    this.getReportDataByType(this.state.filterDataForGraphs);
                }
            );
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    resetFormForReport = () => {
        this.setState(
            {
                filterDataForGraphs: initialReportFilterDataForGraphState(),
            },
            () => {
                this.validator.hideMessages();
                this.forceUpdate();
                this.getReportDataByType({});
            }
        );
    };
    formSubmit = (values) => {
        this.setState(
            {
                searchFormData: values,
                reportGrid: false,
                reportGridForPro: false,
            },
            () => {
                this.getReportDataByType(this.state.searchFormData);
            }
        );
    };
    resetForm = () => {
        this.setState({
            reportGrid: false,
            reportGridForPro: false,
            searchFormData: initialReportFilterDataState(),
            reportDataList: [],
        });
    };
    formSubmitForPro = (values) => {
        this.setState(
            {
                reportGrid: false,
                reportGridForPro: false,
                isDefaultPro: false,
                searchFormDataForPro: values,
            },
            () => {
                this.getReportDataByType(this.state.searchFormDataForPro);
            }
        );
    };
    resetFormForPro = () => {
        this.toggle('proReportTab');
    };
    formSubmitForRunTime = (values) => {
        this.setState(
            {
                reportGrid: false,
                reportGridForPro: false,
                reportGridForRuntime: false,
                searchFormDataForRuntime: values,
            },
            () => {
                this.getSystemRunTimeReport(
                    this.state.searchFormDataForRuntime
                );
            }
        );
    };
    resetFormForRunTime = () => {
        this.setState({
            reportGrid: false,
            reportGridForPro: false,
            reportGridForRuntime: false,
            searchFormDataForRuntime: initialRunTimeFilterDataState(),
            runtimeReportDataList: [],
        });
    };
    getRoleName = (taskData, userId, common = true) => {
        let roles = [];
        let roleIds = [];
        const departmentIds =
            taskData.complianceAllocationDepartmentId != '' &&
                taskData.complianceAllocationDepartmentId != null
                ? taskData.complianceAllocationDepartmentId.split(',')
                : [];
        const collaboratorUserIds =
            taskData.collaboratorUserId != '' &&
                taskData.collaboratorUserId != null
                ? taskData.collaboratorUserId.split(',').map(Number)
                : [];
        if (taskData.submitterId == userId) {
            roleIds.push('SUBMITTER');
            roles.push('Submitter');
        } else if (
            taskData.firstApproverUserId !== null &&
            taskData.firstApproverUserId == userId
        ) {
            roleIds.push('FIRST_APPROVER');
            roles.push('Reviewer');
        } else if (
            taskData.secondApproverUserId !== null &&
            taskData.secondApproverUserId == userId
        ) {
            roleIds.push('SECOND_APPROVER');
            roles.push('Approver');
        } else if (
            taskData.auditorId !== null &&
            taskData.auditorId == userId
        ) {
            roleIds.push('AUDITOR');
            roles.push('Auditor');
        } else if (
            departmentIds.some((department) => {
                if (!common) {
                    let departmentHod = this.state.entityDepartments?.find(
                        (departmentEntity) =>
                            departmentEntity.entityId == taskData.entityId &&
                            departmentEntity.departmentId == department &&
                            departmentEntity.userId == userId
                    );
                    if (departmentHod) {
                        return true;
                    }
                } else {
                    let departmentHod = this.state.userDepartments?.find(
                        (departmentEntity) =>
                            departmentEntity.entityId ==
                            taskData.complianceAllocationEntityId &&
                            departmentEntity.departmentId == department
                    );
                    if (departmentHod) {
                        return true;
                    }
                }
            })
        ) {
            roleIds.push('HOD');
            roles.push('HOD');
        } else if (
            taskData.collaboratorUserId !== null &&
            taskData.collaboratorUserId != '' &&
            collaboratorUserIds.includes(userId)
        ) {
            roleIds.push('COLLABORATOR');
            roles.push('Collaborator');
        } else if (
            taskData.createdBy !== null &&
            taskData.createdBy == userId
        ) {
            roles.push('Task Creator');
        }
        return { roleNames: roles, roleIds };
    };

    isReportedTask = (task) => {
        return (task.taskStatusId > PENDING_FOR_COMPLETION && task.dueDate &&
            task.dueDate != PENDING_TO_TRIGGERED && task.compliancePerformanceDate <= task.dueDate && (task.auditorId === null || (task.auditorId !== null && task.auditorId !== this.state.loginUserId))) ? true : false;
    }
    isDelayTask = (task) => {
        return (task.dueDate && task.dueDate != PENDING_TO_TRIGGERED && task.compliancePerformanceDate > task.dueDate && (task.auditorId === null || (task.auditorId !== null && task.auditorId !== this.state.loginUserId))) ? true : false
    }
    isPendingObservationTask = (task) => {
        return (task.pendingObservation === 1) ? true : false
    }
    isDiscrepancyResolutionTask = (task) => {
        return ([DISCREPANCY_ONGOING_RESOLUTION, DISCREPANCY_COMPLETED_RESOLUTION].includes(task.taskStatusId)) ? true : false
    };
    isDiscrepancyTask = (task) => {
        return ([DISCREPANCY_PENDING_RESOLUTION, DISCREPANCY_ONGOING_RESOLUTION, DISCREPANCY_COMPLETED_RESOLUTION].includes(task.taskStatusId)) ? true : false
    }
    isDiscrepancyMonetaryTask = (task) => {
        if ([DISCREPANCY_PENDING_RESOLUTION, DISCREPANCY_ONGOING_RESOLUTION, DISCREPANCY_COMPLETED_RESOLUTION].includes(task.taskStatusId) && task.discrepancyType) {
            let discrepancyTypes = task.discrepancyType.split(',').map(Number);
            return (discrepancyTypes.includes(DISCREPANCY_TYPE_MONETARY)) ? true : false;
        } else {
            return false;
        }
    }
    isDiscrepancyNonMonetaryTask = (task) => {
        if ([DISCREPANCY_PENDING_RESOLUTION, DISCREPANCY_ONGOING_RESOLUTION, DISCREPANCY_COMPLETED_RESOLUTION].includes(task.taskStatusId) && task.discrepancyType) {
            let discrepancyTypes = task.discrepancyType.split(',').map(Number);
            return (discrepancyTypes.includes(DISCREPANCY_TYPE_NON_MONETARY)) ? true : false;
        } else {
            return false;
        }
    }
    isOverdueTask = (task) => {
        return (task.isOverDue === 1 && ![CLOSED, AUDITED_AND_CLOSED, PENDING_FOR_AUDIT, DISCREPANCY_PENDING_RESOLUTION, DISCREPANCY_ONGOING_RESOLUTION, DISCREPANCY_COMPLETED_RESOLUTION].includes(task.taskStatusId)) ? true : false
    }
    isClosedTask = (task) => {
        return ([CLOSED, AUDITED_AND_CLOSED, PENDING_FOR_AUDIT, DISCREPANCY_PENDING_RESOLUTION, DISCREPANCY_ONGOING_RESOLUTION, DISCREPANCY_COMPLETED_RESOLUTION].includes(task.taskStatusId)) ? true : false
    }
    isInprogressTask = (task) => {
        return (![PENDING_FOR_COMPLETION, CLOSED, PENDING_FOR_AUDIT, AUDITED_AND_CLOSED, DISCREPANCY_PENDING_RESOLUTION, DISCREPANCY_ONGOING_RESOLUTION, DISCREPANCY_COMPLETED_RESOLUTION].includes(task.taskStatusId)) ? true : false
    }
    generateTable = (records) => {
        let reportRecords = [];
        let preFilledData = initialReportFilterDataForProState();
        reportRecords = records.map((reportData, i) => {
            let { roleNames, roleIds } = this.getRoleName(
                reportData,
                this.state.loginUserId,
                true
            );
            const findTaskStatusName = this.state?.taskStatusList
                && this.state?.taskStatusList.length > 0 ? this.state?.taskStatusList.find((element) => element.value == reportData.taskStatusId)?.label ?? "" : "";

            const departmentIds =
                reportData.complianceAllocationDepartmentId != '' &&
                    reportData.complianceAllocationDepartmentId != null
                    ? reportData.complianceAllocationDepartmentId
                        .split(',')
                        .map(Number)
                    : [];
            let collaboratorUserIds =
                reportData.collaboratorUserId != '' &&
                    reportData.collaboratorUserId != null
                    ? reportData.collaboratorUserId.split(',').map(Number)
                    : [];
            // set prefilled data for pro report
            if (this.state.isDefaultPro == true) {
                preFilledData = {
                    ...preFilledData,
                    roleId: [...new Set([...preFilledData.roleId, ...roleIds])],
                    userId: [...new Set([...preFilledData.userId, reportData.submitterUserId, reportData.firstApproverUserId, reportData.secondApproverUserId, ...collaboratorUserIds])],
                    countryId: [
                        ...new Set([
                            ...preFilledData.countryId,
                            reportData.complianceAllocationEntityLocationCountryId,
                        ]),
                    ],
                    stateId: [
                        ...new Set([
                            ...preFilledData.stateId,
                            reportData.complianceAllocationEntityLocationStateId,
                        ]),
                    ],
                    cityId: [
                        ...new Set([
                            ...preFilledData.cityId,
                            reportData.complianceAllocationEntityLocationCityId,
                        ]),
                    ],
                    departmentId: [
                        ...new Set([
                            ...preFilledData.departmentId,
                            ...departmentIds,
                        ]),
                    ],
                    entityId: [
                        ...new Set([
                            ...preFilledData.entityId,
                            reportData.complianceAllocationEntityId,
                        ]),
                    ],
                    lawId: [
                        ...new Set([
                            ...preFilledData.lawId,
                            reportData.complianceAllocationLawComplianceLawId,
                        ]),
                    ],
                    taskStatusId: [
                        ...new Set([
                            ...preFilledData.taskStatusId,
                            reportData.taskStatusId,
                        ]),
                    ],
                    complianceTypeId: [
                        ...new Set([
                            ...preFilledData.complianceTypeId,
                            reportData.complianceAllocationLawComplianceTypeId,
                        ]),
                    ],
                    complianceCategoryId: [
                        ...new Set([
                            ...preFilledData.complianceCategoryId,
                            reportData.complianceAllocationLawComplianceCategoryId,
                        ]),
                    ],
                    complianceRiskRatingId: [
                        ...new Set([
                            ...preFilledData.complianceRiskRatingId,
                            reportData.complianceAllocationLawComplianceRiskRatingId,
                        ]),
                    ],
                };
            }
            let dNames = [];
            let hodNames = [];
            const departmentNames =
                this.state.masterData?.entityDepartmentsWithHod?.some((edh) => {
                    if (
                        departmentIds.includes(edh.departmentId) &&
                        edh.entityId == reportData.complianceAllocationEntityId
                    ) {
                        dNames.push(edh.departmentName);
                        let hodNameByDepartment = edh.userNames.split(',');
                        hodNames = [...hodNames, ...hodNameByDepartment];
                    }
                });
            dNames = [...new Set(dNames)];
            hodNames = [...new Set(hodNames)];

            let taskUserIds = [];
            let taskUsers = {
                taskId: reportData.id,
                taskSubmitterUserId: '',
                taskFirstApproverUserId: '',
                taskSecondApproverUserId: '',
                taskAuditorId: '',
                taskSubmitterUserLastActiveAt: '',
                taskFirstApproverUserLastActiveAt: '',
                taskSecondApproverUserLastActiveAt: '',
                taskAuditorLastActiveAt: '',
                taskCollaboratorUserId: collaboratorUserIds.join(),
                taskCreator: (reportData.createdBy !== null && reportData.createdBy != '') ? reportData.createdBy : ''
            };
            if (reportData?.submitterUserId && reportData?.submitterUserId !== null && reportData?.submitterUserId !== '') {
                taskUserIds.push(reportData.submitterUserId);
                taskUsers.taskSubmitterUserId = reportData.submitterUserId;
                taskUsers.taskSubmitterUserLastActiveAt = reportData.submitterUserLastActiveAt;
            }
            if (reportData?.firstApproverUserId && reportData?.firstApproverUserId !== null && reportData?.firstApproverUserId !== '') {
                taskUserIds.push(reportData.firstApproverUserId);
                taskUsers.taskFirstApproverUserId = reportData.firstApproverUserId;
                taskUsers.taskFirstApproverUserLastActiveAt = reportData.firstApproverUserLastActiveAt;
            }
            if (reportData?.secondApproverUserId && reportData?.secondApproverUserId !== null && reportData?.secondApproverUserId !== '') {
                taskUserIds.push(reportData.secondApproverUserId);
                taskUsers.taskSecondApproverUserId = reportData.secondApproverUserId;
                taskUsers.taskSecondApproverUserLastActiveAt = reportData.secondApproverUserLastActiveAt;
            }
            if (reportData?.auditorId && reportData?.auditorId !== null && reportData?.auditorId !== '') {
                taskUserIds.push(reportData.auditorId);
                taskUsers.taskAuditorId = reportData.auditorId;
                taskUsers.taskAuditorLastActiveAt = reportData.auditorUserLastActiveAt;
            }
            taskUserIds = [...taskUserIds];

            let complianceTypeId = (reportData.complianceVersionLawComplianceTypeId == null) ? reportData.complianceAllocationLawComplianceTypeId : reportData.complianceVersionLawComplianceTypeId;
            let complianceTypeName = this.state.masterData.masterComplianceTypesData.find((cType) => cType.id === complianceTypeId)?.complianceTypeName || '';

            let complianceCategoryId = (reportData.complianceVersionLawComplianceCategoryId == null) ? reportData.complianceAllocationLawComplianceCategoryId : reportData.complianceVersionLawComplianceCategoryId;
            let complianceCategoryName = this.state.masterData.masterComplianceCategoriesData.find((c) => c.id === complianceCategoryId)?.complianceCategoryName || '';

            let complianceRiskRatingId = reportData.complianceVersionLawComplianceRiskRatingId == null
                ? reportData.complianceAllocationLawComplianceRiskRatingId
                : reportData.complianceVersionLawComplianceRiskRatingId;
            let complianceRiskRatingName = this.state.masterData.masterComplianceRiskRatingsData.find((c) => c.id === complianceRiskRatingId)?.riskRatingName || '';
            let countryName = this.state.masterData.masterCountryData.find((c) => c.id === reportData.complianceAllocationEntityLocationCountryId)?.countryName || '';

            let stateName = this.state.masterData.masterStateData.find((c) => c.id === reportData.complianceAllocationEntityLocationStateId)?.stateName || '';

            let cityName = this.state.masterData.masterCityData.find((c) => c.id === reportData.complianceAllocationEntityLocationCityId)?.cityName || '';
            return {
                ...taskUsers,
                taskUserIds: taskUserIds.join(),
                companyGroupName:
                    reportData.complianceAllocationEntityCompanyGroupName !== null
                        ? reportData.complianceAllocationEntityCompanyGroupName
                        : '',
                complianceAllocationEntityId:
                    reportData.complianceAllocationEntityId !== null
                        ? reportData.complianceAllocationEntityId
                        : '',
                isDelayTask: this.isDelayTask(reportData),
                isReportedTask: this.isReportedTask(reportData),
                isPendingTask: (reportData.taskStatusId === PENDING_FOR_COMPLETION) ? true : false,
                isPendingAuditTask: (reportData.taskStatusId === PENDING_FOR_AUDIT) ? true : false,
                isAuditedTask: (reportData.taskStatusId === AUDITED_AND_CLOSED) ? true : false,
                isPendingObservationTask: this.isPendingObservationTask(reportData),
                isDiscrepancyTask: this.isDiscrepancyTask(reportData),
                isDiscrepancyResolutionTask: this.isDiscrepancyResolutionTask(reportData),
                isDiscrepancyMonetaryTask: this.isDiscrepancyMonetaryTask(reportData),
                isDiscrepancyNonMonetaryTask: this.isDiscrepancyNonMonetaryTask(reportData),
                isOverdueTask: this.isOverdueTask(reportData),
                isClosedTask: this.isClosedTask(reportData),
                isInprogressTask: this.isInprogressTask(reportData),
                taskStatusId: reportData.taskStatusId !== null ? reportData.taskStatusId : '',
                countryId:
                    reportData.complianceAllocationEntityLocationCountryId !== null
                        ? reportData.complianceAllocationEntityLocationCountryId
                        : '',
                stateId:
                    reportData.complianceAllocationEntityLocationStateId !== null
                        ? reportData.complianceAllocationEntityLocationStateId
                        : '',
                cityId:
                    reportData.complianceAllocationEntityLocationCityId !== null
                        ? reportData.complianceAllocationEntityLocationCityId
                        : '',
                lawId:
                    reportData.complianceAllocationLawComplianceLawId !== null
                        ? reportData.complianceAllocationLawComplianceLawId
                        : '',
                lawStateId:
                    reportData.complianceAllocationLawComplianceLawStateId !== null
                        ? reportData.complianceAllocationLawComplianceLawStateId
                        : '',
                riskRatingId:
                    reportData.complianceAllocationLawComplianceRiskRatingId !== null
                        ? reportData.complianceAllocationLawComplianceRiskRatingId
                        : '',
                departmentIds: departmentIds?.length ? departmentIds.join(', ') : '',
                entityId: reportData.complianceAllocationEntityId !== null
                    ? reportData.complianceAllocationEntityId
                    : '',
                complianceAllocationLawComplianceId:
                    reportData.complianceAllocationLawComplianceId !== null
                        ? reportData.complianceAllocationLawComplianceId
                        : '',
                companyGroupName:
                    reportData.complianceAllocationEntityCompanyGroupName !==
                        null
                        ? reportData.complianceAllocationEntityCompanyGroupName
                        : '',
                entityName:
                    reportData.complianceAllocationEntityName !== null
                        ? reportData.complianceAllocationEntityName
                        : '',
                country: countryName,
                state: stateName,
                city: cityName,
                department: dNames.join(', ') || "",
                lawCode:
                    reportData.complianceAllocationLawComplianceLawCode !== null
                        ? reportData.complianceAllocationLawComplianceLawCode
                        : '',
                lawShortName:
                    reportData.complianceAllocationLawComplianceLawShortName !==
                        null
                        ? reportData.complianceAllocationLawComplianceLawShortName
                        : '',
                complianceCode:
                    reportData.complianceAllocationLawComplianceCode !== null
                        ? reportData.complianceAllocationLawComplianceCode
                        : '',
                complianceTitle:
                    reportData.parentTaskId === null
                        ? reportData.complianceVersionLawComplianceTitle == null
                            ? reportData.complianceAllocationLawComplianceTitle
                            : reportData.complianceVersionLawComplianceTitle
                        : reportData.title !== null
                            ? reportData.title
                            : '',
                complianceType: complianceTypeName,
                complianceCategory: complianceCategoryName,
                riskRating: complianceRiskRatingName,
                systemLiveDate: reportData?.createdAt ? reportData.createdAt : "",
                dueDate:
                    reportData.dueDate &&
                        reportData.dueDate != PENDING_TO_TRIGGERED
                        ? getDateFrom(reportData.dueDate)
                        : PENDING_TO_TRIGGERED,
                systemReportingDate: reportData.systemReportingDate
                    ? getDateFrom(reportData.systemReportingDate)
                    : '',
                compliancePerformanceDate: reportData.compliancePerformanceDate
                    ? getDateFrom(reportData.compliancePerformanceDate)
                    : '',
                noDelayDays: dateDifference(
                    reportData.dueDate,
                    reportData.systemReportingDate
                ) || '-',
                statusName:
                    findTaskStatusName !== null
                        ? findTaskStatusName
                        : '',
                roleName: roleNames.length > 0 ? roleNames.join(';') : '',
                submitter:
                    reportData.submitterUserName !== null
                        ? reportData.submitterUserName
                        : '',
                approver1:
                    reportData.firstApproverUserName !== null
                        ? reportData.firstApproverUserName
                        : '',
                approver2:
                    reportData.secondApproverUserName !== null
                        ? reportData.secondApproverUserName
                        : '',
                pendingObservation:
                    reportData.pendingObservation || 0,
                hod: hodNames.length > 0 ? hodNames.join(';') : '',
                auditor:
                    reportData.auditorUserName !== null
                        ? reportData.auditorUserName
                        : '',
            };
        });
        return { reportRecords, preFilledData };
    };
    generateRuntimeTable = (records) => {
        let runtimeRecords = [];
        runtimeRecords = records.map((logData, i) => {
            return {
                countryName: logData.countryName.join(';'),
                entityName: logData.entityName.join(';'),
                departmentName: logData.departmentName.join(';'),
                userName: logData.userName,
                userRole: logData.userRole.join(';'),
                userEmail: decryptText(logData.userEmail),
                userContactNo: (logData.userContactNo) ? decryptText(logData.userContactNo) : '',
                lastLoginDate: logData.lastLoginDate,
                totalLoginTime: diffYMDHMS(
                    logData.lastLoginDate,
                    logData.lastLogoutDate
                ),
                complianceScore: getComplianceScore(
                    logData.pendingTasks,
                    logData.completedTasks
                ),
                displayTime: logData.displayTime
            };
        });
        return runtimeRecords;
    };
    exportDataByType = (type) => {
        switch (type) {
            case 'pro-report':
                exportToCSV([this.state.proReportDataList], type);
                break;
            case 'custom-report':
                exportToCSV([this.state.reportDataList], type);
                break;
            case 'runtime-report':
                exportToCSV([this.state.runtimeReportDataList], type);
                break;
            default:
                break;
        }
    };
    filterDataForDepartmentByEntity = (entityId) => {
        this.setState(
            {
                entityId: entityId,
            },
            () => {
                let complianceStatusOptions = {};
                let complianceStatusData = [];
                if (entityId != '') {
                    let groupOfEntity = Object.values(this.state.groupOfEntity);
                    const indexOfGrouEntity = groupOfEntity.findIndex(
                        (obj) => obj.entityId == entityId
                    );
                    let groupByDepartment = [];
                    if (indexOfGrouEntity !== -1) {
                        for (const key in groupOfEntity[indexOfGrouEntity]) {
                            if (key != 'entityId' && key != 'entityName') {
                                let data =
                                    groupOfEntity[indexOfGrouEntity][key];
                                let temp = data.map((task) => {
                                    const departmentIds =
                                        task.complianceAllocationDepartmentId !=
                                            '' &&
                                            task.complianceAllocationDepartmentId !=
                                            null
                                            ? task.complianceAllocationDepartmentId
                                                .split(',')
                                                .map(Number)
                                            : [];
                                    let temp1 =
                                        this.state.masterData?.entityDepartmentsWithHod?.some(
                                            (edh) => {
                                                if (
                                                    departmentIds.includes(
                                                        edh.departmentId
                                                    ) &&
                                                    edh.entityId ==
                                                    task.complianceAllocationEntityId
                                                ) {
                                                    groupByDepartment[
                                                        edh.departmentId
                                                    ] = groupByDepartment[
                                                    edh.departmentId
                                                    ] ?? {
                                                            departmentId:
                                                                edh.departmentId,
                                                            departmentName:
                                                                edh.departmentName,
                                                            compliant: [],
                                                            overdue: [],
                                                            delay: [],
                                                            reported: [],
                                                        };
                                                    groupByDepartment[
                                                        edh.departmentId
                                                    ][key].push(task);
                                                    return edh;
                                                }
                                            }
                                        );
                                });
                            }
                        }
                    }
                    groupByDepartment = Object.values(groupByDepartment);
                    complianceStatusOptions = complianceStatusChartOptions(
                        groupByDepartment.length > 0
                            ? groupByDepartment.map(
                                (department) => department.departmentName
                            )
                            : [],
                        'Department'
                    );
                    complianceStatusData = [
                        {
                            name: 'Compliant',
                            data: groupByDepartment.map(
                                (departmentSpecific) => {
                                    return departmentSpecific?.compliant !==
                                        undefined
                                        ? departmentSpecific.compliant.length
                                        : 0;
                                }
                            ),
                        },
                        {
                            name: 'Overdue',
                            data: groupByDepartment.map(
                                (departmentSpecific) => {
                                    return departmentSpecific?.overdue !==
                                        undefined
                                        ? departmentSpecific.overdue.length
                                        : 0;
                                }
                            ),
                        },
                        {
                            name: 'Delay',
                            data: groupByDepartment.map(
                                (departmentSpecific) => {
                                    return departmentSpecific?.delay !==
                                        undefined
                                        ? departmentSpecific.delay.length
                                        : 0;
                                }
                            ),
                        },
                        {
                            name: 'Reported',
                            data: groupByDepartment.map(
                                (departmentSpecific) => {
                                    return departmentSpecific?.reported !==
                                        undefined
                                        ? departmentSpecific.reported.length
                                        : 0;
                                }
                            ),
                        },
                    ];
                } else {
                    let groupOfCountry = Object.values(
                        this.state.groupOfCountry
                    );
                    const maxXaxisValue = Array.isArray(groupOfCountry) && groupOfCountry.map((value) => {
                        return Math.max(...[
                            value.compliant.length,
                            value.overdue.length,
                            value.delay.length,
                            value.reported.length
                        ])
                    })
                    const finalMaxValue = Math.max(...maxXaxisValue) + 15
                    complianceStatusOptions = complianceStatusChartOptions(
                        groupOfCountry.length > 0
                            ? groupOfCountry.map((country) => {
                                return country.countryName;
                            })
                            : [],
                        'Country',
                        finalMaxValue
                    );
                    complianceStatusData = [
                        {
                            name: 'Compliant',
                            data: groupOfCountry.map((countrySpecific) => {
                                return countrySpecific?.compliant !== undefined
                                    ? countrySpecific.compliant.length
                                    : 0;
                            }),
                        },
                        {
                            name: 'Overdue',
                            data: groupOfCountry.map((countrySpecific) => {
                                return countrySpecific?.overdue !== undefined
                                    ? countrySpecific.overdue.length
                                    : 0;
                            }),
                        },
                        {
                            name: 'Delay',
                            data: groupOfCountry.map((countrySpecific) => {
                                return countrySpecific?.delay !== undefined
                                    ? countrySpecific.delay.length
                                    : 0;
                            }),
                        },
                        {
                            name: 'Reported',
                            data: groupOfCountry.map((countrySpecific) => {
                                return countrySpecific?.reported !== undefined
                                    ? countrySpecific.reported.length
                                    : 0;
                            }),
                        },
                    ];
                }
                this.setState(
                    {
                        complianceStatusOptions: complianceStatusOptions,
                    },
                    () => {
                        this.setState({
                            complianceStatusData: complianceStatusData,
                        });
                    }
                );
            }
        );
    };
    openPdfGenerate = (type) => {
        let pdfReportData = { list: [], filters: {} };
        if (type == 'pro') {
            pdfReportData.list = this.state.proReportDataList;
            pdfReportData.filters = this.state.searchFormDataForPro;
        }
        if (type == 'custom') {
            pdfReportData.list = this.state.reportDataList;
            pdfReportData.filters = this.state.searchFormData;
        }
        this.setState({
            showPdfPopup: true,
            pdfReportType: type,
            pdfReport: true,
            pdfReportData: pdfReportData
        });
    }
    closePdfGenerate = () => {
        this.setState({
            pdfReport: false,
            showPdfPopup: false,
            pdfReportType: '',
            pdfReportData: {}
        });
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.allCompliancesCategoriesList &&
            this.props.allCompliancesCategoriesList !==
            nextprops.allCompliancesCategoriesList
        ) {
            this.setState({
                allCompliancesCategoriesList:
                    nextprops.allCompliancesCategoriesList
                        ?.map((opt) => ({
                            value: opt.id,
                            label: opt.complianceCategoryName,
                        }))
                        .sort((item1, item2) =>
                            compareString(item1.label, item2.label)
                        ),
            });
        }
        if (
            nextprops.allCompliancesTypesList &&
            this.props.allCompliancesTypesList !==
            nextprops.allCompliancesTypesList
        ) {
            this.setState({
                allCompliancesTypesList: nextprops.allCompliancesTypesList
                    ?.map((opt) => ({
                        value: opt.id,
                        label: opt.complianceTypeName,
                    }))
                    .sort((item1, item2) =>
                        compareString(item1.label, item2.label)
                    ),
            });
        }
        if (
            nextprops.riskRatingsList &&
            this.props.riskRatingsList !== nextprops.riskRatingsList
        ) {
            this.setState({
                riskRatingsList: nextprops.riskRatingsList
                    ?.map((opt) => ({
                        value: opt.id,
                        label: opt.riskRatingName,
                    }))
                    .sort((item1, item2) =>
                        compareString(item1.label, item2.label)
                    ),
            });
        }
        if (
            nextprops.reportApiResponse &&
            nextprops.reportApiResponse !== this.props.reportApiResponse
        ) {
            if (nextprops.reportApiResponse.responseType == SUCCESS) {
                switch (nextprops.reportApiResponse.data.reportType) {
                    case 'dashboard':
                        const delayCounts = nextprops.reportApiResponse.data.dashboardData.riskChartData.delay;
                        const overdueCounts = nextprops.reportApiResponse.data.dashboardData.riskChartData.overdue;
                        let groupOfCategory = [];
                        let groupOfCountry = [];
                        let groupOfEntity = [];


                        nextprops.reportApiResponse.data.tasks.map((task) => {
                            const {
                                complianceAllocationLawComplianceCategoryId,
                                complianceAllocationEntityLocationCountryId,
                                complianceAllocationEntityId,
                            } = task;
                            groupOfCategory[complianceAllocationLawComplianceCategoryId] = groupOfCategory[complianceAllocationLawComplianceCategoryId] ?? {
                                compliant: [],
                                delay: [],
                                overdue: [],
                                reported: [],
                                total: 0,
                            };
                            groupOfCountry[complianceAllocationEntityLocationCountryId] = groupOfCountry[complianceAllocationEntityLocationCountryId] ?? {
                                countryName: nextprops.reportApiResponse.data.masterData.masterCountryData.find((c) => c.id === task.complianceAllocationEntityLocationCountryId)?.countryName || '',
                                countryIsoCode2: nextprops.reportApiResponse.data.masterData.masterCountryData.find((c) => c.id === task.complianceAllocationEntityLocationCountryId)?.isoCode2 || '',
                                compliant: [],
                                delay: [],
                                overdue: [],
                                reported: [],
                                total: 0,
                            };
                            groupOfEntity[complianceAllocationEntityId] =
                                groupOfEntity[complianceAllocationEntityId] ?? {
                                    entityId: complianceAllocationEntityId,
                                    entityName: task.complianceAllocationEntityName,
                                    compliant: [],
                                    delay: [],
                                    overdue: [],
                                    reported: [],
                                };
                            if (
                                task.taskStatusId == CLOSED ||
                                task.taskStatusId == AUDITED_AND_CLOSED ||
                                task.taskStatusId == PENDING_FOR_AUDIT ||
                                task.taskStatusId == DISCREPANCY_PENDING_RESOLUTION ||
                                task.taskStatusId == DISCREPANCY_ONGOING_RESOLUTION ||
                                task.taskStatusId == DISCREPANCY_COMPLETED_RESOLUTION
                            ) {
                                groupOfCategory[complianceAllocationLawComplianceCategoryId].compliant.push(task);
                                groupOfCountry[complianceAllocationEntityLocationCountryId].compliant.push(task);
                                groupOfEntity[complianceAllocationEntityId].compliant.push(task);
                            }
                            const compliancePerformanceDate = moment(task.compliancePerformanceDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
                            const dueDate = moment(task.dueDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
                            if (task.compliancePerformanceDate != null && compliancePerformanceDate > dueDate && (task.auditorId === null || (task.auditorId !== null && task.auditorId !== this.state.loginUserId))) {
                                groupOfCategory[complianceAllocationLawComplianceCategoryId].delay.push(task);
                                groupOfCountry[complianceAllocationEntityLocationCountryId].delay.push(task);
                                groupOfEntity[complianceAllocationEntityId].delay.push(task);
                            }
                            if (
                                (task.isOverDue === 1 || task.isOverDue === true) &&
                                task.taskStatusId !== CLOSED &&
                                task.taskStatusId !== AUDITED_AND_CLOSED &&
                                task.taskStatusId !== PENDING_FOR_AUDIT &&
                                task.taskStatusId !== DISCREPANCY_PENDING_RESOLUTION &&
                                task.taskStatusId !== DISCREPANCY_ONGOING_RESOLUTION &&
                                task.taskStatusId !== DISCREPANCY_COMPLETED_RESOLUTION
                            ) {
                                groupOfCategory[complianceAllocationLawComplianceCategoryId].overdue.push(task);
                                groupOfCountry[complianceAllocationEntityLocationCountryId].overdue.push(task);
                                groupOfEntity[complianceAllocationEntityId].overdue.push(task);
                            }
                            if (
                                task.taskStatusId !== PENDING_FOR_COMPLETION &&
                                task.compliancePerformanceDate <= task.dueDate && (task.auditorId === null || (task.auditorId !== null && task.auditorId !== this.state.loginUserId))
                            ) {
                                groupOfCategory[complianceAllocationLawComplianceCategoryId].reported.push(task);
                                groupOfCountry[complianceAllocationEntityLocationCountryId].reported.push(task);
                                groupOfEntity[complianceAllocationEntityId].reported.push(task);
                            }
                            return task;
                        });

                        const info = groupOfCategory.map((categoryWiseData, key) => {
                            const total = [
                                ...categoryWiseData.compliant,
                                ...categoryWiseData.overdue,
                                ...categoryWiseData.delay,
                                ...categoryWiseData.reported,
                            ];
                            return { ...categoryWiseData, total: total };
                        }
                        );
                        let complianceClassificationData = [
                            {
                                name: 'Total',
                                type: 'column',
                                data: [
                                    info[1] && info[1]?.total !== undefined
                                        ? info[1]?.total.length
                                        : 0,
                                    info[2] && info[2]?.total !== undefined
                                        ? info[2]?.total.length
                                        : 0,
                                    info[3] && info[3]?.total !== undefined
                                        ? info[3]?.total.length
                                        : 0,
                                    info[4] && info[4]?.total !== undefined
                                        ? info[4]?.total.length
                                        : 0,
                                ],
                            },
                            {
                                name: 'Compliant',
                                type: 'line',
                                data: [
                                    info[1] && info[1]?.compliant !== undefined
                                        ? info[1]?.compliant?.length
                                        : 0,
                                    info[2] && info[2]?.compliant !== undefined
                                        ? info[2]?.compliant?.length
                                        : 0,
                                    info[3] && info[3]?.compliant !== undefined
                                        ? info[3]?.compliant?.length
                                        : 0,
                                    info[4] && info[4]?.compliant !== undefined
                                        ? info[4]?.compliant?.length
                                        : 0,
                                ],
                                yAxisIndex: 1,
                            },
                            {
                                name: 'Overdue',
                                type: 'line',
                                data: [
                                    info[1] && info[1]?.overdue !== undefined
                                        ? info[1]?.overdue?.length
                                        : 0,
                                    info[2] && info[2]?.overdue !== undefined
                                        ? info[2]?.overdue?.length
                                        : 0,
                                    info[3] && info[3]?.overdue !== undefined
                                        ? info[3]?.overdue?.length
                                        : 0,
                                    info[4] && info[4]?.overdue !== undefined
                                        ? info[4]?.overdue?.length
                                        : 0,
                                ],
                                yAxisIndex: 1,
                            },
                            {
                                name: 'Delay',
                                type: 'line',
                                data: [
                                    info[1] && info[1]?.delay !== undefined
                                        ? info[1]?.delay?.length
                                        : 0,
                                    info[2] && info[2]?.delay !== undefined
                                        ? info[2]?.delay?.length
                                        : 0,
                                    info[3] && info[3]?.delay !== undefined
                                        ? info[3]?.delay?.length
                                        : 0,
                                    info[4] && info[4]?.delay !== undefined
                                        ? info[4]?.delay?.length
                                        : 0,
                                ],
                                yAxisIndex: 1,
                            },
                            {
                                name: 'Reported',
                                type: 'line',
                                data: [
                                    info[1] && info[1] !== undefined
                                        ? info[1]?.reported?.length
                                        : 0,
                                    info[2] && info[2] !== undefined
                                        ? info[2]?.reported?.length
                                        : 0,
                                    info[3] && info[3] !== undefined
                                        ? info[3]?.reported?.length
                                        : 0,
                                    info[4] && info[4] !== undefined
                                        ? info[4]?.reported?.length
                                        : 0,
                                ],
                                yAxisIndex: 1,
                            },
                        ];
                        this.setState(
                            {
                                userDepartments: nextprops.reportApiResponse?.data
                                    ?.userDepartments,
                                masterData: nextprops.reportApiResponse.data
                                    ?.masterData,
                                groupOfEntity: groupOfEntity,
                                groupOfCountry: groupOfCountry,
                                complianceClassificationData: complianceClassificationData,
                                riskLevelData: [
                                    { name: 'Delay', data: delayCounts },
                                    { name: 'Overdue', data: overdueCounts }
                                ],
                            }, () => {
                                this.filterDataForDepartmentByEntity('');
                            }
                        );
                        break;

                    case 'pro':
                        this.setState(
                            {
                                reportGridForPro: true,
                                userDepartments:
                                    nextprops.reportApiResponse?.data
                                        ?.userDepartments,
                                masterData:
                                    nextprops.reportApiResponse.data
                                        ?.masterData,
                            },
                            () => {
                                let { reportRecords, preFilledData } =
                                    this.generateTable(
                                        nextprops.reportApiResponse.data?.tasks
                                    );
                                if (this.state.isDefaultPro === true) {
                                    this.setState(
                                        {
                                            preFilledData: preFilledData,
                                            proReportDataList: reportRecords,
                                        },
                                        () => {
                                            this.getStateListByCountry(
                                                preFilledData.countryId
                                            );
                                            this.getCityListByState(
                                                preFilledData.stateId
                                            );
                                            this.getUserListByEntityAndRole(
                                                preFilledData.entityId, preFilledData.roleId
                                            );
                                        }
                                    );
                                } else {
                                    this.setState({
                                        proReportDataList: reportRecords,
                                    });
                                }
                            }
                        );
                        break;

                    default:
                        this.setState(
                            {
                                reportGrid: true,
                                userDepartments:
                                    nextprops.reportApiResponse?.data
                                        ?.userDepartments,
                                masterData:
                                    nextprops.reportApiResponse.data
                                        ?.masterData,
                            },
                            () => {
                                let { reportRecords } = this.generateTable(
                                    nextprops.reportApiResponse.data?.tasks
                                );
                                this.setState({
                                    reportDataList: reportRecords
                                        ? reportRecords
                                        : [],
                                });
                            }
                        );
                        break;
                }
            } else {
                toastrMessage(__t('alerts.no_data_for_report'), DANGER);
                this.setState({
                    reportDataList: [],
                    proReportDataList: [],
                });
            }
        }
        if (
            nextprops.systemRuntimeReportApiResponse &&
            nextprops.systemRuntimeReportApiResponse !==
            this.props.systemRuntimeReportApiResponse
        ) {
            if (
                nextprops.systemRuntimeReportApiResponse.responseType == SUCCESS
            ) {
                this.setState(
                    {
                        entityDepartments:
                            nextprops.systemRuntimeReportApiResponse.data
                                .entityDepartments,
                    },
                    () => {
                        this.setState({
                            runtimeReportGrid: true,
                            runtimeReportDataList: this.generateRuntimeTable(
                                nextprops.systemRuntimeReportApiResponse.data
                                    .userLogs
                            ),
                        });
                    }
                );
            } else {
                toastrMessage(__t('alerts.no_data_for_report'), DANGER);
                this.setState({
                    runtimeReportDataList: [],
                });
            }
        }

        if (
            nextprops.sortedTaskStatusListDropdown &&
            nextprops.sortedTaskStatusListDropdown !==
            this.props.sortedTaskStatusListDropdown
        ) {
            this.setState({
                taskStatusList: nextprops.sortedTaskStatusListDropdown
            })
        }
    }
    render() {
        const dataForPro = {
            columns: listColumns1,
            rows: this.state.proReportDataList,
        };
        const data = {
            columns: listColumns1,
            rows: this.state.reportDataList,
        };
        const dataLogs = {
            columns: listColumns2,
            rows: this.state.runtimeReportDataList,
        };
        let roleListDropdown = roleList;
        let sortedStatusDropdown = this.props.sortedTaskStatusListDropdown;

        // let taskStatusDropdownArray = sortedStatusDropdown.filter((s) => {
        //     if (s.value !== PENDING_FOR_AUDIT &&
        //         s.value !== AUDITED_AND_CLOSED &&
        //         s.value !== DISCREPANCY_PENDING_RESOLUTION &&
        //         s.value !== DISCREPANCY_ONGOING_RESOLUTION &&
        //         s.value !== DISCREPANCY_COMPLETED_RESOLUTION) {
        //         return true;
        //     }
        // });
        // let auditStatusDropdownArray = sortedStatusDropdown.filter((s) => {
        //     if (s.value === PENDING_FOR_AUDIT ||
        //         s.value === AUDITED_AND_CLOSED ||
        //         s.value === DISCREPANCY_PENDING_RESOLUTION ||
        //         s.value === DISCREPANCY_ONGOING_RESOLUTION ||
        //         s.value === DISCREPANCY_COMPLETED_RESOLUTION) {
        //         return true;
        //     }
        // });
        // sortedStatusDropdown = [
        //     {
        //         label: 'Task Status',
        //         options: taskStatusDropdownArray.sort((item1, item2) => compareIntValue(item1.value, item2.value))
        //     },
        //     {
        //         label: 'Audit Status',
        //         options: auditStatusDropdownArray.sort((item1, item2) => compareIntValue(item1.value, item2.value))
        //     },
        // ];
        // console.log("complianceStatusData===>", this.state.complianceStatusData)
        return (
            <React.Fragment>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <CommonBreadcrum type={'generate-report'} />
                        </Col>
                        <Col lg="6">
                            <h4 className="generatereport_title">
                                Generate Report
                            </h4>
                        </Col>
                        <Col
                            lg="6"
                            className="d-flex justify-content-end report_tabs"
                        >
                            <Nav tabs className="nav-tabs-custom nav-justified">
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active:
                                                this.state.activeTab ===
                                                'dashboardTab',
                                        })}
                                        onClick={() => {
                                            this.toggle('dashboardTab');
                                        }}
                                    >
                                        <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect
                                                x="1"
                                                y="1"
                                                width="6"
                                                height="7"
                                                rx="1"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinejoin="round"
                                            />
                                            <rect
                                                x="1"
                                                y="12"
                                                width="6"
                                                height="5"
                                                rx="1"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinejoin="round"
                                            />
                                            <rect
                                                x="11"
                                                y="1"
                                                width="6"
                                                height="5"
                                                rx="1"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinejoin="round"
                                            />
                                            <rect
                                                x="11"
                                                y="10"
                                                width="6"
                                                height="7"
                                                rx="1"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                        <span>
                                            {__t(
                                                'form_labels.reports.dashboard'
                                            )}
                                        </span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active:
                                                this.state.activeTab ===
                                                'proReportTab',
                                        })}
                                        onClick={() => {
                                            this.toggle('proReportTab');
                                        }}
                                    >
                                        {' '}
                                        <svg
                                            width="16"
                                            height="21"
                                            viewBox="0 0 16 21"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M15 16V6.5C15 5.09554 15 4.39331 14.6629 3.88886C14.517 3.67048 14.3295 3.48298 14.1111 3.33706C13.9364 3.22033 13.738 3.14403 13.4949 3.09415C12.7502 2.94135 12.3779 2.86495 11.8353 3.07073C11.2926 3.27651 10.9617 3.71767 10.3 4.6L10 5H6L5.7 4.6C5.03825 3.71767 4.70738 3.27651 4.16474 3.07073C3.6221 2.86495 3.24975 2.94135 2.50507 3.09415C2.26198 3.14403 2.06356 3.22033 1.88886 3.33706C1.67048 3.48298 1.48298 3.67048 1.33706 3.88886C1 4.39331 1 5.09554 1 6.5V16C1 17.8856 1 18.8284 1.58579 19.4142C2.17157 20 3.11438 20 5 20H11C12.8856 20 13.8284 20 14.4142 19.4142C15 18.8284 15 17.8856 15 16Z"
                                                fill="#7E869E"
                                                fillOpacity="0.15"
                                            />
                                            <path
                                                d="M11.5 3C12.9045 3 13.6067 3 14.1111 3.33706C14.3295 3.48298 14.517 3.67048 14.6629 3.88886C15 4.39331 15 5.09554 15 6.5V16C15 17.8856 15 18.8284 14.4142 19.4142C13.8284 20 12.8856 20 11 20H5C3.11438 20 2.17157 20 1.58579 19.4142C1 18.8284 1 17.8856 1 16V6.5C1 5.09554 1 4.39331 1.33706 3.88886C1.48298 3.67048 1.67048 3.48298 1.88886 3.33706C2.39331 3 3.09554 3 4.5 3"
                                                stroke="currentColor"
                                                strokeWidth="1.2"
                                            />
                                            <path
                                                d="M5 3C5 1.89543 5.89543 1 7 1H9C10.1046 1 11 1.89543 11 3C11 4.10457 10.1046 5 9 5H7C5.89543 5 5 4.10457 5 3Z"
                                                stroke="currentColor"
                                                strokeWidth="1.2"
                                            />
                                            <path
                                                d="M5 10L11 10"
                                                stroke="currentColor"
                                                strokeWidth="1.2"
                                                strokeLinecap="round"
                                            />
                                            <path
                                                d="M5 14L9 14"
                                                stroke="currentColor"
                                                strokeWidth="1.2"
                                                strokeLinecap="round"
                                            />
                                        </svg>
                                        <span>
                                            {__t(
                                                'form_labels.reports.pro_report'
                                            )}
                                        </span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active:
                                                this.state.activeTab ===
                                                'customReportTab',
                                        })}
                                        onClick={() => {
                                            this.toggle('customReportTab');
                                        }}
                                    >
                                        <svg
                                            width="21"
                                            height="21"
                                            viewBox="0 0 21 21"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M15 16V6.5C15 5.09554 15 4.39331 14.6629 3.88886C14.517 3.67048 14.3295 3.48298 14.1111 3.33706C13.9364 3.22033 13.738 3.14403 13.4949 3.09415C12.7502 2.94135 12.3779 2.86495 11.8353 3.07073C11.2926 3.27651 10.9617 3.71767 10.3 4.6L10 5H6L5.7 4.6C5.03825 3.71767 4.70738 3.27651 4.16474 3.07073C3.6221 2.86495 3.24975 2.94135 2.50507 3.09415C2.26198 3.14403 2.06356 3.22033 1.88886 3.33706C1.67048 3.48298 1.48298 3.67048 1.33706 3.88886C1 4.39331 1 5.09554 1 6.5V16C1 17.8856 1 18.8284 1.58579 19.4142C2.17157 20 3.11438 20 5 20H11C12.8856 20 13.8284 20 14.4142 19.4142C15 18.8284 15 17.8856 15 16Z"
                                                fill="#7E869E"
                                                fillOpacity="0.15"
                                            />
                                            <path
                                                d="M11.5 3C12.9045 3 13.6067 3 14.1111 3.33706C14.3295 3.48298 14.517 3.67048 14.6629 3.88886C15 4.39331 15 5.09554 15 6.5V16C15 17.8856 15 18.8284 14.4142 19.4142C13.8284 20 12.8856 20 11 20H5C3.11438 20 2.17157 20 1.58579 19.4142C1 18.8284 1 17.8856 1 16V6.5C1 5.09554 1 4.39331 1.33706 3.88886C1.48298 3.67048 1.67048 3.48298 1.88886 3.33706C2.39331 3 3.09554 3 4.5 3"
                                                stroke="currentColor"
                                                strokeWidth="1.2"
                                            />
                                            <path
                                                d="M5 3C5 1.89543 5.89543 1 7 1H9C10.1046 1 11 1.89543 11 3C11 4.10457 10.1046 5 9 5H7C5.89543 5 5 4.10457 5 3Z"
                                                stroke="currentColor"
                                                strokeWidth="1.2"
                                            />
                                            <path
                                                d="M5 10L11 10"
                                                stroke="currentColor"
                                                strokeWidth="1.2"
                                                strokeLinecap="round"
                                            />
                                            <path
                                                d="M5 14L9 14"
                                                stroke="currentColor"
                                                strokeWidth="1.2"
                                                strokeLinecap="round"
                                            />
                                            <path
                                                d="M8 18H10.708L18.6947 10.0133L15.9867 7.30534L11.9933 11.2987L8 15.292V18ZM9.44425 15.8914L15.9867 9.34895L16.651 10.0133L10.1086 16.5558H9.44425V15.8914ZM19.099 5.21118C19.0322 5.14424 18.9529 5.09113 18.8655 5.05489C18.7782 5.01865 18.6845 5 18.59 5C18.4954 5 18.4017 5.01865 18.3144 5.05489C18.227 5.09113 18.1477 5.14424 18.0809 5.21118L16.7594 6.53267L19.4673 9.24063L20.7888 7.91915C20.8558 7.85234 20.9089 7.77299 20.9451 7.68563C20.9813 7.59827 21 7.50462 21 7.41005C21 7.31547 20.9813 7.22183 20.9451 7.13447C20.9089 7.04711 20.8558 6.96776 20.7888 6.90095L19.099 5.21118Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                        <span>
                                            {__t(
                                                'form_labels.reports.custom_report'
                                            )}
                                        </span>
                                    </NavLink>
                                </NavItem>
                                {(isManagementRole() || isHodRole()) && (
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                active:
                                                    this.state.activeTab ===
                                                    'userLogsTab',
                                            })}
                                            onClick={() => {
                                                this.toggle('userLogsTab');
                                            }}
                                        >
                                            <svg
                                                width="21"
                                                height="21"
                                                viewBox="0 0 21 21"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M4.30676 15.3821C4.64514 15.899 5.04391 16.3775 5.4968 16.8067C6.61899 17.8703 8.02535 18.586 9.54575 18.8671C11.0662 19.1482 12.6354 18.9826 14.0637 18.3905C15.492 17.7984 16.7181 16.8051 17.5937 15.5308C18.4693 14.2564 18.9569 12.7557 18.9974 11.21C19.0379 9.66439 18.6296 8.14014 17.822 6.82167C17.0144 5.5032 15.842 4.44701 14.4467 3.78089C13.5024 3.33008 12.4834 3.07101 11.4481 3.01294L12.0032 5.08484C12.5483 5.17728 13.0809 5.34509 13.585 5.58577C14.6315 6.08536 15.5108 6.8775 16.1165 7.86635C16.7223 8.8552 17.0284 9.99839 16.9981 11.1576C16.9677 12.3168 16.602 13.4424 15.9453 14.3982C15.2886 15.3539 14.369 16.0989 13.2978 16.543C12.2266 16.9871 11.0496 17.1112 9.90934 16.9004C8.76905 16.6896 7.71427 16.1529 6.87263 15.3551C6.69689 15.1886 6.53201 15.0121 6.37866 14.8269L4.30676 15.3821Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M4.91239 3.06647C5.68924 2.47037 6.54796 1.99272 7.46042 1.64739C7.87978 1.48868 8.08946 1.40932 8.28694 1.51053C8.48442 1.61174 8.54649 1.84338 8.67063 2.30667L10.7412 10.0341C10.8632 10.4894 10.9242 10.7171 10.8206 10.8964C10.7171 11.0758 10.4894 11.1368 10.0341 11.2588L2.30667 13.3294C1.84338 13.4535 1.61174 13.5156 1.42535 13.3952C1.23896 13.2747 1.20284 13.0535 1.13061 12.6109C0.973443 11.6481 0.95774 10.6656 1.08555 9.69474C1.25696 8.39275 1.68314 7.13728 2.33975 6C2.99636 4.86272 3.87054 3.8659 4.91239 3.06647Z"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                />
                                            </svg>
                                            <span>
                                                {__t(
                                                    'form_labels.reports.user_logs'
                                                )}
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                )}
                            </Nav>
                        </Col>

                        <Col lg="12">
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane
                                    className="report-tabs dashboard dashboard_section"
                                    tabId="dashboardTab"
                                >
                                    <CustomisedCollapseForOne
                                        className="accordion"
                                        viewDialog={false}
                                        dialogName={__t(
                                            'page_headers.advance_filters'
                                        )}
                                    >
                                        <CardBody className="px-4 pt-4 pb-2">
                                            <Formik
                                                initialValues={
                                                    this.state
                                                        .filterDataForGraphs
                                                }
                                                enableReinitialize={true}
                                                onSubmit={
                                                    this.formSubmitForReport
                                                }
                                            >
                                                {({
                                                    values,
                                                    setFieldValue,
                                                }) => (
                                                    <Form>
                                                        <Row>
                                                            <FormGroup className="col-lg-3">
                                                                <Field
                                                                    component={
                                                                        CustomMultiSelect
                                                                    }
                                                                    name="countryId[]"
                                                                    className="form-control selectbox"
                                                                    options={
                                                                        this
                                                                            .props
                                                                            .sortedCountryListDropdown
                                                                    }
                                                                    styles={{
                                                                        menuPortal:
                                                                            (
                                                                                provided
                                                                            ) => ({
                                                                                ...provided,
                                                                                zIndex: 9999,
                                                                            }),
                                                                        menu: (
                                                                            provided
                                                                        ) => ({
                                                                            ...provided,
                                                                            zIndex: 9999,
                                                                        }),
                                                                    }}
                                                                    onChange={(
                                                                        opt
                                                                    ) => {
                                                                        let countryId =
                                                                            opt
                                                                                ? opt.map(
                                                                                    (
                                                                                        x
                                                                                    ) =>
                                                                                        x.value
                                                                                )
                                                                                : [];
                                                                        setFieldValue(
                                                                            'countryId',
                                                                            countryId
                                                                        );
                                                                        setFieldValue(
                                                                            'stateId',
                                                                            []
                                                                        );
                                                                        this.getStateListByCountry(
                                                                            countryId
                                                                        );
                                                                    }}
                                                                    value={this.props.sortedCountryListDropdown?.filter(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            values.countryId?.includes(
                                                                                option.value
                                                                            )
                                                                    )}
                                                                    isMulti
                                                                    closeMenuOnSelect={
                                                                        false
                                                                    }
                                                                    hideSelectedOptions={
                                                                        false
                                                                    }
                                                                    allowSelectAll={
                                                                        true
                                                                    }
                                                                    components={{
                                                                        Option,
                                                                        MultiValue,
                                                                        animatedComponents,
                                                                    }}
                                                                />
                                                                <Label className="form-label">
                                                                    {__t(
                                                                        'form_labels.reports.country'
                                                                    )}
                                                                    <span className="text-danger required">
                                                                        *
                                                                    </span>
                                                                </Label>
                                                                {this.validator.message(
                                                                    __t(
                                                                        'form_labels.reports.country'
                                                                    ),
                                                                    values.countryId,
                                                                    'required',
                                                                    {
                                                                        messages:
                                                                        {
                                                                            required:
                                                                                __t(
                                                                                    'validations.reports.country_required'
                                                                                ),
                                                                        },
                                                                    }
                                                                )}
                                                            </FormGroup>
                                                            <FormGroup className="col-lg-3">
                                                                <Field
                                                                    component={
                                                                        CustomMultiSelect
                                                                    }
                                                                    name="stateId[]"
                                                                    className="form-control selectbox"
                                                                    options={
                                                                        this
                                                                            .props
                                                                            .sortedStateListDropdown
                                                                    }
                                                                    onChange={(
                                                                        opt
                                                                    ) => {
                                                                        let stateId =
                                                                            opt
                                                                                ? opt.map(
                                                                                    (
                                                                                        x
                                                                                    ) =>
                                                                                        x.value
                                                                                )
                                                                                : [];
                                                                        setFieldValue(
                                                                            'stateId',
                                                                            stateId
                                                                        );
                                                                        setFieldValue(
                                                                            'cityId',
                                                                            []
                                                                        );
                                                                        this.getCityListByState(
                                                                            opt &&
                                                                            opt.map(
                                                                                (
                                                                                    x
                                                                                ) =>
                                                                                    x.value
                                                                            )
                                                                        );
                                                                    }}
                                                                    value={this.props.sortedStateListDropdown?.filter(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            values.stateId?.includes(
                                                                                option.value
                                                                            )
                                                                    )}
                                                                    isMulti
                                                                    closeMenuOnSelect={
                                                                        false
                                                                    }
                                                                    hideSelectedOptions={
                                                                        false
                                                                    }
                                                                    allowSelectAll={
                                                                        true
                                                                    }
                                                                    components={{
                                                                        Option,
                                                                        MultiValue,
                                                                        animatedComponents,
                                                                    }}
                                                                />
                                                                <Label className="form-label">
                                                                    {__t(
                                                                        'form_labels.reports.state'
                                                                    )}
                                                                    <span className="text-danger required">
                                                                        *
                                                                    </span>
                                                                </Label>
                                                                {this.validator.message(
                                                                    __t(
                                                                        'form_labels.reports.state'
                                                                    ),
                                                                    values.stateId,
                                                                    'required',
                                                                    {
                                                                        messages:
                                                                        {
                                                                            required:
                                                                                __t(
                                                                                    'validations.reports.state_required'
                                                                                ),
                                                                        },
                                                                    }
                                                                )}
                                                            </FormGroup>
                                                            <FormGroup className="col-lg-3">
                                                                <Field
                                                                    component={
                                                                        CustomMultiSelect
                                                                    }
                                                                    name="entityId[]"
                                                                    className="form-control selectbox"
                                                                    options={
                                                                        this
                                                                            .props
                                                                            .sortedEntityListDropdown
                                                                    }
                                                                    onChange={(
                                                                        opt
                                                                    ) => {
                                                                        let entityId =
                                                                            opt
                                                                                ? opt.map(
                                                                                    (
                                                                                        x
                                                                                    ) =>
                                                                                        x.value
                                                                                )
                                                                                : [];
                                                                        setFieldValue(
                                                                            'entityId',
                                                                            entityId
                                                                        );
                                                                    }}
                                                                    value={this.props.sortedEntityListDropdown?.filter(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            values.entityId?.includes(
                                                                                option.value
                                                                            )
                                                                    )}
                                                                    isMulti
                                                                    closeMenuOnSelect={
                                                                        false
                                                                    }
                                                                    hideSelectedOptions={
                                                                        false
                                                                    }
                                                                    allowSelectAll={
                                                                        true
                                                                    }
                                                                    components={{
                                                                        Option,
                                                                        MultiValue,
                                                                        animatedComponents,
                                                                    }}
                                                                />
                                                                <Label className="form-label">
                                                                    {__t(
                                                                        'form_labels.reports.entity'
                                                                    )}
                                                                    <span className="text-danger required">
                                                                        *
                                                                    </span>
                                                                </Label>
                                                                {this.validator.message(
                                                                    __t(
                                                                        'form_labels.reports.entity'
                                                                    ),
                                                                    values.entityId,
                                                                    'required',
                                                                    {
                                                                        messages:
                                                                        {
                                                                            required:
                                                                                __t(
                                                                                    'validations.reports.entity_required'
                                                                                ),
                                                                        },
                                                                    }
                                                                )}
                                                            </FormGroup>
                                                            <FormGroup className="col-lg-3">
                                                                <Field
                                                                    component={
                                                                        CustomMultiSelect
                                                                    }
                                                                    name="departmentId[]"
                                                                    className="form-control selectbox"
                                                                    options={
                                                                        this
                                                                            .props
                                                                            .sortedDepartmentListDropdown
                                                                    }
                                                                    onChange={(
                                                                        opt
                                                                    ) => {
                                                                        let departmentId =
                                                                            opt
                                                                                ? opt.map(
                                                                                    (
                                                                                        x
                                                                                    ) =>
                                                                                        x.value
                                                                                )
                                                                                : [];
                                                                        setFieldValue(
                                                                            'departmentId',
                                                                            departmentId
                                                                        );
                                                                    }}
                                                                    value={this.props.sortedDepartmentListDropdown?.filter(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            values.departmentId?.includes(
                                                                                option.value
                                                                            )
                                                                    )}
                                                                    isMulti
                                                                    closeMenuOnSelect={
                                                                        false
                                                                    }
                                                                    hideSelectedOptions={
                                                                        false
                                                                    }
                                                                    allowSelectAll={
                                                                        true
                                                                    }
                                                                    components={{
                                                                        Option,
                                                                        MultiValue,
                                                                        animatedComponents,
                                                                    }}
                                                                />
                                                                <Label className="form-label">
                                                                    {__t(
                                                                        'form_labels.reports.department'
                                                                    )}
                                                                </Label>
                                                            </FormGroup>

                                                            <FormGroup className="col-lg-6">
                                                                <Field
                                                                    component={
                                                                        CustomMultiSelect
                                                                    }
                                                                    name="lawId[]"
                                                                    className="form-control selectbox"
                                                                    options={
                                                                        this
                                                                            .props
                                                                            .sortedLawListOfLoginUserDropdown
                                                                    }
                                                                    onChange={(
                                                                        opt
                                                                    ) => {
                                                                        let lawId =
                                                                            opt
                                                                                ? opt.map(
                                                                                    (
                                                                                        x
                                                                                    ) =>
                                                                                        x.value
                                                                                )
                                                                                : [];
                                                                        setFieldValue(
                                                                            'lawId',
                                                                            lawId
                                                                        );
                                                                    }}
                                                                    value={this.props.sortedLawListOfLoginUserDropdown?.filter(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            values.lawId?.includes(
                                                                                option.value
                                                                            )
                                                                    )}
                                                                    isMulti
                                                                    closeMenuOnSelect={
                                                                        false
                                                                    }
                                                                    hideSelectedOptions={
                                                                        false
                                                                    }
                                                                    allowSelectAll={
                                                                        true
                                                                    }
                                                                    components={{
                                                                        Option,
                                                                        MultiValue,
                                                                        animatedComponents,
                                                                    }}
                                                                />
                                                                <Label className="form-label">
                                                                    {__t(
                                                                        'form_labels.reports.law'
                                                                    )}
                                                                </Label>
                                                            </FormGroup>

                                                            <FormGroup className="col-lg-3">
                                                                <Field
                                                                    component={
                                                                        CustomMultiSelect
                                                                    }
                                                                    name="complianceCategoryId[]"
                                                                    className="form-control selectbox"
                                                                    options={
                                                                        this
                                                                            .state
                                                                            .allCompliancesCategoriesList
                                                                    }
                                                                    onChange={(
                                                                        opt
                                                                    ) => {
                                                                        let complianceCategoryId =
                                                                            opt
                                                                                ? opt.map(
                                                                                    (
                                                                                        x
                                                                                    ) =>
                                                                                        x.value
                                                                                )
                                                                                : [];
                                                                        setFieldValue(
                                                                            'complianceCategoryId',
                                                                            complianceCategoryId
                                                                        );
                                                                    }}
                                                                    value={this.state.allCompliancesCategoriesList?.filter(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            values.complianceCategoryId?.includes(
                                                                                option.value
                                                                            )
                                                                    )}
                                                                    isMulti
                                                                    closeMenuOnSelect={
                                                                        false
                                                                    }
                                                                    hideSelectedOptions={
                                                                        false
                                                                    }
                                                                    allowSelectAll={
                                                                        true
                                                                    }
                                                                    components={{
                                                                        Option,
                                                                        MultiValue,
                                                                        animatedComponents,
                                                                    }}
                                                                />
                                                                <Label className="form-label">
                                                                    {__t(
                                                                        'form_labels.reports.compliance_classification'
                                                                    )}
                                                                </Label>
                                                            </FormGroup>

                                                            <FormGroup className="col-lg-3">
                                                                <Field
                                                                    component={
                                                                        CustomMultiSelect
                                                                    }
                                                                    name="complianceRiskRatingId[]"
                                                                    className="form-control selectbox"
                                                                    options={
                                                                        this
                                                                            .state
                                                                            .riskRatingsList
                                                                    }
                                                                    onChange={(
                                                                        opt
                                                                    ) => {
                                                                        let riskRatingId =
                                                                            opt
                                                                                ? opt.map(
                                                                                    (
                                                                                        x
                                                                                    ) =>
                                                                                        x.value
                                                                                )
                                                                                : [];
                                                                        setFieldValue(
                                                                            'complianceRiskRatingId',
                                                                            riskRatingId
                                                                        );
                                                                    }}
                                                                    value={this.state.riskRatingsList?.filter(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            values.complianceRiskRatingId?.includes(
                                                                                option.value
                                                                            )
                                                                    )}
                                                                    isMulti
                                                                    closeMenuOnSelect={
                                                                        false
                                                                    }
                                                                    hideSelectedOptions={
                                                                        false
                                                                    }
                                                                    allowSelectAll={
                                                                        true
                                                                    }
                                                                    components={{
                                                                        Option,
                                                                        MultiValue,
                                                                        animatedComponents,
                                                                    }}
                                                                />
                                                                <Label className="form-label">
                                                                    {__t(
                                                                        'form_labels.reports.risk_level'
                                                                    )}
                                                                </Label>
                                                            </FormGroup>
                                                            <FormGroup className="col-lg-3">
                                                                <Field
                                                                    component={
                                                                        Select
                                                                    }
                                                                    name="roleId[]"
                                                                    className="form-control selectbox"
                                                                    options={
                                                                        roleListDropdown
                                                                    }
                                                                    styles={{
                                                                        menuPortal:
                                                                            (
                                                                                provided
                                                                            ) => ({
                                                                                ...provided,
                                                                                zIndex: 9999,
                                                                            }),
                                                                        menu: (
                                                                            provided
                                                                        ) => ({
                                                                            ...provided,
                                                                            zIndex: 9999,
                                                                        }),
                                                                    }}
                                                                    onChange={(
                                                                        opt
                                                                    ) => {
                                                                        let roleId =
                                                                            opt
                                                                                ? opt.map(
                                                                                    (
                                                                                        x
                                                                                    ) =>
                                                                                        x.value
                                                                                )
                                                                                : [];
                                                                        setFieldValue(
                                                                            'roleId',
                                                                            roleId
                                                                        );
                                                                    }}
                                                                    value={roleListDropdown.filter(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            values.roleId.includes(
                                                                                option.value
                                                                            )
                                                                    )}
                                                                    isMulti
                                                                    closeMenuOnSelect={
                                                                        false
                                                                    }
                                                                    isClearable={
                                                                        true
                                                                    }
                                                                />
                                                                <Label className="form-label">
                                                                    {__t(
                                                                        'form_labels.reports.type_of_role'
                                                                    )}
                                                                </Label>
                                                            </FormGroup>
                                                            <Col
                                                                lg={3}
                                                                className="d-flex"
                                                            >
                                                                <FormGroup className="w-100 sticky_label mr-2 position-relative">
                                                                    <Field
                                                                        component={
                                                                            DatePicker
                                                                        }
                                                                        isClearable={
                                                                            true
                                                                        }
                                                                        className="form-control"
                                                                        name="fromDate"
                                                                        selected={
                                                                            values.fromDate
                                                                                ? getDateObjectWithValueFromMoment(
                                                                                    values.fromDate
                                                                                )
                                                                                : null
                                                                        }
                                                                        dateFormat={
                                                                            DATE_FORMAT_DATEPICKER
                                                                        }
                                                                        placeholderText={
                                                                            DATE_FORMAT_PLACEHOLDER
                                                                        }
                                                                        onChange={(
                                                                            date
                                                                        ) => {
                                                                            setFieldValue(
                                                                                'fromDate',
                                                                                date
                                                                            );
                                                                        }}
                                                                        showMonthDropdown={
                                                                            true
                                                                        }
                                                                        showYearDropdown={
                                                                            true
                                                                        }
                                                                        dropdownMode="select"
                                                                        onFocus={(
                                                                            e
                                                                        ) =>
                                                                            e.target.blur()
                                                                        }
                                                                        maxDate={
                                                                            values.toDate
                                                                        }
                                                                    ></Field>
                                                                    <Label className="form-label pl-0">
                                                                        {__t(
                                                                            'form_labels.task_filters.from_date'
                                                                        )}
                                                                        <span className="text-danger required">
                                                                            *
                                                                        </span>
                                                                    </Label>
                                                                    {this.validator.message(
                                                                        __t(
                                                                            'form_labels.reports.from_date'
                                                                        ),
                                                                        values.fromDate,
                                                                        'required',
                                                                        {
                                                                            messages:
                                                                            {
                                                                                required:
                                                                                    __t(
                                                                                        'validations.reports.from_date_required'
                                                                                    ),
                                                                            },
                                                                        }
                                                                    )}
                                                                </FormGroup>
                                                                <FormGroup className="w-100 sticky_label ml-2 position-relative">
                                                                    <Field
                                                                        component={
                                                                            DatePicker
                                                                        }
                                                                        isClearable={
                                                                            true
                                                                        }
                                                                        className="form-control"
                                                                        name="toDate"
                                                                        selected={
                                                                            values.toDate
                                                                                ? getDateObjectWithValueFromMoment(
                                                                                    values.toDate
                                                                                )
                                                                                : null
                                                                        }
                                                                        dateFormat={
                                                                            DATE_FORMAT_DATEPICKER
                                                                        }
                                                                        onChange={(
                                                                            date
                                                                        ) => {
                                                                            setFieldValue(
                                                                                'toDate',
                                                                                date
                                                                            );
                                                                        }}
                                                                        showMonthDropdown={
                                                                            true
                                                                        }
                                                                        showYearDropdown={
                                                                            true
                                                                        }
                                                                        dropdownMode="select"
                                                                        placeholderText={
                                                                            DATE_FORMAT_PLACEHOLDER
                                                                        }
                                                                        onFocus={(
                                                                            e
                                                                        ) =>
                                                                            e.target.blur()
                                                                        }
                                                                        minDate={
                                                                            values.fromDate
                                                                        }
                                                                    ></Field>
                                                                    <Label className="form-label pl-0">
                                                                        {__t(
                                                                            'form_labels.reports.to_date'
                                                                        )}
                                                                        <span className="text-danger required">
                                                                            *
                                                                        </span>
                                                                    </Label>
                                                                    {this.validator.message(
                                                                        __t(
                                                                            'form_labels.reports.to_date'
                                                                        ),
                                                                        values.toDate,
                                                                        'required',
                                                                        {
                                                                            messages:
                                                                            {
                                                                                required:
                                                                                    __t(
                                                                                        'validations.reports.to_date_required'
                                                                                    ),
                                                                            },
                                                                        }
                                                                    )}
                                                                </FormGroup>
                                                            </Col>
                                                            <FormGroup className="col-lg-3">
                                                                <Field
                                                                    component={
                                                                        CustomMultiSelect
                                                                    }
                                                                    name="taskStatusId[]"
                                                                    className="form-control selectbox select-optgroup-field"
                                                                    options={sortedStatusDropdown}
                                                                    onChange={(
                                                                        opt
                                                                    ) => {
                                                                        let taskStatusId =
                                                                            opt
                                                                                ? opt.map(
                                                                                    (
                                                                                        x
                                                                                    ) =>
                                                                                        x.value
                                                                                )
                                                                                : [];
                                                                        setFieldValue(
                                                                            'taskStatusId',
                                                                            taskStatusId
                                                                        );
                                                                    }}
                                                                    isMulti
                                                                    closeMenuOnSelect={
                                                                        false
                                                                    }
                                                                    hideSelectedOptions={
                                                                        false
                                                                    }
                                                                    allowSelectAll={
                                                                        true
                                                                    }
                                                                    components={{
                                                                        Option,
                                                                        MultiValue,
                                                                        animatedComponents,
                                                                    }}
                                                                />
                                                                <Label className="form-label">
                                                                    {__t(
                                                                        'form_labels.reports.compliance_status'
                                                                    )}
                                                                </Label>
                                                            </FormGroup>

                                                            <FormGroup className="col d-flex justify-content-end mb-2">
                                                                <div className="d-inline-block pt-2">
                                                                    <Button
                                                                        type="reset"
                                                                        color="secondary"
                                                                        onClick={
                                                                            this
                                                                                .resetFormForReport
                                                                        }
                                                                    >
                                                                        {CLEAR}
                                                                    </Button>
                                                                    <Button
                                                                        type="submit"
                                                                        color="primary"
                                                                        className="ml-2"
                                                                    >
                                                                        {SEARCH}
                                                                    </Button>
                                                                </div>
                                                            </FormGroup>
                                                        </Row>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </CardBody>
                                    </CustomisedCollapseForOne>
                                    <Row className="report_dashboard">
                                        <Col lg="5">
                                            <Card className="chart-card dashboard_head animate__animated animate__fadeInUp chart_dropdown">
                                                <CardHeader className="pb-0">
                                                    <h5>Compliance Status</h5>
                                                    <FormGroup className="">
                                                        <Select
                                                            className="form-control selectbox"
                                                            options={this.props.sortedEntityListDropdown.map(
                                                                (
                                                                    entityObj
                                                                ) => ({
                                                                    ...entityObj,
                                                                    isDisabled:
                                                                        this
                                                                            .state
                                                                            .filterDataForGraphs
                                                                            .entityId
                                                                            .length >
                                                                            0 &&
                                                                            !this.state.filterDataForGraphs.entityId.includes(
                                                                                entityObj.value
                                                                            )
                                                                            ? true
                                                                            : false,
                                                                })
                                                            )}
                                                            onChange={(opt) => {
                                                                let entityId =
                                                                    opt
                                                                        ? opt.value
                                                                        : '';
                                                                this.filterDataForDepartmentByEntity(
                                                                    entityId
                                                                );
                                                            }}
                                                            value={this.props.sortedEntityListDropdown?.filter(
                                                                (option) =>
                                                                    this.state
                                                                        .entityId ==
                                                                    option.value
                                                            )}
                                                            isClearable={true}
                                                            placeholder={__t(
                                                                'form_labels.reports.chart_entity'
                                                            )}
                                                        />
                                                    </FormGroup>
                                                </CardHeader>
                                                <CardBody className="px-3 pb-0">
                                                    {this.state
                                                        .complianceStatusData
                                                        .length > 0 && (
                                                            <ApexCharts
                                                                options={
                                                                    this.state
                                                                        .complianceStatusOptions
                                                                }
                                                                series={
                                                                    this.state
                                                                        .complianceStatusData
                                                                }
                                                                type="bar"
                                                            />
                                                        )}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg="3">
                                            <Card className="chart-card dashboard_head animate__animated animate__fadeInUp">
                                                <CardHeader className="pb-0">
                                                    <h5>
                                                        Compliance Risk Level
                                                        (Delay & Overdue)
                                                    </h5>
                                                </CardHeader>
                                                <CardBody className="px-3 pb-0">
                                                    {this.state.riskLevelData
                                                        .length > 0 && (
                                                            <ApexCharts
                                                                options={
                                                                    this.state
                                                                        .riskLevelOptions
                                                                }
                                                                series={
                                                                    this.state
                                                                        .riskLevelData
                                                                }
                                                                type="radar"
                                                                height={350}
                                                            />
                                                        )}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg="4">
                                            <Card className="chart-card dashboard_head animate__animated animate__fadeInUp">
                                                <CardHeader className="pb-0">
                                                    <h5>
                                                        Compliance
                                                        Classification
                                                    </h5>
                                                </CardHeader>
                                                <CardBody className="px-3 pb-0">
                                                    <ApexCharts
                                                        options={
                                                            this.state
                                                                .complianceClassificationOptions
                                                        }
                                                        series={
                                                            this.state
                                                                .complianceClassificationData
                                                        }
                                                        type="line"
                                                        height={350}
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane
                                    className="report-tabs automated-pro"
                                    tabId="proReportTab"
                                >
                                    <CustomisedCollapseForOne
                                        className="accordion"
                                        viewDialog={false}
                                        dialogName={__t(
                                            'page_headers.advance_filters'
                                        )}
                                    >
                                        <CardBody className="px-4 pt-4 pb-2">
                                            <ProReportFilterForm
                                                isDefaultPro={
                                                    this.state.isDefaultPro
                                                }
                                                preFilledData={
                                                    this.state.preFilledData
                                                }
                                                sortedCountryListDropdown={
                                                    this.props
                                                        .sortedCountryListDropdown
                                                }
                                                getStateListByCountry={(
                                                    countryIds
                                                ) =>
                                                    this.getStateListByCountry(
                                                        countryIds
                                                    )
                                                }
                                                sortedStateListDropdown={
                                                    this.props
                                                        .sortedStateListDropdown
                                                }
                                                getCityListByState={(
                                                    stateIds
                                                ) =>
                                                    this.getCityListByState(
                                                        stateIds
                                                    )
                                                }
                                                sortedCityListDropdown={
                                                    this.props
                                                        .sortedCityListDropdown
                                                }
                                                sortedEntityListDropdown={
                                                    this.props
                                                        .sortedEntityListDropdown
                                                }
                                                sortedDepartmentListDropdown={
                                                    this.props
                                                        .sortedDepartmentListDropdown
                                                }
                                                allCompliancesCategoriesList={
                                                    this.state
                                                        .allCompliancesCategoriesList
                                                }
                                                allCompliancesTypesList={
                                                    this.state
                                                        .allCompliancesTypesList
                                                }
                                                riskRatingsList={
                                                    this.state.riskRatingsList
                                                }
                                                sortedTaskStatusListDropdown={
                                                    sortedStatusDropdown
                                                }
                                                sortedLawListOfLoginUserDropdown={
                                                    this.props
                                                        .sortedLawListOfLoginUserDropdown
                                                }
                                                getUserListByEntityAndRole={(entityIds, roleIds) =>
                                                    this.getUserListByEntityAndRole(entityIds, roleIds)
                                                }
                                                sortedUserListByEntityAndRoleDropdown={this.props.sortedUserListByEntityAndRoleDropdown}
                                                filterData={
                                                    this.state
                                                        .searchFormDataForPro
                                                }
                                                operationType={
                                                    this.state.operationType
                                                }
                                                onFormSubmit={(values) => {
                                                    this.formSubmitForPro(
                                                        values
                                                    );
                                                }}
                                                onFormReset={
                                                    this.resetFormForPro
                                                }
                                            />
                                        </CardBody>
                                    </CustomisedCollapseForOne>

                                    {this.state.reportGridForPro && (
                                        <Card>
                                            <CardBody>
                                                <div className="export-btn export_excel_btn">
                                                    <Button
                                                        color="primary"
                                                        onClick={() => {
                                                            this.openPdfGenerate('pro');
                                                        }}
                                                    >
                                                        Export PDF Report
                                                        <i class="fas fa-file-pdf fa-lg">
                                                            {' '}
                                                        </i>
                                                    </Button>
                                                    <Button
                                                        color="warning"
                                                        onClick={() => {
                                                            this.exportDataByType(
                                                                'pro-report'
                                                            );
                                                        }}
                                                    >
                                                        Export Excel
                                                        <svg
                                                            width="20"
                                                            height="26"
                                                            viewBox="0 0 20 26"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M0 0V26H20V6.6L19.7 6.3L13.7 0.3L13.4 0H0ZM2 2H12V8H18V24H2V2ZM14 3.4L16.6 6H14V3.4ZM5 10L8.8 15.5L5 21H7.4L10 17.2L12.6 21H15L11.2 15.5L15 10H12.6L10 13.8L7.4 10H5Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </Button>
                                                </div>
                                                <CustomisedDatatable
                                                    entries={50}
                                                    tableRecords={
                                                        dataForPro
                                                    }
                                                />

                                            </CardBody>
                                        </Card>
                                    )}
                                </TabPane>
                                <TabPane
                                    className="report-tabs custom"
                                    tabId="customReportTab"
                                >
                                    <CustomisedCollapseForOne
                                        className="accordion"
                                        viewDialog={true}
                                        dialogName={__t(
                                            'page_headers.advance_filters'
                                        )}
                                    >
                                        <CardBody className="px-4 pt-4 pb-2">
                                            <ReportFilterForm
                                                sortedCountryListDropdown={
                                                    this.props
                                                        .sortedCountryListDropdown
                                                }
                                                getStateListByCountry={(
                                                    countryIds
                                                ) =>
                                                    this.getStateListByCountry(
                                                        countryIds
                                                    )
                                                }
                                                sortedStateListDropdown={
                                                    this.props
                                                        .sortedStateListDropdown
                                                }
                                                getCityListByState={(
                                                    stateIds
                                                ) =>
                                                    this.getCityListByState(
                                                        stateIds
                                                    )
                                                }
                                                sortedCityListDropdown={
                                                    this.props
                                                        .sortedCityListDropdown
                                                }
                                                sortedEntityListDropdown={
                                                    this.props
                                                        .sortedEntityListDropdown
                                                }
                                                sortedDepartmentListDropdown={
                                                    this.props
                                                        .sortedDepartmentListDropdown
                                                }
                                                getUserListByEntityAndRole={(entityIds, roleIds) =>
                                                    this.getUserListByEntityAndRole(entityIds, roleIds)
                                                }
                                                sortedUserListByEntityAndRoleDropdown={this.props.sortedUserListByEntityAndRoleDropdown} allCompliancesCategoriesList={
                                                    this.state
                                                        .allCompliancesCategoriesList
                                                }
                                                allCompliancesTypesList={
                                                    this.state
                                                        .allCompliancesTypesList
                                                }
                                                riskRatingsList={
                                                    this.state.riskRatingsList
                                                }
                                                sortedTaskStatusListDropdown={
                                                    sortedStatusDropdown
                                                }
                                                sortedLawListOfLoginUserDropdown={
                                                    this.props
                                                        .sortedLawListOfLoginUserDropdown
                                                }
                                                filterData={
                                                    this.state.searchFormData
                                                }
                                                operationType={
                                                    this.state.operationType
                                                }
                                                onFormSubmit={(values) => {
                                                    this.formSubmit(values);
                                                }}
                                                onFormReset={this.resetForm}
                                            />
                                        </CardBody>
                                    </CustomisedCollapseForOne>

                                    {this.state.reportGrid && (
                                        <Card>
                                            <CardBody>
                                                <div className="export-btn export_excel_btn">
                                                    <Button
                                                        color="primary"
                                                        onClick={() => {
                                                            this.openPdfGenerate('custom');
                                                        }}
                                                    >
                                                        Export PDF Report
                                                        <i class="fas fa-file-pdf fa-lg">
                                                            {' '}
                                                        </i>
                                                    </Button>
                                                    <Button
                                                        color="warning"
                                                        onClick={() =>
                                                            this.exportDataByType(
                                                                'custom-report'
                                                            )
                                                        }
                                                    >
                                                        Export Excel
                                                        <svg
                                                            width="20"
                                                            height="26"
                                                            viewBox="0 0 20 26"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M0 0V26H20V6.6L19.7 6.3L13.7 0.3L13.4 0H0ZM2 2H12V8H18V24H2V2ZM14 3.4L16.6 6H14V3.4ZM5 10L8.8 15.5L5 21H7.4L10 17.2L12.6 21H15L11.2 15.5L15 10H12.6L10 13.8L7.4 10H5Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </Button>
                                                </div>
                                                <CustomisedDatatable
                                                    entries={50}
                                                    tableRecords={data}
                                                />
                                            </CardBody>
                                        </Card>
                                    )}
                                </TabPane>
                                <TabPane
                                    className="report-tabs user-logs"
                                    tabId="userLogsTab"
                                >
                                    <CustomisedCollapseForOne
                                        className="accordion"
                                        viewDialog={true}
                                        dialogName={__t(
                                            'page_headers.advance_filters'
                                        )}
                                    >
                                        <CardBody className="px-4 pt-4 pb-2">
                                            <RuntimeFilterForm
                                                sortedCountryListDropdown={
                                                    this.props
                                                        .sortedCountryListDropdown
                                                }
                                                sortedEntityListDropdown={
                                                    this.props
                                                        .sortedEntityListDropdown
                                                }
                                                sortedDepartmentListDropdown={
                                                    this.props
                                                        .sortedDepartmentListDropdown
                                                }
                                                filterData={
                                                    this.state
                                                        .searchFormDataForRuntime
                                                }
                                                operationType={
                                                    this.state.operationType
                                                }
                                                onFormSubmit={(values) => {
                                                    this.formSubmitForRunTime(
                                                        values
                                                    );
                                                }}
                                                onFormReset={
                                                    this.resetFormForRunTime
                                                }
                                            />
                                        </CardBody>
                                    </CustomisedCollapseForOne>

                                    {this.state.runtimeReportGrid && (
                                        <Card>
                                            <CardBody>
                                                <div className="export-btn export_excel_btn">
                                                    <Button
                                                        color="warning"
                                                        onClick={() =>
                                                            this.exportDataByType(
                                                                'runtime-report'
                                                            )
                                                        }
                                                    >
                                                        Export Excel
                                                        <svg
                                                            width="20"
                                                            height="26"
                                                            viewBox="0 0 20 26"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M0 0V26H20V6.6L19.7 6.3L13.7 0.3L13.4 0H0ZM2 2H12V8H18V24H2V2ZM14 3.4L16.6 6H14V3.4ZM5 10L8.8 15.5L5 21H7.4L10 17.2L12.6 21H15L11.2 15.5L15 10H12.6L10 13.8L7.4 10H5Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </Button>
                                                </div>
                                                <CustomisedDatatable
                                                    entries={50}
                                                    tableRecords={dataLogs}
                                                />
                                            </CardBody>
                                        </Card>
                                    )}
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </Container>
                <PdfReportModal
                    reportType={this.state.pdfReportType}
                    isModalOpen={this.state.showPdfPopup}
                    pdfReportData={this.state.pdfReportData}
                    onModalDismiss={() => {
                        this.closePdfGenerate()
                    }}
                    masterData={this.state.masterData}
                    riskRatingsList={this.state.riskRatingsList}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    reportApiResponse: makeReportSelectField('reportApiResponse'),
    systemRuntimeReportApiResponse: makeReportSelectField(
        'systemRuntimeReportApiResponse'
    ),
    sortedCountryListDropdown: selectSortedCountryList(),
    sortedStateListDropdown: selectSortedStateList(),
    sortedCityListDropdown: selectSortedCityList(),
    sortedEntityListDropdown: selectSortedEntityList(),
    sortedDepartmentListDropdown: selectSortedDepartmentList(),
    allCompliancesCategoriesList: makeLawComplianceSelectField(
        'allCompliancesCategoriesList'
    ),
    allCompliancesTypesList: makeLawComplianceSelectField('allCompliancesTypesList'),
    riskRatingsList: makeLawComplianceSelectField('riskRatingsList'),
    sortedTaskStatusListDropdown: selectSortedTaskStatusList(),
    sortedLawListOfLoginUserDropdown: selectSortedLawListOfLoginUser(),
    sortedUserListByEntityAndRoleDropdown: selectSortedUserListByEntityAndRole()
});
const withConnect = connect(mapStatetoProps, {
    getReportDataByTypeRequest,
    getSystemRuntimeReportRequest,
    getCountryListByStatusRequest,
    getUserEntityListRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getDepartmentListByStatusRequest,
    getComplianceCategoriesRequest,
    getComplianceTypesRequest,
    getRiskRatingsRequest,
    getTaskStatusListRequest,
    getLawsListByLoginUserRequest,
    getUserListByEntityAndRoleRequest,
    clearFilterEntityUserResponse
});
export default compose(
    withConnect,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withEntityReducer,
    withEntitySaga,
    withDepartmentReducer,
    withDepartmentSaga,
    withLawReducer,
    withLawSaga,
    withLawComplianceReducer,
    withLawComplianceSaga,
    withTaskReducer,
    withTaskSaga,
    withReportReducer,
    withReportSaga,
    withRouter
)(Index);
