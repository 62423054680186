import produce from 'immer';
import { CLIENT_REGISTRATION_LIST_RESPONSE, CLIENT_REGISTRATION_RESEND_EMAIL_RESPONSE, CLIENT_REGISTRATION_STATUS_UPDATE_CLIENT_RESPONSE, LITE_COUNTRIES_LIST_AS_PER_PLAN_RESPONSE, LITE_COUNTRIES_WISE_ENTITY_ADD_RESPONSE, LITE_COUNTRIES_WISE_ENTITY_LIST_RESPONSE } from './actionTypes';

export const initialState = {
    registeredAccounts: [],
    resendEmail: null,
    statusUpdate: null,
    liteCountriesList: null,
    listOfLiteEntity: null,
    addLiteEntity: null
}


const RegisteredAccount = (state = initialState, action = '') =>
    produce(state, draft => {
        switch (action.type) {
            case CLIENT_REGISTRATION_LIST_RESPONSE:
                draft.registeredAccounts = action.payload.data;
                break;
            case CLIENT_REGISTRATION_RESEND_EMAIL_RESPONSE:
                draft.resendEmail = {
                    message: action.payload.data.message,
                    data: action.payload.data.data,
                    responseType: action.payload.data.status,
                };
                break;
            case CLIENT_REGISTRATION_STATUS_UPDATE_CLIENT_RESPONSE:
                draft.statusUpdate = {
                    message: action.payload.data.message,
                    data: action.payload.data.data,
                    responseType: action.payload.data.status,
                };
                break;
            case LITE_COUNTRIES_LIST_AS_PER_PLAN_RESPONSE:
                draft.liteCountriesList = {
                    message: action.payload.data.message,
                    data: action.payload.data.data,
                    responseType: action.payload.data.status,
                }
            case LITE_COUNTRIES_WISE_ENTITY_LIST_RESPONSE:
                draft.listOfLiteEntity = {
                    message: action.payload.data.message,
                    data: action.payload.data.data,
                    responseType: action.payload.data.status,
                }
            case LITE_COUNTRIES_WISE_ENTITY_ADD_RESPONSE:
                draft.addLiteEntity = {
                    message: action.payload.data.message,
                    data: action.payload.data.data,
                    responseType: action.payload.data.status,
                }
            default:
                state = { ...state };
                break;
        }
    });
export default RegisteredAccount;