import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Label, FormGroup } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import taskReducer from 'store/Task/reducer';
import taskSaga from 'store/Task/saga';
import lawComplianceSaga from 'store/LawCompliance/saga';
import lawComplianceReducer from 'store/LawCompliance/reducer';
import {
    getAssignedTaskSearchListRequest,
    getTaskStatusListRequest,
    getTaskUserDetailsByTaskIdRequest,
    changeTaskDetailsByTaskIdRequest,
    getTaskDetailsByTaskIdRequest,
    getComplianceCategoriesRequest,
    clearComplianceCategoryResponse,
    getEntityTeamsByEntityIdRequest
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import {
    initialChangeDetailsFilterDataState,
    listColumns,
} from './CommonFunctions';
import __t from 'i18n/translator';
import {
    makeTaskSelectField,
    selectSortedTaskStatusList,
} from 'store/Task/selector';
import CustomisedCollapseForOne from 'components/Application/CustomisedCollapseForOne';
import { isManagementRole } from 'helpers/projectUtils';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import ChangeDetailsFilterForm from './ChangeDetailsFilterForm';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
import {
    AUDITED_AND_CLOSED,
    CLOSED,
} from 'constants/databaseConstants';
import { roleList } from 'constants/dropdowns';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import { Option, MultiValue, animatedComponents } from 'helpers/projectUtils';
import ReactTooltip from 'react-tooltip';
import ViewTaskUser from './ViewTaskUser';
import ChangeDetailsModal from './ChangeDetailsModal';
import { toastrMessage } from 'helpers/messageHelper';
import {
    DEFAULT_PAGE_LENGTH,
    PENDING_TO_TRIGGERED,
    SUCCESS,
} from 'constants/commonConstants';
import { getDateFrom } from 'helpers/dateTimeHelper';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import { makeLawComplianceSelectField } from 'store/LawCompliance/selector';
import { compareString } from 'helpers/generalUtils';

const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });

const taskKey = 'task';
const withTaskReducer = injectReducer({
    key: taskKey,
    reducer: taskReducer,
});
const withTaskSaga = injectSaga({ key: taskKey, saga: taskSaga });

const lawComplianceKey = 'lawCompliance';
const withLawComplianceReducer = injectReducer({
    key: lawComplianceKey,
    reducer: lawComplianceReducer,
});
const withLawComplianceSaga = injectSaga({
    key: lawComplianceKey,
    saga: lawComplianceSaga,
});
class Index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            loginUserId: parseInt(getValueByKey(authConstants.USER_ID)),
            taskList: [],
            masterCityData: {},
            userDepartments: [],
            userDepartmentEntities: [],
            sortBy: {
                key: 'createdAt',
                value: 'DESC',
            },
            isViewTaskUserModalOpen: false,
            viewTaskUserData: {},
            isChangeDetailsModalOpen: false,
            taskDetails: {},
            taskId: null,
            taskStatusList: [],
            searchFormData: initialChangeDetailsFilterDataState(),
            pageSize: DEFAULT_PAGE_LENGTH
        };
    }
    componentDidMount() {
        this.props.getTaskStatusListRequest();
        this.props.getComplianceCategoriesRequest();
        this.getTaskSearchList(this.state.sortBy, {});
    }
    componentWillUnmount() {
        this.props.clearComplianceCategoryResponse();
    }
    getTaskSearchList = (sortBy, values = {}) => {
        let apiData = {
            limit: -1,
            page: -1,
            filter: {
                ...values,
                isManagementUser: isManagementRole(),
                notClose: true
            },
            orderBy: sortBy,
        };
        this.props.getAssignedTaskSearchListRequest(apiData, true);
    };
    formSubmit = (values) => {
        this.setState(
            {
                resetTableSection: true,
                searchFormData: values,
                taskList: [],
            },
            () => {
                this.getTaskSearchList(
                    this.state.sortBy,
                    this.state.searchFormData
                );
            }
        );
    };
    resetForm = () => {
        this.setState({
            taskList: [],
            searchFormData: initialChangeDetailsFilterDataState(),
        }, () => {
            this.getTaskSearchList({});
        });
    };

    openViewTaskUserModal = (data) => {
        this.setState({
            isViewTaskUserModalOpen: true,
            viewTaskUserData: data,
        });
    };
    closeViewTaskUserModal() {
        this.setState({
            isViewTaskUserModalOpen: false,
            viewTaskUserData: {},
        });
    }
    openChangeDetailsModal = () => {
        this.setState({
            isChangeDetailsModalOpen: true,
        });
    };
    closeChangeDetailsModal = () => {
        this.setState({
            isChangeDetailsModalOpen: false,
            taskId: null,
        });
    };
    getRoleName = (taskData) => {
        let roles = [];
        const departmentIds =
            taskData.complianceAllocationDepartmentId != '' &&
                taskData.complianceAllocationDepartmentId != null
                ? taskData.complianceAllocationDepartmentId.split(',')
                : [];
        const collaboratorUserIds =
            taskData.collaboratorUserId != '' &&
                taskData.collaboratorUserId != null
                ? taskData.collaboratorUserId.split(',').map(Number)
                : [];
        if (taskData.submitterId == this.state.loginUserId) {
            roles.push('Submitter');
        } else if (taskData.firstApproverUserId == this.state.loginUserId) {
            roles.push('Reviewer');
        } else if (taskData.secondApproverUserId == this.state.loginUserId) {
            roles.push('Approver');
        } else if (taskData.auditorId == this.state.loginUserId) {
            roles.push('Auditor');
            // } else if (departmentIds.some((department) => this.state.userDepartments.includes(parseInt(department)))) {
        } else if (
            departmentIds.some((department) => {
                let departmentHod = this.state.userDepartments?.find(
                    (departmentEntity) =>
                        departmentEntity.entityId ==
                        taskData.complianceAllocationEntityId &&
                        departmentEntity.departmentId == department
                );
                if (departmentHod) {
                    return true;
                }
            })
        ) {
            roles.push('HOD');
        } else if (
            taskData.collaboratorUserId != '' &&
            collaboratorUserIds.includes(this.state.loginUserId)
        ) {
            roles.push('Collaborator');
        } else if (taskData.createdBy == this.state.loginUserId) {
            roles.push('Task Creator');
        }
        return roles;
    };
    getCityName = (cityId) => {
        const cityData = this.state.masterCityData.find(x => x.id === cityId);
        const cityName = cityData ? cityData.cityName : "";
        return cityName;
    }
    generateTable = (records) => {
        let taskRecords = [];
        taskRecords = records.map((taskData, i) => {
            let roleNames = this.getRoleName(taskData);
            let actions = (
                <div className="tbl_action">
                    <a
                        href={() => false}
                        onClick={() => {
                            // this.props.getTaskDetailsByTaskIdRequest(taskData.id);
                            this.setState({ taskId: taskData.id, taskDetails: { ...taskData, dueDate: getDateFrom(taskData.dueDate) } });
                            this.openChangeDetailsModal();
                        }}
                        className="btn btn-link text-success tbl-action"
                    >
                        <i className="fas fa-edit"></i>
                    </a>
                </div>
            );
            let taskCodeVal = (
                <span searchvalue={taskData.taskCode} className="code_td">{taskData.taskCode}</span>
            );
            // let userInfo = "<p>Submitter:";
            // let taskUserInfo = this.state.taskUsers.find((opt) => opt.taskId == taskData.id);
            // if (taskUserInfo) {
            // let taskUser = taskUserInfo.users;
            // userInfo += taskUser?.submitterUser?.fullName + "<br/>";
            // userInfo += "2nd Approver:" + taskUser?.firstApproverUser?.fullName + "<br/>"
            // if (taskUser.secondApproverUserId != '' && taskUser.secondApproverUserId != null) {
            //     userInfo += "2nd Approver:" + taskUser.secondApproverUser?.fullName + "<br/>";
            // }
            // if (taskUser.auditorUserId != '' && taskUser.auditorUserId != null) {
            //     userInfo += "Auditor:" + taskUser?.auditorUser?.fullName + "<br/>";
            // }
            // let departmentNames = '';
            // if (taskUser.departments?.length > 0) {
            //     departmentNames = taskUser.departments.map((opt) => opt.departmentName).join(', ');
            // }
            // userInfo += "Department(s):" + departmentNames + "<br/>";
            // }
            // <br/>1st Approver<br/>2nd Approver<br/>Auditor<br/>Department<br/></p>
            const findTaskStatusName = this.state?.taskStatusList
                && this.state?.taskStatusList.length > 0 ? this.state?.taskStatusList.find((element) => element.value == taskData.taskStatusId)?.label ?? "" : "";
            let infoVal = (
                <React.Fragment key={findTaskStatusName}>
                    <div className='status_info'>
                        <span>{findTaskStatusName}</span>
                        <a
                            href={() => false}
                            onClick={() => {
                                this.props.getTaskUserDetailsByTaskIdRequest(
                                    taskData.id
                                );
                            }}
                            className="text-primary"
                        >
                            <i className="fas fa-info-circle"></i>
                        </a>
                    </div>
                </React.Fragment>
            );
            let complianceCategoryId = taskData.complianceVersionLawComplianceCategoryId == null
                ? taskData.complianceAllocationLawComplianceCategoryId
                : taskData.complianceVersionLawComplianceCategoryId;
            let complianceCategoryName = this.state.compliancesCategoriesList.find((c) => c.value === complianceCategoryId)?.label || '';

            return {
                id: taskData.id,
                taskCode: taskCodeVal,
                companyGroupName:
                    taskData?.ComplianceAllocationEntityCompanyGroupName,
                entityName: taskData.complianceAllocationEntityName,
                complianceCode: taskData.complianceAllocationLawComplianceCode,
                countryName:
                    taskData.complianceAllocationEntityLocationCountryName,
                stateName: taskData.complianceAllocationEntityLocationStateName,
                cityName: this.getCityName(taskData.complianceAllocationEntityLocationCityId),
                complianceTitle:
                    taskData.parentTaskId === null
                        ? taskData.complianceAllocationLawComplianceTitle
                        : taskData.title,
                complianceClass: complianceCategoryName,
                dueDate: taskData.dueDate ? (
                    <Label searchvalue={taskData.dueDate}>
                        {getDateFrom(taskData.dueDate)}
                    </Label>
                ) : (
                    <Label searchvalue={''}>{PENDING_TO_TRIGGERED}</Label>
                ),
                taskStatusId: infoVal,
                parentTaskId:
                    taskData.parentTaskId === null
                        ? 'Statutory'
                        : 'Non-Statutory',
                role: roleNames.length > 0 ? roleNames.join('<br/>') : '-',
                actions:
                    taskData.taskStatusId != CLOSED &&
                        taskData.taskStatusId != AUDITED_AND_CLOSED
                        ? actions
                        : '-',
            };
        });
        return taskRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.allCompliancesCategoriesList &&
            this.props.allCompliancesCategoriesList !==
            nextprops.allCompliancesCategoriesList &&
            this.state.taskList.length === 0
        ) {
            this.setState({
                compliancesCategoriesList:
                    nextprops.allCompliancesCategoriesList
                        ?.map((opt) => ({
                            value: opt.id,
                            label: opt.complianceCategoryName,
                        }))
                        .sort((item1, item2) =>
                            compareString(item1.label, item2.label)
                        ),
            });
        }
        if (
            this.state.taskList.length === 0 &&
            nextprops.assignedTaskSearchApiResponse &&
            nextprops.allCompliancesCategoriesList
        ) {
            this.setState(
                {
                    userDepartments:
                        nextprops.assignedTaskSearchApiResponse.data
                            .userDepartments,
                    masterCityData: nextprops.assignedTaskSearchApiResponse.data.countryStateCityData.masterCityData,
                    taskList: this.generateTable([]),
                },
                () => {
                    this.setState({
                        taskList: this.generateTable(
                            nextprops.assignedTaskSearchApiResponse.data.tasks
                        ),
                    });
                }
            );
        }
        if (nextprops.assignedTaskSearchApiResponse && this.props.assignedTaskSearchApiResponse !== nextprops.assignedTaskSearchApiResponse) {
            this.setState({
                userDepartments:
                    nextprops.assignedTaskSearchApiResponse.data
                        .userDepartments,
                masterCityData: nextprops.assignedTaskSearchApiResponse.data.countryStateCityData.masterCityData,
            }, () => {
                this.setState({
                    taskList: this.generateTable(
                        nextprops.assignedTaskSearchApiResponse.data.tasks
                    ),
                });
            });
        }

        if (
            nextprops.taskUserDetailsApiResponse &&
            nextprops.taskUserDetailsApiResponse !==
            this.props.taskUserDetailsApiResponse
        ) {
            this.setState({
                isViewTaskUserModalOpen: true,
                viewTaskUserData:
                    nextprops.taskUserDetailsApiResponse.data?.taskUserData,
            });
        }
        if (
            nextprops.taskApiResponse &&
            this.props.taskApiResponse !== nextprops.taskApiResponse
        ) {
            toastrMessage(
                nextprops.taskApiResponse.message,
                nextprops.taskApiResponse.responseType
            );
            if (nextprops.taskApiResponse.responseType === SUCCESS) {
                this.closeChangeDetailsModal();
                this.props.getTaskStatusListRequest();
                this.getTaskSearchList(this.state.sortBy, {});
            }
        }
        // if (
        //     nextprops.taskDetailsApiResponse &&
        //     this.props.taskDetailsApiResponse !==
        //     nextprops.taskDetailsApiResponse
        // ) {
        //     this.setState({
        //         taskDetails: nextprops.taskDetailsApiResponse.data.task,
        //     });
        // }

        if (
            nextprops.sortedTaskStatusListDropdown &&
            nextprops.sortedTaskStatusListDropdown !==
            this.props.sortedTaskStatusListDropdown
        ) {
            this.setState({
                taskStatusList: nextprops.sortedTaskStatusListDropdown
            })
        }
    }
    render() {
        let sortedStatusDropdown = this.props.sortedTaskStatusListDropdown.filter((opt) => opt.value != CLOSED && opt.value != AUDITED_AND_CLOSED);
        const data = {
            columns: listColumns,
            rows: this.state.taskList,
        };
        return (
            <React.Fragment>
                <ReactTooltip />
                <Row>
                    <Col lg="12">
                        <CommonBreadcrum type={'change-details'} />
                    </Col>
                    <Col lg="12">
                        <CustomisedCollapseForOne
                            className="accordion"
                            viewDialog={false}
                            dialogName={__t(
                                'form_labels.reporting.more_menu.change_details'
                            )}
                        >
                            <CardBody className="px-4 pt-4 pb-2">
                                <ChangeDetailsFilterForm
                                    taskFilterData={this.state.searchFormData}
                                    pageType={'all'}
                                    operationType={this.state.operationType}
                                    onFormSubmit={(values) => {
                                        this.formSubmit(values);
                                    }}
                                    onFormReset={this.resetForm}
                                />
                            </CardBody>
                        </CustomisedCollapseForOne>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <CardBody className="custom_table">
                                <div className="report-select-box">
                                    <FormGroup>
                                        <CustomMultiSelect
                                            component={CustomMultiSelect}
                                            name="statusId[]"
                                            className="form-control selectbox"
                                            options={sortedStatusDropdown}
                                            onChange={(opt) => {
                                                let selectedValues = opt
                                                    ? opt.map((x) => x.value)
                                                    : [];
                                                this.formSubmit({
                                                    ...this.state
                                                        .searchFormData,
                                                    statusId: selectedValues,
                                                });
                                            }}
                                            value={sortedStatusDropdown?.filter(
                                                (option) =>
                                                    this.state.searchFormData?.statusId?.includes(
                                                        option.value
                                                    )
                                            )}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            allowSelectAll={true}
                                            components={{
                                                Option,
                                                MultiValue,
                                                animatedComponents,
                                            }}
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.task_filters.status'
                                            )}
                                        </Label>
                                    </FormGroup>
                                </div>
                                <CustomisedDatatable
                                    className="table table-bordered table-striped mt-1"
                                    tableRecords={data}
                                    sortRows={[
                                        'taskCode',
                                        'taskStatusId'
                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <ChangeDetailsModal
                    modalName={
                        __t(
                            'form_labels.reporting.more_menu.change_details'
                        ) +
                        ' | ' +
                        this.state.taskDetails?.taskCode
                    }
                    isModalOpen={
                        this.state.isChangeDetailsModalOpen
                    }
                    taskDetails={this.state.taskDetails}
                    onModalDismiss={() =>
                        this.closeChangeDetailsModal()
                    }
                    onFormValid={(values) => {
                        this.props.changeTaskDetailsByTaskIdRequest(
                            this.state.taskId,
                            values
                        );
                    }}
                />
                <ViewTaskUser
                    modalName={__t('form_labels.reporting.view_task_user')}
                    isModalOpen={this.state.isViewTaskUserModalOpen}
                    taskUserData={this.state.viewTaskUserData}
                    onModalDismiss={() => this.closeViewTaskUserModal()}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    assignedTaskSearchApiResponse: makeTaskSelectField(
        'assignedTaskSearchApiResponse'
    ),
    sortedTaskStatusListDropdown: selectSortedTaskStatusList(),
    taskUserDetailsApiResponse: makeTaskSelectField(
        'taskUserDetailsApiResponse'
    ),
    taskApiResponse: makeTaskSelectField('taskApiResponse'),
    taskDetailsApiResponse: makeTaskSelectField('taskDetailsApiResponse'),
    allCompliancesCategoriesList: makeLawComplianceSelectField('allCompliancesCategoriesList')
});
const withConnect = connect(mapStatetoProps, {
    getAssignedTaskSearchListRequest,
    getTaskStatusListRequest,
    getTaskUserDetailsByTaskIdRequest,
    changeTaskDetailsByTaskIdRequest,
    getTaskDetailsByTaskIdRequest,
    getComplianceCategoriesRequest,
    clearComplianceCategoryResponse,
    getEntityTeamsByEntityIdRequest
});
export default compose(
    withConnect,
    withEntityReducer,
    withEntitySaga,
    withTaskReducer,
    withTaskSaga,
    withLawComplianceReducer,
    withLawComplianceSaga,
    withRouter
)(Index);
