import produce from 'immer';
import { LITE_PLAN_SELECTION_RESPONSE, LITE_PRICING_LIST_RESPONSE } from './actionTypes';

export const initialState = {
    pricing: [],
    planResponse: null
}


const LitePricingReducer = (state = initialState, action = '') =>
    produce(state, draft => {
        switch (action.type) {
            case LITE_PRICING_LIST_RESPONSE:
                draft.pricing = action.payload.data;
                break;
            case LITE_PLAN_SELECTION_RESPONSE:
                draft.planResponse = action.payload.data
            default:
                state = { ...state };
                break;
        }
    });
export default LitePricingReducer;