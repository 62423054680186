import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import AllocationReviewFilterForm from './AllocationReviewFilterForm';
import CustomisedCollapseForOne from 'components/Application/CustomisedCollapseForOne';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import __t from 'i18n/translator';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
    getLawComplianceSearchRequest,
    changeStatusTrackAllocationRequest,
    deleteTrackAllocationsByComplianceRequest
} from 'store/actions';
import allocationReducer from 'store/Allocation/reducer';
import allocationSaga from 'store/Allocation/saga';
import lawComplianceReducer from 'store/LawCompliance/reducer';
import lawComplianceSaga from 'store/LawCompliance/saga';
import { DANGER, DEFAULT_PAGE_LENGTH, NA, SUCCESS } from 'constants/commonConstants';
import { confirmBoxByIconWithRemarks, toastrMessage } from 'helpers/messageHelper';
import { makeLawComplianceSelectField } from 'store/LawCompliance/selector';
import { makeAllocationSelectField } from 'store/Allocation/selector';
import CustomisedReactTableWithPagination from 'components/Application/CustomisedReactTableWithPagination';
import { formatDateForApiSystem } from 'helpers/dateTimeHelper';
import Activity from 'app/UpdateCenter/NewAdditions/Activity';
import EditMapLocations from 'app/Allocation/AllocationReview/EditMapLocations';
import ApexCharts from 'react-apexcharts';
import { TRACK_STATUS_APPROVED, TRACK_STATUS_PENDING, TRACK_STATUS_REJECTED } from 'constants/databaseConstants';
import { getTrackAllocationStatusNameById } from 'helpers/projectUtils';
import CustomisedReactTableWithPaginationAndRowSelection from 'components/Application/CustomisedReactTableWithPaginationAndRowSelection';

const lawComplianceKey = 'lawCompliance';
const withLawComplianceReducer = injectReducer({
    key: lawComplianceKey,
    reducer: lawComplianceReducer,
});
const withLawComplianceSaga = injectSaga({
    key: lawComplianceKey,
    saga: lawComplianceSaga,
});
const allocationKey = 'allocation';
const withAllocationReducer = injectReducer({
    key: allocationKey,
    reducer: allocationReducer,
});
const withAllocationSaga = injectSaga({
    key: allocationKey,
    saga: allocationSaga,
});

const lawComplianceSearchListColumns = [
    {
        Header: 'Code',
        accessor: 'complianceCode',
    },
    {
        Header: 'Country',
        TransHeader: 'tables.statutory.country',
        accessor: 'countryName',
    },
    {
        Header: 'State',
        TransHeader: 'tables.statutory.state',
        accessor: 'stateName',
        disableSortBy: true,
    },
    {
        Header: 'Section No',
        accessor: 'sectionReference',
        TransHeader: 'tables.statutory.section_no',
    },
    {
        Header: 'Rule No',
        accessor: 'ruleReference',
        TransHeader: 'tables.statutory.rule_no',
    },
    {
        Header: 'Compliance Title',
        accessor: 'complianceTitle',
    },
    {
        Header: 'Applicablity Details',
        accessor: 'complianceApplicability',
    },
    {
        Header: 'Class',
        TransHeader: 'tables.statutory.compliance_class',
        accessor: 'complianceClass',
    },
    {
        Header: 'Classification',
        TransHeader: 'tables.statutory.compliance_classification',
        accessor: 'complianceClassification',
    },
    {
        Header: 'Due Date',
        TransHeader: 'tables.statutory.due_date',
        accessor: 'dueDateDescription',
    },
    {
        Header: 'Short Name Of Act/Legislation',
        TransHeader: 'tables.statutory.law_short_name',
        accessor: 'lawShortName',
    },
    {
        Header: 'Frequency',
        TransHeader: 'tables.statutory.frequency',
        accessor: 'periodicityName',
    },
    {
        Header: 'Action',
        TransHeader: 'common.action',
        accessor: 'actions',
        disableSortBy: true,
    },
];
const lawComplianceSearchListColumns1 = [
    {
        Header: 'Code',
        accessor: 'complianceCode',
    },
    {
        Header: 'Country',
        TransHeader: 'tables.statutory.country',
        accessor: 'countryName',
    },
    {
        Header: 'State',
        TransHeader: 'tables.statutory.state',
        accessor: 'stateName',
        disableSortBy: true,
    },
    {
        Header: 'Section No',
        accessor: 'sectionReference',
        TransHeader: 'tables.statutory.section_no',
    },
    {
        Header: 'Rule No',
        accessor: 'ruleReference',
        TransHeader: 'tables.statutory.rule_no',
    },
    {
        Header: 'Compliance Title',
        accessor: 'complianceTitle',
    },
    {
        Header: 'Applicablity Details',
        accessor: 'complianceApplicability',
    },
    {
        Header: 'Class',
        TransHeader: 'tables.statutory.compliance_class',
        accessor: 'complianceClass',
    },
    {
        Header: 'Classification',
        TransHeader: 'tables.statutory.compliance_classification',
        accessor: 'complianceClassification',
    },
    {
        Header: 'Due Date',
        TransHeader: 'tables.statutory.due_date',
        accessor: 'dueDateDescription',
    },
    {
        Header: 'Short Name Of Act/Legislation',
        TransHeader: 'tables.statutory.law_short_name',
        accessor: 'lawShortName',
    },
    {
        Header: 'Frequency',
        TransHeader: 'tables.statutory.frequency',
        accessor: 'periodicityName',
    },
    {
        Header: 'Action',
        TransHeader: 'common.action',
        accessor: 'actions',
        disableSortBy: true,
    },
];

export const initialSearchCompliancesDataState = () => {
    return {
        countryId: [],
        eventId: [],
        stateId: [],
        cityId: [],
        industryId: [],
        subIndustryId: [],
        lawCategoryId: [],
        lawId: [],
    };
};
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAllocations: [],
            pageSize: DEFAULT_PAGE_LENGTH,
            pageCount: 0,
            sortBy: {
                key: 'createdAt',
                value: 'DESC',
            },
            currentPage: 1,
            resetTableSection: false,
            searchCompliancesFormData: initialSearchCompliancesDataState(),
            searchLawComplianceList: [],
            lawComplianceData: null,
            lawComplianceDataForEntity: null,
            isEntityDetailsModalOpen: false,
            isActivityLogsModalOpen: false,
            selectedAllocationReviewType: '',
            reviewCounts: [0, 0, 0, 0],
            total_options: {
                chart: {
                    type: 'radialBar',
                    toolbar: {
                        show: false,
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    statusType: 'total',
                    parentHeightOffset: 0,
                    events: {
                        click: this.handleDataPointSelection,
                    },
                },
                labels: ['Total'],
                colors: ['#4F2D7F'],
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: '60%',
                            background: '#4f2d7f80',
                        },
                        track: {
                            dropShadow: {
                                enabled: false,
                                top: 2,
                                left: 0,
                                blur: 4,
                                opacity: 0.15,
                            },
                        },
                        dataLabels: {
                            name: {
                                offsetY: -10,
                                color: '#ffffff',
                                fontSize: '12px',
                            },
                            value: {
                                color: '#ffffff',
                                fontSize: '25px',
                                formatter: function (val) {
                                    return val;
                                },
                            },
                        },
                    },
                },
            },
            todo_options: {
                chart: {
                    type: 'radialBar',
                    toolbar: {
                        show: false,
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    statusType: 'to_do',
                    parentHeightOffset: 0,
                    events: {
                        click: this.handleDataPointSelection,
                    },
                },
                labels: ['To Do'],
                colors: ['#00A7B5'],
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: '60%',
                            background: '#00a7b580',
                        },
                        track: {
                            dropShadow: {
                                enabled: false,
                                top: 2,
                                left: 0,
                                blur: 4,
                                opacity: 0.15,
                            },
                        },
                        dataLabels: {
                            name: {
                                offsetY: -10,
                                color: '#ffffff',
                                fontSize: '12px',
                            },
                            value: {
                                color: '#ffffff',
                                fontSize: '25px',
                                formatter: function (val) {
                                    return val;
                                },
                            },
                        },
                    },
                },
            },
            approved_options: {
                chart: {
                    type: 'radialBar',
                    toolbar: {
                        show: false,
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    statusType: 'approved',
                    parentHeightOffset: 0,
                    events: {
                        click: this.handleDataPointSelection,
                    },
                },
                labels: ['Allocated'],
                colors: ['#9BD732'],
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: '60%',
                            background: '#9bd73299',
                        },
                        track: {
                            dropShadow: {
                                enabled: false,
                                top: 2,
                                left: 0,
                                blur: 4,
                                opacity: 0.15,
                            },
                        },
                        dataLabels: {
                            name: {
                                offsetY: -10,
                                color: '#ffffff',
                                fontSize: '12px',
                            },
                            value: {
                                color: '#ffffff',
                                fontSize: '25px',
                                formatter: function (val) {
                                    return val;
                                },
                            },
                        },
                    },
                },
            },
            rejected_options: {
                chart: {
                    type: 'radialBar',
                    toolbar: {
                        show: false,
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    statusType: 'rejected',
                    parentHeightOffset: 0,
                    events: {
                        click: this.handleDataPointSelection,
                    },
                },
                labels: ['Rejected'],
                colors: ['#DE002E'],
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: '60%',
                            background: '#DE002E80',
                        },
                        track: {
                            dropShadow: {
                                enabled: false,
                                top: 2,
                                left: 0,
                                blur: 4,
                                opacity: 0.15,
                            },
                        },
                        dataLabels: {
                            name: {
                                offsetY: -10,
                                color: '#ffffff',
                                fontSize: '12px',
                            },
                            value: {
                                color: '#ffffff',
                                fontSize: '25px',
                                formatter: function (val) {
                                    return val;
                                },
                            },
                        },
                    },
                },
            },
        }
        this.handleFetchData = this.handleFetchData.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleDataPointSelection = this.handleDataPointSelection.bind(this);
    }
    componentDidMount() {
        this.getLawComplianceSearchList(
            this.state.pageSize,
            this.state.currentPage,
            this.state.sortBy,
            {},
        );
    }
    handleDataPointSelection = (event, chartContext, config) => {
        const statusType = config?.config?.chart?.statusType;
        if (statusType) {
            this.setState({
                selectedAllocationReviewType: statusType
            }, () => {
                this.getLawComplianceSearchList(
                    this.state.pageSize,
                    this.state.currentPage,
                    this.state.sortBy, this.state.searchCompliancesFormData);
            })
        } else {
            this.setState({
                selectedAllocationReviewType: ''
            })
        }
    };

    formSubmit = (values) => {
        this.setState(
            {
                searchCompliancesFormData: values
            },
            () => {
                this.getLawComplianceSearchList(
                    this.state.pageSize,
                    this.state.currentPage,
                    this.state.sortBy,
                    this.state.searchCompliancesFormData,
                );
            }
        );
    };
    resetForm = () => {
        this.setState({
            searchCompliancesFormData: initialSearchCompliancesDataState(),
            currentPage: 1,
            resetTableSection: true,
        }, () => {
            this.getLawComplianceSearchList(this.state.pageSize, 1, { key: 'createdAt', value: 'DESC' }, {});
        });
    };
    generateTable = (records) => {
        let complianceRecords = [];
        complianceRecords = records.map((lawDataRecord, i) => {
            let actions = (
                <div className="table-action">
                    <a
                        href={() => false}
                        title="View Details"
                        className="btn btn-link text-success"
                        onClick={() => {
                            window.open(
                                '/statutory-compliances/view-details/' +
                                lawDataRecord.id,
                                '_blank'
                            );
                        }}
                    >
                        <i className="fas fa-eye"></i>
                    </a>
                    <a
                        href={() => false}
                        title={__t('tables.new_additions.entity_details')}
                        className="btn btn-link tbl-action text-info"
                        onClick={() => {
                            this.openEntityDetailsModal(lawDataRecord);
                        }}
                    >
                        <svg
                            width="17"
                            height="20"
                            viewBox="0 0 17 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                x="16"
                                y="7"
                                width="4"
                                height="4"
                                rx="2"
                                transform="rotate(90 16 7)"
                                fill="#7E869E"
                                fillOpacity="0.25"
                                stroke="currentColor"
                                strokeWidth="1.5"
                            />
                            <rect
                                x="16"
                                y="15"
                                width="4"
                                height="4"
                                rx="2"
                                transform="rotate(90 16 15)"
                                fill="#7E869E"
                                fillOpacity="0.25"
                                stroke="currentColor"
                                strokeWidth="1.5"
                            />
                            <rect
                                x="1"
                                y="5"
                                width="4"
                                height="4"
                                rx="2"
                                transform="rotate(-90 1 5)"
                                fill="#7E869E"
                                fillOpacity="1"
                                stroke="currentColor"
                                strokeWidth="1.5"
                            />
                            <path
                                d="M3 6V13C3 14.8856 3 15.8284 3.58579 16.4142C4.17157 17 5.11438 17 7 17H12"
                                stroke="currentColor"
                                strokeWidth="1.5"
                            />
                            <path
                                d="M3 5V5C3 6.88562 3 7.82843 3.58579 8.41421C4.17157 9 5.11438 9 7 9H12"
                                stroke="currentColor"
                                strokeWidth="1.5"
                            />
                        </svg>
                    </a>
                    <a
                        href={() => false}
                        title={__t('tables.new_additions.activity_logs')}
                        className="btn btn-link text-primary tbl-action"
                        onClick={() => {
                            this.openActivityLogsModal(lawDataRecord);
                        }}
                    >
                        <i className="fas fa-history"></i>
                    </a>
                    {lawDataRecord.trackAllocationPendingCount > 0 && (
                        <a
                            href={() => false}
                            title={__t(
                                'tables.new_additions.approve'
                            )}
                            onClick={() => {
                                this.changeStatus(
                                    [lawDataRecord.id],
                                    TRACK_STATUS_APPROVED
                                );
                            }}
                        >
                            <i className="fas fa-check text-success"></i>
                        </a>
                    )}
                    {lawDataRecord.trackAllocationPendingCount > 0 && (
                        <a
                            href={() => false}
                            title={__t(
                                'tables.new_additions.reject'
                            )}
                            onClick={() => {
                                this.changeStatus(
                                    [lawDataRecord.id],
                                    TRACK_STATUS_REJECTED
                                );
                            }}
                        >
                            <i className="fas fa-times text-danger"></i>
                        </a>
                    )}
                    {this.state.selectedAllocationReviewType == "rejected" && lawDataRecord.trackAllocationRejectedCount > 0 && (
                        <a
                            href={() => false}
                            title={__t(
                                'form_labels.new_additions.undo'
                            )}
                            onClick={() => {
                                this.changeStatus(
                                    [lawDataRecord.id],
                                    TRACK_STATUS_PENDING
                                );
                            }}
                        >
                            <i className="fas fa-undo text-warning"></i>
                        </a>
                    )}
                </div>
            );
            return {
                id: lawDataRecord.id,
                trackAllocationPendingCount: lawDataRecord.trackAllocationPendingCount,
                trackAllocationRejectedCount: lawDataRecord.trackAllocationRejectedCount,
                checkbox: ((lawDataRecord.trackAllocationPendingCount > 0) || this.state.selectedAllocationReviewType == "rejected" && lawDataRecord.trackAllocationRejectedCount > 0) ? true : false,
                countryName: lawDataRecord?.law?.country?.countryName,
                stateName: lawDataRecord?.law?.state
                    ? lawDataRecord?.law?.state?.stateName
                    : NA,
                complianceTitle: lawDataRecord?.complianceTitle,
                complianceCode: lawDataRecord?.complianceCode,
                sectionReference: lawDataRecord?.sectionReference,
                ruleReference: lawDataRecord?.ruleReference,
                complianceApplicability:
                    lawDataRecord.complianceApplicability !== null
                        ? lawDataRecord.complianceApplicability
                        : '',
                complianceClassification:
                    lawDataRecord?.complianceCategory?.complianceCategoryName,
                complianceClass:
                    lawDataRecord?.complianceType?.complianceTypeName,
                lawShortName: lawDataRecord?.law?.lawShortName,
                periodicityName: lawDataRecord?.periodicity?.periodicityName,
                dueDateDescription: lawDataRecord?.dueDateDescription,
                actions: actions,
            };
        });
        return complianceRecords;
    };
    changeStatus = async (lawComplianceIds, status) => {
        let message =
            __t('alerts.track_allocation_change_status') +
            getTrackAllocationStatusNameById(status) +
            '?';
        const response = await confirmBoxByIconWithRemarks(
            __t('common.are_you_sure'),
            message,
            __t('form_labels.new_additions.review_remarks_placeholder'),
            status == TRACK_STATUS_REJECTED ? 'error' : 'warning',
            status == TRACK_STATUS_APPROVED ? true : false
        );

        if (response.result) {
            const wordsCount = response.textarea.value.split(" ").length;
            if (wordsCount > 100) {
                toastrMessage(__t('form_labels.new_additions.review_remarks_placeholder') + ' ' + __t('alerts.100_words_limit'), DANGER)
            } else {
                this.props.changeStatusTrackAllocationRequest({
                    complianceId: lawComplianceIds,
                    status: status,
                    remarks: response.textarea.value,
                });
            }
        }
    };
    openEntityDetailsModal = (lawComplianceData) => {
        this.setState({
            isEntityDetailsModalOpen: true,
            lawComplianceDataForEntity: lawComplianceData,
        });
    };
    closeEntityDetailsModal = () => {
        this.setState({
            isEntityDetailsModalOpen: false,
            lawComplianceDataForEntity: {},
        });
    };
    openActivityLogsModal = (lawComplianceData) => {
        this.setState({
            isActivityLogsModalOpen: true,
            lawComplianceData: lawComplianceData,
        });
    };
    closeActivityLogsModal = () => {
        this.setState({
            isActivityLogsModalOpen: false,
            lawComplianceData: {},
        });
    };
    getLawComplianceSearchList = (limit, page, sortBy, values = {}) => {
        let apiData = {
            limit: limit,
            page: page,
            filter: {
                ...values,
                fromDate: values.fromDate
                    ? formatDateForApiSystem(values.fromDate)
                    : '',
                toDate: values.toDate
                    ? formatDateForApiSystem(values.toDate)
                    : '',
            },
            orderBy: sortBy,
            displayMode: true,
            allocationReview: true,
            allocationReviewType: this.state.selectedAllocationReviewType
        };
        this.props.getLawComplianceSearchRequest(apiData, true);
    };
    handleFetchData(data) {
        if (
            data.pageIndex + 1 !== this.state.currentPage ||
            this.state.pageSize !== data.pageSize
        ) {
            this.setState(
                {
                    pageSize: data.pageSize,
                    searchCompliancesFormData: {
                        ...this.state.searchCompliancesFormData,
                        searchText: data.globalFilter,
                    },
                },
                () => {
                    this.getLawComplianceSearchList(
                        data.pageSize,
                        data.pageIndex + 1,
                        this.state.sortBy,
                        this.state.searchCompliancesFormData,
                    );
                }
            );
        }
    }
    handleSort(data) {
        if (
            data.sortBy[0]?.desc != undefined &&
            data.sortBy[0]?.desc !== this.state.sortBy?.value
        ) {
            let sortOrder = data.sortBy[0]?.desc ? 'DESC' : 'ASC';
            this.setState(
                {
                    pageSize: data.pageSize,
                    searchCompliancesFormData: {
                        ...this.state.searchCompliancesFormData,
                        searchText: data.globalFilter,
                    },
                    sortBy: { key: data.sortBy[0]?.id, value: sortOrder },
                },
                () => {
                    this.getLawComplianceSearchList(
                        data.pageSize,
                        data.pageIndex + 1,
                        { key: data.sortBy[0]?.id, value: sortOrder },
                        this.state.searchCompliancesFormData,
                    );
                }
            );
        }
    }
    handleSearch(data) {
        let pageNum =
            data?.globalFilter == this.state.searchFormData?.searchText
                ? data.pageIndex + 1
                : 1;
        this.setState({
            searchCompliancesFormData: {
                ...this.state.searchCompliancesFormData,
                searchText: data.globalFilter,
            },
        }, () => {
            this.getLawComplianceSearchList(
                this.state.pageSize,
                pageNum,
                this.state.sortBy,
                this.state.searchCompliancesFormData,
            );
        });
    }

    updateSelectedAllocations(row, filterProperty) {
        this.setState({
            selectedAllocations: row
                .map((itemRow) => ({
                    id: itemRow.id,
                    trackAllocationPendingCount: itemRow.trackAllocationPendingCount,
                    trackAllocationRejectedCount: itemRow.trackAllocationRejectedCount,
                }))
                .filter((opt) => opt[filterProperty] === 0),
        });
    }



    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.deleteTrackAllocationApiResponse && nextprops.deleteTrackAllocationApiResponse !== this.props.deleteTrackAllocationApiResponse) {
            if (nextprops.deleteTrackAllocationApiResponse.responseType == SUCCESS) {
                toastrMessage(nextprops.deleteTrackAllocationApiResponse.message, nextprops.deleteTrackAllocationApiResponse.responseType);
                this.closeEntityDetailsModal();
                this.getLawComplianceSearchList();
            }
        }
        if (
            nextprops.lawComplianceSearchApiResponse &&
            this.props.lawComplianceSearchApiResponse !==
            nextprops.lawComplianceSearchApiResponse
        ) {
            this.setState({
                resetTableSection: false,
                lawCompliance: true,
                sortBy: nextprops.lawComplianceSearchApiResponse.data.orderBy,
                pageCount:
                    nextprops.lawComplianceSearchApiResponse.data.totalPages,
                currentPage:
                    nextprops.lawComplianceSearchApiResponse.data.currentPage,
                totalRecords:
                    nextprops.lawComplianceSearchApiResponse.data.totalRecords,
                searchLawComplianceList: this.generateTable(
                    nextprops.lawComplianceSearchApiResponse.data.lawCompliances
                ),
                reviewCounts: (nextprops.lawComplianceSearchApiResponse.data?.reviewCounts) ? nextprops.lawComplianceSearchApiResponse.data?.reviewCounts : [0, 0, 0, 0]
            });
        }
        if (
            nextprops.trackAllocationApiResponse &&
            nextprops.trackAllocationApiResponse !==
            this.props.trackAllocationApiResponse
        ) {
            toastrMessage(
                nextprops.trackAllocationApiResponse.message,
                nextprops.trackAllocationApiResponse.responseType
            );
            this.getLawComplianceSearchList();
        }
    }
    render() {
        const data = {
            columns: lawComplianceSearchListColumns,
            rows: this.state.searchLawComplianceList,
        };
        const data1 = {
            columns: lawComplianceSearchListColumns1,
            rows: this.state.searchLawComplianceList,
        };
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <CommonBreadcrum type={'allocation-review'} />
                    </Col>
                    <Col lg="12">
                        <CustomisedCollapseForOne
                            className="accordion"
                            viewDialog={false}
                            dialogName={__t('page_headers.allocation_review')}
                        >
                            <CardBody className="px-4 pt-4 pb-2">
                                <AllocationReviewFilterForm
                                    searchCompliancesFormData={
                                        this.state
                                            .searchCompliancesFormData
                                    }
                                    onFormSubmit={(
                                        searchFormData
                                    ) => {
                                        this.formSubmit(
                                            searchFormData
                                        );
                                    }}
                                    onResetForm={() => {
                                        this.resetForm();
                                    }}
                                />
                            </CardBody>
                        </CustomisedCollapseForOne>
                    </Col>

                    <Col lg="12">
                        <Row className='allocation_review'>
                            <Col lg="3">
                                <Card>
                                    <CardBody className={(this.state.selectedAllocationReviewType == 'total') ? 'p-0 total_allocation active' : 'p-0 total_allocation'}>
                                        <ApexCharts options={this.state.total_options} series={[this.state.reviewCounts[0]]} type="radialBar" height={230} />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3">
                                <Card>
                                    <CardBody className={(this.state.selectedAllocationReviewType == 'to_do') ? 'p-0 todo_allocation active' : 'p-0 todo_allocation'}>
                                        <ApexCharts options={this.state.todo_options} series={[this.state.reviewCounts[1]]} type="radialBar" height={230} />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3">
                                <Card>
                                    <CardBody className={(this.state.selectedAllocationReviewType == 'approved') ? 'p-0 approver_allocation active' : 'p-0 approver_allocation'}>
                                        <ApexCharts options={this.state.approved_options} series={[this.state.reviewCounts[2]]} type="radialBar" height={230} />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3">
                                <Card>
                                    <CardBody className={(this.state.selectedAllocationReviewType == 'rejected') ? 'p-0 rejected_allocation active' : 'p-0 rejected_allocation'}>
                                        <ApexCharts options={this.state.rejected_options} series={[this.state.reviewCounts[3]]} type="radialBar" height={230} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                    {this.state.selectedAllocationReviewType != '' && !this.state.resetTableSection && (
                        <React.Fragment>
                            <Col lg="12">
                                <Card>
                                    <CardBody className="custom_table table-responsive report_compliances to_do_review">
                                        {(this.state.selectedAllocationReviewType == "to_do" || this.state.selectedAllocationReviewType == "rejected") && (
                                            <div className='bulk_update pl-2'>
                                                <React.Fragment>
                                                    {(this.state.selectedAllocationReviewType == "to_do" && this.state.selectedAllocations.length > 0 &&
                                                        this.state.selectedAllocations.filter(
                                                            (opt) => opt.trackAllocationPendingCount > 0 && opt.trackAllocationRejectedCount == 0
                                                        ).length === this.state.selectedAllocations
                                                            .length) && (
                                                            <Button
                                                                className="btn btn-success mr-3"
                                                                onClick={() => {
                                                                    if (
                                                                        this.state.selectedAllocations
                                                                            .length === 0
                                                                    ) {
                                                                        toastrMessage(
                                                                            __t(
                                                                                'alerts.please_select_record'
                                                                            ),
                                                                            DANGER
                                                                        );
                                                                    } else {
                                                                        this.changeStatus(
                                                                            this.state
                                                                                .selectedAllocations.map((opt) => opt.id),
                                                                            TRACK_STATUS_APPROVED
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                <i className="fas fa-check"></i>
                                                                {__t(
                                                                    'form_labels.new_additions.approve'
                                                                )}
                                                            </Button>
                                                        )}
                                                    {(this.state.selectedAllocationReviewType == "to_do" && this.state.selectedAllocations.length > 0 &&
                                                        this.state.selectedAllocations.filter(
                                                            (opt) => opt.trackAllocationPendingCount > 0
                                                        ).length ===
                                                        this.state.selectedAllocations
                                                            .length) && (
                                                            <Button
                                                                className="btn btn-danger mr-3"
                                                                onClick={() => {
                                                                    if (
                                                                        this.state.selectedAllocations
                                                                            .length === 0
                                                                    ) {
                                                                        toastrMessage(
                                                                            __t(
                                                                                'alerts.please_select_record'
                                                                            ),
                                                                            DANGER
                                                                        );
                                                                    } else {
                                                                        this.changeStatus(
                                                                            this.state
                                                                                .selectedAllocations.map((opt) => opt.id),
                                                                            TRACK_STATUS_REJECTED
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                <i className="fas fa-times"></i>
                                                                {__t(
                                                                    'form_labels.new_additions.reject'
                                                                )}
                                                            </Button>
                                                        )}
                                                    {(this.state.selectedAllocationReviewType == "rejected" && this.state.selectedAllocations.length > 0 &&
                                                        this.state.selectedAllocations.filter(
                                                            (opt) => opt.trackAllocationPendingCount == 0 && opt.trackAllocationRejectedCount > 0
                                                        ).length ===
                                                        this.state.selectedAllocations
                                                            .length) && (
                                                            <Button
                                                                className="btn btn-warning"
                                                                onClick={() => {
                                                                    if (
                                                                        this.state.selectedAllocations
                                                                            .length === 0
                                                                    ) {
                                                                        toastrMessage(
                                                                            __t(
                                                                                'alerts.please_select_record'
                                                                            ),
                                                                            DANGER
                                                                        );
                                                                    } else {
                                                                        this.changeStatus(
                                                                            this.state
                                                                                .selectedAllocations.map((opt) => opt.id),
                                                                            TRACK_STATUS_PENDING
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                <i className="fas fa-undo"></i>
                                                                {__t(
                                                                    'form_labels.new_additions.undo'
                                                                )}
                                                            </Button>
                                                        )}
                                                </React.Fragment>
                                            </div>
                                        )}
                                        {this.state.selectedAllocationReviewType == "to_do" && (
                                            <CustomisedReactTableWithPaginationAndRowSelection
                                                onchangeCheckbox={(row) => {
                                                    this.updateSelectedAllocations(row, 'trackAllocationRejectedCount');

                                                }}
                                                className="table table-bordered table-striped"
                                                displayEntries={true}
                                                searching={true}
                                                currentPage={this.state.currentPage}
                                                pageCount={this.state.pageCount}
                                                handleFetchData={this.handleFetchData}
                                                handleSort={this.handleSort}
                                                handleSearch={this.handleSearch}
                                                preFilledSearchText={this.state.searchCompliancesFormData?.searchText}
                                                tableData={data1}
                                                totalRecords={this.state.totalRecords}
                                            />
                                        )}
                                        {this.state.selectedAllocationReviewType == "rejected" && (
                                            <CustomisedReactTableWithPaginationAndRowSelection
                                                onchangeCheckbox={(row) => {
                                                    this.updateSelectedAllocations(row, 'trackAllocationPendingCount');
                                                }}
                                                // ref={this.myRef}
                                                className="table table-bordered table-striped"
                                                displayEntries={true}
                                                searching={true}
                                                currentPage={this.state.currentPage}
                                                pageCount={this.state.pageCount}
                                                handleFetchData={this.handleFetchData}
                                                handleSort={this.handleSort}
                                                handleSearch={this.handleSearch}
                                                preFilledSearchText={this.state.searchCompliancesFormData?.searchText}
                                                tableData={data1}
                                                totalRecords={this.state.totalRecords}
                                            />
                                        )}
                                        {(this.state.selectedAllocationReviewType == "total" || this.state.selectedAllocationReviewType == "approved") && (
                                            <CustomisedReactTableWithPagination
                                                className="table table-bordered table-striped"
                                                displayEntries={true}
                                                searching={true}
                                                currentPage={this.state.currentPage}
                                                pageCount={this.state.pageCount}
                                                handleFetchData={this.handleFetchData}
                                                handleSort={this.handleSort}
                                                handleSearch={this.handleSearch}
                                                preFilledSearchText={this.state.searchCompliancesFormData?.searchText}
                                                tableData={data}
                                                totalRecords={this.state.totalRecords}
                                            />
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </React.Fragment>
                    )}
                </Row>
                <Activity
                    modalName={__t(
                        'form_labels.new_additions.activity_logs'
                    )}
                    isModalOpen={this.state.isActivityLogsModalOpen}
                    lawComplianceData={this.state.lawComplianceData}
                    onModalDismiss={() => this.closeActivityLogsModal()}
                    type={'new'}
                />
                <EditMapLocations
                    modalName={__t(
                        'form_labels.new_additions.entity_locations'
                    )}
                    isModalOpen={this.state.isEntityDetailsModalOpen}
                    lawComplianceDataForEntity={this.state.lawComplianceDataForEntity}
                    screenType={this.state.selectedAllocationReviewType}
                    onModalDismiss={() =>
                        this.closeEntityDetailsModal()
                    }
                    onFormSubmit={(values) => {
                        this.props.deleteTrackAllocationsByComplianceRequest(values);
                    }}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    lawComplianceSearchApiResponse: makeLawComplianceSelectField('lawComplianceSearchApiResponse'),
    trackAllocationApiResponse: makeAllocationSelectField('trackAllocationApiResponse'),
    deleteTrackAllocationApiResponse: makeAllocationSelectField('deleteTrackAllocationApiResponse')
});
const withConnect = connect(mapStatetoProps, {
    getLawComplianceSearchRequest,
    changeStatusTrackAllocationRequest,
    deleteTrackAllocationsByComplianceRequest
});
export default compose(
    withConnect,
    withLawComplianceReducer,
    withLawComplianceSaga,
    withAllocationReducer,
    withAllocationSaga,
    withRouter
)(Index);
