import {
    MODULE_ALLOCATION_REVIEW,
    MODULE_ARCHIVE_RECORDS,
    MODULE_ASSIGNED_EVENTS,
    MODULE_CHANGE_DETAILS,
    MODULE_CITY,
    MODULE_COMPANY_GROUP,
    MODULE_COUNTRY,
    MODULE_DASHBOARD,
    MODULE_DELEGATION,
    MODULE_DEPARTMENT,
    MODULE_ENTITY,
    MODULE_ENTITY_LOCATION,
    MODULE_ENTITY_ROLE,
    MODULE_EVENT_ALLOCATION,
    MODULE_EVENT_MASTER,
    MODULE_EXCEL_UPLOAD,
    MODULE_EXECUTED_EVENTS,
    MODULE_GENERATE_REPORT,
    MODULE_INDUSTRY,
    MODULE_LAW,
    MODULE_LAW_CATEGORY,
    MODULE_LAW_SECTION_RULE,
    MODULE_LEGAL_UPDATES,
    MODULE_NEED_ADDITIONAL_SERVICES,
    MODULE_NEWS_ALERTS,
    MODULE_NEW_ADDITIONS,
    MODULE_NEW_ALLOCATION,
    MODULE_ONGOING_EVENTS,
    MODULE_RELEVANT_UPDATES,
    MODULE_ROLE_PERMISSION,
    MODULE_STANDARD_DOCUMENTS,
    MODULE_STATE,
    MODULE_STATUTORY_AUDIT_COMPLIANCES,
    MODULE_STATUTORY_COUNTRY_COVERAGE,
    MODULE_STATUTORY_KNOW_YOUR_COMPLIANCES,
    MODULE_STATUTORY_LAW_COMPLIANCES,
    MODULE_STATUTORY_REPORT_COMPLIANCES,
    MODULE_STATUTORY_REVIEW_COMPLIANCES,
    MODULE_STATUTORY_TRACK_COMPLIANCES,
    MODULE_SYSTEM_CONFIGURATION,
    MODULE_TENANT,
    MODULE_NEW_ALLOCATION_STATUS,
    MODULE_TRANSACTIONAL_DOCUMENTS,
    MODULE_USER,
    MODULE_USER_ALLOCATION,
    MODULE_VIEW_ALLOCATION,
    PERMISSION_ADD,
    PERMISSION_DELETE,
    PERMISSION_EDIT,
    PERMISSION_IMPORT,
    PERMISSION_VIEW,
    MODULE_BLOCKED_EMAIL_MANAGEMENT,
    MODULE_RAISE_SUPPORT_TICKET,
    MODULE_APPLICABILITY,
    MODULE_LITE_REGISTERED_CLIENTS,
    MODULE_LITE,
} from 'constants/databaseConstants';

import Login from './app/Auth/Login';
import Logout from './app/Auth/Logout';
import ForgotPassword from './app/Auth/ForgotPassword';
import ResetPassword from './app/Auth/ResetPassword';
import ActivateAccount from './app/Auth/ActivateAccount';
import LawCategory from 'app/Masters/LawCategory/Index';
import Section from 'app/Masters/Section/Index';
import Law from 'app/Masters/Law/Index';
import Industry from 'app/Masters/Industry/Index';
import Applicability from 'app/Masters/Applicability/Index';
import Department from 'app/Masters/Department/Index';
import Country from 'app/Masters/Country/Index';
import State from 'app/Masters/State/Index';
import City from 'app/Masters/City/Index';
import CompanyGroup from 'app/Masters/CompanyGroup/Index';
import User from 'app/Masters/User/Index';
import Entity from 'app/Masters/Entity/Index';
import Tenant from 'app/Masters/Tenant/Index';
import EntityRole from 'app/Masters/EntityRole/Index';
import EntityLocation from 'app/Masters/EntityLocation/Index';
import StandardDocumentRepositoryIndex from 'app/DocumentRepository/Standard/Index';
import TransactionalDocumentRepositoryIndex from 'app/DocumentRepository/Transactional/Index';
import ArchiveRecordsIndex from 'app/DocumentRepository/ArchiveRecords/Index';
import SystemUserSetting from 'app/SystemConfiguration/SystemUserSetting';
import EntityForm from 'app/Masters/Entity/EntityForm';
import TenantForm from 'app/Masters/Tenant/TenantForm';
import LawForm from 'app/Masters/Law/LawForm';
import NewAllocationIndex from 'app/Allocation/NewAllocation/Index';
import EventAllocationIndex from 'app/Allocation/EventAllocation/Index';
import ViewAllocationIndex from 'app/Allocation/ViewAllocation/Index';
import UserAllocationIndex from 'app/Allocation/UserAllocation/Index';
import TrackAllocationIndex from 'app/Allocation/TrackAllocation/Index';
import AllocationReviewIndex from 'app/Allocation/AllocationReview/Index';
import ImportMasterIndex from 'app/Masters/Import/Index';
import Event from 'app/Masters/Event/Index';
import WorkItemTaskIndex from 'app/ComplianceLibrary/AssignedTasks/Index';
import WorkItemTaskDetails from 'app/ComplianceLibrary/AssignedTasks/TaskDetails';
import Compliance from 'app/ComplianceLibrary/ViewLawsCompliances/Index';
import UploadCompliance from 'app/ComplianceLibrary/ViewLawsCompliances/UploadCompliance';
import ComplianceCountryCoverage from 'app/ComplianceLibrary/CountryCoverage/Index';
import CommonShowComplianceDetails from 'components/Application/CommonShowComplianceDetails';
import SetNewPassword from 'app/Auth/SetNewPassword';
import AssignedEventIndex from 'app/Events/Assigned/Index';
import PlanEvent from 'app/Events/Assigned/PlanEvent';
import OngoingEventIndex from 'app/Events/Ongoing/Index';
import ExecutedEventIndex from 'app/Events/Executed/Index';
import RolePermissionIndex from 'app/Masters/RolePermission/Index';
import RolePermissionForm from 'app/Masters/RolePermission/RolePermissionForm';

import ChangePassword from 'app/Profile/ChangePassword';
import EditProfile from 'app/Profile/EditProfile';
import DashboardIndex from 'app/Dashboard/Index';
import ComingSoon from 'app/ComingSoon';

import Delegation from 'app/ServiceDesk/Delegation/Index';
import ChangeDetails from 'app/ServiceDesk/ChangeDetails/Index';
import KnowYourComplianceIndex from 'app/ComplianceLibrary/KnowYourCompliance/Index';
import TrackComplianceStatusIndex from 'app/ComplianceLibrary/TrackComplianceStatus/Index';
import AdditionalServices from 'app/AdditionalService/Index';
import Projects from 'app/Projects/Index';
import ProjectForm from 'app/Projects/ProjectForm';
import ProjectDetailsView from 'app/Projects/ProjectDetailsView';

import LegalUpdates from 'app/UpdateCenter/LegalUpdates/Index';
import LegalUpdatesUpload from 'app/UpdateCenter/LegalUpdates/LegalUpdatesUpload';
import ComplianceLegalUpdateDetails from 'app/UpdateCenter/LegalUpdates/ComplianceLegalUpdateDetails';
import ComplianceDetailsForm from 'app/ComplianceLibrary/ViewLawsCompliances/ComplianceDetailsForm';
import LegalUpdateForm from 'app/UpdateCenter/LegalUpdates/LegalUpdateForm';
import ReportIndex from 'app/ReportCertificates/Reports/Index';
import CertificateIndex from 'app/ReportCertificates/Certificates/Index';
import NotificationIndex from 'app/Notification/Index';
import NewsDetailsIndex from 'app/UpdateCenter/News/CommonDetailsIndex';
import News from 'app/UpdateCenter/News/CommonIndex';
import NewsForm from 'app/UpdateCenter/News/NewsForm';

import RelevantUpdateIndex from 'app/UpdateCenter/RelevantUpdates/Index';
import RelevantUpdateDetails from 'app/UpdateCenter/RelevantUpdates/RelevantUpdateDetails';
import NewAdditionIndex from 'app/UpdateCenter/NewAdditions/Index';
import LawDetailsForm from 'app/ComplianceLibrary/ViewLawsCompliances/LawDetailsForm';
import RelevantUpdateAnalysis from 'app/UpdateCenter/RelevantUpdates/RelevantUpdateAnalysis';
import CommonShowLawDetails from 'components/Application/CommonShowLawDetails';
import ViewEntityForm from 'app/Masters/Entity/ViewEntityForm';
import CommonEventTreeDetails from 'components/Application/CommonEventTreeDetails';

import FundMasterIndex from 'app/Funds/FundMaster/Index';
import Funds from 'app/Funds/Index';
import FundForm from 'app/Funds/FundForm';
import FundDetailsView from 'app/Funds/FundDetailsView';

import BlockEmailManage from 'app/UpdateCenter/BlockEmail/Index';

import SupportTicketsIndex from 'app/ServiceDesk/SupportTickets/MainTicketsIndex';
import CreateSupportTicket from 'app/ServiceDesk/SupportTickets/CreateSupportTicket';
import TicketDetails from 'app/ServiceDesk/SupportTickets/TicketDetails';
import LiteModule from 'app/Lite';
import PricingSelection from 'app/Lite/pricing';
import LiteCountryListModule from 'app/Lite/LiteCountryModule';
import LiteEntityAddModule from 'app/Lite/LiteEntityAddModule';
import LiteEntityEditModule from 'app/Lite/LiteEntityEditModule';

const routes = [
    // public Routes
    { path: '/login', component: Login, ispublic: true },
    { path: '/logout', component: Logout, ispublic: true },
    { path: '/forgot-password', component: ForgotPassword, ispublic: true },
    {
        path: '/reset-password/:token',
        component: ResetPassword,
        ispublic: true,
    },
    {
        path: '/activate-account/:token',
        component: ActivateAccount,
        ispublic: true,
    },
    { path: '/set-new-password', component: SetNewPassword, ispublic: true },

    {
        path: '/home',
        component: DashboardIndex,
        module: MODULE_DASHBOARD,
        permissions: [PERMISSION_VIEW],
    },
    {
        path: '/statutory-compliances/edit-law-details/:lawId',
        component: LawDetailsForm,
    },
    {
        path: '/statutory-compliances/edit-details/:complianceId',
        component: ComplianceDetailsForm,
    },
    {
        path: '/statutory-compliances/upload',
        component: UploadCompliance,
        module: MODULE_STATUTORY_LAW_COMPLIANCES,
        permissions: [PERMISSION_ADD, PERMISSION_IMPORT],
    },
    {
        path: '/statutory-compliances/view-law/:lawId/:activeTab',
        component: CommonShowLawDetails,
    },
    {
        path: '/statutory-compliances/view-law/:lawId',
        component: CommonShowLawDetails,
    },
    {
        path: '/statutory-compliances/view-details/:complianceId',
        component: CommonShowComplianceDetails,
    },
    {
        path: '/statutory-compliances/country-coverage',
        component: ComplianceCountryCoverage,
        module: MODULE_STATUTORY_COUNTRY_COVERAGE,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
        ],
    },
    {
        path: '/statutory-compliances/know-your-compliances',
        component: KnowYourComplianceIndex,
        module: MODULE_STATUTORY_KNOW_YOUR_COMPLIANCES,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
        ],
    },
    {
        path: '/statutory-compliances/track-compliance-status/from-dashboard/:filterType?/:selectedId?',
        component: TrackComplianceStatusIndex,
        module: MODULE_STATUTORY_TRACK_COMPLIANCES,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
        ],
    },
    {
        path: '/statutory-compliances/track-compliance-status',
        component: TrackComplianceStatusIndex,
        module: MODULE_STATUTORY_TRACK_COMPLIANCES,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
        ],
    },
    {
        path: '/statutory-compliances/from-dashboard/:openTab?/:filterType?/:selectedId?/:preSelectedId?',
        component: Compliance,
        module: MODULE_STATUTORY_LAW_COMPLIANCES,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
        ],
    },
    {
        path: '/statutory-compliances',
        component: Compliance,
        module: MODULE_STATUTORY_LAW_COMPLIANCES,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
        ],
    },
    {
        path: '/allocation/new',
        component: NewAllocationIndex,
        module: MODULE_NEW_ALLOCATION,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
        ],
    },
    {
        path: '/allocation/event',
        component: EventAllocationIndex,
        module: MODULE_EVENT_ALLOCATION,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
        ],
    },
    {
        path: '/allocations/list',
        component: ViewAllocationIndex,
        module: MODULE_VIEW_ALLOCATION,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
        ],
    },
    {
        path: '/allocations/users/from-dashboard/:filterType?/:selectedId?',
        component: UserAllocationIndex,
        module: MODULE_USER_ALLOCATION,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
        ],
    },
    {
        path: '/allocations/users',
        component: UserAllocationIndex,
        module: MODULE_USER_ALLOCATION,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
        ],
    },
    {
        path: '/allocations/status',
        component: TrackAllocationIndex,
        module: MODULE_NEW_ALLOCATION_STATUS,
        permissions: [PERMISSION_EDIT, PERMISSION_VIEW],
    },
    {
        path: '/allocations/review',
        component: AllocationReviewIndex,
        module: MODULE_ALLOCATION_REVIEW,
        permissions: [PERMISSION_EDIT, PERMISSION_VIEW],
    },
    {
        path: '/plan-events',
        component: AssignedEventIndex,
        module: MODULE_ASSIGNED_EVENTS,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
        ],
    },
    {
        path: '/plan-event/:countryId?/:eventId?',
        component: PlanEvent,
        module: MODULE_ASSIGNED_EVENTS,
        permissions: [PERMISSION_ADD, PERMISSION_EDIT, PERMISSION_VIEW],
    },
    {
        path: '/ongoing-events',
        component: OngoingEventIndex,
        module: MODULE_ONGOING_EVENTS,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
        ],
    },
    {
        path: '/closed-events',
        component: ExecutedEventIndex,
        module: MODULE_EXECUTED_EVENTS,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
        ],
    },

    // Masters
    {
        path: '/role-permission/manage/edit/:roleId',
        component: RolePermissionForm,
        module: MODULE_ROLE_PERMISSION,
        permissions: [PERMISSION_ADD, PERMISSION_EDIT, PERMISSION_DELETE],
    },
    {
        path: '/role-permission',
        component: RolePermissionIndex,
        module: MODULE_ROLE_PERMISSION,
        permissions: [PERMISSION_VIEW],
    },
    {
        path: '/users/from-dashboard/:filterType?/:companyGroupId?/:selectedId?',
        component: User,
        module: MODULE_USER,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
            PERMISSION_IMPORT,
        ],
    },
    {
        path: '/users',
        component: User,
        module: MODULE_USER,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
            PERMISSION_IMPORT,
        ],
    },
    {
        path: '/entities/manage/add',
        component: EntityForm,
        module: MODULE_ENTITY,
        permissions: [PERMISSION_ADD, PERMISSION_EDIT],
    },
    {
        path: '/entities/manage/edit/:entityId',
        component: EntityForm,
        module: MODULE_ENTITY,
        permissions: [PERMISSION_ADD, PERMISSION_EDIT],
    },

    {
        path: '/entities/manage/view/:entityId',
        component: ViewEntityForm,
        module: MODULE_ENTITY,
        permissions: [PERMISSION_ADD, PERMISSION_VIEW],
    },
    {
        path: '/entities',
        component: Entity,
        module: MODULE_ENTITY,
        permissions: [PERMISSION_VIEW],
    },

    {
        path: '/tenants/manage/add',
        component: TenantForm,
        module: MODULE_TENANT,
        permissions: [PERMISSION_ADD, PERMISSION_EDIT],
    },
    {
        path: '/tenants/manage/edit/:tenantId',
        component: TenantForm,
        module: MODULE_TENANT,
        permissions: [PERMISSION_ADD, PERMISSION_EDIT],
    },
    {
        path: '/tenants',
        component: Tenant,
        module: MODULE_TENANT,
        permissions: [PERMISSION_VIEW],
    },
    {
        path: '/departments',
        component: Department,
        module: MODULE_DEPARTMENT,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
            PERMISSION_IMPORT,
        ],
    },
    {
        path: '/entity-roles',
        component: EntityRole,
        module: MODULE_ENTITY_ROLE,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
            PERMISSION_IMPORT,
        ],
    },
    {
        path: '/entity-locations',
        component: EntityLocation,
        module: MODULE_ENTITY_LOCATION,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
            PERMISSION_IMPORT,
        ],
    },
    {
        path: '/company-groups',
        component: CompanyGroup,
        module: MODULE_COMPANY_GROUP,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
            PERMISSION_IMPORT,
        ],
    },
    {
        path: '/law-categories',
        component: LawCategory,
        module: MODULE_LAW_CATEGORY,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
            PERMISSION_IMPORT,
        ],
    },
    {
        path: '/laws/manage/add',
        component: LawForm,
        module: MODULE_LAW,
        permissions: [PERMISSION_ADD],
    },
    {
        path: '/laws/manage/edit/:lawId',
        component: LawForm,
        module: MODULE_LAW,
        permissions: [PERMISSION_EDIT],
    },
    {
        path: '/laws',
        component: Law,
        module: MODULE_LAW,
        permissions: [PERMISSION_VIEW, PERMISSION_DELETE],
    },
    {
        path: '/sections',
        component: Section,
        module: MODULE_LAW_SECTION_RULE,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
            PERMISSION_IMPORT,
        ],
    },
    {
        path: '/industries',
        component: Industry,
        module: MODULE_INDUSTRY,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
            PERMISSION_IMPORT,
        ],
    },
    {
        path: '/applicabilities',
        component: Applicability,
        module: MODULE_APPLICABILITY,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
            PERMISSION_IMPORT,
        ],
    },
    {
        path: '/countries',
        component: Country,
        module: MODULE_COUNTRY,
        permissions: [PERMISSION_VIEW],
    },
    {
        path: '/states',
        component: State,
        module: MODULE_STATE,
        permissions: [PERMISSION_VIEW],
    },
    {
        path: '/cities',
        component: City,
        module: MODULE_CITY,
        permissions: [PERMISSION_VIEW],
    },
    {
        path: '/events',
        component: Event,
        module: MODULE_EVENT_MASTER,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
            PERMISSION_IMPORT,
        ],
    },
    {
        path: '/event-compliances/:eventId',
        component: CommonEventTreeDetails,
        module: MODULE_STATUTORY_LAW_COMPLIANCES,
        permissions: [PERMISSION_VIEW],
    },
    {
        path: '/upload-masters/:type?',
        component: ImportMasterIndex,
        module: MODULE_EXCEL_UPLOAD,
        permissions: [PERMISSION_VIEW],
    },
    {
        path: '/reports-and-certificates/generate-certificate',
        component: CertificateIndex,
        module: MODULE_GENERATE_REPORT,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
        ],
    },
    {
        path: '/reports-and-certificates/generate-report',
        component: ReportIndex,
        module: MODULE_GENERATE_REPORT,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
        ],
    },
    {
        path: '/document-repository/standard',
        component: StandardDocumentRepositoryIndex,
        module: MODULE_STANDARD_DOCUMENTS,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
        ],
    },
    {
        path: '/document-repository/transactional',
        component: TransactionalDocumentRepositoryIndex,
        module: MODULE_TRANSACTIONAL_DOCUMENTS,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
        ],
    },
    {
        path: '/document-repository/archive-records/from-dashboard/:defaultDate?',
        component: ArchiveRecordsIndex,
        module: MODULE_ARCHIVE_RECORDS,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
        ],
    },
    {
        path: '/document-repository/archive-records',
        component: ArchiveRecordsIndex,
        module: MODULE_ARCHIVE_RECORDS,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
        ],
    },

    { path: '/compliances/report/:taskId', component: WorkItemTaskDetails },
    {
        path: '/compliances/report',
        component: WorkItemTaskIndex,
        module: MODULE_STATUTORY_REPORT_COMPLIANCES,
        permissions: [PERMISSION_ADD, PERMISSION_EDIT, PERMISSION_VIEW],
    },
    { path: '/compliances/review/:taskId', component: WorkItemTaskDetails },
    {
        path: '/compliances/review',
        component: WorkItemTaskIndex,
        module: MODULE_STATUTORY_REVIEW_COMPLIANCES,
        permissions: [PERMISSION_ADD, PERMISSION_EDIT, PERMISSION_VIEW],
    },
    { path: '/compliances/audit/:taskId', component: WorkItemTaskDetails },
    {
        path: '/compliances/audit',
        component: WorkItemTaskIndex,
        module: MODULE_STATUTORY_AUDIT_COMPLIANCES,
        permissions: [PERMISSION_ADD, PERMISSION_EDIT, PERMISSION_VIEW],
    },
    {
        path: '/system-configuration',
        component: SystemUserSetting,
        module: MODULE_SYSTEM_CONFIGURATION,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
        ],
    },

    // Profile
    { path: '/edit-profile', component: EditProfile },
    { path: '/change-password', component: ChangePassword },
    // {
    //     path: '/additional-services',
    //     component: AdditionalServices,
    //     module: MODULE_NEED_ADDITIONAL_SERVICES,
    //     permissions: [
    //         PERMISSION_ADD,
    //         PERMISSION_EDIT,
    //         PERMISSION_VIEW,
    //         PERMISSION_DELETE,
    //     ],
    // },

    // Coming soon
    { path: '/coming-soon', component: ComingSoon },

    {
        path: '/delegation',
        component: Delegation,
        module: MODULE_DELEGATION,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
        ],
    },
    {
        path: '/change-details',
        component: ChangeDetails,
        module: MODULE_CHANGE_DETAILS,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE,
        ],
    },

    { path: '/projects/manage/add', component: ProjectForm },
    { path: '/projects/manage/edit/:projectId', component: ProjectForm },
    {
        path: '/projects/manage/details/:projectId',
        component: ProjectDetailsView,
    },
    { path: '/projects', component: Projects },

    //Legal Updates
    {
        path: '/legal-update/edit/:legalUpdatesId',
        component: LegalUpdateForm,
        module: MODULE_LEGAL_UPDATES,
        permissions: [PERMISSION_ADD],
    },
    {
        path: '/legal-updates/upload',
        component: LegalUpdatesUpload,
        module: MODULE_LEGAL_UPDATES,
        permissions: [PERMISSION_ADD],
    },
    {
        path: '/legal-updates',
        component: LegalUpdates,
        module: MODULE_LEGAL_UPDATES,
        permissions: [PERMISSION_VIEW, PERMISSION_EDIT],
    },
    {
        path: '/legal-update/view-details/:id',
        component: ComplianceLegalUpdateDetails,
    },
    {
        path: '/legal-watch-dog/manage/add',
        component: NewsForm,
        module: MODULE_NEWS_ALERTS,
        permissions: [PERMISSION_ADD],
    },
    {
        path: '/legal-watch-dog/manage/edit/:newsId',
        component: NewsForm,
        module: MODULE_NEWS_ALERTS,
        permissions: [PERMISSION_EDIT],
    },
    {
        path: '/legal-watch-dog/view-details/:newsId',
        component: NewsDetailsIndex,
        module: MODULE_NEWS_ALERTS,
        permissions: [PERMISSION_VIEW],
    },
    {
        path: '/legal-watch-dog/from-dashboard/:defaultDate',
        component: News,
        module: MODULE_NEWS_ALERTS,
        permissions: [PERMISSION_VIEW, PERMISSION_DELETE],
    },

    {
        path: '/legal-watch-dog',
        component: News,
        module: MODULE_NEWS_ALERTS,
        permissions: [PERMISSION_VIEW, PERMISSION_DELETE],
    },
    {
        path: '/new-additions',
        component: NewAdditionIndex,
        module: MODULE_NEW_ADDITIONS,
        permissions: [PERMISSION_VIEW],
    },
    {
        path: '/relevant-updates/view-details/:updateId',
        component: RelevantUpdateDetails,
        module: MODULE_RELEVANT_UPDATES,
        permissions: [PERMISSION_VIEW],
    },
    {
        path: '/relevant-updates/view-analysis/:updateId',
        component: RelevantUpdateAnalysis,
        module: MODULE_RELEVANT_UPDATES,
        permissions: [PERMISSION_VIEW],
    },
    {
        path: '/view-update-analysis/:updateId',
        component: RelevantUpdateAnalysis,
        module: MODULE_LEGAL_UPDATES,
        permissions: [PERMISSION_VIEW],
    },
    {
        path: '/relevant-updates/from-dashboard/:type?/:filterOnValue?',
        component: RelevantUpdateIndex,
        module: MODULE_RELEVANT_UPDATES,
        permissions: [PERMISSION_VIEW],
    },
    {
        path: '/relevant-updates',
        component: RelevantUpdateIndex,
        module: MODULE_RELEVANT_UPDATES,
        permissions: [PERMISSION_VIEW],
    },
    {
        path: '/blocked-email',
        component: BlockEmailManage,
        module: MODULE_BLOCKED_EMAIL_MANAGEMENT,
        permissions: [
            PERMISSION_ADD,
            PERMISSION_EDIT,
            PERMISSION_VIEW,
            PERMISSION_DELETE
        ],
    },
    {
        path: '/notifications',
        component: NotificationIndex,
        module: MODULE_DASHBOARD,
        permissions: [PERMISSION_VIEW],
    },
    { path: '/funds/manage/add', component: FundForm },
    { path: '/funds/manage/edit/:fundId', component: FundForm },
    {
        path: '/funds/manage/details/:fundId',
        component: FundDetailsView,
    },
    { path: '/funds/master', component: FundMasterIndex },
    { path: '/funds', component: Funds },
    {
        path: '/support-tickets/details/:ticketId', component: TicketDetails,
        module: MODULE_RAISE_SUPPORT_TICKET,
        permissions: [PERMISSION_ADD, PERMISSION_VIEW, PERMISSION_EDIT]
    },
    {
        path: '/support-tickets/create', component: CreateSupportTicket,
        module: MODULE_RAISE_SUPPORT_TICKET,
        permissions: [PERMISSION_ADD, PERMISSION_EDIT]
    },
    {
        path: '/support-tickets', component: SupportTicketsIndex,
        module: MODULE_RAISE_SUPPORT_TICKET,
        permissions: [PERMISSION_VIEW]
    },
    {
        path: '/lite',
        component: LiteModule,
        module: MODULE_LITE,
        permissions: [PERMISSION_ADD, PERMISSION_EDIT, PERMISSION_VIEW, PERMISSION_DELETE]
    },
    {
        path: '/pricing',
        component: PricingSelection,
        module: MODULE_LITE,
        permissions: [PERMISSION_ADD, PERMISSION_EDIT, PERMISSION_VIEW, PERMISSION_DELETE]
    },
    {
        path: '/countries-list/entity/edit/:countriesId',
        component: LiteEntityEditModule,
        module: MODULE_LITE,
        permissions: [PERMISSION_ADD, PERMISSION_EDIT, PERMISSION_VIEW, PERMISSION_DELETE],
    },
    {
        path: '/countries-list/entity/:countriesId',
        component: LiteEntityAddModule,
        module: MODULE_LITE,
        permissions: [PERMISSION_ADD, PERMISSION_EDIT, PERMISSION_VIEW, PERMISSION_DELETE],
    },
    {
        path: '/countries-list',
        component: LiteCountryListModule,
        module: MODULE_LITE,
        permissions: [PERMISSION_ADD, PERMISSION_EDIT, PERMISSION_VIEW, PERMISSION_DELETE],
    },

];

export default routes;
