import React, { Component } from "react";
import { Button, Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import __t from "i18n/translator";

class LiteEntityEditModule extends Component {

    constructor(props) {
        super(props)
        this.state = {
            activeTab: "location1",
            pageHeaderName: __t('page_headers.lite_entity_details'),
        }
    }
    render() {
        return <div>
            <Card>
                <CardHeader>
                    <div className="page-header">

                        <h4 className="with-back-btn">
                            <a
                                href={() => false}
                                className="dripicons-arrow-thin-left"
                                onClick={() => {
                                    this.props.history.push(
                                        '/countries/entity/11'
                                    );
                                }}
                            >
                                { }
                            </a>
                            {this.state.pageHeaderName}
                        </h4>
                    </div>
                </CardHeader>
                <CardBody>
                    <Nav tabs className="nav-tabs-custom nav-justified">
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active:
                                        this.state.activeTab === 'location1',
                                })}
                                onClick={() => {
                                    this.setState({
                                        activeTab: "location1"
                                    })
                                }}

                            >
                                Location 1
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active:
                                        this.state.activeTab ===
                                        'location2',
                                })}
                                onClick={() => {
                                    this.setState({
                                        activeTab: "location2"
                                    })
                                }}
                            >
                                Location 2
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <Button>Edit</Button>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane className="laws_tab" tabId="location1">
                            <Row>
                                <Col lg="12">

                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane
                            className="laws_tab"
                            tabId="location2"
                        >
                            <Row>

                            </Row>
                        </TabPane>
                    </TabContent>
                </CardBody>
            </Card>

        </div>
    }
}

export default LiteEntityEditModule;