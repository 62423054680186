export const UPLOAD_INPROGRESS = 'INPROGRESS';
export const UPLOAD_COMPLETED = 'COMPLETED';
export const UPLOAD_ERROR = 'ERROR';

export const ACTIVITY_TYPE_SUBMITTED = 2; // Submitted
export const ACTIVITY_TYPE_AUDITED = 9; // Audit Recommandation
export const ACTIVITY_TYPE_DISCREPANCY = 20; // Audit Discrepancy

export const PENDING_FOR_COMPLETION = 1;
export const PENDING_WITH_FIRST_APPROVER = 2;
export const PENDING_WITH_SECOND_APPROVER = 3;
export const CLOSED = 4;
export const CLOSED_NAME = 'Closed';
export const REOPENED = 5;
export const PENDING_FOR_AUDIT = 6;
export const AUDITED_AND_CLOSED = 7;
export const SEND_BACK_TO_SUBMITTER = 8;
export const SEND_BACK_TO_APPROVER = 9;
export const SEND_BACK_BY_AUDITOR_TO_SUBMITTER = 10;
export const SEND_BACK_BY_AUDITOR_TO_APPROVER = 11;
export const SEND_BACK_BY_AUDITOR_TO_APPROVER2 = 12;
export const DISCREPANCY_PENDING_RESOLUTION = 13;
export const DISCREPANCY_ONGOING_RESOLUTION = 14;
export const DISCREPANCY_COMPLETED_RESOLUTION = 15;

export const SUBMITTER_COMPLETION_STATUS = 1;
export const SUBMITTER_COMPLETION_WITH_EXCEPTION_STATUS = 2;
export const SUBMITTER_DROP_STATUS = 3;

export const PENALIZED_TYPE_MONITORY = 1;
export const PENALIZED_TYPE_NON_MONITORY = 2;

export const EXPENSE_TYPE_NONE = 1;
export const EXPENSE_TYPE_STATUTORY_PAYMENT = 2;
export const EXPENSE_TYPE_PENALTY = 3;

export const DISCREPANCY_TYPE_MONETARY = 1;
export const DISCREPANCY_TYPE_NON_MONETARY = 2;
export const DISCREPANCY_TYPE_OTHERS = 3;

export const CHAT_MESSAGE_TYPE_MESSAGE = 1;
export const CHAT_MESSAGE_TYPE_ATTACHMENT = 2;
export const CHAT_MESSAGE_TYPE_ACTIVITY = 3;

export const ROLE_IT_ADMIN = 1;
export const ROLE_SUPER_ADMIN = 2;
export const ROLE_GLOBAL_COMPLIANCE_UPDATER = 3;
export const ROLE_COMPANY_GROUP_ADMIN = 4;
export const ROLE_ENTITY_ADMIN = 5;
export const ROLE_ENTITY_TEAM = 6;
export const ROLE_CONSULTANT_TEAM = 7;
export const ROLE_MANAGEMENT_TEAM = 8;
export const ROLE_FUNCTIONAL_HEAD = 9;
export const ROLE_TENANT_ADMIN = 15;

export const PERMISSION_ADD = 1;
export const PERMISSION_EDIT = 2;
export const PERMISSION_VIEW = 3;
export const PERMISSION_DELETE = 4;
export const PERMISSION_IMPORT = 5;

export const MODULE_DASHBOARD = 1;
export const MODULE_ROLE_PERMISSION = 2;
export const MODULE_USER = 3;
export const MODULE_COMPANY_GROUP = 4;
export const MODULE_ENTITY = 5;
export const MODULE_ENTITY_LOCATION = 6;
export const MODULE_ENTITY_ROLE = 7;
export const MODULE_LAW_CATEGORY = 8;
export const MODULE_LAW = 9;
export const MODULE_LAW_SECTION_RULE = 10;
export const MODULE_COUNTRY = 11;
export const MODULE_STATE = 12;
export const MODULE_CITY = 13;
export const MODULE_INDUSTRY = 14;
export const MODULE_DEPARTMENT = 15;
export const MODULE_EVENT_MASTER = 16;
export const MODULE_EXCEL_UPLOAD = 17;
export const MODULE_SYSTEM_CONFIGURATION = 18;
export const MODULE_STATUTORY_COUNTRY_COVERAGE = 19;
export const MODULE_STATUTORY_LAW_COMPLIANCES = 20;
export const MODULE_STATUTORY_KNOW_YOUR_COMPLIANCES = 21;
export const MODULE_STATUTORY_TRACK_COMPLIANCES = 22;
export const MODULE_STATUTORY_REPORT_COMPLIANCES = 23;
export const MODULE_STATUTORY_REVIEW_COMPLIANCES = 24;
export const MODULE_STATUTORY_AUDIT_COMPLIANCES = 25;
export const MODULE_NON_STATUTORY_VIEW_TASKS = 26;
export const MODULE_NON_STATUTORY_DESIGNATED_TASKS = 27;
export const MODULE_NON_STATUTORY_TRACK_TASK = 28;
export const MODULE_NON_STATUTORY_ADD_NEW_TASKS = 29;
export const MODULE_NON_STATUTORY_REPORT_TASKS = 30;
export const MODULE_NON_STATUTORY_REVIEW_TASKS = 31;
export const MODULE_NON_STATUTORY_AUDIT_TASKS = 32;
export const MODULE_NEW_ALLOCATION = 33;
export const MODULE_USER_ALLOCATION = 34;
export const MODULE_EVENT_ALLOCATION = 35;
export const MODULE_VIEW_ALLOCATION = 36;
export const MODULE_ASSIGNED_EVENTS = 37;
export const MODULE_ONGOING_EVENTS = 38;
export const MODULE_EXECUTED_EVENTS = 39;
export const MODULE_GENERATE_CERTIFICATES = 40;
export const MODULE_GENERATE_REPORT = 41;
export const MODULE_STANDARD_DOCUMENTS = 42;
export const MODULE_TRANSACTIONAL_DOCUMENTS = 43;
export const MODULE_ARCHIVE_RECORDS = 44;
export const MODULE_DELEGATION = 45;
export const MODULE_CHANGE_DETAILS = 46;
export const MODULE_RAISE_SUPPORT_TICKET = 47;
export const MODULE_USER_MANUAL_VIDEOS = 48;
export const MODULE_LEGAL_UPDATES = 49;
export const MODULE_NEWS_ALERTS = 50;
export const MODULE_RELEVANT_UPDATES = 51;
export const MODULE_ARCHIVED_DATA = 52;
export const MODULE_EVENTS_PODCAST = 53;
export const MODULE_NEED_ADDITIONAL_SERVICES = 54;
export const MODULE_STATUTORY_LAW_COMPLIANCES_UPLOAD = 55;
export const MODULE_NEW_ADDITIONS = 56;
export const MODULE_NEW_ALLOCATION_STATUS = 57;
export const MODULE_TENANT = 58;
export const MODULE_ALLOCATION_REVIEW = 59;
export const MODULE_BLOCKED_EMAIL_MANAGEMENT = 60;
export const MODULE_APPLICABILITY = 61;

export const MODULE_LITE_REGISTERED_CLIENTS = 62;

export const MODULE_LITE_CLIENT_ACCOUNT_DETAILS = 63;

export const MODULE_LITE_COMPLIANCE_DISCOVERY_DETAILS = 64;

export const MODULE_LITE_QUESTION_MASTER = 65;

export const MODULE_LITE = 66;



//Notification Types
export const CHAT_NOTIFICATION = 1;
export const CHAT_NOTIFICATION_TYPE = 'Chat';
export const PROJECT_NOTIFICATION = 2;
export const PROJECT_NOTIFICATION_TYPE = 'Project';
export const PROJECT_TASK_NOTIFICATION = 3;
export const PROJECT_TASK_NOTIFICATION_TYPE = 'ProjectTask';
export const LEGAL_UPDATE_NOTIFICATION = 4;
export const LEGAL_UPDATE_NOTIFICATION_TYPE = 'LegalUpdate';
export const TASK_NOTIFICATION = 5;
export const TASK_NOTIFICATION_TYPE = 'Task';
export const FUND_NOTIFICATION = 6;
export const FUND_NOTIFICATION_TYPE = 'Fund';
export const FUND_TASK_NOTIFICATION = 7;
export const FUND_TASK_NOTIFICATION_TYPE = 'FundTask';
export const RECENT_ACTIVITY_NOTIFICATION = 8;
export const RECENT_ACTIVITY_NOTIFICATION_TYPE = 'RecentActivity';
export const TICKET_NOTIFICATION = 9;
export const TICKET_NOTIFICATION_TYPE = 'Ticket';

export const DELEGATION_STATUS = 0;

export const PENDING = 1;
export const PENDING_WITH_APPROVER = 2;
export const APPROVED = 3;
export const ACKNOWLEDGE_BY_UPDATER = 4;
export const REJECTED_BY_APPROVER = 5;
export const REJECTED_BY_UPDATER = 6;
export const PENDING_WITH_SUPER_ADMIN = 7;
export const CONFIRMED = 8;
export const REJECTED_BY_SUPER_ADMIN = 9;

export const MANDATORY = 1;
export const TENTATIVE = 2;

export const RISK_RATING_CRITICAL = 1;
export const RISK_RATING_HIGH = 2;
export const RISK_RATING_MODERATE = 3;
export const RISK_RATING_LOW = 4;

export const COMPLIANCE_CATEGORY_ONE_TIME = 1;
export const COMPLIANCE_CATEGORY_EVENT_BASED = 2;
export const COMPLIANCE_CATEGORY_DUE_DATE = 3;
export const COMPLIANCE_CATEGORY_ON_GOING = 4;
export const COMPLIANCE_TYPE_LIFETIME = 13;
export const COMPLIANCE_TYPE_1 = 1;
export const COMPLIANCE_TYPE_2 = 2;
export const COMPLIANCE_TYPE_3 = 3;
export const COMPLIANCE_TYPE_4 = 4;
export const COMPLIANCE_TYPE_5 = 5;
export const COMPLIANCE_TYPE_6 = 6;
export const COMPLIANCE_TYPE_7 = 7;
export const COMPLIANCE_TYPE_8 = 8;
export const COMPLIANCE_TYPE_9 = 9;
export const COMPLIANCE_TYPE_10 = 10;
export const COMPLIANCE_TYPE_11 = 11;
export const COMPLIANCE_TYPE_12 = 12;
export const COMPLIANCE_TYPE_13 = 13;
export const COMPLIANCE_TYPE_14 = 14;
export const COMPLIANCE_TYPE_15 = 15;

export const APPROVAL_BASED_UPDATE = 4;
export const DEACTIVATION = 5;
export const NEW_ADDITIONS = 6;
export const HIGH_IMPACT_UPDATE = 4;
export const MODIFICATIONS_UPDATE = 1;
export const PROJECT_ACTIVITY_TYPE_COMMENT = 11;

export const HIGH_IMPACT_COMPLIANCE_CLASS = 5;
export const HIGH_IMPACT_COMPLIANCE_CLASSIFICATION = 6;
export const HIGH_IMPACT_FREQUENCY = 7;
export const HIGH_IMPACT_DUE_DATE_DESCRIPTION = 8;
export const HIGH_IMPACT_DUE_DATE_CALCULATION = 9;
export const HIGH_IMPACT_IF_EVENT_BASED = 10;
export const HIGH_IMPACT_TRIGGERING_COMPLIANCE = 11;
export const HIGH_IMPACT_EVENT_DUE_DATE_CALCULATION = 12;
export const DEACTIVATION_COMPLIANCE = 14;
export const SUB_UPDATE_DEFINITIONS = 19;
export const SUB_UPDATE_DOCUMENTS = 20;
export const DEACTIVATION_DEFINITION = 21;
export const DEACTIVATION_DOCUMENT = 22;

export const TRACK_STATUS_PENDING = 1;
export const TRACK_STATUS_APPROVED = 5;
export const TRACK_STATUS_REJECTED = 6;
export const TRACK_STATUS_ACKNOELEDGE = 2;
export const TRACK_STATUS_DECLINE = 3;
export const TRACK_STATUS_TBD = 4;

export const COMPLIANCE_TYPE_RENEWAL = 14;

export const FUND_ACTIVITY_TYPE_COMMENT = 11;
export const LOCAL_FILE_STORAGE = 'LOCAL';
export const AWS_S3_FILE_STORAGE = 'S3';

export const TICKET_OPEN = 1;
export const TICKET_IN_PROGRESS = 2;
export const TICKET_CLOSE = 3;
export const TICKET_ON_HOLD = 4;
export const TICKET_REOPEN = 5;
export const TICKET_PENDING_TYPE = 1;
export const TICKET_ALL_TYPE = 2;
export const TICKET_UN_RESPONDED_TYPE = 3;
export const TICKET_MY_TYPE = 4;

export const TICKET_HISTORY_TYPE_ACTIVITY = 1;
export const TICKET_HISTORY_TYPE_MESSAGE = 2;
export const TICKET_HISTORY_TYPE_ATTACHMENT = 3;

export const PROJECT_TASK_SUBMITTER = 1;
export const PROJECT_TASK_REVIEWER = 2;
export const PROJECT_TASK_APPROVER = 3;

export const LITE_ACCOUNT_PENDING = 0;
export const LITE_ACCOUNT_APPROVED = 1;
export const LITE_ACCOUNT_REJECTED = 2;
