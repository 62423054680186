import produce from 'immer';
import { ADD_ENTITY_GROUP_RESPONSE, CLEAR_COMPANY_GROUP_LIST_RESPONSE, DELETE_ENTITY_GROUP_BY_ENTITY_GROUP_ID_RESPONSE, GET_ALL_COMPANY_GROUPS_LIST_ON_USER_LIST_PAGE_RESPONSE, GET_ALL_ENTITY_GROUP_LIST_RESPONSE, GET_ENTITY_GROUP_BY_ENTITY_GROUP_ID_RESPONSE, GET_ENTITY_GROUP_LIST_BY_STATUS_RESPONSE, UPDATE_ENTITY_GROUP_RESPONSE, UPDATE_ENTITY_GROUP_STATUS_BY_ENTITY_GROUP_ID_RESPONSE } from './actionTypes';
export const initialState = {
    companyGroupApiResponse: null,
    editCompanyGroupApiResponse: null,
    companyGroupList: [],
    companyGroupListOnUserList: []
};
const companyGroup = (state = initialState, action = '') =>
    produce(state, draft => {
        switch (action.type) {
            case ADD_ENTITY_GROUP_RESPONSE:
            case UPDATE_ENTITY_GROUP_RESPONSE:
            case UPDATE_ENTITY_GROUP_STATUS_BY_ENTITY_GROUP_ID_RESPONSE:
            case DELETE_ENTITY_GROUP_BY_ENTITY_GROUP_ID_RESPONSE:
                draft.companyGroupApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ALL_ENTITY_GROUP_LIST_RESPONSE:
                draft.companyGroupList = action.payload.companyGroupList;
                break;
            case GET_ENTITY_GROUP_BY_ENTITY_GROUP_ID_RESPONSE:
                draft.editCompanyGroupApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ENTITY_GROUP_LIST_BY_STATUS_RESPONSE:
                draft.companyGroupList = action.payload.companyGroupList;
                break;
            case CLEAR_COMPANY_GROUP_LIST_RESPONSE:
                draft.companyGroupList = []
                break;
            case GET_ALL_COMPANY_GROUPS_LIST_ON_USER_LIST_PAGE_RESPONSE:
                draft.companyGroupListOnUserList = action.payload.companyGroupListOnUserList;
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default companyGroup;
