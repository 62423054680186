import React, { Component } from 'react'
import { Button, Col, Form, FormGroup, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import VerifiedAccount from './components/VerifiedAccount';
import UnVerifiedAccount from './components/unVerifiedAccount';
import { getValueByKey } from 'helpers/authUtils';
import { MODULE_LITE_REGISTERED_CLIENTS, PERMISSION_EDIT, ROLE_SUPER_ADMIN } from 'constants/databaseConstants';
import * as authConstants from 'constants/authConstants';
import { checkModulePermission, getLiteClientAccountStatus } from 'helpers/projectUtils';
import CustomisedModal from 'components/Application/CustomisedModal';
import { Field, Formik, isEmptyArray } from 'formik';
import EditIcon from './Svg/EditIcon';
import DoneIcon from './Svg/DoneIcon';
import ResendEmailIcon from './Svg/ResendEmailIcon';
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import __t from 'i18n/translator';
import SimpleReactValidator from 'simple-react-validator';
import * as yup from "yup";
import { DANGER, DEFAULT_PAGE_LENGTH, SUCCESS } from 'constants/commonConstants';

class RegisteredAccounts extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            activeTab: 'verified_account',
            filterAccountApi: true,
            registrationClientList: [],
            registrationClientTable: [],
            modal: false,
            modalData: null,
            pageSize: DEFAULT_PAGE_LENGTH,
            pageCount: 0,
            sortBy: {
                key: 'createdAt',
                value: 'DESC',
            },
            currentPage: 1,
            resetTableSection: false,
            totalRecords: 0,
            searchFormData: {}
        }
        this.handleFetchData = this.handleFetchData.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.getStatusChangeFilter = this.getStatusChangeFilter.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount() {
        const getRole = getValueByKey(authConstants.CURRENT_ROLE);
        if (getRole !== ROLE_SUPER_ADMIN.toString()) {
            window.location.pathname = '/home';
        }
        this.getRegisterClientListSearchList(this.state.pageSize,
            this.state.currentPage,
            this.state.sortBy, { ...this.state.searchFormData, isEmailVerified: this.state.filterAccountApi })
    }
    getRegisterClientListSearchList = (limit, page, sortBy, values = {}) => {
        let apiData = {
            limit: limit,
            page: page,
            filters: values,
            orderBy: sortBy,
        };
        this.props.getClientRegistrationRequest(apiData);
    };
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState(
                {
                    activeTab: tab,
                    searchFormData: {
                        ...this.state.searchFormData,
                        isEmailVerified: tab === "verified_account" ? true : false
                    },
                },
                () => {
                    switch (tab) {
                        case 'verified_account':
                            this.getRegisterClientListSearchList(this.state.pageSize,
                                this.state.currentPage,
                                this.state.sortBy, {
                                ...this.state.searchFormData,
                                isEmailVerified: true
                            })
                            break;
                        case 'unverified_account':
                            this.getRegisterClientListSearchList(this.state.pageSize,
                                this.state.currentPage,
                                this.state.sortBy, {
                                ...this.state.searchFormData,
                                isEmailVerified: false
                            })
                            break;
                        default:
                            break;
                    }
                }
            );
        }
    }

    async statusUpdateClient(user_id, status) {
        if (status == 2) {
            const response = await commonConfirmBox(
                __t('common.are_you_sure'),
                status === true
                    ? __t('alerts.  ')
                    : __t('alerts.lite_client_rejection')
            );
            if (response) {
                this.props.putStatusUpdateClientRequest(
                    {
                        user_id,
                        status
                    }
                );
            }
        } else {
            const response = await commonConfirmBox(
                __t('common.are_you_sure'),
                status === true
                    ? __t('alerts.  ')
                    : __t('alerts.lite_client_accept')
            );
            if (response) {
                this.props.putStatusUpdateClientRequest(
                    {
                        user_id,
                        status
                    }
                );
            }
        }
    }
    generateTable = (records) => {
        let clientRegistration = [];
        clientRegistration = records.data.map((clientdata, i) => {
            const { statusClassName, statusName } = getLiteClientAccountStatus(clientdata.clientStatus);
            return {
                userFullName: clientdata.userFullName,
                accountName: clientdata.accountName,
                emailId: clientdata.emailId,
                createdAt: clientdata.createdAt,
                mobileNo: clientdata.mobileNo,
                companyFullName: clientdata.companyFullName,
                companyWebsite: <a href={`${clientdata.companyWebsite}`} target='_blank' className="text-decoration-underline">{clientdata.accountName}</a>,
                clientStatus: <span className='ticket-status'>
                    <span className={statusClassName}>{statusName}</span>
                </span>,
                actions: <>
                    {
                        checkModulePermission(
                            MODULE_LITE_REGISTERED_CLIENTS,
                            PERMISSION_EDIT
                        ) === true ? (clientdata.clientStatus == 1 || clientdata.clientStatus == 2) ? null : <div className='tbl_action'>
                            <Button onClick={() => this.statusUpdateClient(clientdata.id, 2)} color="danger" type="button" size="sm" className='d-flex align-items-center py-1 mr-2'><svg className='flex-none mr-1' width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.2306 0.340104C12.1229 0.232283 11.9949 0.14675 11.8541 0.0883933C11.7133 0.0300367 11.5623 0 11.4099 0C11.2575 0 11.1065 0.0300367 10.9657 0.0883933C10.8249 0.14675 10.6969 0.232283 10.5892 0.340104L6.28528 4.64556L1.98137 0.340878C1.76371 0.123211 1.46849 0.000927646 1.16066 0.000927649C0.852835 0.000927651 0.557617 0.123211 0.33995 0.340878C0.122284 0.558544 2.29349e-09 0.853763 0 1.16159C-2.29349e-09 1.46942 0.122284 1.76464 0.33995 1.9823L4.6454 6.28543L0.340723 10.5893C0.232946 10.6971 0.147452 10.8251 0.0891236 10.9659C0.0307949 11.1067 0.000773471 11.2576 0.000773471 11.41C0.000773471 11.7179 0.123057 12.0131 0.340723 12.2308C0.55839 12.4484 0.853609 12.5707 1.16144 12.5707C1.46926 12.5707 1.76448 12.4484 1.98215 12.2308L6.28528 7.92531L10.5892 12.23C10.8068 12.4477 11.1021 12.5699 11.4099 12.5699C11.7177 12.5699 12.0129 12.4477 12.2306 12.23C12.4483 12.0123 12.5706 11.7171 12.5706 11.4093C12.5706 11.1014 12.4483 10.8062 12.2306 10.5886L7.92516 6.28543L12.2298 1.98153C12.3377 1.87378 12.4232 1.74583 12.4815 1.60501C12.5399 1.46419 12.5699 1.31325 12.5699 1.16082C12.5699 1.00838 12.5399 0.857442 12.4815 0.716621C12.4232 0.575799 12.3377 0.447857 12.2298 0.340104" fill="currentColor" /></svg> Reject</Button>
                            <Button onClick={() => this.statusUpdateClient(clientdata.id, 1)} color="primary" type="button" size="sm" className='d-flex align-items-center py-1'><svg className='flex-none mr-1' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.922 9.39123C15.3282 12.36 13.0898 15.1554 9.94886 15.78C8.41698 16.0851 6.82789 15.899 5.40787 15.2484C3.98785 14.5978 2.80928 13.5158 2.03999 12.1564C1.2707 10.797 0.949892 9.2296 1.12326 7.67728C1.29662 6.12496 1.95532 4.66691 3.00555 3.51073C5.15968 1.1381 8.79699 0.484979 11.7657 1.67248" stroke="currentColor" stroke-width="1.78125" stroke-linecap="round" stroke-linejoin="round" /><path d="M5.82812 8.20312L8.79688 11.1719L15.9219 3.45312" stroke="currentColor" stroke-width="1.78125" stroke-linecap="round" stroke-linejoin="round" /></svg> Accept</Button>
                        </div> : null

                    }
                </>
            };
        });
        return clientRegistration;
    };

    generateTableUnverified = (records) => {
        let clientRegistration = [];
        clientRegistration = records.data.map((clientdata, i) => {
            return {
                userFullName: clientdata.userFullName,
                accountName: clientdata.accountName,
                emailId: clientdata.emailId,
                createdAt: clientdata.createdAt,
                mobileNo: clientdata.mobileNo,
                companyFullName: clientdata.companyFullName,
                companyWebsite: <a href={`${clientdata.companyWebsite}`} target='_blank' className="text-decoration-underline">{clientdata.accountName}</a>,
                actions: <>
                    {

                        checkModulePermission(
                            MODULE_LITE_REGISTERED_CLIENTS,
                            PERMISSION_EDIT
                        ) === true ? <div className='tbl_action'>
                            <Button color="primary" size="sm" title='Resend Email' className='d-flex align-items-center rounded-circle p-1 mr-2' onClick={() => {
                                this.setState({
                                    modal: true,
                                    modalData: {
                                        email_id: clientdata.emailId,
                                        isEdit: false,
                                        user_id: clientdata.id,
                                        verification_type: clientdata.verificationType
                                    }
                                })
                            }}>
                                <ResendEmailIcon />
                            </Button>
                        </div> : null
                    }
                </>
            };
        });
        return clientRegistration;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.registrationClient &&
            this.props.registrationClient !== nextprops.registrationClient && this.state.activeTab == "verified_account"
        ) {
            this.setState({
                registrationClientList: this.props.registrationClient,
                registrationClientTable: this.generateTable(nextprops.registrationClient),
                pageCount: nextprops.registrationClient.totalPages,
                currentPage:
                    nextprops.registrationClient.currentPage,
                totalRecords:
                    nextprops.registrationClient.totalRecords,
            });
        }
        if (
            nextprops.statusUpdate &&
            this.props.statusUpdate !== nextprops.statusUpdate && this.state.activeTab == "verified_account"
        ) {
            this.getRegisterClientListSearchList(
                this.state.pageSize,
                this.state.currentPage,
                this.state.sortBy, {
                ...this.state.searchFormData,
                isEmailVerified: this.state.activeTab == "verified_account" ? true : false
            })
        }
        if (
            nextprops.registrationClient &&
            this.props.registrationClient !== nextprops.registrationClient && this.state.activeTab == "unverified_account"
        ) {
            this.setState({
                registrationClientList: this.props.registrationClient,
                registrationClientTable: this.generateTableUnverified(nextprops.registrationClient),
                pageCount: nextprops.registrationClient.totalPages,
                currentPage:
                    nextprops.registrationClient.currentPage,
                totalRecords:
                    nextprops.registrationClient.totalRecords,
            });
        }


        if (
            nextprops.resendEmail &&
            this.props.resendEmail !==
            nextprops.resendEmail
        ) {
            toastrMessage(
                nextprops.resendEmail.message,
                nextprops.resendEmail.responseType ? SUCCESS : DANGER
            );
            if (nextprops.resendEmail.responseType) {
                this.setState({
                    modal: false,
                    modalData: null
                })
            }
            this.getRegisterClientListSearchList(
                this.state.pageSize,
                this.state.currentPage,
                this.state.sortBy, {
                ...this.state.searchFormData,
            })
        }
    }
    onModalDismiss() {
        this.setState({
            modal: false
        })
    }
    formSubmit = () => {
        if (this.validator.allValid()) {
            this.props.putResendEmailRequest({
                user_id: this.state.modalData.user_id,
                verification_type: this.state.modalData.verification_type,
                email_id: this.state.modalData.email_id
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }


    handleFetchData(data) {
        if (
            data.pageIndex + 1 !== this.state.currentPage ||
            this.state.pageSize !== data.pageSize
        ) {
            this.setState(
                {
                    pageSize: data.pageSize,
                    searchFormData: {
                        ...this.state.searchFormData,
                        searchText: data.globalFilter,
                        isEmailVerified: this.state.activeTab == "verified_account" ? true : false,
                        clientStatus: this.state.searchFormData.clientStatus ?? null
                    },
                },
                () => {
                    this.getRegisterClientListSearchList(
                        data.pageSize,
                        data.pageIndex + 1,
                        this.state.sortBy,
                        this.state.searchFormData
                    );
                }
            );
        }
    }
    handleSearch(data) {
        this.setState(
            {
                searchFormData: {
                    ...this.state.searchFormData,
                    searchText: data.globalFilter,
                    isEmailVerified: this.state.activeTab == "verified_account" ? true : false,
                },
            },
            () => {
                this.getRegisterClientListSearchList(
                    this.state.pageSize,
                    this.state.currentPage,
                    this.state.sortBy,
                    this.state.searchFormData
                );
            }
        );
    }
    handleSort(data) {
        if (
            data.sortBy[0]?.desc != undefined &&
            data.sortBy[0]?.desc !== this.state.sortBy?.value
        ) {
            let sortOrder = data.sortBy[0]?.desc ? 'DESC' : 'ASC';
            this.setState(
                {
                    pageSize: data.pageSize,
                    searchFormData: {
                        ...this.state.searchFormData,
                        searchText: data.globalFilter,
                        isEmailVerified: this.state.activeTab == "verified_account" ? true : false,
                    },
                    sortBy: { key: data.sortBy[0]?.id, value: sortOrder },
                },
                () => {
                    this.getRegisterClientListSearchList(data.pageSize, data.pageIndex + 1, { key: data.sortBy[0]?.id, value: sortOrder }, this.state.searchFormData)
                }
            );
        }
    }
    getStatusChangeFilter(data) {
        this.setState({
            searchFormData: {
                ...this.state.searchFormData,
                isEmailVerified: this.state.activeTab == "verified_account" ? true : false,
                clientStatus: data.filters.clientStatus ?? null
            }
        }, () => {

            this.getRegisterClientListSearchList(
                this.state.pageSize,
                this.state.currentPage,
                this.state.sortBy,
                {
                    ...this.state.searchFormData
                }
            );
        })
    }

    render() {
        return <div>
            <React.Fragment>
                <Row id={"TabelView"}>
                    <Col lg={12} className="viewlaws_tabs fix-header-table">
                        <Nav tabs className="nav-tabs-custom nav-justified">
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            this.state.activeTab === 'verified_account',
                                    })}
                                    onClick={() => {
                                        this.setState(() => {
                                            this.toggle('verified_account');
                                        });
                                    }}
                                >
                                    {__t("lite.verified_accounts")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            this.state.activeTab ===
                                            'unverified_account',
                                    })}
                                    onClick={() => {
                                        this.setState(() => {
                                            this.toggle('unverified_account');
                                        });
                                    }}
                                >
                                    {__t("lite.unverified_accounts")}
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane className="laws_tab" tabId="verified_account">
                                <VerifiedAccount currentPage={this.state.currentPage} pageCount={this.state.pageCount} totalRecords={this.state.totalRecords} getStatusChangeFilter={this.getStatusChangeFilter} searchFormData={this.state.searchFormData} handleSort={this.handleSort} handleFetchData={this.handleFetchData} handleSearch={this.handleSearch} data={this.state.registrationClientTable} getClientRegistrationRequest={this.props.getClientRegistrationRequest} filterAccountApi={this.state.filterAccountApi} activeTab={this.state.activeTab} />
                            </TabPane>
                            <TabPane
                                className="laws_tab"
                                tabId="unverified_account"
                            >
                                <UnVerifiedAccount currentPage={this.state.currentPage} pageCount={this.state.pageCount} totalRecords={this.state.totalRecords} getStatusChangeFilter={this.getStatusChangeFilter} searchFormData={this.state.searchFormData} handleSort={this.handleSort} handleFetchData={this.handleFetchData} handleSearch={this.handleSearch} data={this.state.registrationClientTable} />
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
            </React.Fragment>
            {
                checkModulePermission(
                    MODULE_LITE_REGISTERED_CLIENTS,
                    PERMISSION_EDIT
                ) === true ? <CustomisedModal
                    modalSize={'modal-md resend-modal'}
                    modalName={"Resend Email"}
                    isModalOpen={this.state.modal}
                    onModalDismiss={() => this.onModalDismiss()}>
                    <div>
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="11" cy="11" r="10" stroke="currentColor" stroke-width="1.5" /><path d="M11 6V12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" /><circle cx="11" cy="15" r="1" fill="currentColor" /></svg>
                        <h3>{__t("lite.modal_title")}</h3>
                        <p>{__t("lite.modal_description")} <br /> {__t("lite.modal_description_secondary")}</p>
                        <Formik initialValues={{ ...this.state.modalData }} validationSchema={yup.object({
                            email_id: yup.string().email(__t("validations.custom.login.email_email")).required(__t("validations.custom.login.email_required")),
                        })} enableReinitialize={true} onSubmit={this.formSubmit}>
                            {({ values, setFieldValue, handleSubmit, errors }) => (
                                <Form onSubmit={handleSubmit}>
                                    <FormGroup>
                                        <Field
                                            autoFocus
                                            name="email_id"
                                            className="form-control"
                                            type="text"
                                            disabled={!values.isEdit}
                                            value={values.email_id}
                                            placeholder={__t("form_labels.login.enter_email")}
                                            onChange={(event) => {
                                                setFieldValue("email_id", event.target.value)
                                                this.setState({
                                                    modalData: {
                                                        ...this.state.modalData,
                                                        email_id: event.target.value
                                                    }
                                                })
                                            }}
                                        />
                                        <Label className='form-label' for="email">  {__t(
                                            'form_labels.login.email'
                                        )}</Label>
                                        <Button type="button" color='link' className='edit-email' title='Edit email id' onClick={() => {
                                            if (values.isEdit) {
                                                this.setState({
                                                    modalData: {
                                                        ...this.state.modalData,
                                                        isEdit: false
                                                    }
                                                })
                                            } else {
                                                this.setState({
                                                    modalData: {
                                                        ...this.state.modalData,
                                                        isEdit: true
                                                    }
                                                })
                                            }
                                        }}>
                                            {!values.isEdit ? <DoneIcon /> :
                                                <EditIcon />
                                            }
                                        </Button>
                                        {
                                            errors.email_id && <small className='text-lg text-red'>{errors.email_id}</small>
                                        }
                                    </FormGroup>
                                    {
                                        values.isEdit ? <FormGroup className="sticky_label radio_btn litigation_radio_btn justify-content-center mb-3">
                                            <div className="form-check form-check-inline">
                                                <Field
                                                    className="form-check-input mr-2"
                                                    type="radio"
                                                    name="matterInLitigation"
                                                    id="verified_url"
                                                    value={"0"}
                                                    checked={values.verification_type == 1}
                                                    onChange={(value) => {
                                                        setFieldValue("verification_type", 1)
                                                        this.setState({
                                                            modalData: {
                                                                ...this.state.modalData,
                                                                verification_type: 1
                                                            }
                                                        })
                                                    }}
                                                />
                                                <Label for="verified_url" className="form-check-label ml-1 d-flex flex-column align-items-start">
                                                    {__t("lite.verify_url")}
                                                    <small className="">
                                                        {__t("lite.link_with_no_expiry")}
                                                    </small>
                                                </Label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Field
                                                    className="form-check-input mr-2"
                                                    type="radio"
                                                    name="matterInLitigation"
                                                    id="verified_url1"
                                                    value={"1"}
                                                    checked={values.verification_type == 0}
                                                    onChange={(value) => {
                                                        setFieldValue("verification_type", 0)
                                                        this.setState({
                                                            modalData: {
                                                                ...this.state.modalData,
                                                                verification_type: 0
                                                            }
                                                        })
                                                    }}
                                                />
                                                <Label for="verified_url1" className="form-check-label ml-1 d-flex flex-column align-items-start">
                                                    {__t('lite.verify_otp')}
                                                    <small className="">
                                                        {__t("lite.expiry_timer_otp")}
                                                    </small>
                                                </Label>
                                            </div>
                                        </FormGroup> : null
                                    }
                                    <FormGroup className="w-100 d-flex justify-content-center mb-0">
                                        <Button
                                            type="button"
                                            color="secondary"
                                            className='mr-1'
                                            onClick={() => {
                                                this.setState({
                                                    modal: false
                                                })
                                            }}
                                        >{__t("form_labels.reporting.close")}</Button>
                                        <Button
                                            type='submit'
                                            color="primary"
                                            className="ml-1"
                                        >
                                            {__t("form_labels.reporting.send")}
                                        </Button>
                                    </FormGroup>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </CustomisedModal> : null
            }

        </div>
    }
}

export default RegisteredAccounts;