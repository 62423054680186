import { createSelector } from 'reselect';
import { initialState } from './reducer';
const reducerState = state => state.pricing || initialState;
const makeLitePricingSelectField = field => {
    return createSelector(reducerState, pricingListState => pricingListState[field]);
}


export {
    makeLitePricingSelectField,
};
