import { setEmptyToNull } from 'helpers/generalUtils';
import { isManagementRole } from 'helpers/projectUtils';
export const listColumns = [
    {
        label: 'Code',
        field: 'taskCode',
    },
    {
        label: 'Entity',
        field: 'entityName',
    },
    {
        label: 'Location',
        field: 'cityName',
    },
    {
        label: 'Compliance Code',
        field: 'complianceCode',
    },
    {
        label: 'Title',
        field: 'complianceTitle',
        attributes: {
            className: 'grid_title sorting',
        },
    },
    {
        label: 'Classification',
        field: 'complianceClass',
    },
    {
        label: 'Due Date',
        field: 'dueDate',
    },
    {
        label: 'Task Type',
        field: 'parentTaskId',
    },
    {
        label: 'Role',
        field: 'role',
    },
    {
        label: 'Status',
        field: 'taskStatusId'
    },
    {
        label: 'Action',
        // attributes: {
        //     className: 'action_col',
        // },
        field: 'actions',
        sort: 'disabled',
    }
];
export const initialChangeDetailsFilterDataState = () => {
    return {
        companyGroupId: [],
        entityId: [],
        countryId: [],
        stateId: [],
        cityId: [],
        statusId: [],
        isManagementUser: isManagementRole(),
        searchText: '',
    };
};
export const intFields = [];
export const nullableFields = ['searchText'];
export const formatValuesForApi = (values) => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};
