import injectReducer from 'helpers/injectReducer';
import injectSaga from 'helpers/injectSaga';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { getLiteCountriesEntityRequest } from 'store/Lite/actions';
import RegisteredAccount from 'store/Lite/reducer';
import LiteRegistrationSaga from 'store/Lite/saga';
import { selectSortedCityList } from 'store/Masters/City/selector';
import { selectSortedIndustryList } from 'store/Masters/Industry/selector';
import { selectSortedSubIndustryList } from 'store/Masters/SubIndustry/selector';
import { selectSortedEntityList } from 'store/Entity/selector';
import { getAllEntityTypeListRequest, getAllFacilityTypeListRequest, getCityListByStateRequest, getCountryListByStatusRequest, getEntityListByCompanyGroupIdRequest, getIndustryListByStatusRequest, getStateListByCountryRequest, getSubIndustryListByIndustryRequest } from 'store/actions';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import { selectSortedStateList } from 'store/Masters/State/selector';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import industryReducer from 'store/Masters/Industry/reducer';
import industrySaga from 'store/Masters/Industry/saga';
import subIndustryReducer from 'store/Masters/SubIndustry/reducer';
import { ACTIVE, DATE_FORMAT_DATEPICKER } from 'constants/commonConstants';
import { Row, FormGroup, Label, Button } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import Select from 'react-select';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import { Option, MultiValue, animatedComponents } from 'helpers/projectUtils';
import __t from 'i18n/translator';
import subIndustrySaga from 'store/Masters/SubIndustry/saga';
import SimpleReactValidator from 'simple-react-validator';
import lawSaga from 'store/Masters/Law/saga';
import lawReducer from 'store/Masters/Law/reducer';
import { selectSortedEntityTypeList, selectSortedFacilityTypeList } from 'store/Masters/Law/selector';
import DatePicker from 'react-multi-date-picker';
import CreatableSelect from 'react-select/creatable';
import * as Yup from "yup";

const countriesEntityList = 'RegisteredAccount';
const withLiteCountriesListReducer = injectReducer({
    key: countriesEntityList,
    reducer: RegisteredAccount,
});
const withLiteCountriesListSaga = injectSaga({ key: countriesEntityList, saga: LiteRegistrationSaga });

const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const industryKey = 'industry';
const withIndustryReducer = injectReducer({
    key: industryKey,
    reducer: industryReducer,
});
const withIndustrySaga = injectSaga({ key: industryKey, saga: industrySaga });
const subIndustryKey = 'subIndustry';
const withSubIndustryReducer = injectReducer({
    key: subIndustryKey,
    reducer: subIndustryReducer,
});
const withSubIndustrySaga = injectSaga({
    key: subIndustryKey,
    saga: subIndustrySaga,
});
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });
const lawKey = 'law';
const withLawReducer = injectReducer({
    key: lawKey,
    reducer: lawReducer,
});
const withLawSaga = injectSaga({ key: lawKey, saga: lawSaga });
class LiteEntityAddModule extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            sortedSubIndustryListDropdown: []
        }
    }
    componentDidMount() {
        this.props.getLiteCountriesEntityRequest();
        // this.props.getCountryListByStatusRequest(ACTIVE);
        this.props.getStateListByCountryRequest((this.props?.match?.params?.countriesId) ? [this.props?.match?.params?.countriesId] : []);
        this.props.getIndustryListByStatusRequest(ACTIVE);
        this.props.getAllEntityTypeListRequest();
        this.props.getAllFacilityTypeListRequest();
        this.props.getEntityListByCompanyGroupIdRequest([2])
    }
    getStateListByCountry(countryIds) {
        this.props.getStateListByCountryRequest((countryIds) ? countryIds : []);
    }
    getCityListByState(stateIds) {
        this.props.getCityListByStateRequest((stateIds) ? stateIds : []);
    }
    getLawsByFilters(values) {
        let apiData = {
            limit: -1,
            page: -1,
            filter: {
                countryId: (values.countryId) ? values.countryId : [],
                stateId: (values.stateId) ? values.stateId : [],
                cityId: (values.cityId) ? values.cityId : [],
                industryId: (values.industryId) ? [values.industryId] : [],
                subIndustryId: (values.subIndustryId) ? [values.subIndustryId] : [],
                lawCategoryId: (values.lawCategoryId) ? values.lawCategoryId : [],
                type: this.props.pageType,
                isActive: true
            },
            orderBy: {}
        }
        this.props.getLawListRequest(apiData, false);
    }

    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.sortedSubIndustryListDropdown && nextprops.sortedSubIndustryListDropdown !== this.props.sortedSubIndustryListDropdown) {
            this.setState({
                sortedSubIndustryListDropdown: nextprops.sortedSubIndustryListDropdown
            })
        }
    }
    formSubmit() {

    }
    render() {
        return <div className='d-flex flex-column'>
            <Formik
                initialValues={{
                    industryId: [],
                    subIndustryId: [],
                    entityId: "",
                    countryId: this.props?.match?.params?.countriesId ?? "",
                    financial_year_start: "",
                    financial_year_end: "",
                    stateId: [],
                    cityId: [],
                    entityTypeId: [],
                    managementUser: ""
                }}
                validationSchema={Yup.object({
                    industryId: Yup.array().of(Yup.string()).min(1, __t("lite.entity.industry")).required(__t("lite.entity.industry")),
                    subIndustryId: Yup.array().of(Yup.string()).min(1, __t("lite.entity.subIndustry")).required(__t("lite.entity.subIndustry")),
                    entityId: Yup.string().required(__t("lite.entity.entity_name")),
                    financial_year_start: Yup.string().required(__t("lite.entity.financial_year_start")),
                    financial_year_end: Yup.string().required(__t("lite.entity.financial_year_end")),
                    managementUser: Yup.string().required(__t("lite.entity.management_user")),
                    stateId: Yup.array().of(Yup.string()).min(1, __t("lite.entity.state")).required(__t("lite.entity.state")),
                    cityId: Yup.array().of(Yup.string()).min(1, __t("lite.entity.city")).required(__t("lite.entity.city")),
                    entityTypeId: Yup.array().of(Yup.string()).min(1, __t("lite.entity.entity_type")).required(__t("lite.entity.entity_type")),
                    facilityTypeId: Yup.array().of(Yup.string()).min(1, __t("lite.entity.facility_type")).required(__t("lite.entity.facility_type")),
                })}
                enableReinitialize={true}
                onSubmit={this.formSubmit}
            >
                {({ values, setFieldValue, errors }) => {
                    return <Form>
                        <Row className='filter-form'>
                            <FormGroup className='col-lg-3'>
                                <Field
                                    component={CreatableSelect}
                                    name="entityId"
                                    className="form-control selectbox"
                                    options={
                                        this.props.sortedEntityListDropDown
                                    }
                                    onChange={opt => {
                                        setFieldValue("entityId", opt?.value)
                                    }}
                                    value={this.props.sortedEntityListDropDown?.filter(
                                        option => values?.entityId == option.value
                                    )}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={true}
                                    components={{ Option, animatedComponents }}
                                    key="entityId"

                                />
                                {
                                    errors.entityId && <small className='error'>{errors.entityId}</small>
                                }
                                <Label className="form-label">
                                    {__t('form_labels.lite_entity.entity_setup.entity_name')}
                                </Label>
                            </FormGroup>
                            <FormGroup className='col-lg-3'>
                                <Field
                                    component={CustomMultiSelect}
                                    name="stateId[]"
                                    className="form-control selectbox"
                                    options={
                                        this.props.sortedStateListDropdown
                                    }
                                    onChange={opt => {
                                        let selectedValues = (opt) ? opt.map(x => x.value) : []
                                        setFieldValue(
                                            'stateId',
                                            selectedValues
                                        );
                                        setFieldValue(
                                            'cityId',
                                            []
                                        );
                                        this.getCityListByState(selectedValues);
                                    }}
                                    value={this.props.sortedStateListDropdown?.filter(
                                        option =>
                                            values.stateId?.includes(option.value)
                                    )}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    allowSelectAll={true}
                                    components={{ Option, MultiValue, animatedComponents }}
                                    key="stateId"
                                />
                                {
                                    errors.stateId && <small className='error'>{errors.stateId}</small>
                                }
                                <Label className="form-label">
                                    {__t('form_labels.lite_entity.entity_setup.state')}
                                </Label>
                            </FormGroup>
                            <FormGroup className='col-lg-3'>
                                <Field
                                    component={CustomMultiSelect}
                                    name="cityId[]"
                                    className="form-control selectbox"
                                    options={
                                        this.props.sortedCityListDropdown
                                    }
                                    onChange={opt => {
                                        let selectedValues = (opt) ? opt.map(x => x.value) : []
                                        setFieldValue(
                                            'cityId',
                                            selectedValues
                                        );
                                    }}
                                    value={this.props.sortedCityListDropdown?.filter(
                                        option => values.cityId?.includes(option.value)
                                    )}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    allowSelectAll={true}
                                    components={{ Option, MultiValue, animatedComponents }}
                                    key="cityId"
                                />
                                {
                                    errors.cityId && <small className='error'>{errors.cityId}</small>
                                }
                                <Label className="form-label">
                                    {__t('form_labels.lite_entity.entity_setup.city')}
                                </Label>
                            </FormGroup>

                            <FormGroup className='col-lg-3'>
                                <Field
                                    component={CreatableSelect}
                                    name="managementUser"
                                    className="form-control selectbox"
                                    options={
                                        this.props.sortedEntityListDropDown
                                    }
                                    onChange={opt => {
                                        setFieldValue("managementUser", opt?.value)
                                    }}
                                    value={this.props.sortedEntityListDropDown?.filter(
                                        option => values?.entityId == option.value
                                    )}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={true}
                                    components={{ Option, animatedComponents }}
                                    key="managementUser"

                                />
                                {
                                    errors.managementUser && <small className='error'>{errors.managementUser}</small>
                                }
                                <Label className="form-label">
                                    {__t('form_labels.lite_entity.entity_setup.management_user')}
                                </Label>
                            </FormGroup>
                            <FormGroup className="col-lg-3 sticky_label">
                                <Field
                                    component={
                                        DatePicker
                                    }
                                    isClearable={true}
                                    className="form-control"
                                    name="financial_year_start"
                                    onChange={(
                                        date
                                    ) => {
                                        console.log("date==>", date)
                                        setFieldValue(
                                            'financial_year_start',
                                            date
                                        );
                                    }}
                                    onlyMonthPicker
                                    hideYear
                                    format="MMMM"
                                    onFocus={(e) =>
                                        e.target.blur()
                                    }
                                ></Field>
                                {
                                    errors.financial_year_start && <small className='error'>{errors.financial_year_start}</small>
                                }
                                <Label className="form-label">
                                    {__t('form_labels.lite_entity.entity_setup.financial_year_start')}
                                </Label>
                            </FormGroup>
                            <FormGroup className="col-lg-3 sticky_label">
                                <Field
                                    component={
                                        DatePicker
                                    }
                                    isClearable={true}
                                    className="form-control"
                                    name="financial_year_end"
                                    onChange={(
                                        date
                                    ) => {
                                        setFieldValue(
                                            'financial_year_end',
                                            date
                                        );
                                    }}
                                    onlyMonthPicker
                                    hideYear
                                    format="MMMM"
                                    onFocus={(e) =>
                                        e.target.blur()
                                    }
                                ></Field>
                                {
                                    errors.financial_year_end && <small className='error'>{errors.financial_year_end}</small>
                                }
                                <Label className="form-label">
                                    {__t('form_labels.lite_entity.entity_setup.financial_year_end')}
                                </Label>
                            </FormGroup>

                            <FormGroup className='col-lg-3'>
                                <Field
                                    component={CustomMultiSelect}
                                    name="entityType[]"
                                    className="form-control selectbox"
                                    options={
                                        this.props.sortedEntityTypeListDropDown
                                    }
                                    onChange={opt => {
                                        let entityTypeId =
                                            opt
                                                ? opt.map(
                                                    (x) =>
                                                        x.value
                                                )
                                                : [];
                                        setFieldValue(
                                            'entityTypeId',
                                            entityTypeId
                                        );
                                    }}
                                    value={this.props.sortedEntityTypeListDropDown?.filter(
                                        option => values?.entityTypeId?.includes(option.value)
                                    )}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    allowSelectAll={true}
                                    components={{ Option, MultiValue, animatedComponents }}
                                    key="entityTypeId"
                                />
                                {
                                    errors.entityTypeId && <small className='error'>{errors.entityTypeId}</small>
                                }
                                <Label className="form-label">
                                    {__t('form_labels.lite_entity.entity_setup.entity_type')}
                                </Label>
                            </FormGroup>
                            <FormGroup className='col-lg-3'>
                                <Field
                                    component={CustomMultiSelect}
                                    name="facilityType[]"
                                    className="form-control selectbox"
                                    options={
                                        this.props.sortedFacilityTypeListDropDown
                                    }
                                    onChange={opt => {
                                        let facilityTypeId =
                                            opt
                                                ? opt.map(
                                                    (x) =>
                                                        x.value
                                                )
                                                : [];
                                        setFieldValue(
                                            'facilityTypeId',
                                            facilityTypeId
                                        );
                                    }}
                                    value={this.props.sortedFacilityTypeListDropDown?.filter(
                                        option => values?.facilityTypeId?.includes(option.value)
                                    )}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    allowSelectAll={true}
                                    components={{ Option, MultiValue, animatedComponents }}
                                    key="facilityTypeId"
                                />
                                {
                                    errors.facilityTypeId && <small className='error'>{errors.facilityTypeId}</small>
                                }
                                <Label className="form-label">
                                    {__t('form_labels.lite_entity.entity_setup.facility_type')}
                                </Label>
                            </FormGroup>
                            <FormGroup className='col-lg-3'>
                                <Field
                                    component={CustomMultiSelect}
                                    name="industryId[]"
                                    className="form-control selectbox"
                                    options={
                                        this.props.sortedIndustryListDropdown
                                    }
                                    onChange={opt => {
                                        let industryId =
                                            opt
                                                ? opt.map(
                                                    (x) =>
                                                        x.value
                                                )
                                                : [];
                                        setFieldValue(
                                            'industryId',
                                            industryId
                                        );
                                        setFieldValue(
                                            'subIndustryId',
                                            ''
                                        );
                                        if (industryId != '') {
                                            this.props.getSubIndustryListByIndustryRequest(industryId)
                                        } else {
                                            this.setState({
                                                sortedSubIndustryListDropdown: []
                                            })
                                        }
                                    }}
                                    value={this.props.sortedIndustryListDropdown?.filter(
                                        option => values?.industryId?.includes(option.value)
                                    )}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    allowSelectAll={true}
                                    components={{ Option, MultiValue, animatedComponents }}
                                    key="industryId"
                                />
                                {
                                    errors.industryId && <small className='error'>{errors.industryId}</small>
                                }

                                <Label className="form-label">
                                    {__t('form_labels.lite_entity.entity_setup.industry')}
                                </Label>
                            </FormGroup>
                            <FormGroup className='col-lg-3'>
                                <Field
                                    component={CustomMultiSelect}
                                    name="subIndustryId[]"
                                    className="form-control selectbox"
                                    options={
                                        this.state.sortedSubIndustryListDropdown
                                    }
                                    onChange={opt => {
                                        let subIndustry = opt
                                            ? opt.map(
                                                (x) =>
                                                    x.value
                                            )
                                            : [];
                                        setFieldValue(
                                            'subIndustryId',
                                            subIndustry
                                        );
                                    }}
                                    value={this.state.sortedSubIndustryListDropdown?.filter(
                                        option => values?.subIndustryId?.includes(option.value)
                                    )}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    allowSelectAll={true}
                                    components={{ Option, MultiValue, animatedComponents }}
                                    key="subIndustryId"
                                />
                                {
                                    errors.subIndustryId && <small className='error'>{errors.subIndustryId}</small>
                                }
                                <Label className="form-label">
                                    {__t('form_labels.lite_entity.entity_setup.sub_industry')}
                                </Label>
                            </FormGroup>
                            <Button color='primary' className='mx-auto d-block mb-5'>{__t("form_labels.lite_entity.submit")}</Button>
                        </Row>
                    </Form>
                }}
            </Formik>

            <div>
                <p>{__t("form_labels.lite_entity.entity_setup.configure_entity")}</p>
                <div>
                    {[1, 2, 3, 4, 5].map(() => {
                        return <div>
                            Entity Card
                        </div>
                    })}
                </div>
            </div>
        </div>
    }
}


const mapStatetoProps = createStructuredSelector({
    sortedStateListDropdown: selectSortedStateList(),
    sortedCityListDropdown: selectSortedCityList(),
    sortedIndustryListDropdown: selectSortedIndustryList(),
    sortedSubIndustryListDropdown: selectSortedSubIndustryList(),
    sortedEntityTypeListDropDown: selectSortedEntityTypeList(),
    sortedFacilityTypeListDropDown: selectSortedFacilityTypeList(),
    sortedEntityListDropDown: selectSortedEntityList(),
});
const withConnect = connect(mapStatetoProps, {
    getLiteCountriesEntityRequest,
    getIndustryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getAllEntityTypeListRequest,
    getAllFacilityTypeListRequest,
    getEntityListByCompanyGroupIdRequest,
});
export default compose(
    withConnect,
    withLiteCountriesListReducer,
    withLiteCountriesListSaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withIndustryReducer,
    withIndustrySaga,
    withSubIndustryReducer,
    withSubIndustrySaga,
    withEntityReducer,
    withEntitySaga,
    withLawReducer,
    withLawSaga,
    withRouter
)(LiteEntityAddModule);
