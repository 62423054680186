import React, { Component } from 'react';
import Layout from './components/Layout/';
import { compose } from 'redux';
import {
    withRouter,
    Route,
    Switch,
    BrowserRouter as Router,
    Redirect,
} from 'react-router-dom';

import routes from './routes';
import './custom.css';
// import './App.scss';
import 'animate.css';

// Get all Auth methods
import { getValueByKey, isUserAuthenticated } from './helpers/authUtils';
import * as authConstants from 'constants/authConstants';
import Error404 from 'app/Error404';
import 'react-circular-progressbar/dist/styles.css';
import NotAllowedInIframe from 'app/NotAllowedInIframe';
import { ALLOWED_IP } from 'helpers/axiosHelper';

function withLayout(WrappedComponent, path) {
    // ...and returns another component...
    return class extends React.Component {
        render() {
            return (
                <Layout routePath={path}>
                    <WrappedComponent></WrappedComponent>
                </Layout>
            );
        }
    };
}

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showIframeMessage: false
        };
    }

    componentDidMount() {
        let element = document.getElementById('root');
        element.setAttribute("style", "display: none");
        // Dynamically import the SCSS file
        let themeMode = getValueByKey(authConstants.THEME_MODE);
        var rootElement = document.getElementById('root');
        rootElement.setAttribute("data-theme", themeMode);
        rootElement.setAttribute("class", themeMode);
        if (themeMode === 'dark') {
            import('./App-dark.scss').then(() => {
                // console.log('CSS dark file has been loaded!');
            }).catch((error) => {
                console.error('Error loading CSS file:', error);
            });
        } else {
            import('./App.scss').then(() => {
                // console.log('CSS light file has been loaded!');
            }).catch((error) => {
                console.error('Error loading CSS file:', error);
            });
        }
        setTimeout(() => {
            let element = document.getElementById('root');
            element.setAttribute("style", "display: block");
        }, 1000)
        try {
            if (
                !ALLOWED_IP.includes(
                    window.parent.location.hostname,
                )
            ) {
                throw new Error()
            }
        } catch (e) {
            this.setState({
                showIframeMessage: true
            })
        }
    }
    render() {
        const PrivateRoute = ({ component: MainComponent, ...rest }) => {
            return <Route
                {...rest}
                render={(props) =>
                    isUserAuthenticated() === true &&
                        getValueByKey(authConstants.SET_NEW_PASSWORD) ===
                        'false' ? (
                        <Redirect to="/set-new-password" />
                    ) : isUserAuthenticated() === true &&
                        getValueByKey(authConstants.SET_NEW_PASSWORD) ===
                        'true' ? (
                        <MainComponent {...props} />
                    ) : (
                        <Redirect to="/logout" />
                    )
                }
            />
        };

        return (
            <React.Fragment>
                {
                    this.state.showIframeMessage ? <NotAllowedInIframe /> :
                        <Router>
                            <Switch>
                                {routes.map((route, idx) =>
                                    route.ispublic ? (
                                        <Route
                                            path={route.path}
                                            component={route.component}
                                            key={idx}
                                            exact
                                        />
                                    ) : (
                                        <PrivateRoute
                                            path={route.path}
                                            component={withLayout(
                                                route.component,
                                                route.path
                                            )}
                                            key={idx}
                                        />
                                    )
                                )}
                                <Route exact path="/">
                                    <Redirect to="/login" />
                                </Route>
                                <Route path="*" component={Error404} />
                            </Switch>
                        </Router>
                }
            </React.Fragment>
        );
    }
}
export default compose(withRouter)(App);
