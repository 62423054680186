import React, { Component } from 'react'

class QuestionMaster extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return <div>
            QuestionMaster
        </div>
    }
}

export default QuestionMaster;