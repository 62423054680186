import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'helpers/injectReducer';
import injectSaga from 'helpers/injectSaga';
import {
    getDashboardDataByRoleRequest,
    getDashboardMDetailsDataRequest,
    getDashboardManagementDetailsDataRequest,
    getDashboardDetailsDataRequest,
    getDashboardAdminDetailsDataRequest,
    getDashboardDepartmentTaskByEntityRequest,
    getDashboardDataMonthWiseByRoleRequest,
    getDashboardDataForRiskRequest,
    getTaskStatusListRequest
} from 'store/actions';
import dashboardReducer from 'store/Dashboard/reducer';
import DashboardSaga from 'store/Dashboard/saga';
import SuperAdminIndex from './SuperAdminIndex.js';
import EntityTeamIndex from './EntityTeamIndex.js';
import { getValueByKey } from 'helpers/authUtils.js';
import * as authConstants from 'constants/authConstants';
import { isManagementRole } from 'helpers/projectUtils';
import {
    ROLE_COMPANY_GROUP_ADMIN,
    ROLE_CONSULTANT_TEAM,
    ROLE_ENTITY_ADMIN,
    ROLE_ENTITY_TEAM,
    ROLE_FUNCTIONAL_HEAD,
    ROLE_GLOBAL_COMPLIANCE_UPDATER,
    ROLE_IT_ADMIN,
    ROLE_MANAGEMENT_TEAM,
    ROLE_SUPER_ADMIN,
    ROLE_TENANT_ADMIN,
} from 'constants/databaseConstants.js';
import { makeDashboardSelectField } from 'store/Dashboard/selector.js';
import ItAdminIndex from './ItAdminIndex.js';
import ConsultantTeamIndex from './ConsultantTeamIndex.js';
import AdminIndex from './AdminIndex.js';
import HODIndex from './HODIndex.js';
import GCMIndex from './GCMIndex.js';
import TenantAdminIndex from './TenantAdminIndex.js';
import ManagementIndex from './ManagementIndex.js';
import taskReducer from 'store/Task/reducer';
import taskSaga from 'store/Task/saga';
import { selectSortedTaskStatusList } from 'store/Task/selector.js';

const taskKey = 'task';
const withTaskReducer = injectReducer({
    key: taskKey,
    reducer: taskReducer,
});
const withTaskSaga = injectSaga({ key: taskKey, saga: taskSaga });

const dashboardKey = 'dashboard';
const withDashboardReducer = injectReducer({
    key: dashboardKey,
    reducer: dashboardReducer,
});
const withDashboardSaga = injectSaga({
    key: dashboardKey,
    saga: DashboardSaga,
});

const initialDashboardSearchDataState = () => {
    return {
        countryId: 0,
        stateId: '',
        cityId: '',
        role: '',
        date: '',
    };
};
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedLoginUserRole: parseInt(
                getValueByKey(authConstants.CURRENT_ROLE)
            ),
            apiResponseData: {},
            dashboardDetailsData: {},
            dashboardAdminDetailsData: {},
            dashboardMDetailsData: {},
            dashboardManagementDetailsData: {},
            dashboardDepartmentTaskByEntityData: {},
            dashboardDataForRiskResponse: {},
            taskStatusList: []
        };
    }
    getInitialDashboardByRole = (clickableData = {}) => {
        let filterValues = {
            ...initialDashboardSearchDataState(),
            isManagementUser: isManagementRole(),
        };
        let values = {
            clickableType: '',
            clickableTypeData: {
                lawCategoryId: '',
                departmentId: '',
                statusType: '',
            },
        };
        if (clickableData.clickableType !== '') {
            filterValues.clickableType = clickableData.clickableType;
            filterValues.clickableTypeData = clickableData.clickableTypeData;
            values = {
                ...values,
                clickableType: clickableData.clickableType,
                clickableTypeData: clickableData.clickableTypeData,
            };
        }
        this.props.getDashboardDataByRoleRequest(
            this.state.selectedLoginUserRole,
            {
                ...values,
                currentRole: this.state.selectedLoginUserRole,
                filter: { ...filterValues, stateWise: false },
            }
        );
    };
    componentDidMount() {
        if (this.state.selectedLoginUserRole == '') {
            window.location.replace('/coming-soon');
        }
        this.getInitialDashboardByRole();
        if (
            [ROLE_SUPER_ADMIN].includes(this.state.selectedLoginUserRole) ===
            true
        ) {
            this.props.getDashboardDetailsDataRequest({
                filter: {
                    ...initialDashboardSearchDataState(),
                    isManagementUser: isManagementRole(),
                },
            });
        }
        this.props.getTaskStatusListRequest();
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.dashboardApiResponse &&
            nextprops.dashboardApiResponse !== this.props.dashboardApiResponse
        ) {
            this.setState({
                apiResponseData: nextprops.dashboardApiResponse.data,
            });
        }

        if (
            nextprops.dashboardDetailsResponse &&
            nextprops.dashboardDetailsResponse !==
            this.props.dashboardDetailsResponse
        ) {
            this.setState({
                dashboardDetailsData: nextprops.dashboardDetailsResponse.data,
            });
        }

        if (
            nextprops.dashboardAdminDetailsResponse &&
            nextprops.dashboardAdminDetailsResponse !==
            this.props.dashboardAdminDetailsResponse
        ) {
            this.setState({
                dashboardAdminDetailsData:
                    nextprops.dashboardAdminDetailsResponse.data,
            });
        }
        if (
            nextprops.dashboardMDetailsResponse &&
            nextprops.dashboardMDetailsResponse !==
            this.props.dashboardMDetailsResponse
        ) {
            this.setState({
                dashboardMDetailsData:
                    nextprops.dashboardMDetailsResponse.data,
            });
        }
        if (
            nextprops.dashboardManagementDetailsResponse &&
            nextprops.dashboardManagementDetailsResponse !==
            this.props.dashboardManagementDetailsResponse
        ) {
            this.setState({
                dashboardManagementDetailsData:
                    nextprops.dashboardManagementDetailsResponse.data,
            });
        }
        if (
            nextprops.dashboardDepartmentTaskByEntity &&
            nextprops.dashboardDepartmentTaskByEntity !==
            this.props.dashboardDepartmentTaskByEntity
        ) {
            this.setState({
                dashboardDepartmentTaskByEntityData:
                    nextprops.dashboardDepartmentTaskByEntity.data,
            });
        }

        if (
            nextprops.sortedTaskStatusListDropdown &&
            nextprops.sortedTaskStatusListDropdown !==
            this.props.sortedTaskStatusListDropdown
        ) {
            this.setState({
                taskStatusList: nextprops.sortedTaskStatusListDropdown
            })
        }

    }
    getDashboardData = (countryId, role, regionWise) => {
        return this.props.getDashboardDataByRoleRequest(
            this.state.selectedLoginUserRole,
            {
                filter: {
                    countryId: countryId,
                    role: role,
                    stateWise: regionWise,
                },
            }
        );
    }

    getDashboardDataForManagement = (countryId, role, regionWise, requestBody) => {
        return this.props.getDashboardDataByRoleRequest(
            this.state.selectedLoginUserRole,
            {
                ...requestBody,
                filter: {
                    ...requestBody.filter,
                    countryId: countryId,
                    role: role,
                    stateWise: regionWise,
                }
            }
        );
    }

    render() {
        return (
            <React.Fragment>
                {[ROLE_SUPER_ADMIN].includes(
                    this.state.selectedLoginUserRole
                ) === true && (
                        <SuperAdminIndex
                            apiResponseData={this.state.apiResponseData}
                            dashboardDetailsResponse={
                                this.state.dashboardDetailsData
                            }
                            countryChangeEvent={(countryId, role, regionWise) =>
                                this.getDashboardData(countryId, role, regionWise)
                            }
                            dashboardDetailsFilter={(values) =>
                                this.props.getDashboardDetailsDataRequest({
                                    filter: {
                                        ...values,
                                        isManagementUser: isManagementRole(),
                                    },
                                })
                            }
                        />
                    )}

                {[ROLE_IT_ADMIN].includes(this.state.selectedLoginUserRole) && (
                    <ItAdminIndex
                        apiResponseData={this.state.apiResponseData}
                    />
                )}
                {[ROLE_GLOBAL_COMPLIANCE_UPDATER].includes(
                    this.state.selectedLoginUserRole
                ) === true && (
                        <GCMIndex
                            getDataByClickableType={(clickableData) =>
                                this.getInitialDashboardByRole(clickableData)
                            }
                            entityRoleDashboardRes={this.state.apiResponseData}
                            dashboardFilter={(values) =>
                                this.props.getDashboardDataByRoleRequest(
                                    this.state.selectedLoginUserRole,
                                    {
                                        filter: {
                                            ...values,
                                        },
                                    }
                                )
                            }
                        />
                    )}

                {[ROLE_TENANT_ADMIN].includes(
                    this.state.selectedLoginUserRole
                ) && (
                        <TenantAdminIndex
                            apiResponseData={this.state.apiResponseData}
                            getDataByClickableType={(clickableData) =>
                                this.getInitialDashboardByRole(clickableData)
                            }
                            countryChangeEvent={(countryId, role, regionWise) =>
                                this.getDashboardData(countryId, role, regionWise)
                            }
                        />
                    )}

                {[ROLE_COMPANY_GROUP_ADMIN, ROLE_ENTITY_ADMIN].includes(
                    this.state.selectedLoginUserRole
                ) && (
                        <AdminIndex
                            apiResponseData={this.state.apiResponseData}
                            dashboardFilter={(values) =>
                                this.props.getDashboardDataByRoleRequest(
                                    this.state.selectedLoginUserRole,
                                    {
                                        filter: {
                                            ...values,
                                        },
                                    }
                                )
                            }                            
                            getDataByClickableType={(clickableData) =>
                                this.getInitialDashboardByRole(clickableData)
                            }
                        />
                    )}
                {[ROLE_MANAGEMENT_TEAM].includes(
                    this.state.selectedLoginUserRole
                ) && (
                        //new
                        <ManagementIndex
                            apiResponseData={this.state.apiResponseData}
                            countryChangeEvent={(countryId, regionWise, requestBody) =>
                                this.getDashboardDataForManagement(countryId, ROLE_MANAGEMENT_TEAM, regionWise, requestBody)
                            }
                            dashboardMDetailsResponse={
                                this.state.dashboardMDetailsData
                            }
                            dashboardDetailsResponse={
                                this.state.dashboardManagementDetailsData
                            }
                            getDataByClickableType={(clickableData) =>
                                this.getInitialDashboardByRole(clickableData)
                            }
                            dashboardMDetails={(data) =>
                                this.props.getDashboardMDetailsDataRequest(
                                    {
                                        ...data,
                                        currentRole:
                                            this.state.selectedLoginUserRole,
                                    }
                                )
                            }
                            dashboardManagementDetails={(data) =>
                                this.props.getDashboardManagementDetailsDataRequest(
                                    {
                                        ...data,
                                        currentRole:
                                            this.state.selectedLoginUserRole,
                                    }
                                )
                            }
                            dashboardFilter={(values, apiData, entityId) => {
                                this.props.getDashboardMDetailsDataRequest(
                                    {
                                        ...apiData,
                                        currentRole:
                                            this.state.selectedLoginUserRole,
                                        filter: {
                                            ...values,
                                        },
                                    }
                                );
                                this.props.getDashboardDataForRiskRequest(this.state.selectedLoginUserRole,
                                    {
                                        topMainRole: ROLE_MANAGEMENT_TEAM,
                                        currentRole: this.state.selectedLoginUserRole,
                                        filter: {
                                            ...values,
                                            isManagementUser: 1
                                        },
                                    })
                                this.props.getDashboardDepartmentTaskByEntityRequest(
                                    entityId,
                                    {
                                        currentRole:
                                            this.state.selectedLoginUserRole,
                                        filter: {
                                            ...values,
                                            isManagementUser: 1
                                        }
                                    }
                                );
                            }}
                            // dashboardMonthWiseByRole={(filterValues) => {
                            //     this.props.getDashboardDataMonthWiseByRoleRequest(this.state.selectedLoginUserRole,
                            //         {
                            //             topMainRole: ROLE_MANAGEMENT_TEAM,
                            //             currentRole:
                            //                 this.state.selectedLoginUserRole,
                            //             filter: filterValues,
                            //         })
                            // }}
                            dashboardDepartmentTaskByEntity={(entityId, data) => {
                                this.props.getDashboardDepartmentTaskByEntityRequest(
                                    entityId,
                                    { ...data }
                                );
                            }}
                            dashboardDepartmentTaskByEntityResponse={
                                this.state.dashboardDepartmentTaskByEntityData
                            }
                            // monthWiseResponseData={this.props.monthWiseResponseData}
                            dashboardDataForOverall={(filterValues) => {
                                this.props.getDashboardDataForRiskRequest(this.state.selectedLoginUserRole,
                                    {
                                        topMainRole: ROLE_MANAGEMENT_TEAM,
                                        currentRole: this.state.selectedLoginUserRole,
                                        filter: {
                                            ...filterValues,
                                            isManagementUser: 1
                                        },
                                    })
                            }}
                            dashboardDataForRiskResponse={this.props.dashboardDataForRiskResponse}
                            taskStatusList={this.state.taskStatusList}
                        />
                    )}

                {[ROLE_FUNCTIONAL_HEAD].includes(
                    this.state.selectedLoginUserRole
                ) && (
                        <HODIndex
                            apiResponseData={this.state.apiResponseData}
                            getDataByClickableType={(clickableData) =>
                                this.getInitialDashboardByRole(clickableData)
                            }
                            dashboardFilter={(values, chartData) =>
                                this.props.getDashboardDataByRoleRequest(
                                    this.state.selectedLoginUserRole,
                                    {
                                        ...chartData,
                                        filter: {
                                            ...values,
                                            isManagementUser:
                                                isManagementRole() &&
                                                [ROLE_FUNCTIONAL_HEAD].includes(
                                                    this.state.selectedLoginUserRole
                                                ),
                                        },
                                    }
                                )
                            }
                            dashboardDataForOverall={(filterValues) => {
                                this.props.getDashboardDataForRiskRequest(this.state.selectedLoginUserRole,
                                    {
                                        topMainRole: ROLE_FUNCTIONAL_HEAD,
                                        currentRole: this.state.selectedLoginUserRole,
                                        filter: {
                                            ...filterValues,
                                            isManagementUser:
                                                isManagementRole() &&
                                                [ROLE_FUNCTIONAL_HEAD].includes(
                                                    this.state.selectedLoginUserRole
                                                ),
                                        },
                                    })
                            }}
                            dashboardDataForRiskResponse={this.props.dashboardDataForRiskResponse}
                            taskStatusList={this.state.taskStatusList}
                        />
                    )}

                {/* {[ROLE_CONSULTANT_TEAM].includes(
                    this.state.selectedLoginUserRole
                ) && (
                        <ConsultantTeamIndex
                            apiResponseData={this.state.apiResponseData}
                        />
                    )} */}
                {[ROLE_CONSULTANT_TEAM].includes(
                    this.state.selectedLoginUserRole
                ) && (
                        <ConsultantTeamIndex
                            entityRoleDashboardRes={this.state.apiResponseData}
                            dashboardFilter={(values) =>
                                this.props.getDashboardDataByRoleRequest(
                                    this.state.selectedLoginUserRole,
                                    {
                                        filter: {
                                            ...values,
                                            isManagementUser:
                                                isManagementRole() &&
                                                [
                                                    ROLE_COMPANY_GROUP_ADMIN,
                                                    ROLE_ENTITY_ADMIN,
                                                    ROLE_MANAGEMENT_TEAM,
                                                ].includes(
                                                    this.state.selectedLoginUserRole
                                                ),
                                        },
                                    }
                                )
                            }
                            dashboardMonthWiseByRole={(filterValues) => {
                                this.props.getDashboardDataMonthWiseByRoleRequest(this.state.selectedLoginUserRole,
                                    {
                                        topMainRole: ROLE_CONSULTANT_TEAM,
                                        currentRole: this.state.selectedLoginUserRole,
                                        filter: filterValues,
                                    })
                            }}
                            dashboardDataForOverall={(filterValues) => {
                                this.props.getDashboardDataForRiskRequest(this.state.selectedLoginUserRole,
                                    {
                                        topMainRole: ROLE_CONSULTANT_TEAM,
                                        currentRole: this.state.selectedLoginUserRole,
                                        filter: filterValues,
                                    })
                            }}
                            monthWiseResponseData={this.props.monthWiseResponseData}
                            dashboardDataForRiskResponse={this.props.dashboardDataForRiskResponse}
                            taskStatusList={this.state.taskStatusList}
                        />
                    )}

                {[ROLE_ENTITY_TEAM].includes(
                    this.state.selectedLoginUserRole
                ) && (
                        <EntityTeamIndex
                            entityRoleDashboardRes={this.state.apiResponseData}
                            dashboardFilter={(values) =>
                                this.props.getDashboardDataByRoleRequest(
                                    this.state.selectedLoginUserRole,
                                    {
                                        filter: {
                                            ...values,
                                            isManagementUser:
                                                isManagementRole() &&
                                                [
                                                    ROLE_COMPANY_GROUP_ADMIN,
                                                    ROLE_ENTITY_ADMIN,
                                                    ROLE_MANAGEMENT_TEAM,
                                                ].includes(
                                                    this.state.selectedLoginUserRole
                                                ),
                                        },
                                    }
                                )
                            }
                            dashboardMonthWiseByRole={(filterValues) => {
                                this.props.getDashboardDataMonthWiseByRoleRequest(this.state.selectedLoginUserRole,
                                    {
                                        topMainRole: ROLE_ENTITY_TEAM,
                                        currentRole: this.state.selectedLoginUserRole,
                                        filter: filterValues,
                                    })
                            }}
                            dashboardDataForOverall={(filterValues) => {
                                this.props.getDashboardDataForRiskRequest(this.state.selectedLoginUserRole,
                                    {
                                        topMainRole: ROLE_ENTITY_TEAM,
                                        currentRole: this.state.selectedLoginUserRole,
                                        filter: filterValues,
                                    })
                            }}
                            monthWiseResponseData={this.props.monthWiseResponseData}
                            dashboardDataForRiskResponse={this.props.dashboardDataForRiskResponse}
                            taskStatusList={this.state.taskStatusList}
                        />
                    )}
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    dashboardApiResponse: makeDashboardSelectField('dashboardApiResponse'),
    dashboardDetailsResponse: makeDashboardSelectField(
        'dashboardDetailsResponse'
    ),
    dashboardAdminDetailsResponse: makeDashboardSelectField(
        'dashboardAdminDetailsResponse'
    ),
    dashboardMDetailsResponse: makeDashboardSelectField('dashboardMDetailsResponse'),
    dashboardManagementDetailsResponse: makeDashboardSelectField('dashboardManagementDetailsResponse'),
    dashboardDepartmentTaskByEntity: makeDashboardSelectField('dashboardDepartmentTaskByEntity'),
    monthWiseResponseData: makeDashboardSelectField('monthWiseResponseData'),
    dashboardDataForRiskResponse: makeDashboardSelectField('dashboardDataForRiskResponse'),
    sortedTaskStatusListDropdown: selectSortedTaskStatusList(),
});
const withConnect = connect(mapStatetoProps, {
    getDashboardDataByRoleRequest,
    getDashboardDetailsDataRequest,
    getDashboardAdminDetailsDataRequest,
    getDashboardMDetailsDataRequest,
    getDashboardManagementDetailsDataRequest,
    getDashboardDepartmentTaskByEntityRequest,
    getDashboardDataMonthWiseByRoleRequest,
    getDashboardDataForRiskRequest,
    getTaskStatusListRequest
});
export default compose(
    withConnect,
    withRouter,
    withTaskReducer,
    withTaskSaga,
    withDashboardReducer,
    withDashboardSaga
)(Index);
