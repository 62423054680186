import { CLIENT_REGISTRATION_LIST_REQUEST, CLIENT_REGISTRATION_LIST_RESPONSE, CLIENT_REGISTRATION_RESEND_EMAIL_REQUEST, CLIENT_REGISTRATION_RESEND_EMAIL_RESPONSE, CLIENT_REGISTRATION_STATUS_UPDATE_CLIENT_REQUEST, CLIENT_REGISTRATION_STATUS_UPDATE_CLIENT_RESPONSE, LITE_COUNTRIES_LIST_AS_PER_PLAN_REQUEST, LITE_COUNTRIES_LIST_AS_PER_PLAN_RESPONSE, LITE_COUNTRIES_WISE_ENTITY_ADD_REQUEST, LITE_COUNTRIES_WISE_ENTITY_ADD_RESPONSE, LITE_COUNTRIES_WISE_ENTITY_LIST_REQUEST, LITE_COUNTRIES_WISE_ENTITY_LIST_RESPONSE } from "./actionTypes";

export const getClientRegistrationRequest = data => {
    return {
        type: CLIENT_REGISTRATION_LIST_REQUEST,
        payload: { data }
    };
};

export const getClientRegistrationResponse = data => {
    return {
        type: CLIENT_REGISTRATION_LIST_RESPONSE,
        payload: { data },
    };
};


export const putResendEmailRequest = (data) => {
    return {
        type: CLIENT_REGISTRATION_RESEND_EMAIL_REQUEST,
        payload: { data },
    };
}
export const putResendEmailResponse = (data) => {
    return {
        type: CLIENT_REGISTRATION_RESEND_EMAIL_RESPONSE,
        payload: { data },
    };
}

export const putStatusUpdateClientRequest = (data) => {
    return {
        type: CLIENT_REGISTRATION_STATUS_UPDATE_CLIENT_REQUEST,
        payload: { data },
    };
}
export const putStatusUpdateClientResponse = (data) => {
    return {
        type: CLIENT_REGISTRATION_STATUS_UPDATE_CLIENT_RESPONSE,
        payload: { data },
    };
}


export const getLiteCountriesListRequest = (data) => {
    return {
        type: LITE_COUNTRIES_LIST_AS_PER_PLAN_REQUEST,
        payload: { data },
    };
}

export const getLiteCountriesListResponse = (data) => {
    return {
        type: LITE_COUNTRIES_LIST_AS_PER_PLAN_RESPONSE,
        payload: { data },
    };
}


export const getLiteCountriesEntityRequest = (data) => {
    return {
        type: LITE_COUNTRIES_WISE_ENTITY_LIST_REQUEST,
        payload: { data },
    };
}

export const getLiteCountriesEntityResponse = (data) => {
    return {
        type: LITE_COUNTRIES_WISE_ENTITY_LIST_RESPONSE,
        payload: { data },
    };
}


export const addLiteEntityRequest = (data) => {
    return {
        type: LITE_COUNTRIES_WISE_ENTITY_ADD_REQUEST,
        payload: { data },
    };
}

export const addLiteEntityResponse = (data) => {
    return {
        type: LITE_COUNTRIES_WISE_ENTITY_ADD_RESPONSE,
        payload: { data },
    };
}
