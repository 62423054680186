import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import injectReducer from 'helpers/injectReducer';
import injectSaga from 'helpers/injectSaga';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { getLiteCountriesListRequest } from 'store/Lite/actions';
import RegisteredAccount from 'store/Lite/reducer';
import LiteRegistrationSaga from 'store/Lite/saga';
import spain_flag from './../../images/flags/spain_flag.jpg';
import { Link } from 'react-router-dom/cjs/react-router-dom';


const countriesList = 'RegisteredAccount';
const withLiteCountriesListReducer = injectReducer({
    key: countriesList,
    reducer: RegisteredAccount,
});
const withLiteCountriesListSaga = injectSaga({ key: countriesList, saga: LiteRegistrationSaga });
class LiteCountryListModule extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.props.getLiteCountriesListRequest();
    }
    render() {
        return <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <CommonBreadcrum type={'countries-list'} />
                    </Col>
                </Row>
                <div className="page-header">
                    <h5 className="with-back-btn mt-0 d-flex">
                        <a
                            href={() => false}
                            className="dripicons-arrow-thin-left"
                            onClick={() => {
                                this.props.history.push('/home');
                            }}
                        >
                            {}
                        </a>{' '}
                        Countries
                    </h5>
                </div>
                <ListGroup horizontal className='country_list'>
                    <ListGroupItem className='p-0 text-center'>
                        <Link className="p-3 px-4 d-inline-block">
                            <img src={spain_flag} alt='Spain Flag' />
                            <h5 className='my-2 font-weight-normal text-primary'>Spain</h5>
                            <p className='m-0'><span>5/5</span> Entities</p>
                        </Link>
                    </ListGroupItem>
                    <ListGroupItem className='p-0 text-center'>
                        <Link className="p-3 px-4 d-inline-block">
                            <img src={spain_flag} alt='Spain Flag' />
                            <h5 className='my-2 font-weight-normal text-primary'>Spain</h5>
                            <p className='m-0'><span>5/5</span> Entities</p>
                        </Link>
                    </ListGroupItem>
                    <ListGroupItem className='p-0 text-center'>
                        <Link className="p-3 px-4 d-inline-block">
                            <img src={spain_flag} alt='Spain Flag' />
                            <h5 className='my-2 font-weight-normal text-primary'>Spain</h5>
                            <p className='m-0'><span>5/5</span> Entities</p>
                        </Link>
                    </ListGroupItem>
                    <ListGroupItem className='p-0 text-center'>
                        <Link className="p-3 px-4 d-inline-block">
                            <img src={spain_flag} alt='Spain Flag' />
                            <h5 className='my-2 font-weight-normal text-primary'>Spain</h5>
                            <p className='m-0'><span>5/5</span> Entities</p>
                        </Link>
                    </ListGroupItem>
                </ListGroup>
            </Container>
        </>
    }
}

const mapStatetoProps = createStructuredSelector({

});
const withConnect = connect(mapStatetoProps, {
    getLiteCountriesListRequest
});
export default compose(
    withConnect,
    withLiteCountriesListReducer,
    withLiteCountriesListSaga,
    withRouter
)(LiteCountryListModule);