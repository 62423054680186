import CustomisedReactTableWithPagination from 'components/Application/CustomisedReactTableWithPagination';
import React, { Component } from 'react'
import { Card, CardBody, Col } from 'reactstrap';

const AccountDetailsHeader = [
    {
        Header: 'Account Name',
        accessor: 'account_name',
    },
    {
        Header: 'Company Name',
        accessor: 'company_full_name',
    },
    {
        Header: 'country',
        accessor: 'country',
    },
    {
        Header: 'Client Account Status',
        accessor: 'client_account_status',
    },

    {
        Header: 'Plan Details',
        accessor: 'plan_details',
    },

    {
        Header: 'Payment Status',
        accessor: 'payment_status',
    },
    {
        Header: 'Status of EL',
        accessor: 'status_of_el',
    },
    {
        Header: 'Action',
        TransHeader: 'common.action',
        accessor: 'actions',
        disableSortBy: true,
    },
];

const extradata = [
    {
        account_name: "HCS",
        company_full_name: "Neel",
        country: "neel@yopmail.com",
        client_account_status: "26-09-2024 16:26:30",
        plan_details: "9876543210",
        payment_status: "HoneyComb Solutions",
        status_of_el: "https://www.yopmail.com",
        actions: 1
    },
    {
        account_name: "HCS",
        company_full_name: "Neel",
        country: "neel@yopmail.com",
        client_account_status: "26-09-2024 16:26:30",
        plan_details: "9876543210",
        payment_status: "HoneyComb Solutions",
        status_of_el: "https://www.yopmail.com",
        actions: 1
    },
    {
        account_name: "HCS",
        company_full_name: "Neel",
        country: "neel@yopmail.com",
        client_account_status: "26-09-2024 16:26:30",
        plan_details: "9876543210",
        payment_status: "HoneyComb Solutions",
        status_of_el: "https://www.yopmail.com",
        actions: 1
    },
    {
        account_name: "HCS",
        company_full_name: "Neel",
        country: "neel@yopmail.com",
        client_account_status: "26-09-2024 16:26:30",
        plan_details: "9876543210",
        payment_status: "HoneyComb Solutions",
        status_of_el: "https://www.yopmail.com",
        actions: 1
    },
    {
        account_name: "HCS",
        company_full_name: "Neel",
        country: "neel@yopmail.com",
        client_account_status: "26-09-2024 16:26:30",
        plan_details: "9876543210",
        payment_status: "HoneyComb Solutions",
        status_of_el: "https://www.yopmail.com",
        actions: 1
    },
]
class AccountDetails extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const data = {
            columns: AccountDetailsHeader,
            rows: extradata,
        };
        return <div>
            <Col lg="12">
                <Card>
                    <CardBody className="custom_table table-responsive">
                        <CustomisedReactTableWithPagination
                            className="table table-bordered table-striped"
                            displayEntries={
                                true
                            }
                            searching={true}
                            currentPage={
                                1
                            }
                            pageCount={
                                10
                            }
                            tableData={data}
                        />
                    </CardBody>
                </Card>
            </Col>
        </div>
    }
}

export default AccountDetails