
import moment from "moment";
export const uploadColumns = [
    {
        label: 'Batch Code',
        field: 'batchId',
    },
    {
        label: 'File Name',
        field: 'fileName',
    },
    {
        label: 'Status',
        field: 'status',
    },
    {
        label: 'Uploaded On',
        field: 'uploadedOn',
    },
    {
        label: 'Uploaded By',
        field: 'uploadedBy',
    },
    {
        label: 'Action',
        field: 'actions',
    },
];

export const initialApprovalFormDataState = () => {
    return {
        userId: '',
        remarks: '',
    };
};

export const legalUpdateAllHeaders = [
    {
        label: 'Jurisdiction: Federal/National/Central',
        field: 'country',
    },
    {
        label: 'State/Province/Autonomous Region/Prefecture/Canton',
        field: 'state',
    },
    {
        label: 'Local Body: Municipality/County/City',
        field: 'city',
    },
    {
        label: 'Date of Update',
        field: 'dateOfUpdate',
    },
    {
        label: 'Name of Act',
        field: 'lawName',
    },
    {
        label: 'Law ID',
        field: 'lawCode',
    },
    {
        label: 'Compliance ID',
        field: 'complianceCode',
    },
    {
        label: 'Area of Law',
        field: 'lawCategory',
    },
    {
        label: 'Industry',
        field: 'industry',
    },
    {
        label: 'Sub-Industry',
        field: 'subIndustry',
    },
    {
        label: 'Reference No',
        field: 'referenceNo',
    },
    {
        label: 'Reference No',
        field: 'referenceNo',
    },
    {
        label: 'Document type',
        field: 'documentType',
    },
    {
        label: 'Parent Update Type',
        field: 'updateType',
    },
    {
        label: 'Sub Update Category',
        field: 'subUpdateType',
    },
    {
        label: 'Update Summary',
        field: 'updateSummary',
    },
    {
        label: 'Particulars of Update',
        field: 'particularsOfUpdate',
    },
    {
        label: 'Impact of Update',
        field: 'impactOfUpdate',
    },
    {
        label: 'Impacted Section',
        field: 'impactedSections',
    },
    {
        label: 'Links of Update',
        field: 'links',
    },
    {
        label: 'Source of Update',
        field: 'source',
    },
    {
        label: 'Effective Date of Notification',
        field: 'effectiveDate',
    },
    {
        label: 'Date of Publication',
        field: 'dateOfPublication',
    },
    {
        label: 'Document Attached',
        field: 'isDocumentAttached',
    },
    {
        label: 'Compliance Classification',
        field: 'complianceCategory',
    },
    {
        label: 'Name of Business Function Impacted',
        field: 'nameOfBusinessFunction',
    },
    {
        label: 'Update Priority',
        field: 'updatePriority',
    },
    {
        label: 'Legal Update Status',
        field: 'legalUpdateStatus',
    },
    {
        label: 'Approver User',
        field: 'approverUser',
    },
    {
        label: 'Updater User',
        field: 'updaterUser',
    },
];

export const initialLegalUpdateDocumentsState = () => {
    return {
        documentFile: '',
    };
};

export const initialLegalUpdatesState = () => {
    return {
        countryId: '',
        stateId: '',
        cityId: '',
        lawCategoryId: '',
        industryId: '',
        subIndustryId: '',
        dateOfUpdate: '',
        lawId: '',
        lawComplianceId: '',
        referenceNo: '',
        documentUrl: '',
        documentTypeId: '',
        updateTypeId: '',
        subUpdateTypeId: '',
        updateSummary: '',
        particularsOfUpdate: '',
        impactOfUpdate: '',
        lawSectionId: '',
        links: '',
        source: '',
        effectiveDate: '',
        dateOfPublication: '',
        isDocumentAttached: '',
        complianceCategoryId: '',
        legalUpdateStatusId: '',
        approverUserId: '',
        updaterUserId: '',
        remarks: '',
        departmentId: '', //nameOfBusinessFunction
        updatePriority: '',
        localAttornyValidation: '',
    };
};

export const checkTwoDayFromEffectiveDate = (efffectiveDate) => {
    const effectiveDateMoment = moment(efffectiveDate, "DD-MM-YYYY");
    const currentDate = moment(moment().format("DD-MM-YYYY"), "DD-MM-YYYY");
    const daysDifference = currentDate.diff(effectiveDateMoment, 'days');
    const subtractTwoDayFromEffective = effectiveDateMoment.subtract("2", "d");
    return {
        daysDifference,
        subtractTwoDayFromEffective
    }
}